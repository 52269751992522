import { IDropdownOption, IDropdownProps } from "@fluentui/react";
import { useContext, useEffect } from "react";

import { MeasureSelectionComponentRegular } from "./MeasureSelectionComponentRegular";
import { buildSubareaOptions, OptionWithOptionalInfostatOwl } from "./shared";
import { MeasureSelectionComponentSurvey } from "./MeasureSelectionComponentSurvey";
import { defined } from "../../../../../../lib/core/defined";
import { voidFunc } from "../../../../../../lib/core/voidFunc";
import {
  Categories,
  defaultSubject,
  prepareCategoryParts,
} from "../../../../../../lib/domain/categories";
import {
  DataSelection,
  MeasureSelection,
  MeasureSelectionSurvey,
} from "../../../../../../lib/domain/selections/definitions";
import { Button } from "../../../../../../components/Button";
import { FoldoutPanel } from "../../../../../../components/FoldoutPanel";
import {
  DocLink,
  DocLinksHandler,
} from "../../../../../../lib/application/links";
import { ExternalLink } from "../../../../../../components/ExternalLink";
import { AlertBox } from "../../../../../../components/AlertBox";
import { useHttpRequestWithStatus } from "../../../../../../lib/application/hooks/useRequestWithStatus";
import { getCategoriesWithCache } from "../../../../../../lib/application/requests/common_requests";
import { TimeResolution } from "../../../../../../lib/domain/time";
import { eitherDisplayer } from "../../../../../../components/wrappers/either";
import { NotReadyHttpErrNotification } from "../../../../../../components/Loading";
import {
  measureSelectionIsRegular,
  measureSelectionIsSurvey,
  measureSelectionIsSurveyString,
} from "../../../../../../lib/domain/measure";
import { StyledDropdown } from "../../card_general/StyledDropdown";
import { ShowDraftDataContext } from "../../../../../../lib/application/contexts";

type DataSelectionSetter = (s: DataSelection) => void;

type SingleDataSelectionProps = {
  header?: string;
  selectSingle?: boolean;
  dataSelection: DataSelection;
  groupingSelectionMode: boolean;
  isGroupingSelection: boolean;
  setDataSelection: DataSelectionSetter;
  autofill: boolean;
  disabled?: boolean;
  dataframeMode?: boolean;
  maxCategoriesResolution?: TimeResolution;
};
export function SingleDataSelection(props: SingleDataSelectionProps) {
  const {
    groupingSelectionMode,
    isGroupingSelection,
    maxCategoriesResolution,
  } = props;
  const [status, getCategories] = useHttpRequestWithStatus(
    getCategoriesWithCache
  );

  const adminShowDraftData = useContext(ShowDraftDataContext);
  useEffect(() => {
    getCategories(
      !groupingSelectionMode
        ? undefined
        : isGroupingSelection
        ? "groupingOnly"
        : "groupableOnly",
      maxCategoriesResolution ?? TimeResolution.maximal(),
      adminShowDraftData
    );
  }, [
    adminShowDraftData,
    getCategories,
    groupingSelectionMode,
    isGroupingSelection,
    maxCategoriesResolution,
  ]);

  return <SingleDataSelectionLoader item={status} {...props} />;
}

export function SingleDataSelectionInner(
  props: SingleDataSelectionProps & { item: Categories }
) {
  const { item: categories, dataSelection, setDataSelection } = props;
  const measureSelection = dataSelection.measureSelection;
  const path = dataSelection.subjectPath;
  const area = path[0] as string | undefined;
  const subarea = path[1] as string | undefined;
  const subject = path[2] as string | undefined;

  const { areas, subareas, subjects } = prepareCategoryParts(
    [area, subarea, subject],
    categories
  );

  const subareasInputProps: IDropdownProps =
    defined(area) && defined(subareas)
      ? ({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange: (_unused: any, item: IDropdownOption<any> | undefined) => {
            if (defined(item)) {
              const subjectPath = props.autofill
                ? defaultSubject(
                    [area, item.key as string, undefined],
                    categories
                  )
                : [area, item.key as string];
              if (!defined(subjectPath)) {
                return;
              }
              setDataSelection({
                ...dataSelection,
                measureSelection: undefined,
                subjectPath: subjectPath,
              });
            }
          },
          options: buildSubareaOptions(subareas),
          onRenderTitle: (options: IDropdownOption[]): JSX.Element => {
            const option = options[0]; // This is a single-select field
            return (
              <OptionWithOptionalInfostatOwl
                option={option}
              ></OptionWithOptionalInfostatOwl>
            );
          },
          onRenderOption: (option: IDropdownOption): JSX.Element => {
            return (
              <OptionWithOptionalInfostatOwl
                option={option}
              ></OptionWithOptionalInfostatOwl>
            );
          },
        } as IDropdownProps)
      : ({ onChange: voidFunc, options: [] } as IDropdownProps);

  const subjectsInputProps =
    defined(area) && defined(subarea) && defined(subjects)
      ? {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange: (_unused: any, item: IDropdownOption<any> | undefined) => {
            if (defined(item)) {
              setDataSelection({
                ...dataSelection,
                measureSelection: undefined,
                subjectPath: [area, subarea, item.key as string],
              });
            }
          },
          options: subjects.map((s) => ({ key: s, text: s })),
        }
      : { onChange: voidFunc, options: [] };

  return (
    <div className="data-selection">
      {defined(props.header) && <h3>{props.header}</h3>}
      <StyledDropdown
        disabled={props.disabled}
        className="item area-dropdown"
        selectedKey={area}
        onChange={(_, item) => {
          if (defined(item)) {
            const subjectPath = props.autofill
              ? defaultSubject(
                  [item.key as string, undefined, undefined],
                  categories
                )
              : [item.key as string];
            if (!defined(subjectPath)) {
              return;
            }
            setDataSelection({
              ...dataSelection,
              measureSelection: undefined,
              subjectPath: subjectPath,
            });
          }
        }}
        dropdownWidth="auto"
        label="Område"
        options={areas.map((area) => ({ key: area, text: area }))}
      />
      <StyledDropdown
        disabled={props.disabled || !defined(area)}
        className="item subarea-dropdown"
        selectedKey={subarea ?? ""}
        dropdownWidth="auto"
        label="Delområde"
        {...subareasInputProps}
      />
      <StyledDropdown
        disabled={props.disabled || !defined(subjects)}
        className="item subject-dropdown"
        selectedKey={subject ?? ""}
        dropdownWidth="auto"
        label="Ämne"
        {...subjectsInputProps}
      />

      {measureSelection && measureSelectionIsRegular(measureSelection) && (
        <MeasureSelectionComponentRegular
          selectSingle={props.selectSingle}
          isGroupingSelection={props.isGroupingSelection}
          measureSelection={measureSelection}
          setMeasureSelection={(measureSelection: MeasureSelection) =>
            setDataSelection({ ...dataSelection, measureSelection })
          }
        ></MeasureSelectionComponentRegular>
      )}
      {measureSelection &&
        (measureSelectionIsSurvey(measureSelection) ||
          measureSelectionIsSurveyString(measureSelection)) && (
          <MeasureSelectionComponentSurvey
            dataframeMode={props.dataframeMode}
            isGroupingSelection={props.isGroupingSelection}
            measureSelection={measureSelection as MeasureSelectionSurvey}
            setMeasureSelection={(measureSelection: MeasureSelection) =>
              setDataSelection({ ...dataSelection, measureSelection })
            }
          ></MeasureSelectionComponentSurvey>
        )}
    </div>
  );
}

const SingleDataSelectionLoader = eitherDisplayer(
  NotReadyHttpErrNotification,
  SingleDataSelectionInner
);

interface GroupDataButtonProps {
  cardStateId: string;
  primaryDataSelection: DataSelection;
  switchToGroupingMode: () => void;
}

export function AdvancedOptionsSection(props: GroupDataButtonProps) {
  const primaryMeasureSelection = props.primaryDataSelection.measureSelection;
  const measureHasMunicipalData =
    primaryMeasureSelection?.measure.geo_types.includes("municipal") ?? false;

  const enableGrouping =
    defined(primaryMeasureSelection) &&
    ((measureHasMunicipalData &&
      primaryMeasureSelection?.valueType !== "category") ||
      measureSelectionIsSurvey(primaryMeasureSelection));

  const btn = (
    <div>
      <Button
        title="Sambandsanalys"
        onClick={() => props.switchToGroupingMode()}
        disabled={!enableGrouping}
      ></Button>
    </div>
  );
  return (
    <section className="advanced-options">
      <FoldoutPanel title="Avancerat">
        <>
          <p>
            Med <strong>Sambandsanalys</strong> kan man på kommunnivå undersöka
            sambandet mellan olika mått.{" "}
            <ExternalLink
              link={DocLinksHandler.getLinkPath(DocLink.SharingHelp)}
              text="Se vidare"
            ></ExternalLink>
            .
          </p>
          {enableGrouping ? (
            btn
          ) : (
            <span>
              <div>
                <AlertBox className="section" intent="warning">
                  <span>
                    Sambandsanalys är bara tillgängligt för mått på kommunnivå
                  </span>
                </AlertBox>
              </div>
              {btn}
            </span>
          )}
        </>
      </FoldoutPanel>
    </section>
  );
}
