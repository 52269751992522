import {
  Record,
  Number as NumberRT,
  Optional,
  Array as ArrayRT,
  String as StringRT,
  Static,
  Partial,
  Boolean as BooleanRT,
  Union,
  Unknown,
  Literal,
  Tuple,
  Dictionary,
  Undefined,
} from "runtypes";
import {
  DateRangeRawRT,
  SelectedDimensionsV2RT,
} from "../../../../../domain/measure/definitions";

import {
  DataValueTypeAnyRT,
  DataValueTypeMicroGeoRT,
} from "../../../../../infra/api_responses/dataset";
import {
  CardColorSchemeLinkRT,
  ColorSchemeContainerRT,
} from "../../document-style/definitions";
import { ReportMetaPersistentSharingRT } from "../../document-meta/definitions";
import { DataOutputSettingsPartialRT } from "../../document-core/DataOutputSettings";

const PythonCardSelectedViewRT = Union(
  Literal("create-dataframe"),
  Literal("execute-code")
);

export const GeoTypeRT = Union(
  Literal("country"),
  Literal("nuts1"),
  Literal("nuts2"),
  Literal("nuts3"),
  Literal("municipal")
);

export const GeoItemRT = Record({
  geocode: StringRT,
  label: StringRT,
  type: GeoTypeRT,
});

const RegionResponseItemRT = GeoItemRT.And(
  Partial({
    nuts1: StringRT,
    nuts2: StringRT,
    nuts3: StringRT,
  })
);

const GeoSelectionsRT = Dictionary(ArrayRT(RegionResponseItemRT), GeoTypeRT);

const PageBreakRT = Union(
  Literal("none"),
  Literal("before"),
  Literal("after"),
  Literal("both")
);
const DataOutputViewRT = Union(
  Literal("diagram"),
  Literal("table"),
  Literal("map"),
  Literal("info")
);

const MicroOutputTabRT = Union(
  Literal("map-select"),
  Literal("map-view"),
  Literal("table"),
  Literal("chart"),
  Literal("info")
);

const ThirdPartyDocCardSettingsRT = Record({
  showBreakdowns: Optional(BooleanRT),
  showGeoSelector: Optional(BooleanRT),
  showTimeline: Optional(BooleanRT),

  hideTabs: Optional(ArrayRT(DataOutputViewRT)),

  showToolbar: Optional(BooleanRT),
  // toolbar sub settings, valid only if showToolbar is true
  showToolbarSettings: Optional(BooleanRT),
  showToolbarDownload: Optional(BooleanRT),
});

const ThirdPartyMicroCardSettingsRT = Record({
  showBreakdowns: Optional(BooleanRT),
  showTimeline: Optional(BooleanRT),
  allowEditFilterParams: Optional(BooleanRT),

  hideTabs: Optional(ArrayRT(MicroOutputTabRT)),

  showToolbar: Optional(BooleanRT),
  // toolbar sub settings, valid only if showToolbar is true
  showToolbarSettings: Optional(BooleanRT),
  showToolbarDownload: Optional(BooleanRT),
});

export const StyleContainerGeoMicroRT = Record({
  styles: ArrayRT(
    Record({
      geometry: DataValueTypeMicroGeoRT,
      dataGroupId: StringRT,
      label: StringRT,
      fill: Optional(StringRT),
      border: Optional(StringRT),
    })
  ),
  id: StringRT,
});

const StyleContainerLinkGeoMicroRT = Record({
  cardId: StringRT,
  styleContainerId: StringRT,
});

const InfostatLngLatRT = Record({
  lat: NumberRT,
  lng: NumberRT,
});

const InfostatBoundingBoxRT = Record({
  sw: InfostatLngLatRT,
  ne: InfostatLngLatRT,
});

const MicroSettingsRT = Record({
  map: Record({
    showBorders: BooleanRT,
    showMapLabels: Optional(BooleanRT),
    hideGeoObjectsOutsideSelection: Optional(BooleanRT),
    mapSelectTool: StringRT,
    resultsOpacity: NumberRT,
    colorScheme: StringRT,
    zMinMaxAbsolute: NumberRT,
    localZRange: Optional(BooleanRT),
    bordersOnlyNoFill: Optional(BooleanRT),
    showDesoRegsoLabels: Optional(BooleanRT),
    showDesoRegsoValues: Optional(BooleanRT),
  }),
  dataOutputSettings: DataOutputSettingsPartialRT,
});

const GeoSelectionsMicroRT = Record({
  type: Literal("deso"),
  selected: ArrayRT(
    Record({
      type: Literal("deso"),
      props: Record({
        deso: StringRT,
        deso_label: StringRT,
        regso: StringRT,
        regso_label: StringRT,
      }),
    })
  ),
}).Or(
  Record({
    type: Literal("regso"),
    selected: ArrayRT(
      Record({
        type: Literal("regso"),
        props: Record({
          regso: StringRT,
          regso_label: StringRT,
        }),
      })
    ),
  })
);

export type GeoSelectionsMicroDto = Static<typeof GeoSelectionsMicroRT>;

const MicroDataFilterRT = Union(
  Record({
    type: Literal("gte"),
    value: NumberRT,
  }),
  Record({
    type: Literal("lte"),
    value: NumberRT,
  }),
  Record({
    type: Literal("interval"),
    gte: NumberRT,
    lte: NumberRT,
  }),
  Record({
    type: Literal("topX"),
    value: NumberRT,
  }),
  Record({
    type: Literal("topXPercent"),
    value: NumberRT,
  }),
  Record({
    type: Literal("bottomX"),
    value: NumberRT,
  }),
  Record({
    type: Literal("bottomXPercent"),
    value: NumberRT,
  }),
  Record({
    type: Literal("intervalPercent"),
    gte: NumberRT,
    lte: NumberRT,
  }),
  Record({
    type: Literal("belowAvg"),
  }),
  Record({
    type: Literal("aboveAvg"),
  })
);

const ComputedMeasurementTypeRT = Union(
  Literal("count_within_distance"),
  Literal("count_in_area"),
  Literal("distance_to_nearest")
);

const FilterSetRT = Record({
  type: Literal("simple-and-filter"),
  filters: ArrayRT(MicroDataFilterRT),
});

const MicroSubjectPath = ArrayRT(StringRT.Or(Undefined));
const SelectedDimensionsMicroRT = Dictionary(
  Optional(ArrayRT(NumberRT)),
  StringRT
);
const MicroSelectionPrimaryRT = Record({
  type: Literal("primary"),
  id: StringRT,
  measureId: Optional(NumberRT),
  subjectPath: MicroSubjectPath,
  timeSelection: Optional(Tuple(StringRT, StringRT)),
  selectedDimensions: SelectedDimensionsMicroRT,
  multiSelectEnabled: BooleanRT,
  computedMeasurementType: Optional(ComputedMeasurementTypeRT),
  selectedComputedVariables: Optional(Dictionary(NumberRT, StringRT)),
  filterSet: Optional(FilterSetRT),
});

const MeasureSelectionGeoMicroRT = Record({
  type: Literal("geo-micro"),
  id: StringRT,
  measureId: Optional(NumberRT),
  subjectPath: MicroSubjectPath,
  timeSelection: Optional(Tuple(StringRT, StringRT)),
  selectedDimensions: SelectedDimensionsMicroRT,
});

const DataSelectionMicroRT = Union(
  MicroSelectionPrimaryRT,
  MeasureSelectionGeoMicroRT
);
export type DataSelectionMicroDto = Static<typeof DataSelectionMicroRT>;

export const DataSelectionMinimalRT = Record({
  measureId: NumberRT,
  measureType: DataValueTypeAnyRT,
  breakdownSelection: SelectedDimensionsV2RT,
});
export type DataSelectionMinimal = Static<typeof DataSelectionMinimalRT>;

const commonCardRecord = Record({
  id: StringRT,
  label: StringRT,
  isEditing: BooleanRT,
  pageBreak: Optional(PageBreakRT),
  hideSpaceBefore: Optional(BooleanRT),
  hideSpaceAfter: Optional(BooleanRT),
});

export const dataCardRecord = commonCardRecord.And(
  Record({
    type: Literal("dataCard"),
    data: Record({
      thirdPartyDataCardSettings: Optional(ThirdPartyDocCardSettingsRT),
      selectedView: Optional(DataOutputViewRT),
      settings: DataOutputSettingsPartialRT,
      dataSelections: ArrayRT(DataSelectionMinimalRT),
    }).And(
      Partial({
        lockToLatestTime: BooleanRT,
        groupingSelection: DataSelectionMinimalRT,
        geoSelections: GeoSelectionsRT,
        timeSelection: DateRangeRawRT,
      })
    ),
  })
);

export const pythonCardRecord = commonCardRecord.And(
  Record({
    type: Literal("pythonCard"),
    data: Record({
      selectedView: PythonCardSelectedViewRT,
      pythonCode: Optional(StringRT),
      surveySelection: Optional(
        Record({
          timeSelection: Tuple(StringRT, StringRT),
          selection: DataSelectionMinimalRT,
        })
      ),
      columns: ArrayRT(
        Union(
          Record({
            type: Literal("stats"),
            columnName: StringRT,
            selection: DataSelectionMinimalRT,
            geoSelections: GeoSelectionsRT,
            timeSelection: Tuple(StringRT, StringRT),
          }),
          Record({
            type: Literal("micro"),
            columnName: StringRT,
            selection: DataSelectionMicroRT,
            timeSelection: Tuple(StringRT, StringRT),
          }),
          Record({
            type: Literal("user-defined"),
            columnName: StringRT,
            expression: StringRT,
          })
        )
      ),
    }),
  })
);

export const textCardRecord = commonCardRecord.And(
  Record({
    type: Literal("textCardSimple"),
    data: Optional(Record({ ops: Optional(ArrayRT(Unknown)) })),
  })
);

export const textCardCKRecord = commonCardRecord.And(
  Record({
    type: Literal("textCardCK"),
    data: StringRT,
  })
);

const FilterMeasureMicroRT = Record({
  id: StringRT,
  subjectPath: MicroSubjectPath,
  filterSet: Optional(FilterSetRT),
  measureSelection: Optional(
    Record({
      computedMeasurementType: Optional(ComputedMeasurementTypeRT),
      selectedComputedVariables: Optional(Dictionary(NumberRT, StringRT)),
      selectedDimensions: SelectedDimensionsMicroRT,
      measureId: NumberRT,
    })
  ),
});

export type FilterMeasureMicroDto = Static<typeof FilterMeasureMicroRT>;

export const microCardRecord = commonCardRecord.And(
  Record({
    type: Literal("microCard"),
    data: Record({
      settings: MicroSettingsRT,
      thirdPartyMicroCardSettings: Optional(ThirdPartyMicroCardSettingsRT),
      mapLocationBounds: InfostatBoundingBoxRT,
      selectedTab: Optional(StringRT),
      geoSelections: Optional(GeoSelectionsMicroRT),
      dataSelections: Optional(ArrayRT(DataSelectionMicroRT)),
      filterMeasures: ArrayRT(FilterMeasureMicroRT),
    }).And(
      Partial({
        lockToLatestTime: BooleanRT,
      })
    ),
  })
);

export const cardRecord = Union(
  dataCardRecord,
  textCardRecord,
  textCardCKRecord,
  microCardRecord,
  pythonCardRecord
);

export const WorkspaceV8RT = Record({
  workspaceVersion: NumberRT,
  state: Record({
    cards: ArrayRT(cardRecord),
    reportMeta: Optional(ReportMetaPersistentSharingRT),
    colorSchemes: ArrayRT(ColorSchemeContainerRT),
    cardColorSchemes: ArrayRT(CardColorSchemeLinkRT),
    styleContainersGeoMicro: Optional(ArrayRT(StyleContainerGeoMicroRT)),
    styleContainerLinksGeoMicro: Optional(
      ArrayRT(StyleContainerLinkGeoMicroRT)
    ),
  }),
});
export type WorkspaceV8 = Static<typeof WorkspaceV8RT>;
export type WorkspaceCardV8 = WorkspaceV8["state"]["cards"][0];
export type WorkspaceTextCardV8 = Static<typeof textCardRecord>;
export type WorkspaceDataCardV8 = Static<typeof dataCardRecord>;
export type WorkspacePythonCardV8 = Static<typeof pythonCardRecord>;
