import { AuthorizationParams } from "@auth0/auth0-react";
import { Literal, Static, Union } from "runtypes";
import { pathJoin } from "./lib/paths";

function getEnvVar(name: string): string {
  const fullName = "REACT_APP_" + name;
  const value = process.env[fullName];
  if (value === undefined) {
    throw new Error(`Env var ${fullName} not found`);
  }
  return value;
}

const appEnvRT = Union(Literal("dev"), Literal("prod"), Literal("local"));
export type AppEnvType = Static<typeof appEnvRT>;
export type AppMode = "infostat" | "embedded";

export const config = {
  packagedDocDomain: "share.infostat.se",
  appVersion: getEnvVar("APP_VERSION"),
  infostatSeLinks: {
    home: "https://infostat.se/",
    contact: "https://infostat.se/kontakt/",
    tutorial: "https://app.infostat.se/stats/links/ZZ8r9fvrwEVZgqANLWWSBP",
  },
  infostatSupportEmail: "support@infostat.se",
  /** When saving a document fails, a notice will be shown after a delay as specified here. */
  saveFailureNoticeDelayMs: 1000 * 60,
  defaultAppNoticeDurationMs: 15000,
  vanityPathMaxLength: 50,
  docTitleMaxLength: 100,
  cardTitleMaxLength: 100,
  valueLabelMaxLength: 100,
  statsMapChartsLimit: 20, // Max number of maps generated for a single stats dataset, shown in map tab
  maxNumDocCards: 50,
  sharingDomain: getEnvVar("SHARING_DOMAIN"),
  admin: {
    defaultLicenseCount: 10,
  },
  auth0: {
    domain: getEnvVar("AUTH0_DOMAIN"),
    clientId: getEnvVar("AUTH0_CLIENTID"),
  },
  geoFiles: {
    microTree: "/geo_info_local.json?v=2",
    country: "/d3-geo-se-country.geojson",
    nuts1: "/d3-geo-se-nuts1.geojson",
    nuts2: "/d3-geo-se-nuts2.geojson",
    nuts3: "/d3-geo-se-nuts3.geojson",
    lau2: "/d3-geo-se-lau2.geojson",
  },
  mapbox: {
    apiToken: getEnvVar("MAPBOX_API_KEY"),
    styleUrl: "mapbox://styles/erik-infostat/cl3k9y44x002t15odhfqvp4pq",
  },
  apis: {
    statsV1: {
      audience: getEnvVar("API_AUDIENCE"),
      url: pathJoin(getEnvVar("API_STATS_URL"), "v1"),
    },
    statsV2: {
      audience: getEnvVar("API_AUDIENCE"),
      url: pathJoin(getEnvVar("API_STATS_URL"), "v2"),
    },
    importV1: {
      audience: getEnvVar("API_AUDIENCE"),
      url: pathJoin(getEnvVar("API_IMPORT_URL"), "v1"),
    },
    importV2: {
      audience: getEnvVar("API_AUDIENCE"),
      url: pathJoin(getEnvVar("API_IMPORT_URL"), "v2"),
    },
  },
  appEnv: appEnvRT.check(getEnvVar("APP_ENV")),
  bugsnag: {
    apiKey: "17155497047566fe4ca6857474c4af9c",
  },
  tidioEmbedUrl: "https://code.tidio.co/xj7bqqenhqalhqkqjo0hbbq2vvfvisud.js",
  micro: {
    numSimultaneousCardsRendered: 5,
    maxNumBreakdowns: 5,
    maxNumFeaturesSelected: 10000,
    maxNumAreasForAddressExport: 500,
  },
  surveyString: {
    numDefaultRowsTable: 20,
  },
  appMode: "infostat" as AppMode,
  releaseId: undefined as undefined | string,
  storageKeys: {
    adminDraftMode: "admin-draft-mode",
    dataAdminMode: "data-admin-mode",
    dataCreatorRecentlySelectedStatsCategories:
      "data-creator-recently-selected-stats-categories",
    dataCreatorRecentlySelectedSurveyCategories:
      "data-creator-recently-selected-survey-categories",
    dataCreatorRecentStatsMeasures: "data-creator-recent-stats-measures",
  },
  locale: "sv-SE",
};

export const IS_PROD = config.appEnv === "prod";
export const IS_LOCAL = config.appEnv === "local";

export type Api = (typeof config)["apis"]["statsV1"];

export const auth0AuthorizationParams: AuthorizationParams = {
  redirect_uri: window.location.origin,
  audience: config.apis.statsV2.audience,
  scope: "read:current_user",
};

export function packagedDocLink(docId: string) {
  return `https://${config.packagedDocDomain}/docs/${docId}`;
}

/**
 * Feature flags
 */
export const Features = {};

export const DEFAULT_ANSWER_LABEL_SURVEY = "Svarsalternativ";

const startupTime = Date.now();
export function timeSinceStartup() {
  return Date.now() - startupTime;
}
