import { config } from "../../../../config";
import {
  AutoImportGroupListDtoRT,
  AutoImportLogListDtoRT,
  AutoImportScheduleListDtoRT,
  AutoImportSpecDto,
  AutoImportSpecsListDtoRT,
  SingleAutoImportSpecDto,
} from "../../../infra/api_responses/admin/import_management";
import { HttpResult } from "../../../infra/HttpResult";
import { authedRequest, decodedAuthedRequest } from "../shared";

export function listAutoImportSpecs(): Promise<
  HttpResult<AutoImportSpecDto[]>
> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/specs",
    undefined,
    "GET",
    AutoImportSpecsListDtoRT
  );
}

export function getAutoImportSpec(
  id: number
): Promise<HttpResult<SingleAutoImportSpecDto>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${id}`,
    undefined,
    "GET"
  );
}

export function getAutoImportLogs(specId: number) {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${specId}/logs`,
    undefined,
    "GET",
    AutoImportLogListDtoRT
  );
}

export function createAutoImportSpec(
  dataId: number,
  groupId: number,
  scheduleId: number,
  priority: number,
  script: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "admin/autoimport/specs",
    {
      auto_import_group_id: groupId,
      auto_import_schedule_id: scheduleId,
      data_id: dataId,
      priority: priority,
      script: script,
    },
    "POST"
  );
}

export function listAutoImportGroups() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/groups",
    undefined,
    "GET",
    AutoImportGroupListDtoRT
  );
}

export function listAutoImportSchedules() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/schedules",
    undefined,
    "GET",
    AutoImportScheduleListDtoRT
  );
}
export type PatchableAutoImportSpecField =
  | "auto_import_schedule_id"
  | "auto_import_spec_group_id"
  | "override_scheduled_time"
  | "override_scheduled_days"
  | "override_scheduled_months"
  | "data_id"
  | "priority"
  | "disabled"
  | "script";
export function patchAutoImportSpec(
  id: number,
  field: PatchableAutoImportSpecField,
  value: any
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${id}`,
    { [field]: value },
    "PATCH"
  );
}
