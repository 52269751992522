import { useMemo, useState } from "react";
import { nonEmptyString } from "../../core/nonEmptyString";
import { uniq } from "lodash";
import { defined } from "../../core/defined";

export function useEmailsInputState() {
  const [emailsRaw, setEmailsRaw] = useState<string>("");

  const currentEmails = useMemo(
    () =>
      uniq(
        emailsRaw
          .trim()
          .split(/[\s ,;]+/)
          .filter(nonEmptyString)
          .map((email) => email.trim())
      ),
    [emailsRaw]
  );

  const emailValidationMessage = useMemo(() => {
    if (currentEmails.length === 0) {
      return "Inga e-postadresser angivna";
    }
    const invalidEmail = currentEmails.find((email) => {
      if (!email.includes("@")) {
        return "Ogiltig e-postadress";
      }
    });
    if (defined(invalidEmail)) {
      return "Ogiltig e-postadress: " + invalidEmail;
    }
    return undefined;
  }, [currentEmails]);

  return {
    emailValidationMessage,
    emailsRaw,
    setEmailsRaw,
    currentEmails,
  };
}
