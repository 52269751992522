import _, { chain, sortBy } from "lodash";
import {
  DropdownMenuItemType,
  IDropdownOption,
  IRenderFunction,
} from "@fluentui/react";
import { useAuth0, User } from "@auth0/auth0-react";

import infostatIcon from "../../../../../../logo_icon_color.svg";
import { SELECT_ALL_LABEL } from "../../../../../../lib/application/menus";
import { defined } from "../../../../../../lib/core/defined";
import { MeasureSelection } from "../../../../../../lib/domain/selections/definitions";
import {
  DimensionV2Dto,
  DimensionValueV2Dto,
  SelectedDimensionsV2,
} from "../../../../../../lib/domain/measure/definitions";
import { FluentIcon } from "../../../../../../components/Icons";
import {
  deleteAlertRegistration,
  getAlertRegistrations,
  createMeasureAlertRegistration,
} from "../../../../../../lib/application/requests/common_requests";
import { useCallback, useContext, useMemo } from "react";
import { getClaimUserId } from "../../../../../../lib/application/auth/claims";
import { AppMessagesContext } from "../../../../../../lib/application/contexts";
import { logger } from "../../../../../../lib/infra/logging";
import { useLoadableHttpResource } from "../../../../../../lib/application/hooks/useLoadableResource";
import { getText } from "../../../../../../lib/application/strings";
import { classNames } from "../../../../../../lib/core/classNames";
import { StyledDropdown } from "../../card_general/StyledDropdown";
import { getApplicableDimensionValues } from "../../../../../../lib/domain/measure/breakdowns";
import { nonEmptyString } from "../../../../../../lib/core/nonEmptyString";

export const INFOSTAT_SURVEY_SUBAREA = "Infostats undersökningar";
const SELECT_ALL_KEY_NUMERIC = -1;
export const SELECT_AT_LEAST_1_OPTION = "Välj minst ett alternativ";

export function OptionWithOptionalInfostatOwl(props: {
  option: IDropdownOption;
}) {
  const option = props.option;
  return option.key === INFOSTAT_SURVEY_SUBAREA ? (
    <div className="dropdown-option-infostat-surveys">
      <img src={infostatIcon} className="infostat-logo" alt="logotyp" />
      <span>{option.text}</span>
    </div>
  ) : (
    <div>
      <span>{option.text}</span>
    </div>
  );
}

export function MeasureSelectionContainer(props: {
  children: JSX.Element;
  measureId: number;
}) {
  const { user } = useAuth0();
  return (
    <div className="item measure-selection-container">
      <div className="select">{props.children}</div>
      {defined(user) && (
        <MeasureAlert measureId={props.measureId} user={user} />
      )}
    </div>
  );
}

function MeasureAlert(props: { measureId: number; user: User }) {
  const { measureId, user } = props;
  const appMessages = useContext(AppMessagesContext);
  const userId = getClaimUserId(user);

  const loadAlerts = useCallback(() => getAlertRegistrations(userId), [userId]);
  const [measureAlertsLoadable, reloadMeasureAlerts] =
    useLoadableHttpResource(loadAlerts);
  const measureAlerts = measureAlertsLoadable.fold(
    () => undefined,
    (arg) => arg
  );
  if (!defined(measureAlerts)) {
    return null;
  }

  const existingRegistration = measureAlerts.measureRegistration(measureId);
  if (defined(existingRegistration)) {
    return (
      <FluentIcon
        className="alerts-active"
        onClick={() => {
          deleteAlertRegistration(userId, existingRegistration.id)
            .then(() => {
              reloadMeasureAlerts();
              appMessages?.add("success", getText("alerts-now-unregistered"));
            })
            .catch((err) => {
              logger.error(err);
              appMessages?.add("error", getText("alerts-could-not-unregister"));
            });
        }}
        title="Bevakning"
        size="md"
        name="notifications-updated"
      ></FluentIcon>
    );
  }

  return (
    <FluentIcon
      className="alerts-inactive"
      onClick={() => {
        createMeasureAlertRegistration(userId, props.measureId)
          .then(() => {
            reloadMeasureAlerts();
            appMessages?.add("success", getText("alerts-now-registered"));
          })
          .catch((err) => {
            logger.error(err);
            appMessages?.add("error", getText("alerts-could-not-register"));
          });
      }}
      title="Bevakning"
      size="md"
      name="notifications"
    ></FluentIcon>
  );
}

/**
 * Builds array of options, sorting the special infostat surveys subarea
 * last if it is available.
 */
export function buildSubareaOptions(labels: string[]): IDropdownOption[] {
  const options: IDropdownOption[] = [];
  let hasInfostatSurvey = false;
  for (const label of labels) {
    if (label === INFOSTAT_SURVEY_SUBAREA) {
      hasInfostatSurvey = true;
      continue;
    }
    options.push({ key: label, text: label });
  }
  if (hasInfostatSurvey) {
    options.push({
      key: "special-subarea-divider",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    });
    options.push({
      key: INFOSTAT_SURVEY_SUBAREA,
      text: INFOSTAT_SURVEY_SUBAREA,
    });
  }
  return options;
}

export function BreakdownSelectSingleV2(props: {
  dimension: DimensionV2Dto;
  // null = there is a parent dimension but no value is selected
  parentDimensionSelectedValueId?: null | number;
  selectedValueId?: number;
  setSelectedKey: (breakdownDimension: string, key?: number) => void;
  noneSelectedError?: string;
  disabled?: boolean;
  noneSelectedLabel?: string;
}) {
  const { dimension, selectedValueId, setSelectedKey } = props;
  const labelText = dimension.label;
  const labelCode = dimension.data_column;

  const options = useMemo(() => {
    if (props.parentDimensionSelectedValueId === null) {
      return [];
    }

    const valuesAllUnsorted =
      dimension.values?.filter((v) => {
        return defined(props.parentDimensionSelectedValueId)
          ? v.parent_id === props.parentDimensionSelectedValueId
          : true;
      }) ?? [];
    const valuesAll = sortBy(valuesAllUnsorted, [dimensionValueSortOrder]);
    return valuesAll.map((b) => ({
      text: b.label,
      key: b.id,
    }));
  }, [dimension.values, props.parentDimensionSelectedValueId]);

  return (
    <StyledDropdown
      disabled={props.disabled || options.length === 0}
      errorMessage={
        !defined(selectedValueId) ? props.noneSelectedError : undefined
      }
      key={labelCode}
      className="item"
      label={labelText}
      dropdownWidth="auto"
      placeholder={props.noneSelectedLabel}
      selectedKey={selectedValueId}
      onChange={(_, item) => {
        if (!defined(item)) {
          setSelectedKey(dimension.data_column);
          return;
        }
        setSelectedKey(dimension.data_column, item.key as number);
      }}
      options={options}
    />
  );
}

export function BreakdownSelectMultiV2(props: {
  dimension: DimensionV2Dto;
  /**
   * Used to disambiguate labels in hierarchical category trees.
   * The user needs to see which parent(s) a label comes from.
   **/
  lookupPath?: (valueId: number) => string | undefined;
  parentDimensionSelectedValueIds?: number[];
  selectedBreakdowns: SelectedDimensionsV2;
  clearBreakdownValues: (breakdownDimension: string) => void;
  setBreakdowns: (
    breakdownDimension: string,
    keys: number[],
    selected: boolean
  ) => void;
  noneSelectedError?: string;
  disabled?: boolean;
  noneSelectedLabel?: string;
  className?: string;
}) {
  const { dimension, selectedBreakdowns, setBreakdowns } = props;
  const labelText = dimension.label;

  const selectedKeys = useMemo(
    () => selectedBreakdowns[dimension.data_column] ?? [],
    [dimension.data_column, selectedBreakdowns]
  );

  const valuesAll = useMemo(() => {
    const valuesAllUnsorted = getApplicableDimensionValues(
      dimension,
      props.parentDimensionSelectedValueIds
    );
    return sortBy(valuesAllUnsorted, [dimensionValueSortOrder]);
  }, [dimension, props.parentDimensionSelectedValueIds]);
  const valuesAllLookup = useMemo(() => {
    if (!defined(props.lookupPath)) {
      return {};
    }
    const lookup: { [key: number]: DimensionValueV2Dto } = {};
    for (const value of valuesAll) {
      lookup[value.id] = value;
    }
    return lookup;
  }, [props.lookupPath, valuesAll]);

  const allSelected = useMemo(() => {
    return valuesAll.every((value) => selectedKeys.includes(value.id));
  }, [selectedKeys, valuesAll]);
  const selectedKeysSortedOrAll = useMemo(
    () => selectedKeys.concat(allSelected ? [SELECT_ALL_KEY_NUMERIC] : []),
    [selectedKeys, allSelected]
  );

  const getLabelString = useCallback(
    (baseLabel: string, id: number, labelIsUnique: boolean) => {
      if (labelIsUnique || !defined(props.lookupPath)) {
        return baseLabel;
      }
      const parent_id = valuesAllLookup[id]?.parent_id;
      const filler = defined(parent_id)
        ? props.lookupPath?.(parent_id)
        : undefined;
      return baseLabel + (nonEmptyString(filler) ? ` (${filler})` : "");
    },
    [props, valuesAllLookup]
  );

  const options = useMemo(() => {
    const labelCount: { [key: string]: number } = {};
    for (const value of valuesAll) {
      labelCount[value.label] = (labelCount[value.label] ?? 0) + 1;
    }
    const opts: IDropdownOption<any>[] = [
      { text: SELECT_ALL_LABEL, key: SELECT_ALL_KEY_NUMERIC },
    ];
    for (const value of valuesAll) {
      opts.push({
        text: getLabelString(
          value.label,
          value.id,
          labelCount[value.label] === 1
        ),
        key: value.id,
      });
    }

    return opts;
  }, [getLabelString, valuesAll]);

  const onRenderTitle: IRenderFunction<IDropdownOption<any>[]> | undefined =
    useCallback((p?: IDropdownOption<any>[]) => {
      return (
        <span>
          {chain(p ?? [])
            .filter((v) => v.key !== SELECT_ALL_KEY_NUMERIC)
            .map((v) => v.text)
            .value()
            .join(", ") ?? ""}
        </span>
      );
    }, []);

  const onChange = useCallback(
    (_: any, item: IDropdownOption<any> | undefined) => {
      if (!defined(item)) {
        return;
      }
      if (item.key === SELECT_ALL_KEY_NUMERIC) {
        if (allSelected) {
          props.clearBreakdownValues(dimension.data_column);
        } else {
          setBreakdowns(
            dimension.data_column,
            valuesAll.map((v) => v.id),
            !allSelected
          );
        }
      } else {
        setBreakdowns(
          dimension.data_column,
          [item.key as number],
          item.selected as boolean
        );
      }
    },
    [allSelected, dimension, props, setBreakdowns, valuesAll]
  );

  return (
    <StyledDropdown
      disabled={props.disabled}
      errorMessage={
        selectedKeys.length === 0 ? props.noneSelectedError : undefined
      }
      onRenderTitle={onRenderTitle}
      key={dimension.data_column}
      className={classNames(props.className, "item")}
      label={labelText}
      dropdownWidth="auto"
      placeholder={props.noneSelectedLabel}
      selectedKeys={selectedKeysSortedOrAll}
      onChange={onChange}
      multiSelect
      options={options}
    />
  );
}

export function measureSelectionToAvailableOptions(
  measureSelection: MeasureSelection
): IDropdownOption<any>[] {
  return _.chain(measureSelection.available)
    .filter((m) => !m.retired)
    .map((m) => ({
      key: m.data_id,
      text: m.measure,
    }))
    .value();
}

export function dimensionValueSortOrder(item: DimensionValueV2Dto): number {
  if (item.sort_mode === "fixed_bottom") {
    return 100000 + item.sort_order;
  } else if (item.sort_mode === "fixed_top") {
    return -100000 + item.sort_order;
  }
  return item.sort_order;
}
