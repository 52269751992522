import { Separator } from "@fluentui/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import React, { useCallback, useContext, useMemo, useState } from "react";

import "./DataCard.scss";

import { DataOutput } from "./DataOutput";
import { CardContainer } from "../CardContainer";
import { TabContainer } from "../card_tabs/CardTab";
import { MetadataAdmin } from "../admin/MetadataAdmin";
import { GroupingSection } from "./GroupingSection";

import { SearchMain } from "../../SearchMain";
import {
  TabbedCardTabsContainer,
  TabbedCardTab,
  TabbedCardsContentContainer,
  TabbedCardMain,
} from "../../../../../components/Card";
import { wrapWithCheckedRenders } from "../../../../../components/CheckedRerender";
import { HorizontalDivider } from "../../../../../components/Divider";
import {
  InfostatDataAdminModeContext,
  AppMessagesContext,
  SharingInfoContext,
  ShowDraftDataContext,
  CardUpdateCountContext,
  GeographiesContext,
} from "../../../../../lib/application/contexts";
import { setDefaultMeasure } from "../../../../../lib/application/requests/admin/common_requests_admin";
import {
  DocCardStats,
  makeDataSelection,
} from "../../../../../lib/application/state/stats/document-core/core";
import { dataCardStateEqual } from "../../../../../lib/application/state/stats/document-core/eq";
import {
  singleDataCardQuery,
  groupingSelectionQuery,
  primarySelectionQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { defined } from "../../../../../lib/core/defined";
import { propertiesEqual } from "../../../../../lib/core/propertiesEqual";
import { Milliseconds } from "../../../../../lib/core/time";
import { Categories } from "../../../../../lib/domain/categories";
import { DataSelection } from "../../../../../lib/domain/selections/definitions";
import { TimeResolution } from "../../../../../lib/domain/time";
import { DataCardTab } from "../card_tabs/DataCardTab";
import { SingleDataSelection } from "./selections/DataCardSelection";
import { TimeSelect } from "../card_general/TimeSelect";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { RetiredMeasureWarning } from "./selections/RetiredMeasureWarning";
import { useChangePrimarySelection } from "../../../../../lib/application/state/actions/selections/useChangePrimarySelection";
import {
  PageBreakAfter,
  PageBreakBefore,
} from "../../../../../components/print/page_breaks";
import { GeographiesSerializable } from "../../../../../lib/domain/geography";
import { useStateTransition } from "../../../../../lib/application/hooks/useStateTransition";
import { useUpdateSelectionOnToggleDraftMode } from "../../../../../lib/application/state/actions/selections/shared/useUpdateSelectionOnToggleDraftMode";
import { useUpdateSelectionOnSettingsChange } from "../../../../../lib/application/state/actions/selections/shared/useUpdateSelectionOnSettingsChange";
import { config } from "../../../../../config";
import { useCreateSelectionFromSearchHit } from "../../../../../lib/application/state/actions/selections/useCreateSelectionFromSearchHit";
import { classNames } from "../../../../../lib/core/classNames";
import {
  useActivateLockToLatestTime,
  useChangeTime,
  useUnsetLockToLatestTime,
} from "../../../../../lib/application/state/actions/selections/useChangeTime";
import { useWrappedIncrementer } from "../../../../../lib/application/hooks/useIncrementer";
import { lastCardScrolledTo } from "../../../../../lib/application/stats/document_position";
import { reportMetaStateQuery } from "../../../../../lib/application/state/stats/document-meta/queries";
import { DataCardThirdPartyDoc } from "./DataCardThirdPartyDoc";
import { ThirdPartDocCardSettings } from "./ThirdPartyDocCardSettings";

const adminTabName = "admin_meta";

interface Props {
  categories: Categories;
  cardId: string;
  onDuplicateCard: (id: string) => void;
  removeCard: (id: string) => void;
  sharingInfo: ISharingInfo;
}

export function DataCard(props: Props) {
  const cardStateId = props.cardId;
  const card = useRecoilValue(singleDataCardQuery({ cardStateId }));
  const { isEditingCard: isEditing } = useCardEditMode(
    cardStateId,
    props.sharingInfo
  );

  const statsMeta = useRecoilValue(reportMetaStateQuery);
  const sharingInfo = useContext(SharingInfoContext);
  const updateCounter = useWrappedIncrementer();

  const geographies = useContext(GeographiesContext);
  if (!defined(geographies)) {
    return null;
  }

  if (!defined(sharingInfo)) {
    throw new Error("Sharing info not defined -- must not happen");
  }
  if (statsMeta?.thirdPartySharingDoc && !isEditing) {
    return (
      <CardUpdateCountContext.Provider value={updateCounter}>
        <DataCardThirdPartyDoc
          sharingInfo={sharingInfo.info}
          cardId={card.id}
          geographies={geographies}
        />
      </CardUpdateCountContext.Provider>
    );
  }

  return (
    <CardUpdateCountContext.Provider value={updateCounter}>
      <CheckedDataCardInner
        geographies={geographies}
        sharingInfo={sharingInfo.info}
        card={card}
        isEditing={isEditing}
        removeCard={props.removeCard}
        onDuplicateCard={props.onDuplicateCard}
      ></CheckedDataCardInner>
    </CardUpdateCountContext.Provider>
  );
}

interface InnerProps {
  [key: string]: unknown;
  geographies: GeographiesSerializable;
  sharingInfo: ISharingInfo;
  card: DocCardStats;
  isEditing: boolean;
  removeCard: (id: string) => void;
  onDuplicateCard: (id: string) => void;
}

const CheckedDataCardInner = wrapWithCheckedRenders<InnerProps>(
  DataCardInner,
  (prev, next) => {
    const handlersEqual = propertiesEqual(prev, next, ["onDuplicateCard"]);
    return dataCardStateEqual(prev.card, next.card) && handlersEqual;
  }
);

export function DataCardInner(props: InnerProps) {
  const { card, sharingInfo, onDuplicateCard, removeCard, geographies } = props;

  const groupingSelection = card.data.groupingSelection;
  const dataAdminMode = useContext(InfostatDataAdminModeContext);
  const [isRemovingCard, setIsRemovingCard] = useState(false);
  const primarySelection = useRecoilValue(
    primarySelectionQuery({ cardStateId: card.id })
  );
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const cardTabs = useMemo(() => {
    const cardTabsHolder = ["data"];

    if (dataAdminMode) {
      cardTabsHolder.push(adminTabName);
    }
    return cardTabsHolder;
  }, [dataAdminMode]);

  const [currentTabUnchecked, setCurrentTab] = useState(cardTabs[0]);

  const currentTab = cardTabs.includes(currentTabUnchecked)
    ? currentTabUnchecked
    : cardTabs[0];

  const appMessagesHandler = useContext(AppMessagesContext);
  const { handleChangePrimarySelection } = useChangePrimarySelection(
    card.id,
    geographies
  );

  const handleTriggerSettingsSavedRaw = useUpdateSelectionOnSettingsChange(
    handleChangePrimarySelection
  );
  const handleTriggerSettingsSaved = useCallback(
    (s: DataSelection) => {
      return handleTriggerSettingsSavedRaw(s, adminShowDraftData);
    },
    [handleTriggerSettingsSavedRaw, adminShowDraftData]
  );

  const removeCardWithDelay = () => {
    setIsRemovingCard(true);
    setTimeout(() => {
      removeCard(card.id);
    }, 200);
  };

  const updatePrimarySelectionOnToggleDraftMode =
    useUpdateSelectionOnToggleDraftMode(
      handleChangePrimarySelection,
      false,
      defined(groupingSelection)
    );

  // When toggling draft mode, we need to ensure selections are updated so that
  // draft measures are included/excluded
  useStateTransition(adminShowDraftData, (prev, next) => {
    if (defined(prev) && defined(next) && prev !== next) {
      if (!defined(primarySelection)) {
        return;
      }
      updatePrimarySelectionOnToggleDraftMode(primarySelection, next);
    }
  });

  const primaryMeasureId = primarySelection?.measureSelection?.measure.data_id;

  const onSelectTab = (name: string) => setCurrentTab(name);

  const onSetMeasureAsDefault = () => {
    if (!(defined(primarySelection) && defined(primaryMeasureId))) {
      return;
    }
    setDefaultMeasure(primarySelection.subjectPath, primaryMeasureId)
      .then(() => {
        appMessagesHandler?.add(
          "success",
          `Mått valt som default för ${primarySelection.subjectPath.join(
            ", "
          )}`,
          { durationMs: Milliseconds.second * 5 }
        );
      })
      .catch(() => {
        appMessagesHandler?.add("error", "Kunde inte välja mått som default!", {
          durationMs: Milliseconds.second * 5,
        });
      });
  };

  return (
    <>
      <CardContainer
        isRemovingCard={isRemovingCard}
        cardId={card.id}
        removeBrokenCard={removeCard}
        sharingInfo={sharingInfo}
      >
        <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
        <TabbedCardTabsContainer currentTab={currentTab}>
          <TabbedCardTab
            key="primary-tab"
            containerKey={
              card.id +
              card.data.dataSelections
                .map((d) => d.measureSelection?.measure.data_id ?? "")
                .join(",")
            }
            onSelect={onSelectTab}
            name={cardTabs[0]}
          >
            <DataCardTab
              sharingInfo={sharingInfo}
              handleTriggerSettingsSaved={handleTriggerSettingsSaved}
              containerKey={
                card.id +
                card.data.dataSelections
                  .map((d) => d.measureSelection?.measure.data_id ?? "")
                  .join(",")
              }
              primaryMeasureId={primaryMeasureId}
              cardId={card.id}
              handleDuplicateCard={() => onDuplicateCard(card.id)}
              handleRemoveCard={removeCardWithDelay}
              singleSelectedMeasure={card.data.dataSelections.length === 1}
              setMeasureAsDefault={onSetMeasureAsDefault}
            ></DataCardTab>
          </TabbedCardTab>
          {defined(cardTabs[1]) ? (
            <TabbedCardTab
              key="metadata-tab"
              onSelect={onSelectTab}
              name={cardTabs[1]}
            >
              <TabContainer>
                <></>
                <p>Metadata</p>
              </TabContainer>
            </TabbedCardTab>
          ) : (
            <div></div>
          )}
        </TabbedCardTabsContainer>
        <TabbedCardsContentContainer currentTab={currentTab}>
          <TabbedCardMain name={cardTabs[1]}>
            <MetadataAdmin
              measureId={primaryMeasureId}
              primarySubjectPath={primarySelection?.subjectPath}
            ></MetadataAdmin>
          </TabbedCardMain>
          <TabbedCardMain name={cardTabs[0]}>
            <DataCardContentMemo
              cardId={card.id}
              geographies={props.geographies}
              isEditing={props.isEditing}
            />
          </TabbedCardMain>
        </TabbedCardsContentContainer>
        <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
      </CardContainer>
    </>
  );
}

const DataCardContentMemo = React.memo(DataCardContent);

/**
 * The main content of a card, including search, measure selection, breakdowns and data output.
 */
export function DataCardContent(props: {
  cardId: string;
  geographies: GeographiesSerializable;
  isEditing: boolean;
  isLoading?: boolean;
}) {
  const { geographies, isEditing: isEditingCard } = props;
  const card = useRecoilValue(
    singleDataCardQuery({ cardStateId: props.cardId })
  );

  const primarySelection = useRecoilValue(
    primarySelectionQuery({ cardStateId: card.id })
  );
  const metaState = useRecoilValue(reportMetaStateQuery);
  const groupingSelection = card.data.groupingSelection;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const isGroupingMode = defined(groupingSelection);
  const { handleChangePrimarySelection, handleChangePrimaryPath } =
    useChangePrimarySelection(card.id, geographies);

  const setGroupingSelection = useSetRecoilState(
    groupingSelectionQuery({ cardStateId: card.id })
  );
  const [allowAutofocus, setAllowAutofocus] = useState(true);

  const primaryResolutionRaw =
    primarySelection?.measureSelection?.measure.resolution;
  const maxResolutionGrouping = useMemo(() => {
    return defined(primaryResolutionRaw)
      ? TimeResolution.deserialize(primaryResolutionRaw)
      : TimeResolution.maximal();
  }, [primaryResolutionRaw]);

  const handleUpdateTime = useChangeTime(card);
  const handleUnsetLockToLatestTime = useUnsetLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );
  const handleActivateLockToLatest = useActivateLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );

  const handleCreateSelectionFromHit =
    useCreateSelectionFromSearchHit(adminShowDraftData);
  const handleSelectMeasureResult = useCallback(
    async (
      measureId: number,
      subjectPath: string[],
      dimensionDataColumn?: string,
      dimensionValueId?: number
    ) => {
      const result = await handleCreateSelectionFromHit(
        measureId,
        subjectPath,
        dimensionDataColumn,
        dimensionValueId
      );
      if (!defined(primarySelection)) {
        return handleChangePrimarySelection({
          ...makeDataSelection(result.subjectPath, result.measureSelection),
          measureSelection: result.measureSelection,
        });
      }
      return handleChangePrimarySelection({
        ...primarySelection,
        ...result,
      });
    },
    [
      handleChangePrimarySelection,
      handleCreateSelectionFromHit,
      primarySelection,
    ]
  );

  return (
    <div
      className={classNames(
        isEditingCard ? "editing" : "read-only",
        "data-card-content"
      )}
    >
      <>
        {isEditingCard && (
          <div className="content-padding-right data-selections">
            <div>
              {metaState?.thirdPartySharingDoc && isEditingCard && (
                <ThirdPartDocCardSettings card={card} />
              )}
              <SearchMain
                placeholder="Sök statistik"
                autofocus={allowAutofocus && lastCardScrolledTo() === card.id}
                autofocusDelayMs={200}
                onSelectPath={handleChangePrimaryPath}
                onSelectMeasure={handleSelectMeasureResult}
                canBeGroupedOnly={defined(card.data.groupingSelection)}
              ></SearchMain>
              <div
                className="data-selections-inner"
                // Disable autofocus of search field after user interaction
                onTouchStartCapture={() => {
                  setAllowAutofocus(false);
                }}
                onClick={() => {
                  setAllowAutofocus(false);
                }}
              >
                {defined(primarySelection) &&
                  primarySelection.measureSelection?.measure.retired && (
                    <RetiredMeasureWarning></RetiredMeasureWarning>
                  )}
                {defined(primarySelection) && (
                  <SingleDataSelection
                    header={isGroupingMode ? "Mått" : undefined}
                    groupingSelectionMode={isGroupingMode}
                    isGroupingSelection={false}
                    dataSelection={primarySelection}
                    autofill
                    setDataSelection={handleChangePrimarySelection}
                  ></SingleDataSelection>
                )}
              </div>
            </div>
          </div>
        )}
      </>

      <>
        {isEditingCard && (
          <>
            <Separator></Separator>
            <GroupingSection
              geographies={geographies}
              maxResolutionGrouping={maxResolutionGrouping}
              setGroupingSelection={setGroupingSelection}
              card={card}
              cardStateId={card.id}
            ></GroupingSection>
            <TimeSelect
              setLockToLatestTime={handleActivateLockToLatest}
              updateCardStateWithTimeRange={handleUpdateTime}
              unsetLockToLatestTime={handleUnsetLockToLatestTime}
              appMode={config.appMode}
              cardStateId={card.id}
            ></TimeSelect>
            <HorizontalDivider
              invisible
              bottomMargin="none"
            ></HorizontalDivider>
          </>
        )}
      </>
      <DataOutput
        geographies={geographies}
        isEditing={isEditingCard}
        cardStateId={card.id}
      ></DataOutput>
    </div>
  );
}
