import { useCallback, useEffect } from "react";
import mapbox from "mapbox-gl";

import { defined } from "../../../../../lib/core/defined";
import { ResultsPanelControl } from "./components/ResultsPanel";
import {
  MicroMapSettings,
  MicroMapView,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { useAddRemoveControl } from "./useAddRemoveControl";

export function useResultsControl(
  view: MicroMapView,
  map: mapbox.Map | undefined,
  mapSettings: MicroMapSettings,
  isEditingCard: boolean,
  microDataset: MicroDataset | undefined
) {
  const makeControl = useCallback(() => {
    return new ResultsPanelControl();
  }, []);

  const {
    add,
    remove,
    control: resultsControl,
  } = useAddRemoveControl(map, makeControl, "top-right");

  useEffect(() => {
    if (!defined(resultsControl)) {
      return;
    }
    resultsControl.render(
      view === "map-view" || !isEditingCard
        ? microDataset?.microMapResults()
        : undefined,
      mapSettings
    );
  }, [
    isEditingCard,
    map,
    mapSettings,
    mapSettings.colorScheme,
    microDataset,
    resultsControl,
    view,
  ]);

  return [resultsControl, add, remove] as const;
}
