import { IconProps } from "@blueprintjs/core";
import {
  PrimaryButton,
  DefaultButton,
  IContextualMenuProps,
  Spinner,
} from "@fluentui/react";
import { classNames } from "../lib/core/classNames";

import "./Button.scss";

interface Props {
  id?: string;
  large?: boolean;
  small?: boolean;
  hoverTitle?: string;
  onClick?: () => void;
  title: string | null;
  intent?: "primary" | "danger";
  icon?: IconProps["icon"];
  className?: string;
  disabled?: boolean;
}
export function Button(props: Props) {
  const Component = props.intent === "primary" ? PrimaryButton : DefaultButton;
  return (
    <Component
      id={props.id}
      title={props.hoverTitle}
      className={classNames(
        "infostat-button",
        props.intent,
        props.className,
        props.large ? "large" : undefined,
        props.small ? "small" : undefined
      )}
      onClick={props.onClick}
      iconProps={props.icon ? { iconName: props.icon as string } : {}}
      disabled={props.disabled}
    >
      {props.title}
    </Component>
  );
}

export function ButtonWithSideSpinner(props: Props & { loading: boolean }) {
  return (
    <div className="button-with-side-spinner">
      <Button {...props} disabled={props.disabled || props.loading} />
      <Spinner
        className={classNames(!props.loading ? "transparent" : "", "spinner")}
      />
    </div>
  );
}

export function MenuButton(
  props: Omit<Props, "onClick"> & { menu: IContextualMenuProps }
) {
  const Component = props.intent === "primary" ? PrimaryButton : DefaultButton;
  return (
    <Component
      className={classNames(
        "infostat-button",
        props.intent === "primary" ? "primary" : undefined,
        props.className,
        props.large ? "large" : undefined
      )}
      menuProps={props.menu}
      // onClick={props.onClick}
      iconProps={props.icon ? { iconName: props.icon as string } : {}}
      disabled={props.disabled}
    >
      {props.title}
    </Component>
  );
}

interface SaveButtonProps {
  large?: boolean;
  small?: boolean;
  hoverTitle?: string;
  onClick?: () => void;
  title?: string;
  intent?: "primary" | "danger";
  icon?: IconProps["icon"];
  className?: string;
  disabled?: boolean;
}
export function SaveButton(props: SaveButtonProps) {
  return (
    <Button
      {...props}
      intent={props.intent || "primary"}
      title={props.title || "Spara"}
    ></Button>
  );
}

interface CancelButtonProps {
  large?: boolean;
  small?: boolean;
  hoverTitle?: string;
  onClick?: () => void;
  title?: string;
  intent?: "primary" | "danger";
  icon?: IconProps["icon"];
  className?: string;
  disabled?: boolean;
}
export function CancelButton(props: CancelButtonProps) {
  return <Button {...props} title={props.title || "Avbryt"}></Button>;
}
