import { getMeasureDimensionsV2 } from "./datasets/dimensions";
import {
  DimensionV2Dto,
  MeasureUpdatesResponse,
  SelectedDimensionsV2,
} from "../../domain/measure/definitions";
import { HttpResult } from "../../infra/HttpResult";
import {
  getMeasureDatesStatsV2,
  getMeasureDatesSurveyV2,
  getMeasureUpdates,
} from "./common_requests";
import { MyOrganizationDto } from "../../infra/api_responses/account";
import { getMyOrganization } from "./account";

type HR<T> = HttpResult<T>;
/**
 * Temporary interface for the new stats API. After switching over to v2, we will upgrade v1 and then move back to v1 (in routes).
 */
export interface StatsApiV2 {
  /** Stats & Survey */
  getDimensions(
    measureId: number,
    adminShowDraftData: boolean,
    useCache: boolean
  ): Promise<HR<DimensionV2Dto[]>>;
  getSurveyDates(
    measureId: number,
    adminSeeDraftData: boolean,
    useCache: boolean
  ): Promise<HR<string[] | null>>;
  getStatsDates(
    measureId: number,
    breakdowns: SelectedDimensionsV2,
    adminSeeDraftData: boolean,
    useCache: boolean
  ): Promise<HR<string[] | null>>;
  getMeasureUpdates(): Promise<HR<MeasureUpdatesResponse>>;
  getMyOrganization(): Promise<HR<MyOrganizationDto>>;
}

export const statsApiV2: StatsApiV2 = {
  getDimensions: getMeasureDimensionsV2,
  getStatsDates: getMeasureDatesStatsV2,
  getSurveyDates: getMeasureDatesSurveyV2,
  getMeasureUpdates: getMeasureUpdates,
  getMyOrganization: getMyOrganization,
};
