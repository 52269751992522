import { useContext, useMemo } from "react";

import { NotReadyHttpErrNotice } from "../../../components/Loading";
import { MainContent } from "../../../components/MainContent";
import { eitherDisplayer } from "../../../components/wrappers/either";
import {
  CategoriesContext,
  SharingInfoContext,
  SharingInfoReloader,
} from "../../../lib/application/contexts";
import { getSharingInfoNonDocument } from "../../../lib/application/files/SharingInfo";
import { DocCardStats } from "../../../lib/application/state/stats/document-core/core";
import { useLoadSingleStaticMeasure } from "../../../lib/application/state/actions/load/useLoadSingleStaticMeasure";
import { voidFunc } from "../../../lib/core/voidFunc";
import { GeographiesSerializable } from "../../../lib/domain/geography";
import { HttpResult } from "../../../lib/infra/HttpResult";
import { newFromSingleMeasurePath } from "../../../lib/paths";
import { DataCardInner } from "./cards/data_card/DataCard";
import { Toolbar } from "./Toolbar";
import { CustomThemeSpecApplied } from "../../../lib/application/stats/shared/core/colors/colorSchemes";

interface Props {
  measureId: number;
  geographies: GeographiesSerializable;
  defaultPalette: CustomThemeSpecApplied;
}

export function StatsSingleMeasure(props: Props) {
  const [cardResource] = useLoadSingleStaticMeasure(
    props.measureId,
    props.geographies
  );

  return (
    <StatsSingleMeasureOrLoading
      measureId={props.measureId}
      geographies={props.geographies}
      item={cardResource}
    ></StatsSingleMeasureOrLoading>
  );
}

const StatsSingleMeasureOrLoading = eitherDisplayer(
  NotReadyHttpErrNotice,
  StatsSingleMeasureInner
);

function StatsSingleMeasureInner(props: {
  item: DocCardStats;
  geographies: GeographiesSerializable;
  measureId: number;
}) {
  const sharingInfo = useMemo(() => getSharingInfoNonDocument(), []);
  const sharing = useMemo(() => {
    return {
      info: sharingInfo,
      reload: (() =>
        Promise.resolve(
          HttpResult.fromErr({ code: "4xx" })
        )) as SharingInfoReloader,
    };
  }, [sharingInfo]);

  const categories = useContext(CategoriesContext);
  return (
    <>
      <SharingInfoContext.Provider value={sharing}>
        <div className="toolbars">
          <Toolbar
            userId={undefined}
            reloadAlertRegistrations={voidFunc}
            alertRegistrations={null}
            handleSaveAs={(name) => {
              window.open(
                newFromSingleMeasurePath(props.measureId, name),
                "_blank"
              );
            }}
            handleAddPythonCard={voidFunc}
            handleAddMicroCard={voidFunc}
            handleAddDataCard={voidFunc}
            handleAddNewTextCardCK={voidFunc}
            categories={categories}
          ></Toolbar>
        </div>
      </SharingInfoContext.Provider>
      <div className="page-scrollable">
        <MainContent>
          <DataCardInner
            geographies={props.geographies}
            sharingInfo={sharingInfo}
            card={props.item}
            isEditing={false}
            useCardInsertAnimation={false}
            removeCard={voidFunc}
            onDuplicateCard={voidFunc}
            setGroupingSelection={voidFunc}
          ></DataCardInner>
        </MainContent>
      </div>
    </>
  );
}
