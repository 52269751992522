import { DEFAULT_FONT_COLOR_TICKS } from "../../../../lib/application/stats/shared/core/colors/colors";
import { MainChartDimensions } from "../../../../lib/application/stats/shared/core/definitions";
import { XTicksContainer } from "../../../../lib/application/stats/shared/core/ticks";
import { defined } from "../../../../lib/core/defined";

interface Props {
  dims: MainChartDimensions;
  ticksContainer: XTicksContainer;
  customTickStrokeColor?: string | null;
  fontColor?: string | null;
  format?: (x: string) => string;
}

export function TicksTop({
  dims,
  ticksContainer,
  fontColor,
  customTickStrokeColor,
  format = (x) => x,
}: Props) {
  const ticks = ticksContainer.ticks;
  return (
    <g transform={`translate(${dims.marginLeft}, ${dims.marginTop})`}>
      {ticks.map((tick) => {
        return (
          <g
            key={format(tick.text)}
            transform={`translate(${tick.xOffset}, 0)`}
          >
            {defined(tick.text) && (
              <>
                {customTickStrokeColor !== null && (
                  <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2={-tick.tickSize}
                    stroke={customTickStrokeColor ?? "gray"}
                    shapeRendering="crispEdges"
                  />
                )}
                <text
                  style={{
                    ...ticksContainer.textStyle.svgFontAttrs(),
                  }}
                  dominantBaseline="hanging"
                  textAnchor="middle"
                  transform={`translate(0, ${
                    -tick.textOffsetY - ticksContainer.textStyle.fontSize()
                  })`}
                  fill={fontColor ?? DEFAULT_FONT_COLOR_TICKS}
                >
                  {format(tick.text)}
                </text>
              </>
            )}
          </g>
        );
      })}
    </g>
  );
}
