import { ComputedVariableOperator } from "../../state/stats/document-core/DataOutputSettings";

export const computedVariableOperators: ComputedVariableOperator[] = [
  "+",
  "-",
  "/",
  "*",
  "%",
];

export function displayOperator(operator: ComputedVariableOperator): string {
  switch (operator) {
    case "+":
      return "+";
    case "-":
      return "-";
    case "%":
      return "% av";
    case "*":
      return "*";
    case "/":
      return "/";
  }
}
