import {
  DatePicker,
  defaultDatePickerStrings,
  IDatePicker,
  TextField,
} from "@fluentui/react";
import { padStart } from "lodash";
import { useRef } from "react";
import { defined } from "../../lib/core/defined";

interface Props {
  label: string;
  className?: string;
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  disabled: boolean;
}
export function InfostatDatePickerControlled(props: Props) {
  const datePickerRef = useRef<IDatePicker>(null);
  const { value, onChange, label, disabled } = props;

  return (
    <DatePicker
      className={props.className ?? ""}
      disabled={disabled}
      componentRef={datePickerRef}
      label={label}
      allowTextInput
      ariaLabel="Välj ett datum"
      value={value}
      onSelectDate={onChange as (date?: Date | null) => void}
      formatDate={onFormatDate}
      // DatePicker uses English strings by default. For localized apps, you must override this prop.
      strings={defaultDatePickerStrings}
    />
  );
}

export function InfostatTimeInput(props: {
  label: string;
  className?: string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  disabled: boolean;
}) {
  const { value, onChange, label, disabled } = props;

  return (
    <TextField
      label={label}
      value={value ?? ""}
      placeholder="10:30"
      onChange={(e, newValue) => {
        if (newValue) {
          onChange(newValue);
        } else {
          onChange(undefined);
        }
      }}
      disabled={disabled}
    />
  );
}

const onFormatDate = (date?: Date): string => {
  if (!defined(date)) {
    return "";
  }

  const month = padStart((date.getMonth() + 1).toString(), 2, "0");
  const days = padStart(date.getDate().toString(), 2, "0");
  return `${date.getFullYear()}-${month}-${days}`;
};
