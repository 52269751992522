import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { assertNever } from "@fluentui/utilities";
import { SpinnerSize } from "@fluentui/react";

import { config } from "../../../config";
import { Categories } from "../../../lib/domain/categories";
import { DataCard } from "./cards/data_card/DataCard";
import { Card } from "../../../components/Card";
import { TextCard } from "./cards/TextCard/TextCard";
import { useRecoilValue } from "recoil";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import {
  useGetAllCardsCallback,
  useGetCardSpaceSettings,
  useRemoveCardCallback,
  useSetAllCardsCallback,
} from "../../../lib/application/state/actions/cardCallbacks";
import { cardQuery } from "../../../lib/application/state/stats/document-core/queries/card";
import {
  HideSpaceAfterCardContext,
  HideSpaceBeforeCardContext,
  SaveDocumentContext,
  SharingInfoContext,
} from "../../../lib/application/contexts";
import { defined } from "../../../lib/core/defined";
import { ISharingInfo } from "../../../lib/application/files/SharingInfo";
import { ErrorCard } from "./cards/ErrorCard/ErrorCard";
import { MicroCard } from "./cards/micro/MicroCard";
import { logger } from "../../../lib/infra/logging";
import { duplicateCard } from "../../../lib/application/state/stats/document-core/create";
import { TextCardCK } from "./cards/TextCardCK/TextCardCK";
import { classNames } from "../../../lib/core/classNames";
import { withoutUndefinedProperties } from "../../../lib/core/object";
import { DefaultLoadingStretchedToFit } from "../../../components/Loading";
import {
  CardVisibility,
  RenderLease,
  getCardsVisibility,
  getPageScrollElement,
} from "./cards/cards_visibility";
import {
  handleCardScrolledTo,
  setScrollToCard,
  shouldCardScrollIntoView,
  triggerOnCardScrolledTo,
} from "../../../lib/application/stats/document_position";
import { useCardEditMode } from "../../../lib/application/state/stats/useEditMode";
import { Progress } from "../../../lib/core/progress";
import { MicroCardImage } from "./cards/micro/MicroCardImage";
import {
  shouldHaveSpaceAfter,
  shouldHaveSpaceBefore,
} from "../../../lib/application/state/stats/document-core/core";
import { PythonCard } from "./cards/PythonCard/PythonCard";
import {
  createMicroCardImageEventName,
  renderMicroCardAndExtractImageEventName,
} from "../../../lib/application/state/stats/packaged-doc/pack";
import {
  useAddGeoMicroLinks,
  useGetAllGeoMicroStyleState,
  useSetAllGeoMicroStyles,
} from "../../../lib/application/state/actions/micro/geoColors";
import { copyStyleContainerGeoMicro } from "../../../lib/application/state/stats/document-style/operations";
import { reportMetaStateToPersistent } from "../../../lib/application/state/stats/document-meta/definitions";
import { reportMetaStateQuery } from "../../../lib/application/state/stats/document-meta/queries";

const DEFAULT_PLACEHOLDER_HEIGHT_EDITED_CARD = 1120;
const DEFAULT_PLACEHOLDER_HEIGHT_VIEWED_CARD = 700;

export function DataCardsContainer(props: {
  sharingInfo: ISharingInfo;
  documentId?: number;
  categories: Categories;
  addMaxNumCardsError: () => void;
}): JSX.Element {
  const { categories, addMaxNumCardsError, documentId } = props;

  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const saveDocument = useContext(SaveDocumentContext);
  const removeCard = useRemoveCardCallback();
  const metaState = useRecoilValue(reportMetaStateQuery);

  /** Force rendering of micro cards in order to enable extraction of images
   * when creating a packaged document
   */
  const [forceRenderCard, setForceRenderCard] = useState<string | undefined>();

  const handleRemoveCard = useCallback(
    (cardId: string) => {
      if (!defined(metaState)) {
        logger.error("Meta state not defined in handleRemoveCard");
        return;
      }
      const allCards = getAllCards();
      removeCard(cardId);
      const remainingCards = allCards.filter((c) => c.id !== cardId);
      saveDocument?.(remainingCards, {
        ...reportMetaStateToPersistent(metaState),
        editModeOn: true,
      });
    },
    [getAllCards, metaState, removeCard, saveDocument]
  );

  const setAllCards = useSetAllCardsCallback();

  // This state container is used to force rerendering after scroll position has changed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrollPosition, setScrollPosition] = useState(0);

  const getCardSpaceSettings = useGetCardSpaceSettings();

  useEffect(() => {
    const listener = (e: any) => {
      try {
        const cardId = e.detail.cardId;
        setForceRenderCard(cardId);
        const MICRO_RENDERING_WAIT_TIME = 1800;
        setTimeout(
          () =>
            document.dispatchEvent(
              new CustomEvent(createMicroCardImageEventName, {
                detail: { cardId },
              })
            ),
          MICRO_RENDERING_WAIT_TIME
        );
      } catch (e) {
        logger.error(e);
      }
    };
    document.addEventListener(
      renderMicroCardAndExtractImageEventName,
      listener
    );
    return () => {
      document.removeEventListener(
        renderMicroCardAndExtractImageEventName,
        listener
      );
    };
  }, []);

  // effect to listen to scroll events and update card visibility
  useEffect(() => {
    if (window.location.pathname.includes("data-admin/migrations")) {
      return;
    }

    const handleScroll = debounce(() => {
      const mainContainer = getPageScrollElement();
      if (!defined(mainContainer)) {
        logger.error("Could not find main container");
        return;
      }

      setScrollPosition(mainContainer.scrollTop);
    }, 500);

    const mainContainer = getPageScrollElement();
    if (!defined(mainContainer)) {
      logger.error("Could not find main container");
      return;
    }

    mainContainer.addEventListener("scroll", handleScroll);
    return () => {
      handleScroll.cancel();
      mainContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getAllMicroStyleState = useGetAllGeoMicroStyleState(cardsList);
  const setAllGeoMicroStyles = useSetAllGeoMicroStyles();
  const addGeoMicroLinks = useAddGeoMicroLinks();

  const handleDuplicateCard = (id: string) => {
    if (cardsList.length >= config.maxNumDocCards) {
      addMaxNumCardsError();
      return;
    }
    const allCards = getAllCards();
    const cardToDuplicate = allCards.find((c) => c.id === id);
    if (!defined(cardToDuplicate)) {
      logger.warn("Could not find card to duplicate", { id });
      return;
    }
    if (cardToDuplicate.type === "error") {
      logger.warn("Cannot duplicate error card");
      return;
    }
    if (!defined(metaState)) {
      logger.error("Meta state not defined in handleDuplicateCard");
      return;
    }
    const cardCopy = duplicateCard(cardToDuplicate);
    setScrollToCard(cardCopy.id);
    const insertIndex = cardsList.findIndex((card) => card.id === id) + 1;
    allCards.splice(insertIndex, 0, cardCopy);
    if (cardCopy.type === "microCard") {
      const { styleContainers, links } = getAllMicroStyleState();
      const currentLink = links.find((l) => l.cardId === cardToDuplicate.id);
      const currentStyle = styleContainers.find(
        (c) => c.id === currentLink?.styleContainerId
      );
      if (!defined(currentStyle)) {
        throw new Error("Could not find style container for micro card");
      }
      const styleCopy = copyStyleContainerGeoMicro(currentStyle);
      setAllGeoMicroStyles([...styleContainers, styleCopy]);
      addGeoMicroLinks([
        { cardId: cardCopy.id, styleContainerId: styleCopy.id },
      ]);
    }

    setAllCards(allCards);
    saveDocument?.(allCards, {
      ...reportMetaStateToPersistent(metaState),
      editModeOn: true,
    });
  };

  const cardVisibility: CardVisibility[] = getCardsVisibility(
    cardsList.map((d) => d.id)
  );
  const microCardInfo = useRef<{
    numRendered: number;
    leasedIds: string[];
  }>({
    numRendered: 0,
    leasedIds: [],
  });

  /**
   * Used to force a card to render without placeholder if it's being auto-scrolled to
   * when copying a card or adding a new card
   */
  const cardToBeScrolledTo = useRef<string | null>(null);
  if (!defined(cardToBeScrolledTo.current)) {
    for (const card of cardsList) {
      if (shouldCardScrollIntoView(card.id)) {
        cardToBeScrolledTo.current = card.id;
        break;
      }
    }
  }

  useEffect(() => {
    triggerOnCardScrolledTo((id) => {
      cardToBeScrolledTo.current = null;
    });
    return () => triggerOnCardScrolledTo(null);
  }, []);

  const getMicroCardLease = (id: string): RenderLease => {
    // Special case for card that is about to be scrolled to.
    // We always render this card, even if it is not in viewport
    if (cardToBeScrolledTo.current === id) {
      if (!microCardInfo.current.leasedIds.includes(id)) {
        microCardInfo.current.leasedIds.push(id);
        microCardInfo.current.numRendered++;
      }
      return { render: true, isInViewport: true };
    }

    // ---
    // Regular flow
    // ---

    const additionalCapacityAvailable =
      cardVisibility.filter((c) => c.isMicro && c.render).length <
        config.micro.numSimultaneousCardsRendered &&
      microCardInfo.current.numRendered <
        config.micro.numSimultaneousCardsRendered;
    const cardInfo = cardVisibility.find((c) => c.id === id);

    // Card has not been rendered yet
    // At this point, we don't actually know if the card is in viewport or not
    if (!defined(cardInfo)) {
      if (microCardInfo.current.leasedIds.includes(id)) {
        return { render: true };
      }

      if (additionalCapacityAvailable) {
        microCardInfo.current.leasedIds.push(id);
        microCardInfo.current.numRendered++;
        return { render: true };
      }

      return { render: false };
    }

    // Card has been rendered previously
    switch (cardInfo.render) {
      case true: {
        if (!microCardInfo.current.leasedIds.includes(id)) {
          if (
            microCardInfo.current.leasedIds.length >=
            config.micro.numSimultaneousCardsRendered
          ) {
            microCardInfo.current.leasedIds.shift();
          } else {
            microCardInfo.current.numRendered++;
          }
          microCardInfo.current.leasedIds.push(id);
        }
        return { render: true, isInViewport: cardInfo.isInViewport };
      }
      case false: {
        if (microCardInfo.current.leasedIds.includes(id)) {
          microCardInfo.current.leasedIds =
            microCardInfo.current.leasedIds.filter((c) => c !== id);
          microCardInfo.current.numRendered--;
        }

        if (!defined(cardInfo.dimensions)) {
          return {
            render: false,
            isInViewport: cardInfo.isInViewport,
          };
        }

        return {
          render: false,
          isInViewport: cardInfo.isInViewport,
          marginBottom: cardInfo.dimensions.marginBottom,
          marginTop: cardInfo.dimensions.marginTop,
          height: cardInfo.dimensions.height,
        };
      }
    }
  };

  const leases = adjustForForcedRenders(
    cardsList.map((card) => ({
      id: card.id,
      ...getMicroCardLease(card.id),
    })),
    forceRenderCard
  );

  return (
    <div className="all-cards-container">
      {cardsList.length === 0 && (
        <Card useStdPadding className="no-card-placeholder">
          <h2>Tomt dokument</h2>
          <p>Lägg till ett kort för att börja bygga ditt dokument.</p>
        </Card>
      )}
      {cardsList.map((docListItem, cardIndex) => {
        const prevCardId = cardsList[cardIndex - 1];
        const spaceSettingsPrevCard = defined(prevCardId)
          ? getCardSpaceSettings(prevCardId.id)
          : undefined;
        const nextCardId = cardsList[cardIndex + 1];
        const spaceSettingsNextCard = defined(nextCardId)
          ? getCardSpaceSettings(nextCardId.id)
          : undefined;
        const spaceSettings = getCardSpaceSettings(docListItem.id);

        return (
          <HideSpaceBeforeCardContext.Provider
            key={docListItem.id}
            value={shouldHaveSpaceBefore(spaceSettings, spaceSettingsPrevCard)}
          >
            <HideSpaceAfterCardContext.Provider
              value={shouldHaveSpaceAfter(spaceSettings, spaceSettingsNextCard)}
            >
              <RenderedCard
                cardId={docListItem.id}
                renderLease={leases.find((l) => l.id === docListItem.id)}
                documentId={documentId}
                categories={categories}
                getMicroCardLease={getMicroCardLease}
                onDuplicateCard={handleDuplicateCard}
                removeCard={handleRemoveCard}
                key={docListItem.id}
              ></RenderedCard>
            </HideSpaceAfterCardContext.Provider>
          </HideSpaceBeforeCardContext.Provider>
        );
      })}
    </div>
  );
}

function RenderedCard(props: {
  renderLease?: RenderLease;
  cardId: string;
  getMicroCardLease: (id: string) => RenderLease;
  documentId?: number;
  categories: Categories;
  onDuplicateCard: (id: string) => void;
  removeCard: (id: string) => void;
}) {
  const { categories, cardId, documentId, onDuplicateCard, removeCard } = props;

  const [microRenderDone, setMicroRenderDone] = useState(false);
  const [everRendered, setEverRendered] = useState(false);

  const microRender = useRef<{
    microRenderDone: boolean;
    height?: number;
    marginTop?: string;
    marginBottom?: string;
  } | null>(null);
  const scrollingIntoView = useRef<boolean>(false);

  const handleMicroRenderDone = useCallback(() => {
    setMicroRenderDone(true);
    setEverRendered(true);
    scrollingIntoView.current = false;
    microRender.current = {
      ...microRender?.current,
      microRenderDone: true,
    };
  }, []);

  const sharingInfoContext = useContext(SharingInfoContext);
  if (!defined(sharingInfoContext)) {
    throw new Error("SharingInfoContext is undefined -- must not happen");
  }
  const { isEditingCard, isEditingDocument } = useCardEditMode(
    cardId,
    sharingInfoContext?.info
  );

  const docCard = useRecoilValue(cardQuery(cardId));
  if (docCard.type === "error") {
    return <ErrorCard card={docCard} />;
  } else if (docCard.type === "pythonCard") {
    if (!isEditingDocument) {
      return null;
    }

    if (docCard.initState === Progress.NotStarted) {
      return (
        <InitialDataNotLoadedPlaceholder key={docCard.id + "_initalizing"} />
      );
    }

    return (
      <PythonCard
        cardId={cardId}
        removeCard={removeCard}
        onDuplicateCard={onDuplicateCard}
        sharingInfo={sharingInfoContext.info}
      />
    );
  } else if (docCard.type === "textCardSimple") {
    return (
      <TextCard
        key={docCard.id}
        cardId={docCard.id}
        onDuplicateCard={onDuplicateCard}
        removeCard={removeCard}
        sharingInfo={sharingInfoContext.info}
      ></TextCard>
    );
  } else if (docCard.type === "textCardCK") {
    return (
      <TextCardCK
        key={docCard.id}
        cardId={docCard.id}
        documentId={documentId}
        onDuplicateCard={onDuplicateCard}
        removeCard={removeCard}
        sharingInfo={sharingInfoContext.info}
      />
    );
  } else if (docCard.type === "dataCard") {
    if (docCard.initState === Progress.NotStarted) {
      return (
        <InitialDataNotLoadedPlaceholder key={docCard.id + "_initalizing"} />
      );
    }

    return (
      <DataCard
        key={docCard.id}
        cardId={docCard.id}
        categories={categories}
        onDuplicateCard={onDuplicateCard}
        removeCard={removeCard}
        sharingInfo={sharingInfoContext.info}
      ></DataCard>
    );
  } else if (docCard.type === "microCard") {
    if (docCard.initState === Progress.NotStarted) {
      return (
        <InitialDataNotLoadedPlaceholder key={docCard.id + "_initalizing"} />
      );
    }

    const defaultHeight = isEditingCard
      ? DEFAULT_PLACEHOLDER_HEIGHT_EDITED_CARD
      : DEFAULT_PLACEHOLDER_HEIGHT_VIEWED_CARD;
    const renderLease = props.renderLease;
    const placeHolderKey = "placeholder-" + docCard.id;
    // Can use viewport if card has been rendered at least once
    // or card is not in viewport
    if (shouldCardScrollIntoView(docCard.id) && !scrollingIntoView.current) {
      scrollingIntoView.current = true;
    }

    const forbidPlaceholder: boolean =
      !everRendered || renderLease?.isInViewport === true;
    if (!forbidPlaceholder && renderLease?.render === false) {
      microRender.current = withoutUndefinedProperties({
        microRenderDone: false,
        height: renderLease.height,
        marginTop: renderLease?.marginTop,
        marginBottom: renderLease?.marginBottom,
      });

      return (
        <CardPlaceholder
          key={placeHolderKey}
          cardId={docCard.id}
          {...renderLease}
          height={renderLease.height ?? defaultHeight}
        />
      );
    }

    const done = microRenderDone && microRender.current?.microRenderDone;
    const renderPlaceholderWhileCardLoads = !done && !forbidPlaceholder;

    return (
      <>
        {renderPlaceholderWhileCardLoads && (
          <CardPlaceholder
            key={placeHolderKey}
            cardId={docCard.id}
            {...microRender.current}
            height={microRender.current?.height ?? defaultHeight}
          />
        )}
        <MicroCard
          key={docCard.id}
          cardId={docCard.id}
          onDuplicateCard={onDuplicateCard}
          onRenderDone={handleMicroRenderDone}
          removeCard={removeCard}
          tempRenderInvisible={renderPlaceholderWhileCardLoads}
          sharingInfo={sharingInfoContext.info}
        ></MicroCard>
      </>
    );
  } else if (docCard.type === "microCardImage") {
    return <MicroCardImage card={docCard} />;
  } else {
    assertNever(docCard);
  }
}

/** Placeholder for when card has not been initialized yet after document load */
function InitialDataNotLoadedPlaceholder() {
  return (
    <div className="card-data-initializing">
      <DefaultLoadingStretchedToFit
        delayMs={0}
        spinnerSize={SpinnerSize.large}
      />
    </div>
  );
}

function CardPlaceholder(props: {
  cardId: string;
  height: number;
  marginTop?: string;
  marginBottom?: string;
}) {
  const [height] = useState(props.height);
  const [marginTop] = useState(props.marginTop);
  const [marginBottom] = useState(props.marginBottom);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldCardScrollIntoView(props.cardId)) {
      return;
    }
    if (!defined(ref.current)) {
      return;
    }
    handleCardScrolledTo();
    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [props.cardId]);

  return (
    <div
      ref={ref}
      className={classNames(
        "card-placeholder",
        "card-id-" + props.cardId,
        "document-card-container"
      )}
      style={{ height, marginTop, marginBottom }}
    >
      <span className="micro-output-viewer"></span>
      <DefaultLoadingStretchedToFit
        delayMs={0}
        spinnerSize={SpinnerSize.large}
      />
    </div>
  );
}

function adjustForForcedRenders(
  leases: (RenderLease & { id: string })[],
  forceRenderCard?: string
): (RenderLease & { id: string })[] {
  if (!defined(forceRenderCard)) {
    return leases;
  }

  const adjustedLeases = leases.map((lease) => {
    if (forceRenderCard === lease.id) {
      return { ...lease, render: true, isInViewport: true };
    }
    return lease;
  });
  let numRendered = adjustedLeases.filter((l) => l.render).length;
  for (const lease of adjustedLeases) {
    if (numRendered >= config.micro.numSimultaneousCardsRendered) {
      break;
    }
    if (lease.render && forceRenderCard !== lease.id) {
      lease.render = false;
      lease.isInViewport = false;
      numRendered++;
    }
  }

  return adjustedLeases;
}
