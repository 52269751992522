import {
  Boolean as BooleanRT,
  Literal,
  Number as NumberRT,
  String as StringRT,
  Array as ArrayRT,
  Dictionary,
  Record,
  Static,
  Union,
  Optional,
  Symbol as SymbolRT,
  Partial,
  Null,
} from "runtypes";
import { SelectedDimensionsV2RT } from "../../../../../domain/measure/definitions";
import { DataOutputSettingsPartialRT } from "../../document-core/DataOutputSettings";

const ColorPaletteNameRT = Union(Literal("standard"), Literal("blackWhite"));

const CustomDataOutputSettingsRT = Partial({
  showLabels: BooleanRT,
  showLineCircles: BooleanRT,
  showFatLines: BooleanRT,
  showYAxis: BooleanRT,
  showXAxis: BooleanRT,
  showTicksYAxis: BooleanRT,
  showTicksXAxis: BooleanRT,
  gridLinesXStyle: StringRT,
  gridLinesYStyle: StringRT,
  fixedNumDecimals: NumberRT,
  hideChartTitleSection: BooleanRT,
  showSurveyValueFraction: BooleanRT,
  customMainHeaderSize: NumberRT,
  customSubHeaderSmallSize: NumberRT,
  customSubHeaderLargeSize: NumberRT,
  customUnitSize: NumberRT,
  chartLabelsSize: NumberRT,
});

export const CustomThemeSpecAppliedRT = Record({
  id: StringRT,
  colors: ArrayRT(StringRT),
  useDefaultSpecialColors: BooleanRT,
  customBgColor: Optional(StringRT.Or(Null)),
  customGridLinesColor: Optional(StringRT.Or(Null)),
  customGridLinesYColor: Optional(StringRT.Or(Null)),
  customGridLinesXColor: Optional(StringRT.Or(Null)),
  customAxesColor: Optional(StringRT.Or(Null)),
  customHeadersColor: Optional(StringRT.Or(Null)),
  customLabelsColor: Optional(StringRT.Or(Null)),
  customDataOutputSettings: Optional(CustomDataOutputSettingsRT),
});

const ColorSchemeContainerRT = Record({
  colorScheme: Partial({ useSingleColor: SymbolRT }).And(
    Dictionary(StringRT, StringRT)
  ),
  embeddedPalette: Optional(CustomThemeSpecAppliedRT),
  paletteName: ColorPaletteNameRT,
  id: StringRT,
});

const MeasureSettingsStatsRT = Record({
  type: Literal("stats"),
  breakdownSelection: SelectedDimensionsV2RT,
  geocodes: ArrayRT(StringRT),
});

const MeasureSettingsSurveyRT = Record({
  type: Literal("survey"),
  breakdownSelection: SelectedDimensionsV2RT,
});

const MeasureSettingsSurveyStringRT = Record({
  type: Literal("survey_string"),
  breakdownSelection: SelectedDimensionsV2RT,
});

export const DataOutputViewRT = Union(
  Literal("diagram"),
  Literal("table"),
  Literal("map"),
  Literal("info")
);

export const DefaultSettingsV4RT = Record({
  version: Literal("v4"),
  colorSchemeContainer: ColorSchemeContainerRT,
  selectedView: Optional(DataOutputViewRT),
  // When timeRange is defined but last is not, last defaults to the most recently available time
  timeRange: Optional(Record({ first: StringRT, last: Optional(StringRT) })),
  lockToLatest: Optional(BooleanRT),
  outputSettings: DataOutputSettingsPartialRT,
  measureSettings: MeasureSettingsStatsRT.Or(MeasureSettingsSurveyRT).Or(
    MeasureSettingsSurveyStringRT
  ),
});

export type DefaultSettingsV4 = Static<typeof DefaultSettingsV4RT>;
