import { useRecoilValue } from "recoil";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { useMemo } from "react";

import { MainContent } from "../../../components/MainContent";
import { getSharingInfoNonDocument } from "../../../lib/application/files/SharingInfo";
import { classNames } from "../../../lib/core/classNames";
import { voidFunc } from "../../../lib/core/voidFunc";
import { DataCardsContainer } from "./DataCardsContainer";
import { reportMetaStateQuery } from "../../../lib/application/state/stats/document-meta/queries";
import { useLoadThirdPartyDocument } from "../../../lib/application/state/actions/load/useLoadThirdPartyDocument";
import { NotReadyHttpErrNotification } from "../../../components/Loading";
import { SharingInfoContext } from "../../../lib/application/contexts";
import { Progress } from "../../../lib/core/progress";
import { HttpErrorPage } from "../../../components/errors/HttpErrorView";

const categoriesMock = {};
const sharingInfoMock = getSharingInfoNonDocument();

export function ThirdPartyDoc(props: {
  docId: string;
  logout: Auth0ContextInterface<User>["logout"];
}) {
  const docLoad = useLoadThirdPartyDocument(props.docId);

  return docLoad.match({
    ready: () => {
      return <ThirdPartyDocInner />;
    },
    notReady: (status) => {
      if (
        status.type === Progress.Error &&
        status.err.code === "unauthorized"
      ) {
        return <HttpErrorPage error={status.err} />;
      }
      return <NotReadyHttpErrNotification left={status} />;
    },
  });
}

function ThirdPartyDocInner() {
  const sharingContext = useMemo(() => {
    return { info: sharingInfoMock, reload: undefined };
  }, []);

  const metaState = useRecoilValue(reportMetaStateQuery);

  return (
    <SharingInfoContext.Provider value={sharingContext}>
      <div
        className={classNames(
          "page-scrollable",
          metaState?.noFramesMode ? "no-frames" : ""
        )}
      >
        <MainContent>
          <DataCardsContainer
            sharingInfo={sharingInfoMock}
            categories={categoriesMock}
            addMaxNumCardsError={voidFunc}
          ></DataCardsContainer>
        </MainContent>
      </div>
    </SharingInfoContext.Provider>
  );
}
