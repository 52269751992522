import { useCallback, useMemo } from "react";
import { Card } from "../../../../components/Card";
import { NotReadyHttpErrNotice } from "../../../../components/Loading";
import { eitherDisplayer } from "../../../../components/wrappers/either";
import { FileNodeInfo } from "../../../../lib/application/files/tree";
import { defined } from "../../../../lib/core/defined";

import { config } from "../../../../config";
import { TableLatest } from "./_TableLatest";

const SURVEY_SCHEDULE_DOC_ID = 4982;

export const InFocusOrLoading = eitherDisplayer(NotReadyHttpErrNotice, InFocus);
interface Props {
  handleUseShortcut: (nodeId: number) => void;
  handleOpenDocument: (nodeId: number) => void;
  item: FileNodeInfo;
}
function InFocus(props: Props): JSX.Element {
  const fileItems = props.item;

  const handleItemClick = useCallback(
    (id: number) => {
      const item = fileItems.children?.find((child) => child.id === id);
      if (!defined(item)) {
        return;
      }
      if (item.type === "document") {
        props.handleOpenDocument(id);
      } else if (item.type === "folder") {
        props.handleUseShortcut(id);
      }
    },
    [fileItems.children, props]
  );

  const docs = useMemo(() => {
    return fileItems.children?.slice().sort((left, right) => {
      if (left.id === SURVEY_SCHEDULE_DOC_ID) {
        return -1;
      } else if (right.id === SURVEY_SCHEDULE_DOC_ID) {
        return 1;
      }
      return 0;
    });
  }, [fileItems.children]);

  const isRoot = fileItems.type === "root";
  if (isRoot && docs?.length === 0) {
    return <div className="no-sample-folders"></div>;
  }

  return (
    <Card>
      <div id="in-focus">
        <div className="content-padding">
          <h2 className="padding-top-sm margin-bottom-md">
            Senaste undersökningar
          </h2>
        </div>
        <TableLatest
          columns={[
            { name: "", key: "title", type: "text" },
            { name: "", key: "date", type: "text", classNames: "date" },
          ]}
          onRowClick={(id) => handleItemClick(id)}
          tableData={
            docs?.map((node) => {
              return {
                id: node.id,
                cells: [
                  <p className="line-header">{node.title}</p>,
                  new Date(node.lastModified).toLocaleDateString(config.locale),
                ],
              };
            }) ?? []
          }
        />
      </div>
    </Card>
  );
}
