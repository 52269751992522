import { assertNever } from "@fluentui/react";
import { fromPairs, sortBy } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { defined } from "../../../../../lib/core/defined";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import {
  MetadataInputField,
  isMultiselectField,
  SelectField,
  MultiSelectField,
} from "../../shared/base";
import { isGeometricData } from "../micro_measure/shared";

export function useMetadataInputs<T extends string | number>(
  fieldEntriesUnfiltered: MetadataInputField<T>[],
  previousData?: {
    [key: string]: any;
  }
) {
  const getExistingData = (key: string | number) => {
    return defined(previousData) && nonEmptyString(previousData[key])
      ? previousData[key]
      : undefined;
  };

  const [breakdownParentsRaw, setBreakdownParents] = useState<
    Record<string, string | undefined>
  >(previousData?.["breakdownParents"] ?? {});

  const breakdownParents = useMemo(() => {
    // Automatically remove breakdown parent entries that are no longer valid because
    // a parent has been removed and we only allow one chain of parents
    const actualBreakdownParents = { ...breakdownParentsRaw };
    let canHaveParent = true;
    const entries = Object.entries(breakdownParentsRaw);
    sortBy(entries, ([key, value]) => key).forEach(([key, value], i) => {
      if (!canHaveParent) {
        delete actualBreakdownParents[key];
      }
      if (i === entries.length - 1) {
        return;
      }

      if (defined(breakdownParentsRaw[key]) && !defined(entries[i + 1][1])) {
        canHaveParent = false;
      }
    });
    return actualBreakdownParents;
  }, [breakdownParentsRaw]);

  const [breakdowns, setBreakdownsInner] = useState<Record<string, string>>(
    previousData?.["breakdowns"] ?? {
      breakdown1: "Organisation",
    }
  );

  const setBreakdowns = useCallback((breakdowns: Record<string, string>) => {
    setBreakdownsInner(breakdowns);
  }, []);

  const [textInputs, setTextInputs] = useState(
    fromPairs(
      fieldEntriesUnfiltered
        .filter((f) => f.type === "input" || f.type === "textarea")
        .map((field) => [field.key, getExistingData(field.key) ?? ""])
    )
  );
  const [selectInputs, setSelectInputs] = useState<
    Record<string | number, string | undefined>
  >(
    fromPairs(
      fieldEntriesUnfiltered
        .filter((f) => f.type === "select")
        .map((field) => [field.key, getExistingData(field.key)])
    )
  );

  const [multiselectInputs, setMultiselectInputs] = useState<
    Record<string | number, string[]>
  >(
    fromPairs(
      fieldEntriesUnfiltered
        .filter(isMultiselectField)
        .map((field) => [
          field.key,
          getExistingData(field.key) ?? field.default ?? [],
        ])
    )
  );

  const fieldEntries = isGeometricData(
    fieldEntriesUnfiltered as any,
    selectInputs
  )
    ? fieldEntriesUnfiltered.filter((f) => {
        switch (f.key) {
          case "agg_method_geo":
          case "geo_types":
            return false;
          default:
            return true;
        }
      })
    : fieldEntriesUnfiltered;

  const collectData = useCallback(() => {
    const data = {
      ...selectInputs,
      ...multiselectInputs,
      ...fromPairs(
        Object.entries(textInputs)
          .map(([key, value]) => [key, value?.trim()])
          .filter(([, value]) => defined(value) && value !== "")
      ),
    };
    const breakdownEntries = Object.entries(breakdowns).filter(([key]) =>
      nonEmptyString(key)
    );
    data["breakdowns"] =
      breakdownEntries.length > 0 ? fromPairs(breakdownEntries) : {};
    data["breakdownParents"] = { ...breakdownParents };
    return data;
  }, [
    breakdownParents,
    breakdowns,
    multiselectInputs,
    selectInputs,
    textInputs,
  ]);

  const updateInput = useCallback((key: T, value: string) => {
    setTextInputs((inputs) => ({ ...inputs, [key]: value }));
  }, []);

  const validateField = useCallback(
    (field: (typeof fieldEntries)[number]) => {
      const isOptional = field.optional;

      if (field.type === "input" || field.type === "textarea") {
        const textInput = textInputs[field.key];
        return nonEmptyString(textInput.trim()) || isOptional;
      } else if (field.type === "select") {
        const input = selectInputs[field.key];
        return defined(input) || isOptional;
      } else if (field.type === "multiselect") {
        const input = multiselectInputs[field.key];
        return (defined(input) && input.length > 0) || isOptional;
      } else if (field.type === "breakdowns") {
        const breakdownEntries = Object.entries(breakdowns);
        return (
          breakdownEntries.every(([key, value]) => nonEmptyString(value)) &&
          breakdownEntries.length > 0
        );
      } else if (field.type === "date") {
        const textInput = textInputs[field.key];
        return nonEmptyString(textInput?.trim()) || isOptional;
      }
      assertNever(field.type);
    },
    [breakdowns, multiselectInputs, selectInputs, textInputs]
  );

  const updateSelect = useCallback(
    (field: SelectField<T>, key: string | number) => {
      const updatedSelectInputs = {
        ...selectInputs,
        [field.key]: key as string,
      };
      if (
        isGeometricData(fieldEntriesUnfiltered as any, updatedSelectInputs) &&
        field.key === "value_type"
      ) {
        updatedSelectInputs["agg_method_geo"] = "none";
      }

      setSelectInputs(updatedSelectInputs);
    },
    [fieldEntriesUnfiltered, selectInputs]
  );

  const updateMultiselect = useCallback(
    (field: MultiSelectField<T>, values: string[]) => {
      setMultiselectInputs({
        ...multiselectInputs,
        [field.key]: values,
      });
    },
    [multiselectInputs]
  );

  const allValid: boolean = fieldEntries.every((field) => {
    const valid = validateField(field);
    return valid;
  });

  return {
    applicableEntries: fieldEntries,
    breakdownParents,
    setBreakdownParents,
    textInputs,
    selectInputs,
    multiselectInputs,
    breakdowns,
    setBreakdowns,
    updateInput,
    updateSelect,
    updateMultiselect,
    collectData,
    allValid,
  };
}
