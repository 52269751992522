import { useRecoilValue } from "recoil";
import { useCallback, useMemo } from "react";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import {
  EditableInputText,
  EditableInputTextArea,
} from "../../../../../components/input/EditableInput";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { Button } from "../../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";

import { cardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import { OutputPreview } from "../OutputPreview";
import { assertNever } from "../../../../../lib/core/assert";
import { defined } from "../../../../../lib/core/defined";

import "./CustomDescriptionDialog.scss";
import { CUSTOM_LINE_CHART_LABELS_KEY } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { Dimension } from "../../../../../lib/application/stats/shared/core/definitions";
import { FoldoutPanel } from "../../../../../components/FoldoutPanel";

/**
 * Component that allows editing:
 * - Title
 * - Subtitles
 * - Unit label
 * - Labels
 */
export function CustomDescriptionDialog(props: {
  cardId: string;
  onClose: () => void;
}) {
  const [settings, setSettings] = useChangeDataOutputSettings(props.cardId);
  const card = useRecoilValue(cardQuery(props.cardId));

  const labelsByDimension = useMemo(() => {
    switch (card.type) {
      case "dataCard":
        return card.data.loadedData?.dataset?.labelsByDimension();
      case "microCard":
        return card.data.loadedData?.primaryDataset?.labelsByDimension();
      case "error":
      case "microCardImage":
      case "pythonCard":
      case "textCardCK":
      case "textCardSimple":
        return null;
      default:
        assertNever(card);
    }
  }, [card]);

  const handleSetTitle = useCallback(
    (title: string | null) => {
      setSettings({
        ...settings,
        customTitle: title,
      });
      return Promise.resolve();
    },
    [setSettings, settings]
  );

  const handleSaveEditedLabel = useCallback(
    (updatedLabels: { [dimKey: string]: { [colorKey: string]: string } }) => {
      setSettings({
        ...settings,
        customLabels: updatedLabels,
      });
      return Promise.resolve();
    },
    [setSettings, settings]
  );

  const handleSetSourceText = useCallback(
    (sourceText: string | null) => {
      setSettings({
        ...settings,
        customSourceText: sourceText,
      });
      return Promise.resolve();
    },
    [setSettings, settings]
  );

  const handleSetUnitText = useCallback(
    (unitText: string | null) => {
      setSettings({
        ...settings,
        customUnitText: unitText,
      });
      return Promise.resolve();
    },
    [setSettings, settings]
  );

  const handleSetSubtitles = useCallback(
    (subtitlesRaw: string | null) => {
      const subtitles = subtitlesRaw
        ?.split("\n")
        .map((s) => s.trim())
        .filter((s) => s.length > 0);
      setSettings({
        ...settings,
        customSubtitles: subtitles ?? null,
      });
      return Promise.resolve();
    },
    [setSettings, settings]
  );
  const loadedLineChartLegendLabels = useMemo(() => {
    if (card.type !== "dataCard") {
      return;
    }
    const loadedData = card.data.loadedData?.chartDataState?.loadedChartData;
    if (loadedData?.chartType !== "line") {
      return;
    }
    const labels = loadedData.data.legendLabels;
    if (!defined(labels)) {
      return;
    }
    return labels;
  }, [card]);

  return (
    <FluentModal
      width="md"
      isOpen
      onClose={props.onClose}
      title="Anpassa texter"
    >
      <FluentModalBody>
        <div id="custom-description-dialog">
          <p>
            Fyll i egna texter för att erstätta rubriker för diagram och
            tabeller.
          </p>
          <section>
            <EditableInputText
              label="Titel"
              handleSave={handleSetTitle}
              handleClear={() => handleSetTitle(null)}
              initialValue={settings.customTitle ?? ""}
            />
          </section>
          <section>
            <EditableInputTextArea
              label="Underrubriker"
              handleSave={handleSetSubtitles}
              handleClear={() => handleSetSubtitles(null)}
              initialValue={settings.customSubtitles?.join("\n") ?? ""}
            />
          </section>
          {card.type === "dataCard" &&
          card.data.dataSelections[0]?.measureSelection?.valueType ===
            "survey_string" ? (
            <span></span> // Cannot set unit text for survey string
          ) : (
            <section>
              <EditableInputText
                label="Enhet"
                handleSave={handleSetUnitText}
                handleClear={() => handleSetUnitText(null)}
                initialValue={settings.customUnitText ?? ""}
              />
            </section>
          )}
          <section>
            <EditableInputText
              label="Källa"
              handleSave={handleSetSourceText}
              handleClear={() => handleSetSourceText(null)}
              initialValue={settings.customSourceText ?? ""}
            />
          </section>

          {card.type === "dataCard" &&
            (card.data.selectedView === "diagram" ||
              card.data.selectedView === "table") && (
              <FoldoutPanel title="Egna etiketter">
                <section>
                  {card.data.selectedView === "diagram" &&
                  defined(loadedLineChartLegendLabels) ? (
                    <>
                      <p>
                        Här kan du ändra etiketter för dimensioner och värden i
                        diagram.
                      </p>
                      <div>
                        {loadedLineChartLegendLabels.map((l) => (
                          <EditableInputText
                            key={l.colorKey}
                            label={l.fullOriginalLabel}
                            handleSave={(newValue) => {
                              return handleSaveEditedLabel({
                                ...settings.customLabels,
                                [CUSTOM_LINE_CHART_LABELS_KEY]: {
                                  ...settings.customLabels?.[
                                    CUSTOM_LINE_CHART_LABELS_KEY
                                  ],
                                  [l.colorKey]: newValue ?? "",
                                },
                              });
                            }}
                            handleClear={() => {
                              const copy = {
                                ...settings.customLabels?.[
                                  CUSTOM_LINE_CHART_LABELS_KEY
                                ],
                              };
                              delete copy[l.colorKey];
                              return handleSaveEditedLabel({
                                ...settings.customLabels,
                                [CUSTOM_LINE_CHART_LABELS_KEY]: copy,
                              });
                            }}
                            initialValue={
                              settings.customLabels?.[
                                CUSTOM_LINE_CHART_LABELS_KEY
                              ]?.[l.colorKey] ?? ""
                            }
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    labelsByDimension
                      ?.filter((d) => defined(d) && d[0] !== Dimension.date)
                      // We don't enable customizing date dimension due to its added complexity
                      .map((v) => {
                        const [dimension, labels] = v;
                        return (
                          <div key={dimension}>
                            {labels.map((l) => {
                              return (
                                <EditableInputText
                                  key={dimension + l}
                                  label={l}
                                  handleSave={(newValue) => {
                                    return handleSaveEditedLabel({
                                      ...settings.customLabels,
                                      [dimension]: {
                                        ...settings.customLabels?.[dimension],
                                        [l]: newValue ?? "",
                                      },
                                    });
                                  }}
                                  handleClear={() => {
                                    const copy = {
                                      ...settings.customLabels?.[dimension],
                                    };
                                    delete copy[l];
                                    return handleSaveEditedLabel({
                                      ...settings.customLabels,
                                      [dimension]: copy,
                                    });
                                  }}
                                  initialValue={
                                    settings.customLabels?.[dimension]?.[l] ??
                                    ""
                                  }
                                />
                              );
                            })}
                          </div>
                        );
                      })
                  )}
                </section>
              </FoldoutPanel>
            )}

          <div className="preview margin-top-md">
            <h2>Förhandsvisning</h2>
            <OutputPreview cardId={props.cardId}></OutputPreview>
          </div>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.onClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}
