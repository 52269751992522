import { uniq } from "lodash";

import {
  DocCardState,
  makeSelectionId,
  QuillDocState,
  registerId,
  registerLabel,
  useSingleColorSymbol,
} from "../../document-core/core";
import {
  DataSelectionMicroDto,
  DataSelectionMinimal,
  FilterMeasureMicroDto,
  GeoSelectionsMicroDto,
  WorkspaceCardV8,
} from "./types";
import { defined } from "../../../../../core/defined";
import {
  DataSelection,
  DataSelectionGeneric,
  DataSelectionGrouping,
  MeasureSelectionRegular,
  MeasureSelectionSurvey,
} from "../../../../../domain/selections/definitions";
import {
  DateRangeRaw,
  FilterMeasureMicro,
  MeasureRegularDto,
  MeasureSelectionSurveyString,
  MeasureSurvey,
  MeasureSurveyString,
  MeasureThin,
} from "../../../../../domain/measure/definitions";
import {
  mapChartSettingsDefault,
  chartSettingsDefault,
  tableSettingsDefault,
  tableMicroSettingsDefault,
  chartMicroSettingsDefault,
  tableSurveyStringSettingsDefault,
  forecastSettingsDefault,
} from "../../document-core/create";
import { getSubjectPath } from "../../../../../domain/measure";
import { TimeResolution } from "../../../../../domain/time";
import { logger } from "../../../../../infra/logging";
import { assertNever } from "../../../../../core/assert";
import {
  DataSelectionMicro,
  desoToId,
  measureToMeasureSpecMicro,
  MicroGeoSelections,
  MicroMapSettings,
  regsoToId,
} from "../../document-core/core-micro";
import { last } from "../../../../../core/last";
import { StatsApi } from "../../../../requests/statsApi";
import { StatsApiV2 } from "../../../../requests/statsApiV2";
import { sanitizeTextCardHtml } from "../../../../sanitize_html";
import { ColorSchemeContainer } from "../../document-style/definitions";
import { Progress } from "../../../../../core/progress";
import { HttpResult } from "../../../../../infra/HttpResult";
import { displayHttpError } from "../../../../../../components/errors/HttpErrorNotice";
import { DataframeColumn } from "../../document-core/core";
import {
  DataOutputSettingsPartial,
  DataOutputSettings,
  COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
} from "../../document-core/DataOutputSettings";

export function initializeColorSchemeContainerV8(
  colorScheme: ColorSchemeContainer
): ColorSchemeContainer {
  return {
    ...colorScheme,
    colorScheme: {
      ...colorScheme.colorScheme,
      [useSingleColorSymbol]:
        uniq(Object.values(colorScheme.colorScheme)).length === 1,
    },
  };
}

export async function workspaceCardToCardStateV8(
  c: WorkspaceCardV8,
  useDraftMode: boolean,
  api: StatsApi,
  apiV2: StatsApiV2
): Promise<DocCardState> {
  registerId(c.id);
  registerLabel(c.label);

  if (c.type === "dataCard") {
    const cardUsesGrouping = defined(c.data.groupingSelection);
    let dataSelections: DataSelection[] = [];
    for (const sel of c.data.dataSelections) {
      const dataSelection = await rebuildDataSelection(
        sel,
        cardUsesGrouping,
        false,
        TimeResolution.maximal(),
        useDraftMode,
        api,
        apiV2
      );
      if (typeof dataSelection === "string") {
        return Promise.resolve({
          type: "error",
          id: c.id,
          message: dataSelection,
        });
      }
      dataSelections.push(dataSelection);
    }

    const fullMeasureRes = await api.getSingleMeasure(
      c.data.dataSelections[0].measureId,
      useDraftMode,
      true
    );
    const fullMeasure = fullMeasureRes.match({
      ok: (m) => m,
      err: (e) => {
        logger.error(e);
        return displayHttpError(e);
      },
    });
    if (typeof fullMeasure === "string") {
      return Promise.resolve({ type: "error", id: c.id, message: fullMeasure });
    }

    const groupingSelection = defined(c.data.groupingSelection)
      ? ((await rebuildDataSelection(
          c.data.groupingSelection,
          false,
          true,
          TimeResolution.deserialize(fullMeasure.resolution),
          useDraftMode,
          api,
          apiV2
        )) as DataSelectionGrouping)
      : undefined;

    const primarySelection = dataSelections[0];
    return Promise.resolve({
      type: "dataCard",
      id: c.id,
      isEditing: c.isEditing ?? true,
      label: c.label,
      pageBreak: c.pageBreak,
      hideSpaceAfter: c.hideSpaceAfter,
      hideSpaceBefore: c.hideSpaceBefore,
      initState: Progress.Success,
      data: {
        thirdPartyDataCardSettings: c.data.thirdPartyDataCardSettings,
        selectedView: c.data.selectedView ?? "diagram",
        settings: parsePartialDataOutputSettingsV8(c.data.settings),
        geoSelections: c.data.geoSelections,
        timeSelection: getTimeSelection(
          primarySelection.measureSelection?.availableDates,
          c.data.timeSelection,
          c.data.lockToLatestTime ?? false
        ),
        lockToLatestTime: c.data.lockToLatestTime,
        dataSelections: dataSelections,
        geoExpansions: [],
        geoSelectionsInherited: false,
        groupingSelection: groupingSelection,
      },
    });
  } else if (c.type === "textCardSimple") {
    return Promise.resolve({
      type: "textCardSimple",
      id: c.id,
      label: c.label,
      data: c.data as QuillDocState,
      isEditing: c.isEditing,
      pageBreak: c.pageBreak,
      // Does not have hideSpaceAfter / Before because the setting started existing
      // after textCardSimple was switched to textCardCK
    });
  } else if (c.type === "textCardCK") {
    return Promise.resolve({
      type: c.type,
      id: c.id,
      label: c.label,
      data: sanitizeTextCardHtml(c.data),
      isEditing: c.isEditing,
      pageBreak: c.pageBreak,
      hideSpaceAfter: c.hideSpaceAfter,
      hideSpaceBefore: c.hideSpaceBefore,
    });
  } else if (c.type === "microCard") {
    const filterMeasures = await Promise.all(
      c.data.filterMeasures.map((f) =>
        rebuildFilterMeasure(f, useDraftMode, api)
      )
    );
    const dataSelections = await Promise.all(
      c.data.dataSelections?.map((selection) =>
        rebuildMicroSelection(selection, api, c.data.lockToLatestTime ?? false)
      ) ?? []
    );
    return Promise.resolve({
      type: "microCard",
      id: c.id,
      label: c.label,
      isEditing: c.isEditing,
      pageBreak: c.pageBreak,
      hideSpaceAfter: c.hideSpaceAfter,
      hideSpaceBefore: c.hideSpaceBefore,
      initState: Progress.Success,
      data: {
        filterMeasures,
        mapLocationBounds: c.data.mapLocationBounds,
        thirdPartyMicroCardSettings: c.data.thirdPartyMicroCardSettings,
        geoSelections: rebuildMicroGeoSelection(c.data.geoSelections),
        lockToLatestTime: c.data.lockToLatestTime,
        settings: {
          map: c.data.settings.map as MicroMapSettings,
          dataOutputSettings: parsePartialDataOutputSettingsV8(
            c.data.settings.dataOutputSettings
          ),
        },
        selectedTab: c.data.selectedTab as any, // TODO
        dataSelections,
      },
    });
  } else if (c.type === "pythonCard") {
    const columns: DataframeColumn[] = [];

    for (const col of c.data.columns) {
      if (col.type === "stats") {
        const selection = await rebuildDataSelection(
          col.selection,
          false,
          false,
          TimeResolution.maximal(),
          useDraftMode,
          api,
          apiV2
        );
        if (typeof selection === "string") {
          return {
            type: "error",
            id: c.id,
            message: selection,
          };
        }
        columns.push({
          type: col.type,
          columnName: col.columnName,
          selection: selection,
          geoSelections: col.geoSelections,
          timeSelection: col.timeSelection,
        });
        continue;
      } else if (col.type === "micro") {
        const selection = await rebuildMicroSelection(
          col.selection,
          api,
          false
        );
        columns.push({
          type: col.type,
          columnName: col.columnName,
          selection: selection,
          timeSelection: col.timeSelection,
        });
        continue;
      }

      columns.push({
        type: col.type,
        columnName: col.columnName,
        expression: col.expression,
      });
    }

    const surveyDataSelection = defined(c.data.surveySelection)
      ? await rebuildDataSelection(
          c.data.surveySelection.selection,
          false,
          false,
          TimeResolution.maximal(),
          useDraftMode,
          api,
          apiV2
        )
      : undefined;
    if (typeof surveyDataSelection === "string") {
      return {
        type: "error",
        id: c.id,
        message: surveyDataSelection,
      };
    }

    return Promise.resolve({
      type: "pythonCard",
      id: c.id,
      label: c.label,
      isEditing: c.isEditing,
      pageBreak: c.pageBreak,
      hideSpaceAfter: c.hideSpaceAfter,
      hideSpaceBefore: c.hideSpaceBefore,
      initState: Progress.Success,
      data: {
        selectedView: c.data.selectedView,
        surveySelection: defined(surveyDataSelection)
          ? {
              selection: surveyDataSelection,
              timeSelection: c.data.surveySelection!.timeSelection,
            }
          : undefined,
        pythonCode: c.data.pythonCode,
        geoExpansions: [],
        columns: columns,
      },
    });
  }
  assertNever(c);
}

function rebuildMicroGeoSelection(
  s?: GeoSelectionsMicroDto
): MicroGeoSelections | undefined {
  if (!defined(s)) {
    return undefined;
  }

  switch (s.type) {
    case "deso":
      return {
        type: "deso",
        selected: s.selected.map((item) => {
          if (item.type !== "deso") {
            throw new Error("Unexpected deso type");
          }
          return {
            type: "deso",
            props: { ...item.props, id: desoToId(item.props.deso) },
          };
        }),
      };
    case "regso":
      return {
        type: "regso",
        selected: s.selected.map((item) => {
          if (item.type !== "regso") {
            throw new Error("Unexpected regso type");
          }
          return {
            type: "regso",
            props: { ...item.props, id: regsoToId(item.props.regso) },
          };
        }),
      };
  }
  assertNever(s);
}

export function parsePartialDataOutputSettingsV8(
  settings: DataOutputSettingsPartial
): DataOutputSettings {
  const showReferenceLines = settings.showReferenceLines;
  const showSurveyValueFraction = settings.showSurveyValueFraction;
  return {
    ...settings,
    fixedNumDecimals: settings.fixedNumDecimals ?? null,
    showReferenceLines: defined(showReferenceLines)
      ? showReferenceLines
      : false,
    showSurveyValueFraction: defined(showSurveyValueFraction)
      ? showSurveyValueFraction
      : false,
    hideChartTitleSection: settings.hideChartTitleSection ?? false,
    hideLegendDimensionLabels: settings.hideLegendDimensionLabels ?? false,
    showTicksXAxis: settings.showTicksXAxis ?? true,
    showTicksYAxis: settings.showTicksYAxis ?? true,
    showYAxis: settings.showYAxis ?? true,
    showXAxis: settings.showXAxis ?? true,
    customTitle: settings.customTitle ?? null,
    customSubtitles: settings.customSubtitles ?? null,
    customUnitText: settings.customUnitText ?? null,
    customUnitSize:
      settings.customUnitSize ?? settings.chart?.labelSize ?? null, // default to label size since it was previously used to set unit label size
    customMainHeaderSize: settings.customMainHeaderSize ?? null,
    customSubHeaderLargeSize: settings.customSubHeaderLargeSize ?? null,
    customSubHeaderSmallSize: settings.customSubHeaderSmallSize ?? null,
    customSourceTextSize: settings.customSourceTextSize ?? null,
    customYAxisRange: settings.customYAxisRange ?? null,
    mapChart: {
      ...mapChartSettingsDefault(),
      ...(settings.mapChart ?? {}),
    },
    chart: {
      ...chartSettingsDefault(),
      ...(settings.chart ?? {}),
    },
    table: {
      ...tableSettingsDefault(),
      ...(settings.table ?? {}),
    },
    tableSurveyString: {
      ...tableSurveyStringSettingsDefault(),
      ...(settings.tableSurveyString ?? {}),
    },
    tableMicro: {
      ...tableMicroSettingsDefault(),
      ...(settings.tableMicro ?? {}),
    },
    chartMicro: {
      ...chartMicroSettingsDefault(),
      ...(settings.chartMicro ?? {}),
    },
    forecast: settings.forecast ?? forecastSettingsDefault(),
    computedVariables: settings.computedVariables ?? [],
    computedVariablesV3: settings.computedVariablesV3 ?? [],
    computationInputRounding:
      settings.computationInputRounding ??
      COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
    computationOutputNumDecimals: settings.computationOutputNumDecimals ?? null,
    hiddenBreakdownCombinations: settings.hiddenBreakdownCombinations ?? [],
  } as DataOutputSettings;
}

async function rebuildMicroSelection(
  dto: DataSelectionMicroDto,
  api: StatsApi,
  lockToLatestTime: boolean
): Promise<DataSelectionMicro> {
  const measureId = dto.measureId;
  const measure = defined(measureId)
    ? (
        await api.getSingleMeasureMicro(
          measureId,
          dto.type === "primary" ? dto.computedMeasurementType : undefined
        )
      ).unwrap()
    : undefined;
  const dimensions = defined(measureId)
    ? (await api.getDimensionsMicro(measureId, false)).unwrap() ?? []
    : [];
  const availableDates = defined(measureId)
    ? (
        await api.getDatesMicro(measureId, dto.selectedDimensions, false)
      ).unwrap() ?? []
    : [];

  const timeSelection = getTimeSelection(
    availableDates,
    dto.timeSelection,
    lockToLatestTime
  );
  const measureSpec = defined(measure)
    ? measureToMeasureSpecMicro(measure, dimensions)
    : undefined;
  if (dto.type === "geo-micro") {
    return {
      type: "geo-micro",
      id: dto.id,
      measure: measureSpec,
      availableDates,
      selectedDimensions: dto.selectedDimensions,
      subjectPath: dto.subjectPath,
      timeSelection,
    };
  }
  return {
    availableDates,
    id: dto.id,
    measure: measureSpec,
    multiSelectEnabled: dto.multiSelectEnabled,
    computedMeasureVariablesConfig: dto.selectedComputedVariables,
    selectedDimensions: dto.selectedDimensions,
    subjectPath: dto.subjectPath,
    type: dto.type,
    timeSelection,
    filterSet: dto.filterSet,
  };
}

async function rebuildFilterMeasure(
  dto: FilterMeasureMicroDto,
  useDraftMode: boolean,
  api: StatsApi
): Promise<FilterMeasureMicro> {
  const selection = dto.measureSelection;
  if (!defined(selection)) {
    return {
      id: dto.id,
      subjectPath: dto.subjectPath,
      filterSet: dto.filterSet,
    };
  }
  const measure = (
    await api.getSingleMeasureMicro(
      selection.measureId,
      selection.computedMeasurementType
    )
  ).unwrap();
  const dims =
    (
      await api.getDimensionsMicro(selection.measureId, useDraftMode)
    ).unwrap() ?? [];

  const measureSpec = measureToMeasureSpecMicro(measure, dims);
  return {
    id: dto.id,
    subjectPath: dto.subjectPath,
    filterSet: dto.filterSet,
    measureSelection: {
      computedMeasureVariablesConfig: selection.selectedComputedVariables,
      measure: measureSpec,
      selectedDimensions: selection.selectedDimensions,
    },
  };
}

async function rebuildDataSelection(
  d: DataSelectionMinimal,
  groupableMeasuresOnly: boolean,
  groupingMeasuresOnly: boolean,
  maxResolution: TimeResolution,
  useDraftMode: boolean,
  api: StatsApi,
  apiV2: StatsApiV2
): Promise<DataSelection | string> {
  const fullMeasureRes = await api.getSingleMeasure(
    d.measureId,
    useDraftMode,
    true
  );
  const fullMeasure = resOrErrString(fullMeasureRes);
  if (typeof fullMeasure === "string") {
    return fullMeasure;
  }

  const subjectPath = getSubjectPath(fullMeasure);
  const availableMeasures: MeasureThin[] = await api
    .getAvailableMeasures(
      subjectPath,
      groupableMeasuresOnly,
      groupingMeasuresOnly,
      useDraftMode,
      maxResolution
    )
    .then((measures) => {
      return measures.map((m) => ({
        ...m,
      }));
    });

  if (d.measureType === "survey") {
    const dimensionsRes = await apiV2.getDimensions(
      d.measureId,
      useDraftMode,
      true
    );
    const dimensions = resOrErrString(dimensionsRes);
    if (typeof dimensions === "string") {
      return dimensions;
    }

    const availableDatesRes = await apiV2
      .getSurveyDates(d.measureId, useDraftMode, true)
      .then((res) => res.map((inner) => inner ?? []));
    const availableDates = resOrErrString(availableDatesRes);
    if (typeof availableDates === "string") {
      return availableDates;
    }

    const measureSelection: DataSelectionGeneric<MeasureSelectionSurvey> = {
      id: makeSelectionId(),
      subjectPath,
      measureSelection: {
        availableDates,
        valueType: d.measureType,
        breakdowns: d.breakdownSelection,
        available: availableMeasures,
        measure: {
          ...(fullMeasure as MeasureSurvey),
          dimensions,
        },
      },
    };
    return measureSelection;
  } else if (d.measureType === "survey_string") {
    const dimensionsRes = await apiV2.getDimensions(
      d.measureId,
      useDraftMode,
      true
    );
    const dimensions = resOrErrString(dimensionsRes);
    if (typeof dimensions === "string") {
      return dimensions;
    }

    const availableDatesRes = await apiV2
      .getSurveyDates(d.measureId, useDraftMode, true)
      .then((res) => res.map((inner) => inner ?? []));
    const availableDates = resOrErrString(availableDatesRes);
    if (typeof availableDates === "string") {
      return availableDates;
    }

    const measureSelection: DataSelectionGeneric<MeasureSelectionSurveyString> =
      {
        id: makeSelectionId(),
        subjectPath,
        measureSelection: {
          availableDates,
          valueType: d.measureType,
          breakdowns: d.breakdownSelection,
          available: availableMeasures,
          measure: {
            ...(fullMeasure as MeasureSurveyString),
            dimensions,
          },
        },
      };
    return measureSelection;
  }

  const availableDatesRes = await apiV2
    .getStatsDates(d.measureId, d.breakdownSelection, useDraftMode, true)
    .then((res) => res.map((inner) => inner ?? []));
  const availableDates = resOrErrString(availableDatesRes);
  if (typeof availableDates === "string") {
    return availableDates;
  }

  const dimensionsRes = await apiV2.getDimensions(
    d.measureId,
    useDraftMode,
    true
  );
  const dimensions = resOrErrString(dimensionsRes);
  if (typeof dimensions === "string") {
    return dimensions;
  }

  const measureSelection: DataSelectionGeneric<MeasureSelectionRegular> = {
    id: makeSelectionId(),
    subjectPath,
    measureSelection: {
      availableDates,
      valueType: d.measureType,
      breakdowns: d.breakdownSelection,
      available: availableMeasures,
      measure: {
        ...(fullMeasure as MeasureRegularDto),
        dimensions,
      },
    },
  };

  return measureSelection;
}

export async function workspaceEmbeddedCardToCardStateV8(
  c: WorkspaceCardV8,
  api: StatsApi,
  apiV2: StatsApiV2
): Promise<DocCardState> {
  registerId(c.id);
  registerLabel(c.label);

  switch (c.type) {
    case "dataCard":
      const dataSelections = await Promise.all(
        c.data.dataSelections.map<Promise<DataSelection>>((c) =>
          rebuildDataSelectionEmbeddableCard(c, api, apiV2)
        )
      );
      const primarySelection = dataSelections[0];
      return Promise.resolve({
        type: "dataCard",
        id: c.id,
        isEditing: c.isEditing ?? true,
        label: c.label,
        pageBreak: c.pageBreak,
        initState: Progress.Success,
        hideSpaceAfter: false, // Never used for embedded cards
        hideSpaceBefore: false, // Never used for embedded cards
        data: {
          selectedView: c.data.selectedView,
          settings: parsePartialDataOutputSettingsV8(c.data.settings),
          geoSelections: c.data.geoSelections,
          timeSelection: getTimeSelection(
            primarySelection.measureSelection?.availableDates ?? [],
            c.data.timeSelection,
            c.data.lockToLatestTime ?? false
          ),
          lockToLatestTime: c.data.lockToLatestTime,
          dataSelections: dataSelections,
          geoExpansions: [],
          geoSelectionsInherited: false,
        },
      });
    case "textCardSimple":
    case "textCardCK":
    case "microCard":
    case "pythonCard":
      throw new Error("Not supported: " + c.type);
  }
  assertNever(c);
}

async function rebuildDataSelectionEmbeddableCard(
  d: DataSelectionMinimal,
  api: StatsApi,
  apiV2: StatsApiV2
): Promise<DataSelection> {
  const useDraftMode = false; // Never necessary for embeddable cards. We have no draft view mode for these.
  const fullMeasure = (
    await api.getSingleMeasure(d.measureId, useDraftMode, true)
  ).unwrap();
  const subjectPath = getSubjectPath(fullMeasure);

  if (d.measureType === "survey") {
    throw new Error("survey is not supported");
  } else if (d.measureType === "survey_string") {
    throw new Error("survey_string is not supported");
  }

  const availableDates = await apiV2
    .getStatsDates(d.measureId, d.breakdownSelection, useDraftMode, true)
    .then((res) => res.unwrap() ?? []);
  const dimensions = (
    await apiV2.getDimensions(d.measureId, useDraftMode, true)
  ).unwrap();
  const measureSelection: DataSelectionGeneric<MeasureSelectionRegular> = {
    id: makeSelectionId(),
    subjectPath,
    measureSelection: {
      availableDates,
      valueType: d.measureType,
      breakdowns: d.breakdownSelection,
      available: [fullMeasure],
      measure: {
        ...(fullMeasure as MeasureRegularDto),
        dimensions,
      },
    },
  };

  return measureSelection;
}

function getTimeSelection(
  availableDates: string[] | undefined,
  currentTimeSelection: DateRangeRaw | undefined,
  lockToLatestTime: boolean
): DateRangeRaw | undefined {
  if (!defined(availableDates)) {
    return currentTimeSelection;
  }
  if (!lockToLatestTime) {
    return currentTimeSelection;
  }

  // Lock to latest time

  const lastDate = last(availableDates ?? []);
  if (!defined(lastDate)) {
    return currentTimeSelection;
  }
  if (!defined(currentTimeSelection)) {
    return [lastDate, lastDate];
  }

  const currentIsSinglePoint =
    currentTimeSelection[0] === currentTimeSelection[1];
  if (currentIsSinglePoint) {
    return [lastDate, lastDate];
  }

  return [currentTimeSelection[0], lastDate];
}

function resOrErrString<T>(res: HttpResult<T>): T | string {
  return res.match({
    ok: (m) => m,
    err: (e) => {
      return displayHttpError(e);
    },
  });
}
