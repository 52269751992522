import { Checkbox, Dropdown, Slider, SwatchColorPicker } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Button } from "../../../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../../components/ButtonContainers";
import { CustomDropdown } from "../../../../../../components/CustomDropdown";
import {
  FluentModalBody,
  FluentModalFooter,
  FluentModalTall,
} from "../../../../../../components/Modal";
import { MicroMapSettings } from "../../../../../../lib/application/state/stats/document-core/core-micro";
import {
  extendedStandardColors,
  standardColors,
} from "../../../../../../lib/application/stats/shared/core/colors/colors";
import { classNames } from "../../../../../../lib/core/classNames";
import { defined } from "../../../../../../lib/core/defined";
import {
  getColorRamp,
  MicroColorScheme,
  microColorSchemes,
} from "../../../../../../lib/domain/micro/colors";
import { HandleUpdateSetting } from "../shared";

import { HelpIcon } from "../../../../../../components/HelpIcon";
import { singleMicroCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { GeometryType } from "../../../../../../lib/application/state/stats/document-style/definitions";
import { useApplyGeoStyle } from "../mapColorHooks";

import "./MicroSettingsModal.scss";
import { microLineStyleDashArrayOptions } from "../../../../../../lib/application/stats/shared/core/colors/colorSchemes";

type ColorSet = {
  dataGroupId: string;
  geometry: GeometryType;
  label: string;
  fill?: string;
  fillOpacity?: number;
  lineDashArray?: number[];
  border?: string;
};

export function MicroSettingsModal(props: {
  cardId: string;
  settings: MicroMapSettings;
  primaryMeasureIsComputed: boolean;
  handleUpdateSetting: HandleUpdateSetting;
  handleClose: () => void;
}) {
  const card = useRecoilValue(
    singleMicroCardQuery({ cardStateId: props.cardId })
  );
  const applyStyle = useApplyGeoStyle(props.cardId);

  const colors: ColorSet[] = useMemo(() => {
    const microMapState = card.data.loadedData?.microMapState;

    const colorSets: ColorSet[] = [];
    microMapState?.loadedLines?.forEach((line) => {
      colorSets.push({
        dataGroupId: line.id,
        geometry: "line",
        label: line.style.label,
        border: line.style.border,
        fill: line.style.fill,
        lineDashArray: line.style.lineDashArray,
      });
    });
    microMapState?.loadedPoints?.forEach((point) => {
      colorSets.push({
        dataGroupId: point.id,
        geometry: "point",
        label: point.style.label,
        border: point.style.border,
        fill: point.style.fill,
        fillOpacity: point.style.fillOpacity,
      });
    });
    microMapState?.loadedPolygons?.forEach((polygon) => {
      colorSets.push({
        dataGroupId: polygon.id,
        geometry: "polygon",
        label: polygon.style.label,
        border: polygon.style.border,
        fill: polygon.style.fill,
        fillOpacity: polygon.style.fillOpacity,
        lineDashArray: polygon.style.lineDashArray,
      });
    });
    return colorSets;
  }, [card.data.loadedData?.microMapState]);

  const handleApplyStyle = useCallback(
    (colorSet: ColorSet) => {
      applyStyle(colorSet.geometry, colorSet.dataGroupId, {
        label: colorSet.label,
        fill: colorSet.fill,
        fillOpacity: colorSet.fillOpacity,
        lineDashArray: colorSet.lineDashArray,
        border: colorSet.border,
      });
    },
    [applyStyle]
  );

  const handleToggleLocalColorScheme = useCallback(
    (on: boolean) => {
      props.handleUpdateSetting("localZRange", on);
    },
    [props]
  );

  const loadedMicroDataset =
    card.data.loadedData?.microMapState?.loadedMicroMapData;

  return (
    <FluentModalTall
      containerClassName="micro-settings-modal"
      onClose={props.handleClose}
      width="md"
      isOpen={true}
      title="Inställningar"
    >
      <FluentModalBody>
        <>
          {defined(colors) && colors.length > 0 && (
            <div className="section">
              <h3>Färg och stil</h3>
              <table className="current-colors-geo-micro">
                <thead>
                  <tr>
                    <th>Mått/filter</th>
                    <th>Fyllning</th>
                    <th>Fyllningsopacitet</th>
                    <th>Kontur</th>
                    <th>Linjestil</th>
                  </tr>
                </thead>
                <tbody>
                  {colors.map((c, index) => {
                    return (
                      <ColorSetOptions
                        key={c.dataGroupId}
                        handleApplyStyle={handleApplyStyle}
                        colorSet={c}
                      ></ColorSetOptions>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {defined(loadedMicroDataset) && (
            <div className="section">
              <h3>Färgskala för områden</h3>
              <section className="local-z-range">
                <Checkbox
                  disabled={props.primaryMeasureIsComputed}
                  checked={props.settings.localZRange ?? false}
                  onChange={(ev, checked) => {
                    if (!defined(checked)) {
                      return;
                    }
                    handleToggleLocalColorScheme(checked);
                  }}
                  label="Lokal färgsättning"
                ></Checkbox>
                <HelpIcon tooltipText="Dina valda områden kommer att färgas enligt en skala där områdena med högst och lägst värden färgas med sista respektiva första färgen på färgskalan och resten passas in däremellan."></HelpIcon>
              </section>
              <section>
                <Checkbox
                  label="Ingen fyllnadsfärg, endast konturer"
                  checked={props.settings.bordersOnlyNoFill ?? false}
                  onChange={(ev, checked) => {
                    if (!defined(checked)) {
                      return;
                    }
                    props.handleUpdateSetting("bordersOnlyNoFill", checked);
                  }}
                ></Checkbox>
              </section>
              {microColorSchemes.map((scheme) => {
                return (
                  <div key={scheme} className="color-scheme-row">
                    <div
                      className={classNames(
                        "color-scheme",
                        scheme === props.settings.colorScheme ? "selected" : ""
                      )}
                      onClick={() =>
                        props.handleUpdateSetting("colorScheme", scheme)
                      }
                    >
                      <ColorSchemeIndicator scheme={scheme} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.handleClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModalTall>
  );
}

function ColorSchemeIndicator(props: { scheme: MicroColorScheme }) {
  const canvasContainerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const current = canvasContainerRef?.current;
    if (!defined(current)) {
      return;
    }

    const rampSteps = 100;
    const colorRamp = getColorRamp(props.scheme, rampSteps);
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    canvas.style.width = "100%";
    canvas.style.height = "20px";
    const ctx = canvas.getContext("2d");
    if (!defined(ctx)) {
      throw new Error("Could not get context");
    }
    for (let i = 0; i < rampSteps; i++) {
      ctx.fillStyle = colorRamp[i];
      ctx.fillRect(i, 0, 1, 100);
    }

    current.appendChild(canvas);

    return () => {
      current.removeChild(canvas);
    };
  }, [props.scheme]);

  return (
    <div ref={canvasContainerRef} className="color-scheme-container"></div>
  );
}

const colorToCell = (color: string) => ({ color, id: color });
const colorCells = standardColors
  .map(colorToCell)
  .concat(extendedStandardColors.map(colorToCell));

function ColorSetOptions(props: {
  handleApplyStyle: (colorSet: ColorSet) => void;
  colorSet: ColorSet;
}) {
  const colorSet = props.colorSet;
  const handleApplyStyle = props.handleApplyStyle;
  const { label, fill, border, geometry } = colorSet;
  const [isOpenFill, setIsOpenFill] = useState(false);
  const [isOpenBorder, setIsOpenBorder] = useState(false);
  const [localFillOpacity, setLocalFillOpacity] = useState(
    props.colorSet.fillOpacity
  );
  const currentFillOpacity = useMemo(
    () => props.colorSet.fillOpacity,
    [props.colorSet.fillOpacity]
  );

  useEffect(() => {
    if (!defined(localFillOpacity) || localFillOpacity === currentFillOpacity) {
      return;
    }
    const handle = setTimeout(() => {
      handleApplyStyle({
        ...colorSet,
        fillOpacity: localFillOpacity,
      });
    }, 300);
    return () => clearTimeout(handle);
  }, [currentFillOpacity, localFillOpacity, colorSet, handleApplyStyle]);

  return (
    <tr>
      <td>{label}</td>
      <td>
        {(geometry === "point" ||
          geometry === "line" ||
          geometry === "polygon") && (
          <CustomDropdown
            onClick={(show) => setIsOpenFill(show)}
            isOpen={isOpenFill}
            onDismiss={() => setIsOpenFill(false)}
            label={
              <div
                onClick={() => setIsOpenFill(!isOpenFill)}
                style={{
                  backgroundColor: fill,
                  height: 16,
                  width: 16,
                }}
              ></div>
            }
            content={
              <div className="geo-micro-color-dropdown">
                <SwatchColorPicker
                  onChange={(ev, id) => {
                    if (!defined(id)) {
                      throw new Error("No color ID defined");
                    }
                    props.handleApplyStyle({ ...props.colorSet, fill: id });
                    setIsOpenFill(false);
                  }}
                  columnCount={6}
                  cellHeight={30}
                  cellWidth={30}
                  cellShape="square"
                  colorCells={colorCells}
                ></SwatchColorPicker>
                <NoColorOption
                  onClick={() => {
                    props.handleApplyStyle({
                      ...props.colorSet,
                      fill: undefined,
                    });
                    setIsOpenFill(false);
                  }}
                ></NoColorOption>
              </div>
            }
          ></CustomDropdown>
        )}
      </td>
      <td>
        {(geometry === "polygon" || geometry === "point") && defined(fill) && (
          <Slider
            className="opacity-slider"
            min={0}
            max={1}
            step={0.1}
            value={localFillOpacity ?? 1}
            valueFormat={(value) => `${Math.round(value * 100)}%`}
            onChange={(value) => {
              setLocalFillOpacity(value);
            }}
          />
        )}
      </td>
      <td>
        {geometry === "polygon" && (
          <CustomDropdown
            onClick={(show) => setIsOpenBorder(show)}
            isOpen={isOpenBorder}
            onDismiss={() => setIsOpenBorder(false)}
            label={
              <div
                onClick={() => setIsOpenBorder(!isOpenBorder)}
                style={{
                  backgroundColor: border,
                  height: 16,
                  width: 16,
                }}
              ></div>
            }
            content={
              <div className="geo-micro-color-dropdown">
                <SwatchColorPicker
                  onChange={(ev, id) => {
                    if (!defined(id)) {
                      throw new Error("No color ID defined");
                    }
                    props.handleApplyStyle({ ...props.colorSet, border: id });
                    setIsOpenBorder(false);
                  }}
                  columnCount={6}
                  cellHeight={30}
                  cellWidth={30}
                  cellShape="square"
                  colorCells={colorCells}
                ></SwatchColorPicker>
                <NoColorOption
                  onClick={() => {
                    props.handleApplyStyle({
                      ...props.colorSet,
                      border: undefined,
                    });
                    setIsOpenBorder(false);
                  }}
                ></NoColorOption>
              </div>
            }
          ></CustomDropdown>
        )}
      </td>
      <td>
        {(geometry === "line" ||
          (geometry === "polygon" && defined(border))) && (
          <LineStyleDropdown
            selectedOption={props.colorSet.lineDashArray}
            handleOutputSettingChange={(value) => {
              handleApplyStyle({ ...props.colorSet, lineDashArray: value });
            }}
          ></LineStyleDropdown>
        )}
      </td>
    </tr>
  );
}

function NoColorOption(props: { onClick: () => void }) {
  return (
    <div className="no-color" onClick={props.onClick}>
      <div className="no-color-box"></div>
      <label onClick={props.onClick}>Ingen färg</label>
    </div>
  );
}
interface LineStyleDropdownProps {
  selectedOption?: number[];
  handleOutputSettingChange: (value?: number[]) => void;
}
export const LineStyleDropdown: React.FC<LineStyleDropdownProps> = ({
  selectedOption,
  handleOutputSettingChange,
}) => {
  const options = useMemo(() => microLineStyleDashArrayOptions, []);

  const getKey = useCallback((o?: number[]) => {
    return (o ?? microLineStyleDashArrayOptions[0]).join("-") ?? "";
  }, []);

  return (
    <Dropdown
      style={{ minWidth: "100px" }}
      options={options.map((option) => ({
        key: getKey(option),
        text: "",
      }))}
      selectedKey={getKey(selectedOption)}
      onChange={(e, option) => {
        const key = option?.key;
        if (!defined(key)) {
          return;
        }
        const selected = options.find((o) => getKey(o) === key);
        if (!defined(selected)) {
          return;
        }
        handleOutputSettingChange(selected);
      }}
      onRenderTitle={(selectedOptions) => {
        const option = selectedOptions?.[0];
        if (!defined(option)) {
          return null;
        }
        const found = options.find((o) => getKey(o) === option?.key);
        if (!defined(found)) {
          return null;
        }
        return onRenderLineStyleOption(found);
      }}
      onRenderOption={(option) => {
        const found = options.find((o) => getKey(o) === option?.key);
        if (!defined(found)) {
          return null;
        }
        return onRenderLineStyleOption(found);
      }}
    />
  );
};
function onRenderLineStyleOption(item?: number[]): JSX.Element {
  return (
    <svg viewBox="0 0 40 1">
      <line
        x1="0"
        y1="0"
        x2="40"
        y2="0"
        strokeDasharray={item?.join(",") ?? "1,0"}
        stroke="black"
      />
    </svg>
  );
}
