import { useCallback, useContext, useMemo } from "react";

import { AppMessagesHandler } from "../../../AppMessagesHandler";
import {
  getDocument,
  getNodeSharingInfo,
} from "../../../requests/docs/documents";
import { getVerifiedWorkspace } from "../../stats/workspace/rebuild_state";
import { LoadingResult } from "../../../loading/LoadingResult";
import { useLoadableHttpResource } from "../../../hooks/useLoadableResource";
import { defined } from "../../../../core/defined";
import { DocumentDto } from "../../../../infra/api_responses/document";
import {
  DocMetadataState,
  useLoadDocumentGeneric,
} from "./useLoadDocumentGeneric";
import { HttpError, HttpResult } from "../../../../infra/HttpResult";
import { SharingInfoReloader, ShowDraftDataContext } from "../../../contexts";
import { SharingInfo } from "../../../files/SharingInfo";
import { statsApiV2 } from "../../../requests/statsApiV2";
import { Progress } from "../../../../core/progress";
import { DocCardState } from "../../stats/document-core/core";
import { assertNever } from "../../../../core/assert";
import { hasWriteLicensesForUninitializedDoc } from "../../../auth/permissions";
import { UserInfo } from "../../../auth/UserInfo";
import { BasicDoc, useGetCardGenerator } from "./_shared";
import { statsApi } from "../../../requests/statsApi";

export type Result = [
  LoadingResult<HttpError, SharingInfo>,
  SharingInfoReloader | undefined
];

type GetDocResult = HttpResult<BasicDoc>;

export function useLoadDocumentAndSharingInfo(
  documentId: number,
  appMessagesHandler: AppMessagesHandler | undefined
): Result {
  const showAdminDraftData = useContext(ShowDraftDataContext);

  const loadDoc = useCallback(
    (): Promise<GetDocResult> =>
      getDocument(documentId).then((doc: HttpResult<DocumentDto>) => {
        return doc.match({
          ok: (doc) => {
            return getVerifiedWorkspace(doc, statsApi, statsApiV2).then(
              (res) => {
                return HttpResult.fromOk({
                  ...res,
                  id: documentId,
                  title: doc.title,
                });
              }
            );
          },
          err: (err) => {
            return Promise.resolve(HttpResult.fromErr(err));
          },
        });
      }),
    [documentId]
  );

  const getDocumentMetadata = useCallback(
    (doc: BasicDoc, userInfo?: UserInfo): DocMetadataState => {
      const userHasApplicableWriteLicenses =
        defined(userInfo) && hasWriteLicensesForUninitializedDoc(doc, userInfo);
      const editModeAvailable = userHasApplicableWriteLicenses;
      return {
        id: doc.id,
        title: doc.title,
        thirdPartySharingDoc:
          doc.state.reportMeta?.thirdPartySharingDoc ?? false,
        userHasApplicableWriteLicenses: userHasApplicableWriteLicenses,
        editModeOn:
          editModeAvailable && (doc.state.reportMeta?.editModeOn ?? false),
        editModeAvailable,
      };
    },
    []
  );

  const getUninitializedCards = useCallback((doc: BasicDoc) => {
    return doc.state.cards.map((c) => {
      switch (c.type) {
        case "dataCard":
        case "microCard":
        case "pythonCard":
          return {
            ...c,
            initState: Progress.NotStarted,
          } as DocCardState;
        case "textCardCK":
        case "textCardSimple":
          return c as DocCardState;
      }
      assertNever(c);
    });
  }, []);

  const getCardGenerator = useGetCardGenerator(showAdminDraftData);

  const notReadyStatus = useLoadDocumentGeneric(
    loadDoc,
    getDocumentMetadata,
    getUninitializedCards,
    getCardGenerator,
    appMessagesHandler
  );

  const loadSharingInfo = useCallback(
    () => getNodeSharingInfo(documentId),
    [documentId]
  );
  const [sharingInfoLoadStatus, reloadSharingInfo] =
    useLoadableHttpResource(loadSharingInfo);

  const res: Result = useMemo(() => {
    return [sharingInfoLoadStatus, reloadSharingInfo];
  }, [reloadSharingInfo, sharingInfoLoadStatus]);

  const anyNotReady = useMemo(() => {
    return LoadingResult.anyNotReady(sharingInfoLoadStatus, notReadyStatus);
  }, [notReadyStatus, sharingInfoLoadStatus]);

  if (defined(anyNotReady)) {
    return [anyNotReady, undefined];
  }

  return res;
}
