import sanitize from "sanitize-html";

const allowedOembedDomains = [
  "youtube.com",
  "www.youtube.com",
  "youtu.be",
  "www.youtu.be",
];
const allowedIframeDomains = [
  "app.infostat.se",
  "dev-app.infostat.se",
  "infostat.local:3000",
  "infostat.local",
];
function isAllowedOembedDomain(url: string): boolean {
  const urlObj = new URL(url);
  return allowedOembedDomains.includes(urlObj.hostname);
}
function isAllowedIframeDomain(url: string): boolean {
  const urlObj = new URL(url);
  return allowedIframeDomains.includes(urlObj.hostname);
}

/**
 * Sanitize HTML for text cards to ensure it is safe to render.
 */
export function sanitizeTextCardHtml(dirtyHtml: string): string {
  return sanitize(dirtyHtml, {
    parseStyleAttributes: false,
    allowedTags: sanitize.defaults.allowedTags.concat([
      "img",
      "oembed",
      "iframe",
    ]),
    allowedSchemes: ["http", "https", "data", "mailto"],
    allowedAttributes: {
      ...sanitize.defaults.allowedAttributes,
      "*": [
        "style",
        "class",
        "contenteditable",
        "id",
        "width",
        "height",
        "alt",
      ],
      td: ["colspan", "rowspan"],
      oembed: ["url"],
      figure: (sanitize.defaults.allowedAttributes.figure ?? []).concat([
        "class",
        "contenteditable",
      ]),
      iframe: ["src", "style", "title", "sandbox"],
    },
    transformTags: {
      oembed: (_, attribs) => {
        if (attribs.url && isAllowedOembedDomain(attribs.url)) {
          return {
            tagName: "oembed",
            attribs,
          };
        }
        return { tagName: "", attribs: {} };
      },
      iframe: (_, attribs) => {
        if (attribs.src && isAllowedIframeDomain(attribs.src)) {
          return {
            tagName: "iframe",
            attribs,
          };
        }
        return { tagName: "", attribs: {} };
      },
    },
  });
}
