import { useCallback } from "react";

import { getEmbeddedDocument } from "../../../requests/docs/documents";
import {
  getVerifiedEmbeddedDoc,
  handleEmbeddedCardToCardStateLatest,
} from "../../stats/workspace/rebuild_state";
import {
  DocMetadataState,
  GeneratedCard,
  useLoadDocumentGeneric,
} from "./useLoadDocumentGeneric";
import { statsApiV2 } from "../../../requests/statsApiV2";
import { EmbedSettings, parseEmbedSettings } from "../../../embedded_cards";
import { WorkspaceLatest } from "../../stats/workspace/shared";
import { Progress } from "../../../../core/progress";
import { DocCardState } from "../../stats/document-core/core";
import { assertNever } from "../../../../core/assert";
import { statsApi } from "../../../requests/statsApi";
import { HttpResult } from "../../../../infra/HttpResult";

type BasicEmbeddedDoc = {
  embedSettings: EmbedSettings;
  doc: WorkspaceLatest;
};

export function useLoadEmbeddedDoc(embedKey: string) {
  const loadDoc = useCallback<() => Promise<HttpResult<BasicEmbeddedDoc>>>(
    () =>
      getEmbeddedDocument(embedKey).then(async (dto) => {
        return dto.match({
          ok: async (doc) => {
            return HttpResult.fromOk({
              embedSettings: parseEmbedSettings(doc.embed_settings),
              doc: await getVerifiedEmbeddedDoc(doc, statsApi, statsApiV2),
            });
          },
          err: (err) => {
            throw err;
          },
        });
      }),
    [embedKey]
  );

  const getDocumentMetadata = useCallback(
    (doc: BasicEmbeddedDoc): DocMetadataState => {
      return {
        id: -1,
        title: "-",
        editModeOn: false,
        editModeAvailable: false,
        userHasApplicableWriteLicenses: false,
      };
    },
    []
  );

  const getUninitializedCards = useCallback((doc: BasicEmbeddedDoc) => {
    return doc.doc.state.cards.map((c) => {
      switch (c.type) {
        case "dataCard":
        case "microCard":
          return {
            ...c,
            initState: Progress.NotStarted,
          } as DocCardState;
        case "textCardCK":
        case "textCardSimple":
          return c as DocCardState;
        case "pythonCard":
          throw new Error("Cannot have python card in embedded doc");
      }
      assertNever(c);
    });
  }, []);

  const getCardGenerator = useCallback(function* (doc: BasicEmbeddedDoc) {
    for (const c of doc.doc.state.cards) {
      switch (c.type) {
        case "dataCard":
          yield handleEmbeddedCardToCardStateLatest(
            c,
            statsApi,
            statsApiV2
          ).then((res) => {
            switch (res.type) {
              case "error":
                return { card: res };
              case "dataCard": {
                const link = doc.doc.state.cardColorSchemes.find(
                  (c) => c.cardId === res.id
                );
                return {
                  card: res,
                  colorScheme: doc.doc.state.colorSchemes.find(
                    (c) => c.id === link?.schemeId
                  ),
                };
              }
              case "textCardCK":
              case "textCardSimple":
                throw new Error("Cannot have text card in embedded");
              case "microCard": {
                throw new Error("Cannot have microcard in embedded doc");
              }
            }
            return { card: res } as GeneratedCard; // FIXME: color schemes
          });
          break;
        case "microCard":
        case "textCardCK":
        case "textCardSimple":
          yield Promise.resolve({
            card: c,
          } as GeneratedCard);
          break;
        case "pythonCard":
          throw new Error("Cannot have python card in embedded doc");
        default:
          assertNever(c);
      }
    }
  }, []);

  const status = useLoadDocumentGeneric(
    loadDoc,
    getDocumentMetadata,
    getUninitializedCards,
    getCardGenerator,
    undefined
  );
  return status;
}
