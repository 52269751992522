import { scaleLinear } from "d3-scale";
import * as d3scaleChromatic from "d3-scale-chromatic";
import { range } from "lodash";
import {
  COLOR_GOOD_GREEN,
  INFOSTAT_STD_LIGHT_BLUE,
  INFOSTAT_STD_RED,
} from "../../application/stats/shared/core/colors/colors";
import { assertNever } from "../../core/assert";
import { defined } from "../../core/defined";
import { SimpleCache } from "../../core/SimpleCache";

export type ColorRamp = readonly string[];

const cache = new SimpleCache<ColorRamp>(10);

export function getColorRamp(
  scheme: MicroColorScheme,
  steps: number
): ColorRamp {
  const cacheKey = `${scheme}-${steps}`;
  const cachedRamp = cache.get(cacheKey);
  if (defined(cachedRamp)) {
    return cachedRamp;
  }

  const interpolator = getInterpolator(scheme);
  const ramp = range(0, steps).map((i) => interpolator(i / steps));
  cache.set(cacheKey, ramp);
  return ramp;
}

function invertInterpolator<T>(interpolator: (t: number) => T) {
  return (num: number) => interpolator(1 - num);
}

function getInterpolator(scheme: MicroColorScheme): (t: number) => string {
  switch (scheme) {
    case "d3-div-RdYlGn":
      return d3scaleChromatic.interpolateRdYlGn;
    case "d3-div-GnYlRd":
      return invertInterpolator(d3scaleChromatic.interpolateRdYlGn);
    case "d3-div-BrBG":
      return d3scaleChromatic.interpolateBrBG;
    case "d3-div-BGBr":
      return invertInterpolator(d3scaleChromatic.interpolateBrBG);
    case "d3-white-blue":
      return d3scaleChromatic.interpolateBlues;
    case "d3-blue-white":
      return invertInterpolator(d3scaleChromatic.interpolateBlues);
    case "d3-white-purple":
      return d3scaleChromatic.interpolatePurples;
    case "d3-purple-white":
      return invertInterpolator(d3scaleChromatic.interpolatePurples);
    case "d3-white-red":
      return d3scaleChromatic.interpolateReds;
    case "d3-red-white":
      return invertInterpolator(d3scaleChromatic.interpolateReds);
    case "constant-red":
      return () => INFOSTAT_STD_RED;
    case "constant-green":
      return () => COLOR_GOOD_GREEN;
    case "constant-blue":
      return () => INFOSTAT_STD_LIGHT_BLUE;
    default:
      assertNever(scheme);
  }
}

export const microColorSchemes = [
  "d3-div-RdYlGn",
  "d3-div-GnYlRd",
  "d3-div-BrBG",
  "d3-div-BGBr",
  "d3-white-red",
  "d3-red-white",
  "d3-white-blue",
  "d3-blue-white",
  "d3-white-purple",
  "d3-purple-white",
  "constant-green",
  "constant-red",
  "constant-blue",
] as const;
export type MicroColorScheme = typeof microColorSchemes[number];

export interface ColorSettings {
  /**
   * Maximum/minimum z value (from normal distribution) used for coloring. Any greater/lesser values will be colored with the max/min color.
   */
  zMin: number;
  zMax: number;
  scheme: MicroColorScheme;
}

export function defaultMicroColorSettings(
  colorScheme?: MicroColorScheme,
  zMin?: number,
  zMax?: number
): ColorSettings {
  const colorSettings: ColorSettings = {
    zMin: zMin ?? -2.5,
    zMax: zMax ?? 2.5,
    scheme: colorScheme ?? "d3-div-RdYlGn",
  };
  return colorSettings;
}

export function colorFromZValue(
  zValue: number,
  colorSettings: ColorSettings
): string {
  const interpolator = getInterpolator(colorSettings.scheme);
  const { zMin, zMax } = colorSettings;
  const clampedValue =
    zValue < 0 ? Math.max(zMin, zValue) : Math.min(zMax, zValue);
  const scale = scaleLinear().domain([zMin, zMax]).range([0, 1]);
  const normalizedValue = scale(clampedValue);

  return interpolator(normalizedValue);
}
