import { defined } from "../../../core/defined";
import { Progress } from "../../../core/progress";
import { logger } from "../../../infra/logging";
import {
  createBarChartHorizontalDataAndColors,
  createBarChartHorizontalDataWithExistingColors,
} from "../../stats/chart_containers/createBarChartHorizontalData";
import {
  createBarChartVerticalDataAndColors,
  createBarChartVerticalDataWithExistingColors,
} from "../../stats/chart_containers/createBarChartVerticalData";
import {
  createLineChartDataAndColors,
  createLineChartDataWithExistingColors,
} from "../../stats/chart_containers/createLineChartData";
import { ChartDataUnknown } from "../../stats/shared/chart_data/ChartData";
import { ChartType } from "../../stats/shared/core/definitions";
import { DocCardMicro } from "./document-core/core";
import {
  ChartDataContainerV2,
  ChartDataState,
  DataLoadProgress,
  MicroMapState,
} from "./document-core/_core-shared";
import { ColorSchemeContainer } from "./document-style/definitions";
import { getMicroDatasetWithCacheV2 } from "../../requests/datasets/micro";
import { MicroGeoTree } from "../../stats/shared/MicroGeoTree";
import { MicroDataset } from "../../stats/datasets/MicroDataset";
import { cardColors } from "./document-core/queries/shared";
import { getCardOutputWidth } from "./_shared";
import { validateMicroCardSelection } from "./document-core/card_info";
import { wait } from "../../../core/wait";
import { DrawableGroupV2 } from "../../stats/datasets/MicroDatasetGeo";
import {
  DataSelectionMicro,
  microSelectionPrimary,
} from "./document-core/core-micro";
import { isEqual } from "lodash";
import { assertNever } from "../../../core/assert";
import {
  CustomThemeSpecApplied,
  ExtendedAppearanceSettings,
} from "../../stats/shared/core/colors/colorSchemes";
import { DataOutputSettings } from "./document-core/DataOutputSettings";

function cardWithDataLoadingProgress(
  card: DocCardMicro,
  progress: DataLoadProgress
): DocCardMicro {
  const cardWithProgress: DocCardMicro = {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        primaryProgress: progress,
      },
    },
  };
  switch (card.data.selectedTab) {
    case "chart":
      cardWithProgress.data.loadedData = {
        ...cardWithProgress.data.loadedData,
        chartDataState: {
          ...cardWithProgress.data.loadedData?.chartDataState,
          loadProgress: progress,
        },
      };
      break;
    case "table":
      cardWithProgress.data.loadedData = {
        ...cardWithProgress.data.loadedData,
        tableDataState: {
          ...cardWithProgress.data.loadedData?.tableDataState,
          loadProgress: progress,
        },
      };
      break;
    case "map-view":
      cardWithProgress.data.loadedData = {
        ...cardWithProgress.data.loadedData,
        microMapState: {
          ...cardWithProgress.data.loadedData?.microMapState,
          loadedMicroMapDataProgress: progress,
        },
      };
      break;
  }

  return cardWithProgress;
}

export async function loadAndStoreDataMicro(
  updatedCard: DocCardMicro,
  setCard: (c: DocCardMicro) => void,
  shouldAbort: () => boolean,
  geoTree: MicroGeoTree,
  adminShowDraftData: boolean,
  appearanceSettings: ExtendedAppearanceSettings,
  colors: ColorSchemeContainer | undefined,
  handleSaveCard?: (c: DocCardMicro) => void
) {
  const primarySelection = microSelectionPrimary(updatedCard);
  // No need to load data here if no primary selection exists.
  // (geo selections are not handled as part of central state)
  if (!defined(primarySelection)) {
    return setCard(updatedCard);
  }
  // No need to load data when in map-select mode
  if (updatedCard.data.selectedTab === "map-select") {
    return setCard(updatedCard);
  }

  const previousLoadError =
    updatedCard.data.loadedData?.primaryProgress?.type === Progress.Error;
  // If the previous state had a load error, we don't show the progress
  if (!previousLoadError) {
    const cardWithProgress = cardWithDataLoadingProgress(updatedCard, {
      type: Progress.InProgress,
    });
    setCard(cardWithProgress);
  }

  await wait(1);

  const cardWithData = await cardWithUpdatedDataStateMicro(
    shouldAbort,
    updatedCard,
    geoTree,
    adminShowDraftData,
    appearanceSettings,
    colors
  );
  // Aborting due to later update having started. No need to set progress here
  // since next call will
  if (shouldAbort()) {
    return;
  }
  if (!defined(cardWithData)) {
    return;
  }

  setCard(cardWithData);
  handleSaveCard?.(cardWithData);
}

export async function cardWithUpdatedDataStateMicro(
  shouldAbort: () => boolean,
  card: DocCardMicro,
  microGeoTree: MicroGeoTree,
  adminShowDraftData: boolean,
  appearanceSettings: ExtendedAppearanceSettings,
  colors?: ColorSchemeContainer
): Promise<DocCardMicro | undefined> {
  if (card.data.selectedTab === "map-select") {
    return card;
  }
  const selectedAreas = card.data.geoSelections?.selected;
  if (!defined(selectedAreas) || selectedAreas.length === 0) {
    return card;
  }
  if (!defined(card.data.dataSelections?.[0].timeSelection)) {
    logger.error("No time selection -- should not happen");
    return card;
  }

  const validationError = validateMicroCardSelection(card);
  if (defined(validationError)) {
    return card;
  }

  const primarySelection = microSelectionPrimary(card);
  if (!defined(primarySelection)) {
    return;
  }

  const datasetResult = await getMicroDatasetWithCacheV2(
    card,
    microGeoTree,
    adminShowDraftData,
    false
  );

  if (datasetResult.type === "err") {
    switch (datasetResult.error) {
      case "bad-client-input":
      case "no-data":
      case "unauthorized":
      case "unknown-error":
        return cardWithDataLoadingProgress(card, {
          type: Progress.Error,
          error: datasetResult.error,
        });
    }
    assertNever(datasetResult.error);
  }

  if (shouldAbort()) {
    return;
  }

  const dataset = datasetResult.data;
  if (!defined(dataset)) {
    return;
  }

  const outputWidth = getCardOutputWidth(card);
  return cardWithUpdatedDataStateMicroInner(
    card,
    dataset,
    outputWidth,
    appearanceSettings,
    colors
  );
}

export async function cardWithUpdatedDataOutputSettingsMicro(
  cardOld: DocCardMicro,
  appearanceSettings: ExtendedAppearanceSettings,
  settings: DataOutputSettings
) {
  const card: DocCardMicro = {
    ...cardOld,
    data: {
      ...cardOld.data,
      settings: {
        ...cardOld.data.settings,
        dataOutputSettings: settings,
      },
    },
  };
  const datasetOld = card.data.loadedData?.primaryDataset;
  if (!defined(datasetOld)) {
    logger.warn("No dataset for card");
    return;
  }
  const dataset = datasetOld.copyWithSettings(settings);

  const outputWidth = getCardOutputWidth(card);
  return cardWithUpdatedDataStateMicroInner(
    card,
    dataset,
    outputWidth,
    appearanceSettings,
    cardColors(card)
  );
}

export async function cardWithUpdatedDataStateMicroInner(
  card: DocCardMicro,
  dataset: MicroDataset,
  outputWidth: number,
  appearanceSettings: ExtendedAppearanceSettings,
  colors?: ColorSchemeContainer
): Promise<DocCardMicro | undefined> {
  switch (card.data.selectedTab) {
    case "chart":
      return updateChart(
        card,
        dataset,
        outputWidth,
        appearanceSettings,
        colors
      );
    case "table":
      return updateTable(dataset, card);
    case "map-select":
      // Should not happen -- we check early for this
      return card;
    case "map-view":
      return updateMapView(dataset, card);
  }

  return;
}

/**
 * Create ChartDataState.
 * If no color scheme container is supplied, a new one will be created.
 */
async function chartDataToDataStateMicro(
  chartData: ChartDataUnknown,
  prevLoadedChartData: ChartDataContainerV2 | undefined,
  outputMaxWidth: number,
  defaultCustomColors: CustomThemeSpecApplied,
  colorSchemeContainer?: ColorSchemeContainer
): Promise<ChartDataState | undefined> {
  const vizAreaWidth = outputMaxWidth;
  const windowHeight = window.innerHeight;

  switch (chartData.chartType(vizAreaWidth)) {
    case ChartType.line:
      return {
        loadProgress: { type: Progress.Success },
        loadedChartData: !defined(colorSchemeContainer)
          ? createLineChartDataAndColors(
              chartData,
              vizAreaWidth,
              windowHeight,
              defaultCustomColors
            )
          : createLineChartDataWithExistingColors(
              chartData,
              prevLoadedChartData,
              vizAreaWidth,
              windowHeight,
              colorSchemeContainer
            ),
      };
    case ChartType.barVertical:
      return {
        loadProgress: { type: Progress.Success },
        loadedChartData: !defined(colorSchemeContainer)
          ? createBarChartVerticalDataAndColors(
              chartData,
              vizAreaWidth,
              windowHeight,
              defaultCustomColors
            )
          : createBarChartVerticalDataWithExistingColors(
              chartData,
              vizAreaWidth,
              windowHeight,
              colorSchemeContainer
            ),
      };
    case ChartType.barHorizontal:
      return {
        loadProgress: { type: Progress.Success },
        loadedChartData: !defined(colorSchemeContainer)
          ? createBarChartHorizontalDataAndColors(
              chartData,
              vizAreaWidth,
              defaultCustomColors
            )
          : createBarChartHorizontalDataWithExistingColors(
              chartData,
              vizAreaWidth,
              colorSchemeContainer
            ),
      };
  }

  return;
}

function updateMapView(
  dataset: MicroDataset,
  card: DocCardMicro
): DocCardMicro {
  const data = dataset.microMapData();
  const dataSelections = card.data.dataSelections;
  const microMapState = card.data.loadedData?.microMapState;
  const microMapStateUpdated: MicroMapState = {};
  if (defined(microMapState)) {
    microMapStateUpdated.loadedLines = microMapState.loadedLines?.filter(
      (l) => {
        return itemSelected(l, dataSelections);
      }
    );
    microMapStateUpdated.loadedPolygons = microMapState.loadedPolygons?.filter(
      (p) => {
        return itemSelected(p, dataSelections);
      }
    );
    microMapStateUpdated.loadedPoints = microMapState.loadedPoints?.filter(
      (p) => {
        return itemSelected(p, dataSelections);
      }
    );
  }

  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        primaryProgress: { type: Progress.Success },
        primaryDataset: dataset,
        microMapState: {
          ...microMapState,
          ...microMapStateUpdated,
          loadedMicroMapDataProgress: { type: Progress.Success },
          loadedMicroMapData: data,
        },
      },
    },
  };
}

function updateTable(dataset: MicroDataset, card: DocCardMicro): DocCardMicro {
  const tableSpec = dataset.table();
  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        primaryDataset: dataset,
        primaryProgress: { type: Progress.Success },
        tableDataState: {
          loadProgress: { type: Progress.Success },
          loadedTableData: tableSpec,
        },
      },
    },
  };
}

async function updateChart(
  card: DocCardMicro,
  dataset: MicroDataset,
  outputMaxWidth: number,
  appearanceSettings: ExtendedAppearanceSettings,
  colors: ColorSchemeContainer | undefined
): Promise<DocCardMicro> {
  const chartData = dataset.chartData();
  const chartDataState = await chartDataToDataStateMicro(
    chartData,
    card.data.loadedData?.chartDataState?.loadedChartData,
    outputMaxWidth,
    appearanceSettings.defaultTheme,
    colors
  );
  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        primaryProgress: { type: Progress.Success },
        primaryDataset: dataset,
        chartDataState,
      },
    },
  };
}

function itemSelected(
  drawableGroup: DrawableGroupV2,
  selections: DataSelectionMicro[] | undefined
): boolean {
  return (
    selections?.some((s) =>
      isEqual([...s.subjectPath, s.measure?.measure], drawableGroup.measurePath)
    ) ?? false
  );
}
