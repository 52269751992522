import { useMemo, useRef } from "react";
import _ from "lodash";

import { InfoBox, InfoBoxFoldout } from "../../../../../components/InfoBox";
import { useUpdateInfoTabThumbnail } from "../../../../../lib/application/hooks/thumbnails";
import { StatsDataset } from "../../../../../lib/application/stats/datasets/StatsDataset";
import {
  DataSourceInfo,
  displayAggregationMethod,
} from "../../../../../lib/application/stats/datasets/DatasetGeneric";
import { SurveyDataset } from "../../../../../lib/application/stats/datasets/SurveyDataset";
import { defined } from "../../../../../lib/core/defined";
import { PivotPlaceholder } from "../data_card/PivotPlaceholder";

import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { ExternalLink } from "../../../../../components/ExternalLink";
import { AlertBox } from "../../../../../components/AlertBox";
import { displayShortenedUrl } from "../../../../../lib/application/url";
import { shortenMunicipalityLabel } from "../../../../../lib/domain/names";
import { Button } from "../../../../../components/Button";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";

import { groupingExportToExcel } from "../../../../../lib/application/exports/groupingExportToExcel";

import "./InfoTab.scss";

interface Props {
  dataset?: StatsDataset | SurveyDataset | SurveyStringDataset;
  cardId: string;
}
export function InfoTab(props: Props) {
  const { dataset } = props;

  const htmlContainerRef = useRef<null | HTMLDivElement>(null);
  useUpdateInfoTabThumbnail(htmlContainerRef, props.cardId, dataset);

  if (!defined(dataset)) {
    return <PivotPlaceholder icon={"grouped-bar-chart"}></PivotPlaceholder>;
  }
  return (
    <div className="dataset-info tab-content">
      <div ref={htmlContainerRef}>
        <InfoTabInner {...props} dataset={dataset}></InfoTabInner>
      </div>
    </div>
  );
}

function InfoTabInner(props: Required<Props>) {
  const { dataset } = props;

  if (dataset instanceof SurveyDataset) {
    return (
      <DisplaySurveyInfo
        dataset={dataset}
        infostatNote={dataset.infostatPublicComment()}
      ></DisplaySurveyInfo>
    );
  } else if (dataset instanceof SurveyStringDataset) {
    return (
      <DisplaySurveyStringInfo
        dataset={dataset}
        infostatNote={dataset.infostatPublicComment()}
      />
    );
  }
  return (
    <>
      <DisplayStatsInfo
        dataset={dataset}
        infostatNote={dataset.infostatPublicComment()}
      ></DisplayStatsInfo>
    </>
  );
}

function DisplayStatsInfo(props: {
  dataset: StatsDataset;
  infostatNote: string[];
}) {
  const { dataset } = props;
  const descriptions = dataset.sourceInfo;
  const groupingDescriptions = dataset.groupingSourceInfo;

  return (
    <section className="data-source-info">
      {defined(groupingDescriptions) ? (
        <DisplayGroupingMainInfo
          mainMeasure={descriptions}
          groupingMeasure={groupingDescriptions}
        ></DisplayGroupingMainInfo>
      ) : (
        <>
          <h2>{descriptions.descriptionLong}</h2>
        </>
      )}

      <StatsSingleSourceInfo
        useHeader={defined(groupingDescriptions)}
        descriptions={descriptions}
        infostatNote={dataset.infostatPublicComment()}
      ></StatsSingleSourceInfo>

      {defined(groupingDescriptions) && (
        <StatsSingleSourceInfo
          useHeader={true}
          descriptions={groupingDescriptions}
          infostatNote={[]}
        ></StatsSingleSourceInfo>
      )}
    </section>
  );
}

function StatsSingleSourceInfo(props: {
  useHeader: boolean;
  infostatNote?: string[];
  descriptions: DataSourceInfo;
}) {
  const { descriptions } = props;
  const {
    externalSource,
    externalDescription,
    externalDescriptionLong,
    source,
    sourceUrl,
    descriptionLong: long,
    lastUpdate,
    aggregationNote,
  } = descriptions;
  return (
    <section>
      {props.useHeader && <h2>{props.descriptions.measureTitle}</h2>}
      {defined(props.infostatNote) && (
        <InfostatPublicComment
          infostatNote={props.infostatNote}
        ></InfostatPublicComment>
      )}
      <h3>Källa: {source}</h3>
      {long && long.map((text, i) => <p key={i}>{text}</p>)}
      {defined(externalSource) && <h3>Källa: {externalSource}</h3>}
      {externalDescription && <p>{externalDescription}</p>}
      {externalDescriptionLong &&
        externalDescriptionLong.map((text, i) => <p key={i}>{text}</p>)}

      {defined(aggregationNote) && (
        <>
          <h3>Infostats kommentar om aggregeringsmetod</h3>
          <p>{aggregationNote}</p>
        </>
      )}
      {(nonEmptyString(sourceUrl) || defined(lastUpdate)) && (
        <AlertBox>
          <div>
            <strong>Nedhämtat från källa</strong>: {lastUpdate}
          </div>
          <>
            {nonEmptyString(sourceUrl) && (
              <ExternalLink
                link={sourceUrl}
                text={displayShortenedUrl(sourceUrl)}
              ></ExternalLink>
            )}
          </>
        </AlertBox>
      )}
    </section>
  );
}

function DisplaySurveyStringInfo(props: {
  dataset: SurveyStringDataset;
  infostatNote: string[];
}) {
  const { dataset } = props;

  const descriptions = dataset.sourceInfo;

  return (
    <div className="data-source-info">
      <h2>{descriptions.descriptionLong}</h2>
      <SingleSurveyMeasureInfo
        {...props}
        useHeader={false}
      ></SingleSurveyMeasureInfo>
    </div>
  );
}

function DisplaySurveyInfo(props: {
  dataset: SurveyDataset;
  infostatNote: string[];
}) {
  const { dataset } = props;

  const descriptions = dataset.sourceInfo;
  const groupingDescriptions = dataset.groupingSourceInfo;

  return (
    <div className="data-source-info">
      {defined(groupingDescriptions) ? (
        <DisplayGroupingMainInfo
          mainIsSurvey
          mainMeasure={descriptions}
          groupingMeasure={groupingDescriptions}
        ></DisplayGroupingMainInfo>
      ) : (
        <h2>{descriptions.descriptionLong}</h2>
      )}

      <SingleSurveyMeasureInfo
        {...props}
        useHeader={defined(groupingDescriptions)}
      ></SingleSurveyMeasureInfo>

      {defined(groupingDescriptions) && (
        <StatsSingleSourceInfo
          useHeader
          descriptions={groupingDescriptions}
        ></StatsSingleSourceInfo>
      )}
    </div>
  );
}

function SingleSurveyMeasureInfo(props: {
  dataset: SurveyDataset | SurveyStringDataset;
  useHeader: boolean;
  infostatNote: string[];
}) {
  const { dataset, infostatNote } = props;
  const validResponses = dataset.validResponses;
  const surveyInfo = dataset.surveyInfo;

  const interviewPeriod = surveyInfo.interview_period;
  const descriptions = dataset.sourceInfo;
  const filterInfo = surveyInfo.filter_info;
  const audienceInfo = descriptions.surveyTargetAudience;

  const showSingleLineBaseCountOverview =
    _.uniq(Object.values(dataset.baseCountsOverview)).length === 1;

  const showSingleLineBaseCountDetailed =
    Object.values(dataset.baseCountsDetailed).length === 1;

  const showSingleLineBaseCountTotals =
    Object.values(dataset.baseCountsTotals).length === 1;

  const showBaseCountInfo =
    Object.entries(dataset.baseCountsDetailed).length > 0;

  return (
    <section>
      <div className="section">
        {props.useHeader && <h2>{descriptions.descriptionLong}</h2>}
        <InfostatPublicComment
          infostatNote={infostatNote}
        ></InfostatPublicComment>
        <h3>Källa</h3>
        <p>{descriptions.sourceLong ?? descriptions.source}</p>
        <p>
          <span className="list-item-name">Fråga</span>: {surveyInfo.question}
        </p>
        {dataset.surveySubquestions?.map((subquestion) => (
          <p key={subquestion.label}>
            <span>
              <span className="list-item-name">
                Delfråga ({subquestion.label}):{" "}
              </span>
              {subquestion.values.join(", ")}
            </span>
          </p>
        ))}
        {defined(validResponses) && (
          <p>
            {validResponses.map((d) => (
              <span key={d.label}>
                <span className="list-item-name">
                  {d.label !== "Svarsalternativ"
                    ? `Svarsalternativ (${d.label}): `
                    : "Svarsalternativ: "}
                </span>
                {d.values.join(", ")}
              </span>
            ))}
          </p>
        )}
        {defined(audienceInfo) && (
          <p>
            <span className="list-item-name">Målgrupp</span>: {audienceInfo}
          </p>
        )}
        {defined(filterInfo) && (
          <p>
            <span className="list-item-name">Filter</span>: {filterInfo}
          </p>
        )}
        <p>
          <span className="list-item-name">Period</span>:{" "}
          {interviewPeriod.join(" – ")}
        </p>
        {showBaseCountInfo && (
          <p>
            <span className="list-item-name">Antal intervjuer</span>
            {showSingleLineBaseCountOverview && (
              <>
                {": "}
                {Object.values(dataset.baseCountsOverview)[0]}
              </>
            )}
          </p>
        )}
        {!showSingleLineBaseCountOverview &&
          Object.entries(dataset.baseCountsOverview).map(([key, value]) => {
            return (
              <p key={key}>
                {key}: {value}
              </p>
            );
          })}
        {defined(dataset.dubiousBaseDimensions) &&
          dataset.dubiousBaseDimensions.length > 0 && (
            <>
              <p className="compact">
                <span className="list-item-name">
                  För följande mätpunkter är antalet svar lågt och resultatet
                  bör tolkas försiktigt.
                </span>
              </p>
              {dataset.dubiousBaseDimensions.map((v) => (
                <p key={v} className="compact">
                  {v}
                </p>
              ))}
            </>
          )}
        {showBaseCountInfo && (
          <InfoBoxFoldout
            title={"Visa detaljerad information om antal intervjuer"}
          >
            <p className="base-count-details-description">
              Antal visar genomsnittligt antal respondenter per mätomgång,
              avrundat till hela tiotal.
            </p>

            <>
              {Object.values(dataset.baseCountsTotals).length > 0 && (
                <p>
                  <span className="list-item-name">
                    Samtliga per delfråga, utan filter
                  </span>
                  {showSingleLineBaseCountTotals && (
                    <>: {Object.values(dataset.baseCountsTotals)[0]}</>
                  )}
                </p>
              )}
              {!showSingleLineBaseCountTotals &&
                Object.entries(dataset.baseCountsTotals).map(([key, value]) => {
                  return (
                    <p key={key}>
                      {key}: {value}
                    </p>
                  );
                })}
            </>

            <p>
              <span className="list-item-name">
                Antal intervjuer per nedbrytning
              </span>
              {showSingleLineBaseCountDetailed && (
                <>: {Object.values(dataset.baseCountsDetailed)[0]}</>
              )}
            </p>
            <>
              {!showSingleLineBaseCountDetailed &&
                Object.entries(dataset.baseCountsDetailed).map(
                  ([key, value]) => {
                    return (
                      <p key={key}>
                        {key}: {value}
                      </p>
                    );
                  }
                )}
            </>
          </InfoBoxFoldout>
        )}
      </div>

      {descriptions.methodDescription && (
        <InfoBox
          mainHeader="Metodteknisk bakgrundsinformation"
          sections={[
            {
              text: descriptions.methodDescription,
            },
          ]}
        ></InfoBox>
      )}
    </section>
  );
}

function InfostatPublicComment(props: { infostatNote: string[] }): JSX.Element {
  return props.infostatNote.length > 0 ? (
    <>
      <h3>Infostats kommentar</h3>
      {props.infostatNote.map((note) => (
        <p key={note}>{note}</p>
      ))}
    </>
  ) : (
    <></>
  );
}
function DisplayGroupingMainInfo(props: {
  mainIsSurvey?: boolean;
  mainMeasure: DataSourceInfo;
  groupingMeasure: DataSourceInfo;
}) {
  const descriptions = props.mainMeasure;
  const groupingDescriptions = props.groupingMeasure;
  const groupingClassBoundaries = groupingDescriptions.classBoundaries;
  const aggregationMethodPrimary = defined(descriptions.aggregationMethod)
    ? displayAggregationMethod(descriptions.aggregationMethod, true)
    : undefined;
  const municipalities = useMemo(() => {
    const m = groupingDescriptions.groupingMunicipalities;
    if (!defined(m)) {
      return;
    }
    const dict: { [key: string]: string[] } = {};
    m.forEach((group) => {
      dict[group.grouping] = group.labels.map((l) =>
        shortenMunicipalityLabel(l)
      );
    });

    return dict;
  }, [groupingDescriptions.groupingMunicipalities]);

  const municipalitiesWithValues = useMemo(() => {
    const m = groupingDescriptions.groupingMunicipalities;
    if (!defined(m)) {
      return;
    }
    const col1Label = "Kommun";
    const col2Label = props.groupingMeasure.descriptionLong?.join(" ") ?? "";
    const dict: { [key: string]: (string | number | null)[] } = {
      [col1Label]: [],
      [col2Label]: [],
    };
    for (const group of m) {
      for (const label of group.labels) {
        dict[col1Label].push(shortenMunicipalityLabel(label));
        dict[col2Label].push(group.grouping);
      }
    }

    return dict;
  }, [
    groupingDescriptions.groupingMunicipalities,
    props.groupingMeasure.descriptionLong,
  ]);

  const groupingClassBoundariesTable = useMemo(() => {
    const classBoundariesNumeric = groupingDescriptions.classBoundariesNumeric;
    if (!defined(classBoundariesNumeric)) {
      return;
    }
    const col1 = "Klass";
    const col2 = "Min (exklusive nedre gräns)";
    const col3 = "Max (inklusive övre gräns)";
    const dict: { [key: string]: (null | number | string)[] } = {
      [col1]: [],
      [col2]: [],
      [col3]: [],
    };
    for (const boundary of classBoundariesNumeric) {
      dict[col1].push(boundary.label);
      dict[col2].push(boundary.minExclusive ?? null);
      dict[col3].push(boundary.maxInclusive ?? null);
    }

    return dict;
  }, [groupingDescriptions.classBoundariesNumeric]);

  return (
    <section>
      <h2>{descriptions.measureTitle}</h2>
      <h3>
        {props.mainIsSurvey
          ? "Andel svarande indelat efter: "
          : "Indelat efter: "}
        {groupingDescriptions.measureTitle}
      </h3>
      {defined(aggregationMethodPrimary) && (
        <p>
          Värdena är beräknade som {aggregationMethodPrimary} av kommunernas
          värden inom respektive grupp.
        </p>
      )}
      {defined(groupingClassBoundaries) && (
        <div>
          <h3>
            Nivåindelning av{" "}
            {groupingDescriptions.descriptionLong?.join(", ").toLowerCase()}:
          </h3>
          <div>Enhet: {groupingDescriptions.unitLabel}</div>
          <table className="margin-top-sm">
            <thead></thead>
            <tbody>
              {groupingClassBoundaries.map((span) => (
                <tr key={span.label}>
                  <td>{span.label}</td>
                  <td>
                    {span.minExclusive ?? ""}–{span.maxInclusive}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br></br>
          <p>
            För varje nivå är den lägre gränsen exkluderad och den övre gränsen
            inkluderad i det givna intervallet.
          </p>
        </div>
      )}
      {defined(municipalities) && (
        <InfoBoxFoldout title={"Visa kommuner"}>
          <>
            {defined(municipalitiesWithValues) && (
              <section>
                <Button
                  title="Exportera till Excel"
                  onClick={() =>
                    groupingExportToExcel(
                      "kommuner.xlsx",
                      municipalitiesWithValues,
                      groupingClassBoundariesTable
                    )
                  }
                ></Button>
              </section>
            )}
          </>
          <>
            {Object.entries(municipalities).map(([grouping, labels]) => {
              return (
                <div key={grouping}>
                  <section>
                    <h3>
                      {grouping} ({labels.length} kommuner)
                    </h3>
                    <div className="grid-container">
                      {labels.map((label) => (
                        <div className="grid-item" key={label}>
                          {label}
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              );
            })}
          </>
        </InfoBoxFoldout>
      )}
    </section>
  );
}
