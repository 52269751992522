import { config } from "../../../config";
import { defined } from "../../core/defined";
import {
  emptyGeoSelections,
  geoSelectionsToCodes,
} from "../../domain/geography";
import {
  CreateDataLinksResultDtoRT,
  DataLinkDtoRT,
  DataLinksListDtoRT,
} from "../../infra/api_responses/data_links";
import {
  DocCardMicro,
  DocCardPython,
  DocCardStats,
} from "../state/stats/document-core/core";
import {
  getComputatationInputPrecisionSurvey,
  numDecimalsForStatsComputation,
} from "./common_requests";
import { getNumDecimalsForComputationMicro } from "./datasets/micro";
import {
  UserDefinedSeriesDto,
  userDefinedSeriesToDto,
} from "./datasets/user_defined_series";
import { cardToDataframe } from "./python/requests";
import { authedRequest, decodedAuthedRequest } from "./shared";

export interface CreateDataLinkParams {
  dataId: number;
  geocodes: string[];
  dateStart: string;
  dateEnd: string;
  dimensions: { [key: string]: number[] | undefined };
  lockToLatest: boolean;
  includeSurveyRefRows: boolean;
  userDefinedSeries?: UserDefinedSeriesDto[];
}

function docCardMikroToCreateDataLinkParams(
  card: DocCardMicro
): CreateDataLinkParams {
  if (
    !defined(card.data.dataSelections) ||
    card.data.dataSelections.length !== 1
  ) {
    throw new Error("Exactly one data selection is supported");
  }

  const selection = card.data.dataSelections[0];
  const measure = selection.measure;
  if (!defined(measure)) {
    throw new Error("Measure is required");
  }

  const dateStart = selection.timeSelection?.[0];
  const dateEnd = selection.timeSelection?.[1];
  if (!dateStart || !dateEnd) {
    throw new Error("Date range is required");
  }

  const geocodes: string[] = [];
  for (const area of card.data.geoSelections?.selected ?? []) {
    if (area.type === "deso") {
      geocodes.push(area.props.deso);
    } else if (area.type === "regso") {
      geocodes.push(area.props.regso);
    }
  }

  return {
    dataId: measure.id,
    geocodes,
    dateStart: dateStart,
    dateEnd: dateEnd,
    dimensions: selection.selectedDimensions,
    lockToLatest: card.data.lockToLatestTime ?? false,
    includeSurveyRefRows: false,
    userDefinedSeries: userDefinedSeriesToDto(
      card.data.settings.dataOutputSettings.computedVariablesV3
    ),
  };
}

function docCardStatsToCreateDataLinkParams(
  card: DocCardStats
): CreateDataLinkParams {
  if (card.data.dataSelections.length !== 1) {
    throw new Error("Exactly one data selection is supported");
  }
  const selection = card.data.dataSelections[0];
  const measureSelection = selection.measureSelection;
  if (!defined(measureSelection)) {
    throw new Error("Measure selection is required");
  }
  const measure = measureSelection.measure;

  const dateStart = card.data.timeSelection?.[0];
  const dateEnd = card.data.timeSelection?.[1];
  if (!dateStart || !dateEnd) {
    throw new Error("Date range is required");
  }
  return {
    dataId: measure.data_id,
    geocodes: geoSelectionsToCodes(
      card.data.geoSelections ?? emptyGeoSelections()
    ),
    dateStart: dateStart,
    dateEnd: dateEnd,
    dimensions: measureSelection.breakdowns,
    lockToLatest: card.data.lockToLatestTime ?? false,
    includeSurveyRefRows: card.data.settings.showReferenceLines ?? false,
    userDefinedSeries: userDefinedSeriesToDto(
      card.data.settings.computedVariablesV3
    ),
  };
}

export function createDataFrameDataLink(
  card: DocCardPython,
  description: string
) {
  const df = cardToDataframe(card);
  return decodedAuthedRequest(
    config.apis.statsV2,
    "pythonanalysis/datalinks",
    {
      stats_data_frame: df,
      description,
    },
    "POST",
    CreateDataLinksResultDtoRT
  );
}

export function createMikroDataLink(card: DocCardMicro, description: string) {
  const params = docCardMikroToCreateDataLinkParams(card);
  return decodedAuthedRequest(
    config.apis.statsV2,
    "mikro/datalinks",
    {
      mikro_id: params.dataId,
      geocodes: params.geocodes,
      date_start: params.dateStart,
      date_end: params.dateEnd,
      dimensions: params.dimensions,
      lock_to_latest: params.lockToLatest,
      description,
      user_defined_series: params.userDefinedSeries?.map((series) => {
        return {
          ...series,
          decimal_places_for_calculation: getNumDecimalsForComputationMicro(
            card.data.settings.dataOutputSettings
          ),
        };
      }),
    },
    "POST",
    CreateDataLinksResultDtoRT
  );
}

export function createStatsDataLink(card: DocCardStats, description: string) {
  const params = docCardStatsToCreateDataLinkParams(card);
  return decodedAuthedRequest(
    config.apis.statsV2,
    "stats/datalinks",
    {
      data_id: params.dataId,
      geocodes: params.geocodes,
      date_start: params.dateStart,
      date_end: params.dateEnd,
      dimensions: params.dimensions,
      lock_to_latest: params.lockToLatest,
      description,
      user_defined_series: params.userDefinedSeries?.map((series) => {
        return {
          ...series,
          decimal_places_for_calculation: numDecimalsForStatsComputation(
            card.data.settings
          ),
        };
      }),
    },
    "POST",
    CreateDataLinksResultDtoRT
  );
}

export function createSurveyDataLink(card: DocCardStats, description: string) {
  const params = docCardStatsToCreateDataLinkParams(card);
  return decodedAuthedRequest(
    config.apis.statsV2,
    "survey/datalinks",
    {
      data_id: params.dataId,
      date_start: params.dateStart,
      date_end: params.dateEnd,
      dimensions: params.dimensions,
      lock_to_latest: params.lockToLatest,
      include_ref_rows: params.includeSurveyRefRows,
      description,
      user_defined_series: params.userDefinedSeries?.map((series) => {
        return {
          ...series,
          decimal_places_for_calculation: getComputatationInputPrecisionSurvey(
            card.data.settings
          ),
        };
      }),
    },
    "POST",
    CreateDataLinksResultDtoRT
  );
}

export function getUserDataLinks() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me/datalinks",
    undefined,
    "GET",
    DataLinksListDtoRT
  );
}

export function getUserDataFrameLinks() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me/pythonanalysis/datalinks",
    undefined,
    "GET",
    DataLinksListDtoRT
  );
}

export function patchDataLinkDescription(id: number, description: string) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/datalinks/${id}`,
    { description },
    "PATCH"
  );
}

export function patchDataLinkMicroDescription(id: number, description: string) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/mikro/datalinks/${id}`,
    { description },
    "PATCH"
  );
}

export function patchDataFrameLinkDescription(id: number, description: string) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/pythonanalysis/datalinks/${id}`,
    { description },
    "PATCH"
  );
}

export function deleteDataLink(id: number) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/datalinks/${id}`,
    undefined,
    "DELETE"
  );
}

export function deleteDataLinkMicro(id: number) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/mikro/datalinks/${id}`,
    undefined,
    "DELETE"
  );
}

export function deleteDataFrameLink(id: number) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/pythonanalysis/datalinks/${id}`,
    undefined,
    "DELETE"
  );
}
