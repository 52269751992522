import { useCallback, useContext } from "react";
import {
  HideButtonProps,
  InfostatSearchBox,
} from "../../../components/InfostatSearchBox";
import { statsSearchV2 } from "../../../lib/application/requests/common_requests";
import { defined } from "../../../lib/core/defined";
import { TimeResolution } from "../../../lib/domain/time";
import { ShowDraftDataContext } from "../../../lib/application/contexts";
import {
  SearchResultItemV2,
  SearchResultMeasure,
} from "../../../lib/infra/api_responses/search";
import { logger } from "../../../lib/infra/logging";
import { getSubjectPath } from "../../../lib/domain/measure";
import { INFOSTAT_SURVEY_SUBAREA } from "./cards/data_card/selections/shared";
import infostatIconSource from "../../../logo_icon_color.svg";
import { classNames } from "../../../lib/core/classNames";

export type SelectMeasureResultHandler = (
  measureId: number,
  subjectPath: string[],
  dimensionDataColumn?: string,
  dimensionValueId?: number
) => void;

interface Props {
  placeholder?: string;
  autofocus: boolean;
  autofocusDelayMs?: number;
  onSelectPath: (tree: string[]) => void;
  onSelectMeasure: SelectMeasureResultHandler;
  className?: string;
  hideButton?: HideButtonProps;
  canBeGroupedOnly?: boolean;
}
export function SearchMain(props: Props) {
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const handleSearch = useCallback(
    (searchString: string) => {
      return statsSearchV2(
        searchString,
        TimeResolution.maximal(),
        adminShowDraftData,
        undefined,
        props.canBeGroupedOnly
      )
        .then((resultsRaw) => {
          const results = resultsRaw.unwrap();
          if (!defined(results)) {
            return [];
          }
          return results.map<{ item: SearchResultItemV2; key: string }>(
            (r) => ({
              item: r,
              key: Object.entries(r)
                .map(([key, value]) => key + "-" + value)
                .join("_"),
            })
          );
        })
        .catch((err) => {
          logger.error(err);
          return [];
        });
    },
    [adminShowDraftData, props.canBeGroupedOnly]
  );

  return (
    <>
      <InfostatSearchBox<SearchResultItemV2>
        placeholder={props.placeholder}
        autofocus={props.autofocus}
        autofocusDelayMs={props.autofocusDelayMs}
        hideButton={props.hideButton}
        minimumSearchStringLength={3}
        className={classNames(props.className, "subject-search")}
        search={handleSearch}
        render={renderSearchHit}
        onSelect={(item) =>
          handleSelectSearchHit(item, props.onSelectPath, props.onSelectMeasure)
        }
      ></InfostatSearchBox>
    </>
  );
}

export function handleSelectSearchHit(
  item: SearchResultItemV2,
  onSelectPath: (tree: string[]) => void,
  onSelectMeasure: SelectMeasureResultHandler
) {
  switch (item.hit_type) {
    case "subarea":
      return onSelectPath([item.area, item.subarea]);
    case "subject":
      return onSelectPath([item.area, item.subarea, item.subject]);
    case "measure":
      const subjectPath = getSubjectPath(item);
      return onSelectMeasure(
        item.data_id,
        subjectPath,
        item.dimension_data_column,
        item.dimension_value_id
      );
  }
}

export function renderSearchHit(item: SearchResultItemV2) {
  const logo =
    item.subarea === INFOSTAT_SURVEY_SUBAREA ? (
      <img
        src={infostatIconSource}
        className="infostat-logo"
        alt="logotyp"
      ></img>
    ) : (
      ""
    );
  switch (item.hit_type) {
    case "subarea":
      return (
        <>
          <span className="hit-info">
            {item.area} {">"} {item.subarea}
          </span>
          {logo}
        </>
      );
    case "subject":
      return (
        <>
          <span className="hit-info">
            {item.area} {">"} {item.subarea} {">"} {item.subject}
          </span>
          {logo}
        </>
      );

    case "measure":
      return (
        <>
          <span>
            <HitInfo item={item} />
            <div className="category-line-small">
              {item.area} {">"} {item.subarea}
            </div>
          </span>
          {logo}
        </>
      );
  }
}

function HitInfo(props: { item: SearchResultMeasure }) {
  const { item } = props;
  const { hit_field } = item;
  if (hit_field === "dimension_value_label") {
    const hit = item[hit_field];
    return (
      <div className="hit-info">
        {item.subject} | {item.measure} | {item.dimension_label}:{" "}
        {defined(hit) && <span>{hit}</span>}
      </div>
    );
  }

  return (
    <div className="hit-info">
      <span>
        {item.subject} | {item.measure}
      </span>
    </div>
  );
}
