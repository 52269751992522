import { createRoot } from "react-dom/client";
import mapbox from "mapbox-gl";
import { ThemeProvider } from "@fluentui/react";
import { useEffect, useRef } from "react";

import { defined } from "../../../../../../lib/core/defined";
import { fluentUITheme } from "../../../../../../lib/application/theme";
import { ResultPart } from "../../../../../../lib/domain/micro/definitions";
import {
  ColorRamp,
  getColorRamp,
  MicroColorScheme,
} from "../../../../../../lib/domain/micro/colors";

import "./ResultsPanel.scss";
import { MicroMapResults } from "../../../../../../lib/application/stats/datasets/MicroMapResults";
import { MicroMapSettings } from "../../../../../../lib/application/state/stats/document-core/core-micro";

export class ResultsPanelControl {
  private _map?: mapbox.Map;
  private _container?: HTMLDivElement;

  private _root?: ReturnType<typeof createRoot>;

  render(results: MicroMapResults | undefined, mapSettings: MicroMapSettings) {
    const container = this._container;
    if (!defined(container)) {
      return;
    }
    if (!defined(results)) {
      return;
    }

    if (!this._root) {
      this._root = createRoot(container);
    }

    this._root.render(
      <ResultsPanel
        showInfoBox={mapSettings.showInfoBox ?? true}
        localZRange={mapSettings.localZRange}
        colorSchemeCode={mapSettings.colorScheme}
        results={results}
      />
    );
  }

  onAdd(map: mapbox.Map) {
    this._map = map;
    const container = document.createElement("div");
    container.className = "mapbox-results-panel";
    this._container = container;
    return container;
  }

  onRemove() {
    if (defined(this._container)) {
      setTimeout(() => {
        this._root?.unmount();
        this._root = undefined;
      });
    }
  }
}

const LEGEND_RAMP_HEIGHT = 100;
const LEGEND_RAMP_STEPS = 100;

function ResultsPanel(props: {
  showInfoBox: boolean;
  results: MicroMapResults;
  colorSchemeCode: MicroColorScheme;
  localZRange?: boolean;
}) {
  const results = props.results;
  const ramp = getColorRamp(props.colorSchemeCode, LEGEND_RAMP_STEPS);
  return (
    <ThemeProvider theme={fluentUITheme}>
      <div className="micro-results-panel mapboxgl-ctrl">
        {props.showInfoBox && (
          <div className="table-results">
            <div className="description margin-bottom-md">
              <h3>{results.title()}</h3>
              {defined(results.subtitle) && <p>{results.subtitle}</p>}
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{results.unitLabel()}</th>
                  </tr>
                </thead>
                <tbody>
                  {results.referenceValues?.map((refValue) => (
                    <tr key={refValue.label}>
                      <td>{refValue.label}</td>
                      <td className="value">{refValue.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="legend-container">
          <div className="legend">
            <CanvasLegend colorRamp={ramp} localZRange={props.localZRange} />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

const barHeight = 10;
const outputWidth = 50;
const maxAbsValue = 2.5;
const scaleFactor = (maxAbsValue * 2) / outputWidth;

function CanvasLegend(props: { colorRamp: ColorRamp; localZRange?: boolean }) {
  const colorRamp = props.colorRamp.slice().reverse();
  const rampSteps = colorRamp.length;
  const canvasContainerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const current = canvasContainerRef?.current;
    if (!defined(current)) {
      return;
    }

    const canvas = document.createElement("canvas");
    canvas.width = 10;
    canvas.height = rampSteps;
    canvas.style.height = "calc(100% + 10px)";
    const ctx = canvas.getContext("2d");
    if (!defined(ctx)) {
      throw new Error("Could not get context");
    }
    for (let i = 0; i < rampSteps; i++) {
      ctx.fillStyle = colorRamp[i];
      ctx.fillRect(0, i, 10, 1);
    }

    current.appendChild(canvas);

    return () => {
      current.removeChild(canvas);
    };
  }, [colorRamp, rampSteps]);

  return (
    <div className="canvas-legend-container">
      <div className="labels">
        {props.localZRange ? (
          <>
            <p>Högst</p>
            <span></span>
            <span>Lägst</span>
          </>
        ) : (
          <>
            <p>Över medel</p>
            <span>Medel</span>
            <span>Under medel</span>
          </>
        )}
      </div>
      <div
        className="color-ramp"
        style={{ display: "inline-block" }}
        ref={canvasContainerRef}
      ></div>
    </div>
  );
}

function RenderedResultPart(props: { part: ResultPart }) {
  const value = props.part.value;
  const clampedValue =
    value < 0 ? Math.max(value, -maxAbsValue) : Math.min(value, maxAbsValue);
  return (
    <tr>
      <td>{props.part.name}</td>
      <td>
        <Bar
          width={outputWidth}
          filledWidth={(clampedValue + 2.5) / scaleFactor}
        ></Bar>
      </td>
    </tr>
  );
}

function Bar(props: { width: number; filledWidth: number }) {
  return (
    <div
      className="filled-bar"
      style={{ width: props.width, height: barHeight }}
    >
      <div
        className="fill"
        style={{ width: props.filledWidth, height: barHeight }}
      ></div>
    </div>
  );
}
