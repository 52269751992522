import * as React from "react";
import { useState } from "react";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";

import { TabContainer, TitleActionsContainer, TitleContainer } from "./CardTab";
import { TitleActionsContextMenu } from "./TitleActionsContextMenu";
import { voidFunc } from "../../../../../lib/core/voidFunc";

interface Props {
  cardId: string;
  handleRemoveCard: () => void;
  handleDuplicateCard: () => void;
  sharingInfo: ISharingInfo;
}
export function MicroCardTab(props: Props) {
  const [showContextMenu, setShowContextMenu] = useState(false);

  const {
    hasEditDocumentAccess,
    isEditingCard: isEditing,
    setCardEditing,
    isEditingDocument,
  } = useCardEditMode(props.cardId, props.sharingInfo);

  const contextMenuContainerRef = React.useRef(null);

  if (!isEditingDocument) {
    return <></>;
  }

  return (
    <TabContainer containerKey={props.cardId}>
      <div className="card-label">
        <TitleContainer
          isEditing={isEditing}
          cardId={props.cardId}
        ></TitleContainer>
      </div>

      <TitleActionsContainer
        setEditing={setCardEditing}
        isEditing={isEditing}
        hasEditDocumentAccess={hasEditDocumentAccess}
        setShowContextMenu={setShowContextMenu}
        showContextMenu={showContextMenu}
        contextMenuContainerRef={contextMenuContainerRef}
      ></TitleActionsContainer>
      <TitleActionsContextMenu
        cardId={props.cardId}
        hasEditDocumentAccess={hasEditDocumentAccess}
        handleDuplicateCard={() => props.handleDuplicateCard()}
        handleRemoveCard={() => props.handleRemoveCard()}
        targetRef={contextMenuContainerRef}
        hidden={!showContextMenu}
        onDismiss={() => setShowContextMenu(false)}
      ></TitleActionsContextMenu>
    </TabContainer>
  );
}

export function ThirdPartyMicroCardTabView(props: {
  cardId: string;
  sharingInfo: ISharingInfo;
}) {
  const {
    hasEditDocumentAccess,
    isEditingCard: isEditing,
    setCardEditing,
    isEditingDocument,
  } = useCardEditMode(props.cardId, props.sharingInfo);

  const contextMenuContainerRef = React.useRef(null);

  if (!isEditingDocument) {
    return <></>;
  }

  return (
    <TabContainer containerKey={props.cardId}>
      <div className="card-label">
        <TitleContainer
          isEditing={isEditing}
          cardId={props.cardId}
        ></TitleContainer>
      </div>

      <TitleActionsContainer
        setEditing={setCardEditing}
        isEditing={isEditing}
        hasEditDocumentAccess={hasEditDocumentAccess}
        setShowContextMenu={voidFunc}
        showContextMenu={false}
        contextMenuContainerRef={contextMenuContainerRef}
        hideMenu
      ></TitleActionsContainer>
    </TabContainer>
  );
}
