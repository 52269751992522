import {
  Number as NumberRT,
  Array as ArrayRT,
  Record,
  String as StringRT,
  Static,
  Optional,
  Boolean as BooleanRT,
  Null,
  Partial,
} from "runtypes";

const CustomDataOutputSettingsRT = Partial({
  showLabels: BooleanRT,
  showLineCircles: BooleanRT,
  showFatLines: BooleanRT,
  showYAxis: BooleanRT,
  showXAxis: BooleanRT,
  gridLinesXStyle: StringRT,
  gridLinesYStyle: StringRT,
  showTicksYAxis: BooleanRT,
  showTicksXAxis: BooleanRT,
  fixedNumDecimals: NumberRT,
  hideChartTitleSection: BooleanRT,
  showSurveyValueFraction: BooleanRT,
  customMainHeaderSize: NumberRT,
  customSubHeaderSmallSize: NumberRT,
  customSubHeaderLargeSize: NumberRT,
  customUnitSize: NumberRT,
  chartLabelsSize: NumberRT,
  customSourceTextSize: NumberRT,
  hideLegendDimensionLabels: BooleanRT,
});

export type CustomDataOutputSettings = Static<
  typeof CustomDataOutputSettingsRT
>;

const CustomThemeSpecRT = Record({
  id: StringRT,
  name: StringRT,
  colors: ArrayRT(StringRT),
  useDefaultSpecialColors: BooleanRT,
  customBgColor: Optional(StringRT.Or(Null)),
  customAxesColor: Optional(StringRT.Or(Null)),
  customGridLinesColor: Optional(StringRT.Or(Null)),
  customDataOutputSettings: Optional(CustomDataOutputSettingsRT),
  customHeadersColor: Optional(StringRT.Or(Null)),
  customLabelsColor: Optional(StringRT.Or(Null)),
});

export const UserInfoDtoRT = Record({
  email: StringRT,
  is_third_party_org_user: Optional(BooleanRT),
  organization_id: StringRT,
  organization_name: Optional(StringRT),
  block_reason: StringRT,
  permissions: Optional(ArrayRT(StringRT)).Or(Null),
  organization_preferences: Optional(
    Partial({
      org_defined_themes: ArrayRT(CustomThemeSpecRT),
      default_theme_id: Optional(StringRT),
    }).Or(Null)
  ),
  preferences: Optional(
    Partial({
      hide_support_bubble: Optional(BooleanRT),
      user_defined_themes: ArrayRT(CustomThemeSpecRT),
      default_theme_id: Optional(StringRT),
    }).Or(Null)
  ),
});

export type UserInfoDto = Static<typeof UserInfoDtoRT>;

export const MyOrganizationDtoRT = Record({
  id: StringRT,
  name: StringRT,
  third_party_orgs: Optional(
    ArrayRT(
      Record({
        third_party_org_id: StringRT,
        name: StringRT,
      })
    ).Or(Null)
  ),
});

export type MyOrganizationDto = Static<typeof MyOrganizationDtoRT>;
