import * as d3timefmt from "d3-time-format";
import * as d3time from "d3-time";

import { Milliseconds } from "../core/time";
import { defined } from "../core/defined";
import { logger } from "../infra/logging";
import { DateRangeRaw } from "./measure/definitions";
import { getForecastMaxPeriods } from "./forecast";

export const DEFAULT_TIME_SPAN_SELECTION: [number, number] = [1980, 2020];
export const DEFAULT_TIME_SPAN_SELECTION_RAW: DateRangeRaw = [
  "1980-01-01",
  "2020-01-01",
];
const MAXIMUM_TIME_RESOLUTION = "days-1";
const timeUnits = ["years", "months", "weeks", "days"] as const;
export const validResolutions = [
  "years-5",
  "years-4",
  "years-2",
  "years-1",
  "months-6",
  "months-3",
  "months-1",
  "weeks-1",
  "days-1",
] as const;

type TimeUnit = (typeof timeUnits)[number];

export class TimeResolution {
  private constructor(
    private _rawInput: string,
    private _unit: TimeUnit,
    private _step: number,
    private _interval: d3time.CountableTimeInterval
  ) {}

  get unit() {
    return this._unit;
  }

  /** Returns the max number of periods we allow forecasts for */
  forecastMaxPeriods() {
    return getForecastMaxPeriods(this._rawInput);
  }

  /**
   * Returns true if this time resolution is greater, i.e. finer, than other
   */
  greaterThan(other: TimeResolution): boolean {
    const indexThis = timeUnits.indexOf(this._unit);
    const indexOther = timeUnits.indexOf(other.unit);
    if (indexThis === indexOther) {
      return this._step < other._step;
    }
    return indexThis > indexOther;
  }

  /**
   * Returns all dates in the given range, at the unit interval approprate for
   * this time resolution
   */
  filledRangeInclusive(start: Date, end: Date): Date[] {
    // Add a bit of time to the endpoint, since the D3 range function excludes the upper bound
    // whereas we want to include it.
    const endPastThreshold = new Date(end.getTime() + Milliseconds.hour);
    const dates = this._interval.range(start, endPastThreshold, this._step);
    // WORKAROUND: D3 week intervals depend on the starting day of the week, so if we're using the standard
    // Sunday-starting week a start date of Thursday will not be included in the returned range.
    if (dates.length === 0 || dates[0].getTime() !== start.getTime()) {
      return [start, ...dates];
    }
    if (!defined(dates)) {
      throw new Error(
        `Invalid range: ${start.toISOString()}, ${endPastThreshold}`
      );
    }
    return dates;
  }

  incrementDateBySteps(date: Date, steps: number): Date {
    return this._interval.offset(date, steps * this._step);
  }

  /**
   * Return the number of steps, given this time resolution, to get from
   * @start to @end
   */
  distance(start: Date, end: Date): number {
    if (start.getTime() > end.getTime()) {
      throw new Error("Start must be before end");
    }
    const range = this.filledRangeInclusive(start, end);
    return range.length - 1;
  }

  serialize(): string {
    return this._rawInput;
  }

  get step(): number {
    return this._step;
  }

  display(): string {
    const unit = displayUnit(this._unit);
    if (this._step === 1) {
      return unit;
    }
    return `${unit}, period om ${this._step}`;
  }

  static maximal(): TimeResolution {
    return TimeResolution.deserialize(MAXIMUM_TIME_RESOLUTION);
  }

  static deserialize(input: string): TimeResolution {
    const parts = input.split("-");
    if (parts.length !== 2) {
      throw new Error(
        "Unexpected number of parts in time resolution string: " + parts.length
      );
    }
    const unit = parts[0] as TimeUnit;
    const step = parseInt(parts[1], 10);
    const interval = getIntervalUtc(unit);
    return new TimeResolution(input, unit, step, interval);
  }
}

function getIntervalUtc(type: string) {
  switch (type) {
    case "days":
      return d3time.utcDay;
    case "weeks":
      return d3time.utcWeek;
    case "months":
      return d3time.utcMonth;
    case "years":
      return d3time.utcYear;
    default:
      logger.warn("Unknown interval: " + type);
      return d3time.utcYear;
  }
}

export function getDateFormatter(resolution: TimeResolution) {
  switch (resolution.unit) {
    case "years":
      return d3timefmt.utcFormat("%Y");
    case "months":
      if (resolution.step === 3) {
        return d3timefmt.utcFormat("%Y-Q%q");
      }
      return d3timefmt.utcFormat("%Y-%m");
    case "weeks":
      // We default to showing full dates for weeks instead of week numbers, but may want to below implementation in the future
      return d3timefmt.utcFormat("%Y-%m-%d");
    // Use ISO 8601 week/year numbers.
    // First days of year may belong to last week of previous year.
    // return d3timefmt.utcFormat("%G-%V"); // actual week number
    case "days":
      return d3timefmt.utcFormat("%Y-%m-%d");
    default:
      return d3timefmt.utcFormat("%Y");
  }
}

function displayUnit(unit: TimeUnit): string {
  switch (unit) {
    case "years":
      return "år";
    case "months":
      return "månader";
    case "weeks":
      return "veckor";
    case "days":
      return "dagar";
  }
}

const ymdFormat = d3timefmt.utcFormat("%Y-%m-%d");
export function displayYearMonthDate(d: Date) {
  return ymdFormat(d);
}
