import { useCallback, useContext, useState } from "react";
import { useRecoilState } from "recoil";

import { defined } from "../../../../core/defined";
import { GeoTypeMicro } from "../../../../domain/geography";
import { SelectedDimensionsV2 } from "../../../../domain/measure/definitions";
import { compatibleFilters } from "../../../../domain/micro/filter_measures";
import { MicroMeasure } from "../../../../domain/micro/MicroMeasure";
import { getCompatibleTimeSelection } from "../../../../domain/micro/timeSelection";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../../../infra/api_responses/micro_dataset";
import { logger } from "../../../../infra/logging";
import {
  getMicroDimensionsWithCache,
  getMicroAvailableDates,
  getMicroMeasuresWithCache,
} from "../../../requests/datasets/micro";
import { getText } from "../../../strings";
import { replaceInArrayImmut } from "../../generic";
import {
  findMeasureMicro,
  makeMicroDataGeoSelection,
  makePrimaryMicroDataSelection,
  microSelectionPrimary,
  MicroSubjectPath,
} from "../../stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";
import { ensureCompatibleSelectedTab } from "./shared";
import { updateGeoSelections } from "./updates";
import { getDefaultSelectedDimensions } from "../../../../domain/measure";
import { useSaveCard } from "../useSaveDocument";
import { loadAndStoreDataMicro } from "../../stats/cardToDataStateMicro";
import { useGeoTree } from "../../../../../views/stats/docs/cards/micro/useGeoTree";
import { CardUpdateCountContext } from "../../../contexts";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

export function useChangeMeasure(
  cardId: string,
  adminShowDraftData: boolean,
  acceptGeoTypes: GeoTypeMicro[]
): [
  (
    selectionId: string | undefined,
    measureId: number,
    computedMeasureType?: ComputedMeasurementType
  ) => void,
  boolean
] {
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );

  const [changePending, setChangePending] = useState(false);
  const geoTree = useGeoTree();
  const handleSaveCard = useSaveCard();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  const getDefaultDataSelection = useCallback(
    async (
      subjectPath: MicroSubjectPath,
      measureId: number,
      measureDto: MicroMeasureDto
    ) => {
      const dims = (
        await getMicroDimensionsWithCache(measureId, adminShowDraftData)
      ).unwrap();
      const selectedDimensions: SelectedDimensionsV2 =
        getDefaultSelectedDimensions(dims);

      const availableDates = (
        await getMicroAvailableDates(
          measureId,
          selectedDimensions,
          adminShowDraftData
        )
      ).unwrap();

      const microMeasure = new MicroMeasure(measureDto, dims);
      if (microMeasure.isPrimary()) {
        return makePrimaryMicroDataSelection(
          subjectPath,
          measureDto,
          dims,
          availableDates ?? [],
          selectedDimensions,
          undefined
        );
      }
      return makeMicroDataGeoSelection(
        subjectPath,
        measureDto,
        dims,
        availableDates ?? [],
        selectedDimensions
      );
    },
    [adminShowDraftData]
  );

  const handleSetSelectedMeasure = useCallback(
    async (
      selectionId: string | undefined,
      measureId: number,
      computedMeasureType?: ComputedMeasurementType
    ) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      setChangePending(true);
      try {
        const subjectPath = card.data.dataSelections?.find(
          (s) => s.id === selectionId
        )?.subjectPath;
        if (!defined(subjectPath)) {
          logger.error("No subject path found for measure change");
          return;
        }
        if (!defined(geoTree)) {
          return;
        }
        const availableMeasures = (
          await getMicroMeasuresWithCache(
            subjectPath as any,
            adminShowDraftData,
            acceptGeoTypes,
            []
          )
        ).unwrap();
        const selectedMeasure = findMeasureMicro(
          availableMeasures,
          measureId,
          computedMeasureType
        );
        if (!defined(selectedMeasure) || shouldAbort()) {
          return;
        }
        const dataSelection = await getDefaultDataSelection(
          subjectPath,
          measureId,
          selectedMeasure
        );
        if (!defined(dataSelection) || shouldAbort()) {
          logger.error("Could not get default data selection");
          return;
        }

        const dataSelections = card.data.dataSelections;
        if (!defined(dataSelections)) {
          logger.warn(
            "Expected dataSelections, was undefined. Not changing measure."
          );
          return card;
        }
        const prevSelection = dataSelections.find((d) => d.id === selectionId);
        if (!defined(prevSelection)) {
          throw new Error(
            "Could not find data selection -- should not happen when changing measure"
          );
        }

        // If the previous selection was of type primary, the filters were connected to it.
        // If it was of type geo-micro, it had no effect on the filters, so we simply keep the old filter measures.
        const remainingFilters =
          prevSelection.type === "primary"
            ? compatibleFilters(
                card.data.filterMeasures,
                card.data.geoSelections,
                selectedMeasure
              )
            : card.data.filterMeasures;
        if (remainingFilters.length < card.data.filterMeasures.length) {
          if (
            !window.confirm(
              getText(
                "micro-confirm-change-measure-remove-incompatible-filters"
              )
            )
          ) {
            return card;
          }
        }

        const updatedDataSelections = replaceInArrayImmut(
          dataSelections,
          (s) => s.id === selectionId,
          () => dataSelection
        );
        const updatedGeoSelections = updateGeoSelections(
          updatedDataSelections,
          card.data
        );
        const updatedCard = {
          ...card,
          data: {
            ...card.data,
            selectedTab: ensureCompatibleSelectedTab(
              card.data.selectedTab,
              updatedDataSelections,
              updatedGeoSelections.geoSelections
            ),
            filterMeasures: remainingFilters,
            timeSelection: getCompatibleTimeSelection(
              dataSelection.availableDates,
              microSelectionPrimary(card)?.timeSelection
            ),
            dataSelections: updatedDataSelections,
            ...updatedGeoSelections,
          },
        };

        loadAndStoreDataMicro(
          updatedCard,
          setCard,
          shouldAbort,
          geoTree,
          adminShowDraftData,
          appearanceSettings,
          undefined,
          handleSaveCard
        );
      } finally {
        setChangePending(false);
      }
    },
    [
      acceptGeoTypes,
      adminShowDraftData,
      card,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      getDefaultDataSelection,
      handleSaveCard,
      incrementUpdateCount,
      setCard,
    ]
  );

  return [handleSetSelectedMeasure, changePending];
}
