import { assertNever } from "@fluentui/react";
import { useContext, useCallback } from "react";

import { Progress } from "../../../../core/progress";
import { HttpResult } from "../../../../infra/HttpResult";
import { UserInfo } from "../../../auth/UserInfo";
import { ShowDraftDataContext } from "../../../contexts";
import { getThirdPartyDocument } from "../../../requests/docs/documents";
import { statsApi } from "../../../requests/statsApi";
import { statsApiV2 } from "../../../requests/statsApiV2";
import { DocCardState } from "../../stats/document-core/core";
import { getVerifiedThirdPartyDoc } from "../../stats/workspace/rebuild_state";
import { BasicThirdPartyDoc, useGetCardGenerator } from "./_shared";
import {
  DocMetadataState,
  useLoadDocumentGeneric,
} from "./useLoadDocumentGeneric";
import { useAppMessages } from "../../../hooks/useAppMessages";
import { ThirdPartyDocDto } from "../../../../infra/api_responses/document";

type GetDocResult = HttpResult<BasicThirdPartyDoc>;

export function useLoadThirdPartyDocument(docId: string) {
  const loadDocDto = useCallback(() => getThirdPartyDocument(docId), [docId]);
  return useLoadThirdPartyDocumentGeneral(loadDocDto);
}

export function useLoadThirdPartyDocumentMockLoader(
  loadDocDto: () => Promise<HttpResult<ThirdPartyDocDto>>
) {
  return useLoadThirdPartyDocumentGeneral(loadDocDto);
}
function useLoadThirdPartyDocumentGeneral(
  loadDocDto: () => Promise<HttpResult<ThirdPartyDocDto>>
) {
  const showAdminDraftData = useContext(ShowDraftDataContext);

  const appMessagesHandler = useAppMessages();

  const loadDoc = useCallback(
    (): Promise<GetDocResult> =>
      loadDocDto().then((docRes) => {
        return docRes.match({
          ok: (doc) => {
            return getVerifiedThirdPartyDoc(doc, statsApi, statsApiV2).then(
              (res) => {
                return HttpResult.fromOk({
                  state: res.state,
                  workspaceVersion: res.workspaceVersion,
                  thirdPartyDocId: doc.third_party_doc_id,
                  title: doc.title,
                });
              }
            );
          },
          err: (err) => {
            return Promise.resolve(HttpResult.fromErr(err));
          },
        });
      }),
    [loadDocDto]
  );

  const getDocumentMetadata = useCallback(
    (doc: BasicThirdPartyDoc, userInfo?: UserInfo): DocMetadataState => {
      return {
        id: -1, // Not used for instantiated third party docs
        title: doc.title,
        thirdPartySharingDoc: true,
        userHasApplicableWriteLicenses: false,
        editModeOn: false,
        editModeAvailable: false,
      };
    },
    []
  );

  const getUninitializedCards = useCallback((doc: BasicThirdPartyDoc) => {
    return doc.state.cards.map((c) => {
      switch (c.type) {
        case "dataCard":
        case "microCard":
        case "pythonCard":
          return {
            ...c,
            initState: Progress.NotStarted,
          } as DocCardState;
        case "textCardCK":
        case "textCardSimple":
          return c as DocCardState;
      }
      assertNever(c);
    });
  }, []);

  const getCardGenerator = useGetCardGenerator(showAdminDraftData);

  const loadingResult = useLoadDocumentGeneric(
    loadDoc,
    getDocumentMetadata,
    getUninitializedCards,
    getCardGenerator,
    appMessagesHandler
  );

  return loadingResult;
}
