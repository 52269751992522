import {
  Record,
  String as StringRT,
  Number as NumberRT,
  Unknown,
  Static,
} from "runtypes";

export const DocumentDtoRT = Record({
  id: NumberRT,
  title: StringRT,
  workspace: Unknown,
});
export type DocumentDto = Static<typeof DocumentDtoRT>;

export const ThirdPartyDocDtoRT = Record({
  third_party_doc_id: StringRT,
  title: StringRT,
  created_at: StringRT,
  content: Unknown,
});
export type ThirdPartyDocDto = Static<typeof ThirdPartyDocDtoRT>;
