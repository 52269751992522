import { generatePath } from "react-router";

import { defined } from "./core/defined";
import { nonEmptyString } from "./core/nonEmptyString";
import { config } from "../config";

const sharedDocParam = "shared";
const singleDocParam = "doc";
const thirdPartyDocOrgLabelParam = "org-label";
const linkParam = "link";
const singleMeasureParam = "measureId";
const handleSingleMeasureParam = "handleSingleMeasure";
const embedKey = "embedKey";

export const pathInfo = {
  /**
   * ADMIN PATHS
   */

  dataAdminHome: {
    pathTemplate: "/stats/data-admin",
  },
  dataAdminNewMeasure: {
    pathTemplate: "/stats/data-admin/new",
  },
  dataAdminNewMeasureStats: {
    pathTemplate: "/stats/data-admin/new/stats-v2",
  },
  dataAdminNewMeasureMicro: {
    pathTemplate: "/stats/data-admin/new/micro",
  },
  dataAdminNewMeasureExternal: {
    pathTemplate: "/stats/data-admin/new/external",
  },
  dataAdminNewMeasureMemberOrg: {
    pathTemplate: "/stats/data-admin/new/measure-for-member-orgs",
  },
  dataAdminNewMeasureSurvey: {
    pathTemplate: "/stats/data-admin/new/survey",
  },
  dataAdminHandleDataMicro: {
    pathTemplate: "/stats/data-admin/handle-data/micro",
  },
  dataAdminHandleDataSurvey: {
    pathTemplate: "/stats/data-admin/handle-data/survey",
  },
  dataAdminHandleDataStats: {
    pathTemplate: "/stats/data-admin/handle-data/stats",
  },
  dataAdminHandleDataMemberOrgsMeasures: {
    pathTemplate: "/stats/data-admin/handle-data/member-orgs-measures",
  },
  dataAdminHandleDataMicroConfirmation: {
    pathTemplate: `/stats/data-admin/handle-data/micro/confirm/:${handleSingleMeasureParam}`,
    params: { handleSingleMeasureParam },
  },
  dataAdminHandleDataStatsConfirmation: {
    pathTemplate: `/stats/data-admin/handle-data/stats/confirm/:${handleSingleMeasureParam}`,
    params: { handleSingleMeasureParam },
  },
  dataAdminHandleDataMemberOrgsMeasureConfirmation: {
    pathTemplate: `/stats/data-admin/handle-data/member-orgs-measures/confirm/:${handleSingleMeasureParam}`,
    params: { handleSingleMeasureParam },
  },
  dataAdminHandleDataSurveyConfirmation: {
    pathTemplate: `/stats/data-admin/handle-data/survey/confirm/:${handleSingleMeasureParam}`,
    params: { handleSingleMeasureParam },
  },
  dataAdminAliases: {
    pathTemplate: "/stats/data-admin/aliases",
  },
  dataAdminMigrations: {
    pathTemplate: "/stats/data-admin/migrations",
  },

  /**
   * ADMIN PORTAL PATHS
   */

  adminPortalAutoImports: {
    pathTemplate: "/admin-portal/autoimport",
  },
  adminPortalAutoImportsSingle: {
    pathTemplate: "/admin-portal/autoimport/:id",
    params: { id: "id" },
  },

  /**
   * NON-USER PATHS
   */

  packagedDoc: {
    pathTemplate: `/docs/:${singleDocParam}`,
    params: { singleDocParam },
  },
  embeddedCard: {
    pathTemplate: `/stats/embed/:${embedKey}`,
    params: { embedKey },
  },

  loggedOutMissingLicense: {
    pathTemplate: "/missing-license",
  },

  /**
   * THIRD-PARTY PATHS
   */

  thirdPartyUserDefault: {
    pathTemplate: "/external",
  },
  thirdPartyOrgAdmin: {
    pathTemplate: "/external/org-admin",
  },
  thirdPartyDoc: {
    pathTemplate: `/share/:${singleDocParam}`,
    params: { singleDocParam, thirdPartyDocOrgLabelParam },
  },

  /**
   * USER PATHS
   */

  userSettings: {
    pathTemplate: "/stats/settings",
  },
  userSettingsAlerts: {
    pathTemplate: "/stats/settings/alerts",
  },
  userSettingsAppearance: {
    pathTemplate: "/stats/settings/appearance",
  },
  userSettingsDataLinks: {
    pathTemplate: "/stats/settings/data-links",
  },
  userSettingsGeneral: {
    pathTemplate: "/stats/settings/general",
  },
  orgSettingsAppearance: {
    pathTemplate: "/stats/settings/org-appearance",
  },
  orgSettingsUserManagement: {
    pathTemplate: "/stats/settings/org-user-management",
  },
  newFromSingleMeasure: {
    pathTemplate: `/stats/measures/:${singleMeasureParam}/new`,
    params: { singleMeasureParam },
    queryParams: { name: "name" },
  },
  singleMeasure: {
    pathTemplate: `/stats/measures/:${singleMeasureParam}`,
    params: { singleMeasureParam },
  },

  /**
   * Route for shared links. Must not be changed -- or all outstanding links will be broken.
   */
  shareLink: {
    pathTemplate: `/stats/links/:${linkParam}`,
    params: { linkParam },
  },
  /**
   * Path used for creating a new copy from the given share code for a doc
   */
  newFromSharedDoc: {
    pathTemplate: `/stats/shared/d/:${sharedDocParam}`,
    params: { sharedDocParam },
  },
  statsDocsOverview: {
    pathTemplate: "/stats/docs",
    queryParams: { openSharedFolder: "open-shared-folder" },
  },
  statsDocsSingle: {
    pathTemplate: `/stats/docs/:${singleDocParam}`,
    params: { singleDocParam },
  },
  newDoc: {
    pathTemplate: `/stats/docs/c`,
    queryParams: { title: "title", parentFolderId: "parentFolderId" },
  },
  /**
   * Path used for creating a new copy from the given document
   */
  newCopy: {
    pathTemplate: `/stats/docs/c/:${singleDocParam}`,
    queryParams: { title: "title" },
    params: { singleDocParam },
  },
};

export function handleSingleMicroMeasurePath(measureId: number): string {
  return generatePath(
    pathInfo.dataAdminHandleDataMicroConfirmation.pathTemplate,
    {
      [handleSingleMeasureParam]: measureId,
    }
  );
}

export function handleSingleStatsMeasurePath(measureId: number): string {
  return generatePath(
    pathInfo.dataAdminHandleDataStatsConfirmation.pathTemplate,
    {
      [handleSingleMeasureParam]: measureId,
    }
  );
}

export function handleSingleMeasureForMemberOrgsPath(
  measureId: number
): string {
  return generatePath(
    pathInfo.dataAdminHandleDataMemberOrgsMeasureConfirmation.pathTemplate,
    {
      [handleSingleMeasureParam]: measureId,
    }
  );
}

export function handleSingleSurveyMeasurePath(measureId: number): string {
  return generatePath(
    pathInfo.dataAdminHandleDataSurveyConfirmation.pathTemplate,
    {
      [handleSingleMeasureParam]: measureId,
    }
  );
}

export function newFromSingleMeasurePath(measureId: number, name: string) {
  const info = pathInfo.newFromSingleMeasure;
  return (
    generatePath(info.pathTemplate, {
      [info.params.singleMeasureParam]: measureId,
    }) + `?${info.queryParams.name}=${name}`
  );
}

export function autoImportsSinglePath(id: number): string {
  const info = pathInfo.adminPortalAutoImportsSingle;
  return generatePath(info.pathTemplate, { [info.params.id]: id });
}

export function sharedLinkPath(code: string): string {
  const info = pathInfo.shareLink;
  return generatePath(info.pathTemplate, { [info.params.linkParam]: code });
}

export function thirdPartyLinkFromCode(code: string): string {
  const info = pathInfo.thirdPartyDoc;
  return pathJoin(
    window.origin,
    generatePath(info.pathTemplate, {
      [info.params.singleDocParam]: code,
    })
  );
}

export function newFromSharedDocPath(shareCode: string): string {
  const info = pathInfo.newFromSharedDoc;
  return generatePath(info.pathTemplate, {
    [info.params.sharedDocParam]: shareCode,
  });
}

export function newCopyPath(copyFromId: number, title: string) {
  const info = pathInfo.newCopy;
  return (
    generatePath(info.pathTemplate, {
      [info.params.singleDocParam]: copyFromId,
      title,
    }) + `?${info.queryParams.title}=${encodeURIComponent(title)}`
  );
}

export function newDocPath(title: string, parentFolderId?: number) {
  const info = pathInfo.newDoc;
  return (
    generatePath(info.pathTemplate) +
    `?${info.queryParams.title}=${encodeURIComponent(title)}&${
      info.queryParams.parentFolderId
    }=${defined(parentFolderId) ? encodeURIComponent(parentFolderId) : ""}`
  );
}

export function statsOverviewPath() {
  return pathInfo.statsDocsOverview.pathTemplate;
}

export function statsOverviewOpenFolderPath(folderId: number): string {
  const info = pathInfo.statsDocsOverview;
  return (
    generatePath(info.pathTemplate) +
    `?${info.queryParams.openSharedFolder}=${folderId}`
  );
}

export function documentPath(id: number): string {
  const info = pathInfo.statsDocsSingle;
  return generatePath(info.pathTemplate, { [info.params.singleDocParam]: id });
}

/**
 * Joins path segments into a single path, deduplicating/inserting slashes
 * as needed
 */
export function pathJoin(...paths: string[]): string {
  let fullPath = "";
  for (const p of paths) {
    const lastChar = fullPath[fullPath.length - 1];
    const duplicateSlashes = lastChar === "/" && p[0] === "/";
    const noSlashes = defined(lastChar) && lastChar !== "/" && p[0] !== "/";
    if (duplicateSlashes) {
      fullPath += p.slice(1);
    } else if (noSlashes) {
      fullPath += "/" + p;
    } else {
      fullPath += p;
    }
  }
  return fullPath;
}

/**
 * Navigates to the document with the given ID
 */
export function openDocument(id: number) {
  window.open(documentPath(id), "_blank");
}

export function lastPathSegment(path: string): string {
  const parts = path.split("/").filter(nonEmptyString);
  return parts[parts.length - 1];
}

export function getFullDataLinkStatsMetadataPath(linkCode: string): string {
  return getFullDataLinkMetadataPath("stats", linkCode);
}

export function getFullDataLinkMikroMetadataPath(linkCode: string): string {
  return getFullDataLinkMetadataPath("mikro", linkCode);
}

export function getFullDataLinkSurveyMetadataPath(linkCode: string): string {
  return getFullDataLinkMetadataPath("survey", linkCode);
}

export function getFullDataLinkDataFrame(
  linkCode: string,
  options: DataLinkParams
): string {
  return getFullDataLink("pythonanalysis", linkCode, options);
}

export function getFullDataLinkStats(
  linkCode: string,
  options: DataLinkParams
): string {
  return getFullDataLink("stats", linkCode, options);
}

export function getFullDataLinkMikro(
  linkCode: string,
  options: DataLinkParams
): string {
  return getFullDataLink("mikro", linkCode, options);
}

export function getFullDataLinkSurvey(
  linkCode: string,
  options: DataLinkParams
): string {
  return getFullDataLink("survey", linkCode, options);
}

export interface DataLinkParams {
  format: string;
  delimiter?: string;
  longGeoNames?: boolean;
  swedishGeocodes?: boolean;
  numberFormat?: string;
  omitNullMarkers?: boolean;
  wideFormat?: boolean;
}

function getFullDataLinkMetadataPath(type: string, linkCode: string): string {
  return pathJoin(
    config.apis.statsV2.url,
    type,
    "datalinks",
    linkCode,
    "metadata"
  );
}

function getFullDataLink(
  type: string,
  linkCode: string,
  options: DataLinkParams
): string {
  const queryParams = new URLSearchParams();
  queryParams.set("format", options.format);
  if (defined(options.delimiter)) {
    queryParams.set("sep", options.delimiter);
  }
  if (defined(options.numberFormat)) {
    queryParams.set("num_format", options.numberFormat);
  }
  if (defined(options.omitNullMarkers)) {
    queryParams.set("omit_null_markers", options.omitNullMarkers.toString());
  }
  if (defined(options.wideFormat)) {
    queryParams.set("wide_format", options.wideFormat.toString());
  }
  if (defined(options.longGeoNames)) {
    queryParams.set("long_geo_names", options.longGeoNames.toString());
  }
  if (defined(options.swedishGeocodes)) {
    queryParams.set("swedish_geocodes", options.swedishGeocodes.toString());
  }
  return (
    pathJoin(config.apis.statsV2.url, type, "datalinks", linkCode) +
    "?" +
    queryParams.toString()
  );
}
