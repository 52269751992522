import {
  Array as ArrayRT,
  Record,
  String as StringRT,
  Number as NumberRT,
  Static,
} from "runtypes";

import { config } from "../../../../config";
import { authedRequest, decodedAuthedRequest } from "../shared";
import { HttpResult } from "../../../infra/HttpResult";
import { SimpleCache } from "../../../core/SimpleCache";
import { logger } from "../../../infra/logging";
import { defined } from "../../../core/defined";
import { Milliseconds } from "../../../core/time";
import { omitUndefined } from "../../../core/object";

const AnalysisTemplatesDtoRT = ArrayRT(
  Record({
    template_id: NumberRT,
    category: StringRT,
    name: StringRT,
    description: StringRT,
    code: StringRT,
  })
);

export type AnalysisTemplatesDto = Static<typeof AnalysisTemplatesDtoRT>;

const templatesCache: SimpleCache<AnalysisTemplatesDto> = new SimpleCache(
  1,
  Milliseconds.hour
);

export function getTemplates(): Promise<HttpResult<AnalysisTemplatesDto>> {
  const cacheKey = "templates";
  const cachedTemplates = templatesCache.get(cacheKey);
  if (defined(cachedTemplates)) {
    return Promise.resolve(HttpResult.fromOk(cachedTemplates));
  }

  return decodedAuthedRequest(
    config.apis.statsV2,
    "/pythonanalysis/templates",
    undefined,
    "GET",
    AnalysisTemplatesDtoRT
  ).then((res) => {
    res.match({
      ok: (data) => {
        templatesCache.set(cacheKey, data);
      },
      err: (err) => {
        logger.error(err);
      },
    });
    return res;
  });
}

export function deleteTemplate(
  templateId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `/admin/pythonanalysis/templates/${templateId}`,
    undefined,
    "DELETE"
  );
}

export function saveNewTemplate(
  category: string,
  name: string,
  description: string,
  code: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "/admin/pythonanalysis/templates",
    {
      category,
      name,
      description,
      code,
    },
    "POST"
  );
}

export function updateTemplate(
  templateId: number,
  name: string | undefined,
  description: string | undefined,
  code: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `/admin/pythonanalysis/templates/${templateId}`,
    omitUndefined({
      name,
      description,
      code,
    }),
    "PATCH"
  );
}
