import { defined } from "../../../core/defined";
import { mapOptional } from "../../../core/func/mapOptional";
import { BoundingBox } from "../../../domain/cartography/BoundingBox";
import { numSelectedGeos } from "../../../domain/geography";

export type ColorRange = {
  color: string;
  min?: number | string;
  max?: number | string;
};

export interface SvgPathOptions {
  fillColor?: string;
  color?: string; // stroke color
  opacity?: number;
  fillOpacity?: number;
  weight?: number; // stroke width
}

export interface NumericalRange {
  min?: number | string;
  max?: number | string;
}
export interface ColoredNumericalRange extends NumericalRange {
  color: string;
}

export type ScaleInfo =
  | {
      type: "sequential";
      valueToColor: (value: any) => string;
      ranges: ColoredNumericalRange[];
    }
  | {
      type: "category";
      valueToColor: (value: any) => string;
      categories: { label: string; color: string }[];
    };

export function displayNumericalRange(
  range: NumericalRange,
  formatNumber?: (num: number) => string
): string {
  const format = (n: string | number): string => {
    if (typeof n === "string") {
      return n;
    }
    if (defined(formatNumber)) {
      return formatNumber(n);
    }
    return n.toString();
  };

  const min = range.min;
  const max = range.max;
  if (min === max) {
    return mapOptional(format, min) ?? "";
  }

  return !defined(range.max)
    ? (mapOptional(format, range.min) ?? "") + "⁠ – "
    : (mapOptional(format, range.min) ?? "") +
        "⁠ – ⁠" +
        (mapOptional(format, range.max) ?? "");
}

export type StyleFunc = (f: GeoJSON.Feature | undefined) => SvgPathOptions;
export interface StylingInfo {
  featureToStyle: StyleFunc;
  scaleInfo: ScaleInfo;
}
export interface SingleMapData {
  key: string;
  labelTexts: string[];
  boundingBox: BoundingBox;
  hasMissingData: boolean;
  featureCollection: GeoJSON.FeatureCollection;
}

export interface BackgroundMapData {
  key: string;
  style: SvgPathOptions;
  featuresCollection: GeoJSON.FeatureCollection;
}

export type DrawableGeodata = {
  style: StylingInfo;
  data: SingleMapData[];
};
