import { useEffect, useMemo } from "react";
import {
  TimespanSliderRange,
  TimespanSliderSingle,
} from "../../../../../components/TimespanSlider";
import { defined } from "../../../../../lib/core/defined";
import { DateRangeRaw } from "../../../../../lib/domain/measure/definitions";
import {
  getTimeSettings,
  timespanToSliderRange,
} from "../card_general/TimeSelect";
import { last } from "../../../../../lib/core/last";
import { DEFAULT_TIME_SPAN_SELECTION } from "../../../../../lib/domain/time";
import { dateRangeToRaw } from "../../../../../lib/domain/measure";
import { useTimedState } from "../../../../../lib/application/hooks/useTimedState";
import { isEqual } from "lodash";

export function TimeSelect(props: {
  timeSelection: DateRangeRaw;
  availableDates: string[];
  timeResolution: string;
  handleUpdate: (timespan: DateRangeRaw) => void;
  enableRange: boolean;
}) {
  const timeSelection = props.timeSelection;
  const availableDates = props.availableDates;
  const resolutionRaw = props.timeResolution;
  const handleUpdate = props.handleUpdate;

  const [localTimespan, setLocalTimespan, localTimespanChangeTime] =
    useTimedState<DateRangeRaw | undefined>(undefined);
  const [globalTimespan, setGlobalTimespan, globalTimespanChangeTime] =
    useTimedState<DateRangeRaw | undefined>(undefined);

  useEffect(() => {
    if (isEqual(props.timeSelection, globalTimespan)) {
      return;
    }
    setGlobalTimespan(props.timeSelection);
  }, [globalTimespan, props.timeSelection, setGlobalTimespan]);

  useEffect(() => {
    // Do not run effect until global / props.timeSelection are in sync
    if (isEqual(localTimespan, globalTimespan)) {
      return;
    }

    if (localTimespanChangeTime > globalTimespanChangeTime) {
      if (!defined(localTimespan)) {
        return;
      }
      const handle = setTimeout(() => {
        setGlobalTimespan(localTimespan);
        handleUpdate(localTimespan);
      }, 200);
      return () => {
        clearTimeout(handle);
      };
    } else {
      return setLocalTimespan(globalTimespan);
    }
  }, [
    globalTimespan,
    globalTimespanChangeTime,
    handleUpdate,
    localTimespan,
    localTimespanChangeTime,
    props.timeSelection,
    setGlobalTimespan,
    setLocalTimespan,
    timeSelection,
  ]);

  const availableTimespan: DateRangeRaw = [
    availableDates[0],
    last(availableDates) ?? availableDates[0],
  ];

  const timeSettings =
    defined(resolutionRaw) && defined(availableTimespan)
      ? getTimeSettings(
          { type: "range", range: availableTimespan },
          resolutionRaw,
          availableDates
        )
      : undefined;

  const sliderRange = defined(timeSettings)
    ? timeSettings.numberRange
    : DEFAULT_TIME_SPAN_SELECTION;
  const selectedSliderRange = useMemo(() => {
    if (!defined(localTimespan) || !defined(timeSettings)) {
      return undefined;
    }
    return timespanToSliderRange(localTimespan, timeSettings);
  }, [localTimespan, timeSettings]);

  const disabled = !defined(timeSettings);

  return (
    <div className="time-select content-padding">
      {props.enableRange ? (
        <TimespanSliderRange
          disabled={disabled}
          format={(val) => {
            if (!defined(timeSettings)) {
              return val + "";
            }
            return timeSettings.format(timeSettings.dates[val]);
          }}
          min={sliderRange[0]}
          max={sliderRange[1]}
          selectedRange={selectedSliderRange}
          setTimeRange={(range) => {
            if (!defined(timeSettings)) {
              return;
            }

            const newValue: [Date, Date] = [
              timeSettings.dates[range[0]],
              timeSettings.dates[range[1]],
            ];
            setLocalTimespan(dateRangeToRaw(newValue));
          }}
        ></TimespanSliderRange>
      ) : (
        <TimespanSliderSingle
          disabled={disabled}
          format={(val) => {
            if (!defined(timeSettings)) {
              return val + "";
            }
            return timeSettings.format(timeSettings.dates[val]);
          }}
          min={sliderRange[0] ?? 0}
          max={sliderRange[1] ?? 0}
          selectedValue={
            defined(localTimespan) && defined(timeSettings)
              ? timespanToSliderRange(localTimespan, timeSettings)[0]
              : 0
          }
          setTimeValue={(value) => {
            if (!defined(timeSettings)) {
              return;
            }
            const newValue: [Date, Date] = [
              timeSettings.dates[value],
              timeSettings.dates[value],
            ];
            setLocalTimespan(dateRangeToRaw(newValue));
          }}
        ></TimespanSliderSingle>
      )}
    </div>
  );
}
