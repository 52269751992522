import React, {
  useState,
  Suspense,
  useEffect,
  useMemo,
  useCallback,
  useContext,
  useRef,
} from "react";
import { groupBy } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Checkbox, Dropdown, IDropdownOption } from "@fluentui/react";

import { defined } from "../../../../../lib/core/defined";
import { useRecoilValue } from "recoil";
import { pythonCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import {
  getTemplates,
  updateTemplate,
  saveNewTemplate,
  deleteTemplate,
} from "../../../../../lib/application/requests/python/templates";
import { useLoadableHttpResource } from "../../../../../lib/application/hooks/useLoadableResource";
import { DefaultLoadingStretchedToFit } from "../../../../../components/Loading";
import { Button } from "../../../../../components/Button";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { ButtonsFooter } from "../../../../../components/ButtonContainers";
import { ButtonsFooterRight } from "../../../../../components/ButtonContainers";
import {
  DataOutputDto,
  FileChunkDto,
  cardToDataframe,
  executeCode,
} from "../../../../../lib/application/requests/python/requests";
import {
  AppMessagesContext,
  UserInfoContext,
} from "../../../../../lib/application/contexts";
import { displayHttpError } from "../../../../../components/errors/HttpErrorNotice";
import { useAddPythonResultCardsCallback } from "../../../../../lib/application/state/actions/cardCallbacks";
import { DocCardTextCK } from "../../../../../lib/application/state/stats/document-core/core";
import { makeTextCardCK } from "../../../../../lib/application/state/stats/document-core/create";
import { logger } from "../../../../../lib/infra/logging";
import { docCardsListQuery } from "../../../../../lib/application/state/stats/document-core/docCardsListState";
import { FoldoutPanelControlled } from "../../../../../components/FoldoutPanel";

import type { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { HttpError } from "../../../../../lib/infra/HttpResult";
import { AlertBox } from "../../../../../components/AlertBox";
import { DisplayRequestErrorInfo } from "../../../../../components/errors/DisplayRequestErrorInfo";
import { SaveTemplateDialog } from "./SaveTemplateDialog";
import { Permission } from "../../../../../lib/application/auth/UserInfo";
import { useHandleCardChangePython } from "../../../../../lib/application/state/actions/selections/shared/useHandleSelectionChangePython";
import {
  Editor,
  readOnlyCkEditorConfig,
} from "../../../../../lib/application/editor";
import { FluentIcon } from "../../../../../components/Icons";
import { downloadBlob } from "../../../../../lib/application/browser/downloadBlob";
import { CodeMirrorLazy } from "../../../../../components/CodeMirrorLazy";

export function PythonAnalysis(props: { cardId: string }) {
  const [selectedModule, setSelectedModule] = useState<string | undefined>();
  const card = useRecoilValue(pythonCardQuery(props.cardId));
  const [updatedCode, setUpdatedCode] = useState(card.data.pythonCode ?? "");
  const [foldoutPanelOpen, setFoldoutPanelOpen] = useState(
    !nonEmptyString(card.data.pythonCode)
  );
  const [analysisError, setAnalysisError] = useState<HttpError | undefined>();
  const [executionInProgress, setExecutionInProgress] = useState(false);
  const handleCardUpdate = useHandleCardChangePython(props.cardId);
  const [outputMode, setOutputMode] = useState<"console" | "card">("console");
  const [consoleOutput, setConsoleOutput] = useState<string | null>(null);
  const [availableFiles, setAvailableFiles] = useState<FileChunkDto[]>([]);

  const codeMirrorRef = useRef<ReactCodeMirrorRef | null>(null);
  const user = useContext(UserInfoContext);
  if (!defined(user)) {
    throw new Error("No user info");
  }
  const cardsList = useRecoilValue(docCardsListQuery);

  const canManageCodeTemplates = user.hasPermission(
    Permission.InternalManagePythonAnalysis
  );

  const [showSaveAsTemplate, setShowSaveAsTemplate] = useState(false);

  const appMessagesHandler = useContext(AppMessagesContext);

  const [modulesRes, reloadModules] = useLoadableHttpResource(getTemplates);
  const modulesByCategory = useMemo(() => {
    const modules = modulesRes.getReadyData();
    if (!defined(modules)) {
      return;
    }
    return groupBy(modules, (m) => m.category);
  }, [modulesRes]);

  useEffect(() => {
    if (card.data.pythonCode === updatedCode || !nonEmptyString(updatedCode)) {
      return;
    }
    const handle = setTimeout(() => {
      handleCardUpdate({
        ...card,
        data: { ...card.data, pythonCode: updatedCode },
      });
    }, 1000);

    return () => {
      clearTimeout(handle);
    };
  }, [card, handleCardUpdate, updatedCode]);

  const addPythonCards = useAddPythonResultCardsCallback();

  const handleInsertText = useCallback((text: string) => {
    const view = codeMirrorRef.current?.view;
    if (!defined(view)) {
      logger.warn("No editor view");
      return;
    }
    view.dispatch({
      changes: {
        from: view.state.selection.main.from,
        to: view.state.selection.main.to,
        insert: text,
      },
    });
  }, []);

  const handleInsertTemplate = useCallback((template: string) => {
    const view = codeMirrorRef.current?.view;
    if (!defined(view)) {
      logger.warn("No editor view");
      return;
    }
    view.dispatch({
      changes: {
        from: view.state.selection.main.from,
        to: view.state.selection.main.to,
        insert: template,
      },
    });
  }, []);

  const handleDownloadFiles = useCallback(async (files: FileChunkDto[]) => {
    let numUnnamedFiles = 0;
    for (const f of files) {
      switch (f.type) {
        case "download-csv":
          const fileName = f.file_name ?? `data${numUnnamedFiles++}.csv`;
          downloadBlob(new Blob([f.content], { type: "text/csv" }), fileName);
          break;
        case "download-json":
          const jsonFileName = f.file_name ?? `data${numUnnamedFiles++}.json`;
          downloadBlob(
            new Blob([f.content], { type: "application/json" }),
            jsonFileName
          );
          break;
        case "download-figure":
          const resp = await fetch(f.content);
          const figureFileName =
            f.file_name ?? `figure${numUnnamedFiles++}.png`;
          downloadBlob(await resp.blob(), figureFileName);
          break;
      }
    }
  }, []);

  const handleAddConsoleOutput = useCallback(async (output: DataOutputDto) => {
    let consoleResult = "";
    const files: FileChunkDto[] = [];
    for (const card of output.cards) {
      const res = await getCardContent(card);
      consoleResult += res.textCardOutput;
      files.push(...res.files);
    }

    setAvailableFiles(files);
    setConsoleOutput(consoleResult);
  }, []);

  const handleAddResultCards = useCallback(
    async (results: DataOutputDto) => {
      const newCardsBelow: DocCardTextCK[] = [];
      const newCardsAbove: DocCardTextCK[] = [];
      const newCardsFirst: DocCardTextCK[] = [];
      const newCardsLast: DocCardTextCK[] = [];

      const files: FileChunkDto[] = [];

      for (const card of results.cards) {
        const res = await getCardContent(card);
        files.push(...res.files);
        let currentCardContent = res.textCardOutput;
        const textCard = makeTextCardCK(currentCardContent);
        textCard.label = nonEmptyString(card.name) ? card.name : textCard.label;
        switch (card.placement) {
          case "first":
            newCardsFirst.push(textCard);
            break;
          case "last":
            newCardsLast.push(textCard);
            break;
          case "above":
            newCardsAbove.push(textCard);
            break;
          case "below":
            newCardsBelow.push(textCard);
            break;
          default:
            newCardsBelow.push(textCard);
        }
      }

      const currentCardIndex = cardsList.findIndex(
        (c) => c.id === props.cardId
      );
      let addedCards = 0;
      const cardArraysToAdd: { cards: DocCardTextCK[]; index: number }[] = [];
      cardArraysToAdd.push({ cards: newCardsFirst, index: 0 });
      addedCards += newCardsFirst.length;
      cardArraysToAdd.push({
        cards: newCardsAbove,
        index: currentCardIndex + addedCards,
      });
      addedCards += newCardsAbove.length;
      cardArraysToAdd.push({
        cards: newCardsBelow,
        index: currentCardIndex + addedCards + 1,
      });
      addedCards += newCardsBelow.length;
      cardArraysToAdd.push({
        cards: newCardsLast,
        index: cardsList.length + addedCards,
      });

      addPythonCards(cardArraysToAdd);
      setAvailableFiles(files);
    },
    [addPythonCards, cardsList, props.cardId]
  );

  const handleRemoveTemplate = useCallback(
    (templateId: number) => {
      deleteTemplate(templateId).then((res) => {
        res.match({
          ok: () => {
            appMessagesHandler?.add("success", "Mallen har tagits bort");
          },
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add("error", "Kunde inte ta bort mallen");
          },
        });
      });
    },
    [appMessagesHandler]
  );

  const handleExecute = useCallback(() => {
    const dataframeSpec =
      card.data.columns.length > 0 || defined(card.data.surveySelection)
        ? cardToDataframe(card)
        : undefined;
    setExecutionInProgress(true);
    executeCode(dataframeSpec, updatedCode)
      .then((res) => {
        res.match({
          ok: (d) => {
            if (outputMode === "console") {
              handleAddConsoleOutput(d);
            } else {
              handleAddResultCards(d);
            }
            setAnalysisError(undefined);
          },
          err: (err) => {
            setAnalysisError(err);
          },
        });
      })
      .finally(() => {
        setExecutionInProgress(false);
      });
  }, [
    card,
    handleAddConsoleOutput,
    handleAddResultCards,
    outputMode,
    updatedCode,
  ]);

  if (modulesRes.isInProgress()) {
    return (
      <DefaultLoadingStretchedToFit
        delayMs={0}
        label="Laddar moduler..."
      ></DefaultLoadingStretchedToFit>
    );
  }

  return (
    <div className="python-card-analysis">
      {showSaveAsTemplate && defined(modulesByCategory) && (
        <SaveTemplateDialog
          modulesByCategory={modulesByCategory}
          onSaveNew={(category, templateName, templateDescription) => {
            saveNewTemplate(
              category,
              templateName,
              templateDescription,
              updatedCode
            ).then((res) => {
              res.match({
                ok: (template) => {
                  setShowSaveAsTemplate(false);
                  reloadModules();
                  appMessagesHandler?.add("success", "Mallen har sparats");
                },
                err: (err) => {
                  logger.error(err);
                  appMessagesHandler?.add("error", "Kunde inte spara mallen");
                },
              });
            });
          }}
          onOverwrite={(templateId, name, description) => {
            updateTemplate(templateId, name, description, updatedCode).then(
              (res) => {
                res.match({
                  ok: () => {
                    setShowSaveAsTemplate(false);
                    reloadModules();
                    appMessagesHandler?.add("success", "Mallen har sparats");
                  },
                  err: (err) => {
                    logger.error(err);
                    appMessagesHandler?.add("error", "Kunde inte spara mallen");
                  },
                });
              }
            );
          }}
          onClose={() => setShowSaveAsTemplate(false)}
        />
      )}
      <FoldoutPanelControlled
        title="Mallar"
        className="foldout-panel-templates"
        toggleOpen={() => setFoldoutPanelOpen(!foldoutPanelOpen)}
        isOpen={foldoutPanelOpen}
      >
        <>
          {defined(modulesByCategory) && (
            <section className="content-padding template-selection">
              <Dropdown
                className="item"
                dropdownWidth={"auto"}
                label="Modul"
                options={Object.keys(modulesByCategory).map((o) => ({
                  key: o,
                  text: o,
                }))}
                selectedKey={selectedModule}
                onChange={(e, o) => {
                  if (!defined(o)) {
                    return;
                  }
                  setSelectedModule(o.key as string);
                }}
              />
              {defined(selectedModule) && (
                <>
                  <div className="templates-list-container">
                    <div className="templates-list">
                      {modulesByCategory[selectedModule]?.map((m) => {
                        return (
                          <div
                            className="template"
                            key={m.template_id + m.name}
                          >
                            <p>
                              <strong>{m.name}</strong>
                            </p>
                            <p>{m.description}</p>
                            <span>
                              <Button
                                small
                                title="Infoga"
                                onClick={() => {
                                  handleInsertTemplate(m.code);
                                }}
                              />
                              {canManageCodeTemplates && (
                                <Button
                                  small
                                  title="Ta bort"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Vill du ta bort mallen "${m.name}"?`
                                      )
                                    ) {
                                      handleRemoveTemplate(m.template_id);
                                    }
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </section>
          )}
        </>
      </FoldoutPanelControlled>

      <>
        <Suspense fallback={<div>Loading editor...</div>}>
          <div className="insert-column margin-x-sm margin-bottom-sm">
            <ColumnInserter
              columns={card.data.columns}
              handleInsertText={handleInsertText}
            />
          </div>
          <CodeMirrorLazy
            codeMirrorRef={codeMirrorRef}
            code={updatedCode}
            height="400px"
            onKeyDown={(e) => {
              if (e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                if (
                  executionInProgress ||
                  !nonEmptyString(updatedCode.trim())
                ) {
                  return;
                }
                handleExecute();
              }
            }}
            onCodeChange={(v) => {
              setUpdatedCode(v);
            }}
          />
        </Suspense>
        <div className="padding-y-md">
          {defined(analysisError) && (
            <div className="content-padding margin-y-sm">
              <AlertBox intent="danger">
                <>
                  {displayHttpError(analysisError)}
                  <DisplayRequestErrorInfo info={analysisError.info} />
                </>
              </AlertBox>
            </div>
          )}
          <ButtonsFooter className="content-padding">
            <ButtonsFooterRight>
              <>
                {canManageCodeTemplates && (
                  <Button
                    title="Spara som mall"
                    onClick={() => setShowSaveAsTemplate(true)}
                  />
                )}

                <Dropdown
                  className="infostat-button"
                  dropdownWidth={"auto"}
                  onRenderTitle={(options) => {
                    const option = options?.[0];
                    if (!defined(option)) {
                      return null;
                    }
                    return (
                      <span>Visa resultat i {option.text.toLowerCase()}</span>
                    );
                  }}
                  selectedKey={outputMode}
                  options={[
                    { key: "console", text: "Resultatpanel" },
                    { key: "card", text: "Nytt kort" },
                  ]}
                  onChange={(e, o) => {
                    if (!defined(o)) {
                      return;
                    }
                    setOutputMode(o.key as "console" | "card");
                  }}
                />
                <Button
                  disabled={!nonEmptyString(updatedCode) || executionInProgress}
                  title={executionInProgress ? "Väntar..." : "Kör"}
                  onClick={handleExecute}
                  intent="primary"
                />
              </>
            </ButtonsFooterRight>
          </ButtonsFooter>
        </div>
        {!executionInProgress && availableFiles.length > 0 && (
          <AlertBox className="file-downloads-panel">
            <div>
              <div className="flex flex-row margin-bottom-sm">
                {availableFiles.map((f) => (
                  <div className="downloadable-file">
                    {(f.type === "download-csv" ||
                      f.type === "download-json") && (
                      <FluentIcon name="document" size="sm" />
                    )}
                    {f.type === "download-figure" && (
                      <FluentIcon name="media" size="sm" />
                    )}
                    <div className="margin-left-xs">
                      {defined(f.file_name) && <label>{f.file_name}</label>}
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <Button
                  small
                  title="Ladda ner filer"
                  onClick={() => handleDownloadFiles(availableFiles)}
                />
              </div>
            </div>
          </AlertBox>
        )}
        {outputMode === "console" &&
          (executionInProgress ? (
            <DefaultLoadingStretchedToFit delayMs={0} />
          ) : (
            nonEmptyString(consoleOutput) &&
            !defined(analysisError) && <ConsoleOutput output={consoleOutput} />
          ))}
      </>
    </div>
  );
}

function ConsoleOutput(props: { output: string }) {
  return (
    <div className="console-output">
      <div className="console-header">
        <h3>Resultat</h3>
      </div>
      <CKEditor
        key="readonly"
        editor={Editor}
        config={readOnlyCkEditorConfig}
        data={props.output}
        onReady={(editor) => {
          editor.enableReadOnlyMode("global");
        }}
      />
    </div>
  );
}

interface Column {
  columnName: string;
}

interface ColumnNameDropdownProps {
  columns: Column[];
  handleInsertText: (columnName: string) => void;
}

const ColumnInserter: React.FC<ColumnNameDropdownProps> = ({
  columns,
  handleInsertText,
}) => {
  const dropdownOptions: IDropdownOption[] = columns.map((c) => ({
    key: c.columnName,
    text: c.columnName,
  }));
  const [selectedColumn, setSelectedColumn] = useState<string | undefined>();
  const [selectMultiple, setSelectMultiple] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  return (
    <div className="column-inserter">
      {selectMultiple ? (
        <Dropdown
          key="multi"
          label="Kolumnnamn"
          options={dropdownOptions}
          multiSelect={true}
          selectedKeys={selectMultiple ? selectedKeys : undefined}
          onChange={(event, option) => {
            if (!defined(option)) {
              return;
            }

            if (selectedKeys.includes(option.key.toString())) {
              setSelectedKeys(
                selectedKeys.filter((k) => k !== option.key.toString())
              );
            } else {
              setSelectedKeys([...selectedKeys, option.key.toString()]);
            }
          }}
        />
      ) : (
        <Dropdown
          disabled={dropdownOptions.length === 0}
          key="single"
          label="Kolumnnamn"
          options={dropdownOptions}
          selectedKey={selectedColumn}
          onChange={(event, option) => {
            if (!defined(option)) {
              return;
            }

            setSelectedColumn(option.key.toString());
          }}
        />
      )}
      <Checkbox
        label="Välj flera"
        disabled={dropdownOptions.length < 2}
        checked={selectMultiple}
        onChange={() => {
          if (selectMultiple) {
            setSelectedColumn(undefined);
            setSelectedKeys([]);
          } else {
            if (defined(selectedColumn)) {
              setSelectedKeys([selectedColumn]);
            }
          }

          setSelectMultiple(!selectMultiple);
        }}
      />
      <Button
        title="Infoga"
        disabled={
          (selectMultiple && selectedKeys.length === 0) ||
          (!selectMultiple && !defined(selectedColumn))
        }
        onClick={() => {
          if (selectMultiple && selectedKeys.length > 0) {
            return handleInsertText(
              selectedKeys.map((k) => `'${k}'`).join(", ")
            );
          }

          if (!defined(selectedColumn)) {
            return;
          }
          handleInsertText(`'${selectedColumn}'`);
        }}
      />
    </div>
  );
};

async function getCardContent(
  card: DataOutputDto["cards"][number]
): Promise<{ textCardOutput: string; files: FileChunkDto[] }> {
  let currentCardContent = "";
  const files: FileChunkDto[] = [];
  for (const part of card.chunks) {
    switch (part.type) {
      case "text": {
        let finalText = "";
        for (const line of part.content.split("\n")) {
          // FIXME: necessary?
          const processedLine = line;
          finalText +=
            finalText === "" ? processedLine : "<br>" + processedLine;
        }
        currentCardContent += `<pre><span style="font-family:'Courier New', Courier, monospace;">${finalText}</span></pre>`;
        break;
      }
      case "png": {
        const markup = await getImageMarkup(part.content);
        currentCardContent += markup;
        break;
      }
      case "download-csv":
      case "download-json":
      case "download-figure": {
        files.push(part);
        break;
      }
    }
  }

  return { textCardOutput: currentCardContent, files };
}

async function getImageMarkup(url: string) {
  const container = document.createElement("div");
  const child = document.createElement("div");
  container.appendChild(child);
  return Editor.create(child)
    .then((editor) => {
      editor.execute("insertImage", { source: url });
      const data = editor.getData();
      editor.destroy();
      return data;
    })
    .catch((err) => {
      logger.error(err);
    });
}
