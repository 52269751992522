import {
  ActionButton,
  Checkbox,
  IContextualMenuItem,
  Icon,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useRecoilState } from "recoil";
import { config, IS_LOCAL, IS_PROD } from "../config";
import {
  InfostatDataAdminModeContext,
  ShowDraftDataContext,
  UserInfoContext,
} from "../lib/application/contexts";
import { SharingInfo } from "../lib/application/files/SharingInfo";
import { useLoadableHttpResource } from "../lib/application/hooks/useLoadableResource";
import {
  getNodeSharingInfo,
  patchNode,
} from "../lib/application/requests/docs/documents";
import {
  reportMetaStateQuery,
  reportTitleQuery,
} from "../lib/application/state/stats/document-meta/queries";
import { useDocumentEditModeOptionalMetadata } from "../lib/application/state/stats/useEditMode";
import { useDocumentMetadataValue } from "../lib/application/state/stats/useMetadata";
import { validateNonEmptyString } from "../lib/application/validation/string";
import { classNames } from "../lib/core/classNames";
import { defined } from "../lib/core/defined";
import { Progress } from "../lib/core/progress";
import { truncate } from "../lib/core/truncate";
import { NotReadyHttpErrNotification } from "./Loading";
import { SimpleInputModal } from "./SimpleInputModal";
import { eitherDisplayer } from "./wrappers/either";
import { ReactComponent as LogoFull } from "../logo_with_text_bw.svg";
import { ReactComponent as LogoPoweredBy } from "../logo_with_text_powered_by_white.svg";
import { ReactComponent as LogoIconWhite } from "../logo_icon_white.svg";
import { FluentModal, FluentModalBody, FluentModalFooter } from "./Modal";
import { getLatestClientVersion } from "../lib/application/app_version";
import { Button } from "./Button";
import { ExternalLink } from "./ExternalLink";
import { pathInfo, pathJoin } from "../lib/paths";

import "./NavBar.scss";
import { openTidioWindow } from "../lib/application/customer_support/load";
import { TextButton } from "./buttons/TextButton";
import { IconBuilding, IconUser } from "@tabler/icons-react";

interface Props {
  logout: () => void;
  documentId?: number;
  toggleDataAdminMode: () => void;
  toggleShowDraftData: () => void;
}
export function NavBar(props: Props) {
  const isDataAdminMode = useContext(InfostatDataAdminModeContext);
  const documentId = props.documentId;

  return (
    <>
      {isDataAdminMode && (
        <div id={"data-admin-indicator" + (IS_PROD ? "-prod" : "")}>
          🦉 Dataadminläge {IS_PROD ? "PRODUKTIONSMILJÖ" : " "}
          <Button
            small
            icon="build"
            title="Hantera mått"
            onClick={() =>
              window.open(
                pathJoin(window.origin, pathInfo.dataAdminHome.pathTemplate),
                "_blank"
              )
            }
          ></Button>
          <Button
            small
            icon="power"
            title="Avaktivera"
            onClick={() => props.toggleDataAdminMode()}
          ></Button>
          <DraftDataToggle toggleShowDraftData={props.toggleShowDraftData} />
        </div>
      )}

      <NavBarBase documentId={documentId}>
        <HelpMenu />
        <SettingsMenu {...props}></SettingsMenu>
      </NavBarBase>
    </>
  );
}

export function NavBarBase(props: {
  isPackagedDocMode?: boolean;
  documentId?: number;
  documentTitle?: string;
  children?: JSX.Element[];
}) {
  const documentId = props.documentId;
  const navigate = useNavigate();
  const isPackagedDocMode = props.isPackagedDocMode;
  const svgProps = useMemo(() => {
    return {
      title: "Logotyp",
      onClick: () => {
        if (isPackagedDocMode) {
          window.open(config.infostatSeLinks.home, "_blank");
          return;
        }
        navigate("/");
      },
    };
  }, [isPackagedDocMode, navigate]);

  return (
    <nav id="nav-bar">
      <div className="nav-bar-inner">
        <div
          className={classNames(
            "nav-main",
            isPackagedDocMode ? "packaged-doc" : undefined
          )}
        >
          <div className="logo-container logo-big clickable">
            {isPackagedDocMode ? (
              <LogoPoweredBy {...svgProps} />
            ) : (
              <LogoFull {...svgProps} />
            )}
          </div>
          <div className="logo-container logo-small clickable">
            {isPackagedDocMode ? (
              <LogoPoweredBy {...svgProps} />
            ) : (
              <LogoIconWhite {...svgProps} />
            )}
          </div>
          {defined(props.documentTitle) && (
            <DocumentTitle
              packagedDocMode={isPackagedDocMode}
              title={props.documentTitle}
            ></DocumentTitle>
          )}
          {defined(documentId) && (
            <NavBarDocumentInfoLoad
              documentId={documentId}
            ></NavBarDocumentInfoLoad>
          )}
        </div>
        {props.children}
      </div>
    </nav>
  );
}

export function NavBarThirdPartyDoc(props: {
  documentTitle?: string;
  logout: () => void;
}) {
  const svgProps = useMemo(() => {
    return {
      title: "Logotyp",
      onClick: () => {
        window.open(config.infostatSeLinks.home, "_blank");
        return;
      },
    };
  }, []);

  return (
    <nav id="nav-bar">
      <div className="nav-bar-inner">
        <div className="nav-main third-party-doc">
          <div className="logo-container logo-big clickable">
            <LogoPoweredBy {...svgProps} />
          </div>
          <div className="logo-container logo-small clickable">
            <LogoPoweredBy {...svgProps} />
          </div>
          {defined(props.documentTitle) && (
            <DocumentTitle
              packagedDocMode={true}
              title={props.documentTitle}
            ></DocumentTitle>
          )}
        </div>
        <SettingsMenuThirdPartyDoc
          logout={props.logout}
        ></SettingsMenuThirdPartyDoc>
      </div>
    </nav>
  );
}

function DraftDataToggle(props: { toggleShowDraftData: () => void }) {
  const showDraftData = useContext(ShowDraftDataContext);
  return (
    <Checkbox
      className="draft-data-toggle"
      checked={showDraftData}
      onChange={props.toggleShowDraftData}
      label="Visa utkastdata"
    />
  );
}

function NavBarDocumentInfoLoad(props: {
  documentId: number;
  saveProgress?: [Progress, React.Dispatch<React.SetStateAction<Progress>>];
}) {
  const documentId = props.documentId;
  const loadSharingInfo = useCallback(
    () => getNodeSharingInfo(documentId),
    [documentId]
  );
  const [sharingInfo] = useLoadableHttpResource(loadSharingInfo);

  return (
    <NavBarDocumentInfoOrError
      item={sharingInfo}
      {...props}
    ></NavBarDocumentInfoOrError>
  );
}

const NavBarDocumentInfoOrError = eitherDisplayer(
  NotReadyHttpErrNotification,
  NavBarDocumentInfo
);

function NavBarDocumentInfo(props: {
  documentId: number;
  item: SharingInfo;
  saveProgress?: [Progress, React.Dispatch<React.SetStateAction<Progress>>];
}) {
  const [reportTitle, setReportTitle] = useRecoilState(reportTitleQuery);
  const reportMeta = useRecoilValue(reportMetaStateQuery);
  const [nameModalOpen, setNameModalOpen] = React.useState(false);

  // const saveProgress = props.saveProgress?.[0];
  const setSaveProgress = props.saveProgress?.[1];
  const sharingInfo = props.item;

  const metadata = useDocumentMetadataValue();
  const { hasEditDocumentAccess } = useDocumentEditModeOptionalMetadata(
    sharingInfo,
    metadata
  );

  const handleUpdateTitle = (name: string) => {
    if (defined(reportMeta)) {
      setReportTitle(name);
      setSaveProgress?.(Progress.InProgress);
      patchNode(reportMeta.id, { title: name }).finally(() =>
        setSaveProgress?.(Progress.Success)
      );
    }
  };

  const canEdit = hasEditDocumentAccess;

  return (
    <div className="nav-bar-group">
      {nameModalOpen && (
        <SimpleInputModal
          title="Ändra namn"
          onDismiss={() => setNameModalOpen(false)}
          onSaveInput={(name) => {
            setNameModalOpen(false);
            handleUpdateTitle(name);
          }}
          input={{
            initialValue: defined(reportTitle) ? reportTitle : "",
            label: "Namn",
            maxLength: config.docTitleMaxLength,
            validate: validateNonEmptyString,
          }}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      {defined(reportTitle) && (
        <DocumentTitle
          title={reportTitle}
          onEdit={canEdit ? () => setNameModalOpen(true) : undefined}
        ></DocumentTitle>
      )}
    </div>
  );
}

function DocumentTitle(props: {
  title: string;
  packagedDocMode?: boolean;
  onEdit?: () => void;
}) {
  const packagedDocMode = props.packagedDocMode ?? false;
  const canEdit = defined(props.onEdit);
  return (
    <div className="report-meta">
      <div
        className={classNames("title-container", canEdit ? "editable" : "")}
        onClick={() => {
          if (canEdit) {
            props.onEdit?.();
          }
        }}
      >
        <div className="title">
          {packagedDocMode ? (
            <>
              <span className="hide-on-mobile" title={props.title}>
                {truncate(props.title, 100)}
              </span>
              <span className="mobile-only" title={props.title}>
                {truncate(props.title, 25)}
              </span>
            </>
          ) : (
            <>
              <span className="hide-on-mobile" title={props.title}>
                {truncate(props.title, 50)}
              </span>
              <span className="mobile-only" title={props.title}>
                {truncate(props.title, 20)}
              </span>
            </>
          )}
        </div>
        {canEdit && <Icon className="icon" iconName="edit"></Icon>}
      </div>
    </div>
  );
}

function SettingsMenuThirdPartyDoc(props: { logout: () => void }) {
  const userInfo = useContext(UserInfoContext);

  return (
    <div className="settings-container">
      <ActionButton
        iconProps={{ iconName: "user" }}
        size={10}
        menuProps={{
          shouldFocusOnMount: true,
          shouldFocusOnContainer: true,
          onRenderMenuList: (menuListProps, defaultRender) => {
            return (
              <div>
                <div className="navbar-settings-menu-header padding-x-md padding-y-md">
                  <div>
                    <div className="row">
                      <IconUser size={16} />
                      <span>{userInfo?.email() ?? ""}</span>
                    </div>
                  </div>
                </div>
                {defaultRender?.(menuListProps)}
              </div>
            );
          },
          items: [
            {
              key: "log-out",
              text: "Logga ut",
              onClick: () => {
                props.logout();
              },
            } as IContextualMenuItem,
          ].filter(defined),
        }}
      />{" "}
    </div>
  );
}

function SettingsMenu(props: Props) {
  const navigate = useNavigate();
  const [showAbout, setShowAbout] = useState(false);
  const userInfo = useContext(UserInfoContext);

  return (
    <div className="settings-container">
      {showAbout && (
        <AboutModal
          isOpen={showAbout}
          onClose={() => setShowAbout(false)}
        ></AboutModal>
      )}
      <ActionButton
        iconProps={{ iconName: "user" }}
        size={10}
        menuProps={{
          shouldFocusOnMount: true,
          shouldFocusOnContainer: true,
          onRenderMenuList: (menuListProps, defaultRender) => {
            const orgName = userInfo?.organizationName();
            return (
              <div>
                <div className="navbar-settings-menu-header padding-x-md padding-y-md">
                  <div>
                    <div className="row">
                      <IconUser size={16} />
                      <span>{userInfo?.email() ?? ""}</span>
                    </div>
                    {defined(orgName) && (
                      <div className="row">
                        <IconBuilding size={16} />
                        <span>{orgName}</span>
                      </div>
                    )}
                  </div>
                </div>
                {defaultRender?.(menuListProps)}
              </div>
            );
          },
          items: [
            {
              key: "about",
              text: "Om",
              onClick: () => setShowAbout(true),
            },
            {
              key: "settings",
              text: "Inställningar",
              onClick: () => {
                navigate(pathInfo.userSettings.pathTemplate);
              },
            },
            userInfo?.internalMeasureAdmin()
              ? {
                  key: "toggle-data-admin",
                  text: "Dataadmin på/av",
                  onClick: () => {
                    props.toggleDataAdminMode();
                  },
                }
              : undefined,
            userInfo?.internalCanManageITFeatures() && IS_LOCAL
              ? {
                  key: "toggle-view-lab",
                  text: "Visa vylabb",
                  onClick: () => {
                    navigate("/lab");
                  },
                }
              : undefined,
            userInfo?.internalCanAccessAdminPanel()
              ? {
                  key: "admin-portal",
                  text: "Adminportalen",
                  onClick: () => {
                    window.open("/admin-portal", "_blank");
                  },
                }
              : undefined,
            {
              key: "log-out",
              text: "Logga ut",
              onClick: () => {
                props.logout();
              },
            } as IContextualMenuItem,
          ].filter(defined),
        }}
      />{" "}
    </div>
  );
}

function SavingIndicator(props: { saveProgress: Progress }) {
  return (
    <div className="save-status">
      {props.saveProgress === Progress.InProgress && (
        <div className="saving-spinner">
          <span className="label">Sparar ...</span>
        </div>
      )}
      {props.saveProgress === Progress.Success && (
        <span>Alla ändringar sparade</span>
      )}
    </div>
  );
}

function HelpMenu(props: {}) {
  const [contactOpen, setContactOpen] = React.useState(false);
  const emailAddress = config.infostatSupportEmail;
  const helpText = "Hjälp & guider";
  return (
    <div>
      <FluentModal
        title="Kontakt"
        isOpen={contactOpen}
        onClose={() => setContactOpen(false)}
      >
        <FluentModalBody>
          <p>
            Behöver du hjälp, saknar du ett mått eller vill få råd och tips om
            hur du kan använda Infostat? Dra dig inte för att kontakta oss.
            <br></br>
            <br></br>
            Vi finns tillgängliga via chatt under dagtid (
            <TextButton onClick={openTidioWindow} title="klicka här" />) eller
            via e-post.
            <br></br>
            <br></br>
            E-post: <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </p>
        </FluentModalBody>
        <FluentModalFooter>
          <Button title="Stäng" onClick={() => setContactOpen(false)}></Button>
        </FluentModalFooter>
      </FluentModal>
      <ActionButton
        title={helpText}
        className="help-action-button"
        iconProps={{ iconName: "help" }}
        menuIconProps={{ iconName: "" }}
        text={helpText}
        menuProps={{
          className: "navbar-help-menu-container",
          shouldFocusOnMount: true,
          shouldFocusOnContainer: true,
          items: [
            {
              key: "tutorials",
              text: "", // Not actually used, since we use onRenderContent instead
              onRenderContent: () => {
                return (
                  <span className="custom-menu-item">
                    <ExternalLink
                      link={config.infostatSeLinks.tutorial}
                      text="Infostat på tre minuter"
                    ></ExternalLink>
                  </span>
                );
              },
              onClick: () => {
                window.open(config.infostatSeLinks.tutorial, "_blank");
              },
            },
            {
              key: "contact",
              text: "Kontakt",
              onClick: () => setContactOpen(true),
            },
          ],
        }}
      />
    </div>
  );
}

const changeLog: { versionNum: number; changes: string | string[] }[] = [
  {
    versionNum: 10,
    changes: [
      "Stöd för teman för diagram",
      "Fler möjligheter att anpassa diagramutseende",
      "Delning av diagram via sociala medier",
    ],
  },
  {
    versionNum: 9,
    changes: [
      "Beräknade värden med fler räknesätt",
      "Möjlighet att anpassa textstorlekar i diagram- och tabellrubriker",
    ],
  },
  {
    versionNum: 8,
    changes: "Prestandaförbättringar för hantering av enkätdata.",
  },
];

function AboutModal(props: { isOpen: boolean; onClose: () => void }) {
  const [latestVersion, setLatestVersion] = React.useState<number>();

  useEffect(() => {
    getLatestClientVersion().then((version) => setLatestVersion(version));
  });

  return (
    <FluentModal title="Om" isOpen={props.isOpen} onClose={props.onClose}>
      <FluentModalBody>
        <p>
          Se{" "}
          <ExternalLink
            text="infostat.se"
            link={config.infostatSeLinks.contact}
          ></ExternalLink>{" "}
          för information och kontakt.
        </p>
        <section>
          <h3>Teknisk information</h3>
          <p>
            Version: {config.appVersion}
            <br></br>
            Senaste version: {latestVersion}
          </p>
        </section>
        <section>
          <h3>Senaste uppdateringarna</h3>
          {changeLog.map((changes) => (
            <>
              <h4>Version {changes.versionNum}</h4>
              {typeof changes.changes === "string" ? (
                <p>{changes.changes}</p>
              ) : (
                <ul>
                  {changes.changes.map((change, i) => (
                    <li key={i}>{change}</li>
                  ))}
                </ul>
              )}
            </>
          ))}
        </section>
        <>
          {defined(latestVersion) &&
            parseInt(config.appVersion) < latestVersion && (
              <section>
                <p>
                  Du har en gammal version av appen. Ladda om sidan för att få
                  den senaste versionen.
                </p>
                <div>
                  <Button
                    title="Ladda om"
                    onClick={() => window.location.reload()}
                  ></Button>
                </div>
              </section>
            )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <Button title="Stäng" onClick={props.onClose}></Button>
      </FluentModalFooter>
    </FluentModal>
  );
}
