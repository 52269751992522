import * as _ from "lodash";
import * as d3scaleChromatic from "d3-scale-chromatic";
import * as d3scale from "d3-scale";

import { defined } from "../../../core/defined";
import { StatsDataset } from "../datasets/StatsDataset";
import { scaleColorsCategory } from "../shared/core/colors/colors";
import { ChartDataUnknown } from "../shared/chart_data/ChartData";
import { ColorRange, ScaleInfo } from "./types";
import { logger } from "../../../infra/logging";
import {
  significantFiguresRounderLocale,
  swedishLocaleSimple,
} from "../format";
import {
  DEFAULT_STATS_MAP_COLOR_SCHEME,
  StatsMapColorScheme,
  makeColorGetter,
} from "./colors";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";

/** Default num breakpoints on scale for map tab */
export const DEFAULT_NUM_BREAKPOINTS = 4;
/** Default num scale intervals for map tab */
export const DEFAULT_NUM_INTERVALS = DEFAULT_NUM_BREAKPOINTS + 1;

export function getIntegerColorScaleAuto(
  minValue: number,
  maxValue: number,
  getColors: (numColors: number) => readonly string[],
  targetNumIntervals = DEFAULT_NUM_INTERVALS
): { ranges: ColorRange[]; scale: (value: any) => string } {
  const absDiff = Math.abs(maxValue - minValue);
  // Figure out number of intervals - at least 1, at most 5
  // Step length, at least 1
  const stepLength = Math.ceil(absDiff / targetNumIntervals);

  const isSingleValueIntervals = stepLength < 2;
  if (isSingleValueIntervals) {
    const numIntervals =
      stepLength === 0 ? 1 : 1 + Math.ceil(absDiff / stepLength);
    const colors = getColors(numIntervals);
    const ranges = colors.map((color, index) => {
      const currentValue = minValue + stepLength * index;
      return { color, min: currentValue, max: currentValue };
    });

    return {
      ranges,
      scale: (value) => {
        const color = ranges.find((r) => r.min === value)?.color;
        if (defined(color)) {
          return color;
        }
        logger.error("Did not find color");
        return "#ffffff";
      },
    };
  }

  const numIntervals = stepLength === 0 ? 1 : Math.ceil(absDiff / stepLength);
  const colors = getColors(numIntervals);
  const thresholds = _.range(1, numIntervals).map(
    (interval) => minValue + interval * stepLength + 1
  );
  const s = d3scale
    .scaleThreshold<number, string>()
    .domain(thresholds)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .range(colors as any);

  const ranges = colors.map((color, index) => {
    const minString =
      index === 0 ? undefined : minValue + stepLength * index + 1;
    const maxString =
      index === colors.length - 1
        ? undefined
        : minValue + stepLength * (index + 1);
    return {
      color,
      min: minString,
      max: maxString,
    };
  });

  return { ranges, scale: s };
}

const oneSFRounder = significantFiguresRounderLocale(1, swedishLocaleSimple);
const twoSFRounder = significantFiguresRounderLocale(2, swedishLocaleSimple);
const threeSFRounder = significantFiguresRounderLocale(3, swedishLocaleSimple);
const fourSFRounder = significantFiguresRounderLocale(4, swedishLocaleSimple);

export function findDistinctiveFormatter(
  numbers: number[]
): (value: number) => string {
  for (const formatter of [
    oneSFRounder,
    twoSFRounder,
    threeSFRounder,
    fourSFRounder,
  ]) {
    const formatted = numbers.map(formatter);
    if (_.uniq(formatted).length === numbers.length) {
      return formatter;
    }
  }

  return fourSFRounder;
}

export function getColorScaleManual(
  thresholds: number[],
  getColors: (numColors: number) => readonly string[],
  manualColors?: string[]
): { ranges: ColorRange[]; scale: (value: any) => string } {
  if (defined(manualColors) && manualColors.length !== thresholds.length + 1) {
    throw new Error("Number of colors must match number of thresholds");
  }

  const colors = manualColors ?? getColors(thresholds.length + 1);
  const s = d3scale
    .scaleThreshold<number, string>()
    .domain(thresholds)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .range(colors as any);

  const formatter = findDistinctiveFormatter(thresholds);

  const ranges = colors.map((color, index) => {
    const min = index === 0 ? undefined : thresholds[index - 1];
    const max = index === colors.length - 1 ? undefined : thresholds[index];
    return {
      color,
      min: defined(min) ? formatter(min) : undefined,
      max: defined(max) ? formatter(max) : undefined,
    };
  });

  return { ranges, scale: s };
}

export function getColorScaleInfo(
  originalDataset: StatsDataset,
  chartData: ChartDataUnknown[],
  settings?: DataOutputSettings
): ScaleInfo {
  if (chartData.length === 0) {
    throw new Error("No datasets or no chartData");
  }
  const manualRangeColors = settings?.mapChart.manualColorsForRanges;
  const valueType = originalDataset.primaryValueType;

  const customColorScale = settings?.mapChart.colorScale;
  const getColorsFunc = defined(customColorScale)
    ? makeColorGetter(customColorScale as StatsMapColorScheme)
    : makeColorGetter(DEFAULT_STATS_MAP_COLOR_SCHEME);
  switch (valueType) {
    case "survey":
    case "survey_string":
      throw new Error(
        `Map function getColorScaleInfo not implemented for ${valueType} data`
      );
    case "integer":
    case "decimal": {
      const fullExtent = chartData
        .map((c) => c.rangeExtent(false))
        .reduce((extent1, extent2) => {
          return [
            Math.min(extent1[0], extent2[0]),
            Math.max(extent1[1], extent2[1]),
          ];
        });

      const manualBreakpoints = settings?.mapChart.manualBreakpoints;
      if (manualBreakpoints) {
        // Manual coloring
        const { ranges, scale } = getColorScaleManual(
          manualBreakpoints,
          getColorsFunc,
          settings.mapChart.manualColorsForRanges
        );
        return { type: "sequential", ranges, valueToColor: scale };
      }

      // Auto coloring
      if (valueType === "integer") {
        const { ranges, scale } = getIntegerColorScaleAuto(
          fullExtent[0],
          fullExtent[1],
          (numColors) => {
            if (defined(manualRangeColors)) {
              return manualRangeColors;
            }
            return getColorsFunc(numColors);
          }
        );
        return {
          type: "sequential",
          ranges,
          valueToColor: (input: any) => scale(input),
        };
      } else {
        return getDecimalColorScaleAuto(
          fullExtent,
          chartData,
          manualRangeColors
        );
      }
    }
    case "category": {
      const categories = originalDataset.categories();
      const manualColorsForCategories =
        settings?.mapChart.manualColorsForCategories;
      const categoryToColor = defined(manualColorsForCategories)
        ? (category: string) => manualColorsForCategories[category]
        : scaleColorsCategory(categories);
      return {
        type: "category",
        valueToColor: categoryToColor,
        categories: categories.map((c) => ({
          color: categoryToColor(c),
          label: c,
        })),
      };
    }
  }
}

export function getDecimalColorScaleAuto(
  fullExtent: [number, number],
  chartData: ChartDataUnknown[],
  manualColors?: string[]
): ScaleInfo {
  if (fullExtent[0] === fullExtent[1]) {
    const formatValue = chartData[0].makeTicksRangeFormatter(fullExtent);
    const singleColor = manualColors?.[0] ?? d3scaleChromatic.schemeBlues[5][1];
    return {
      type: "sequential",
      valueToColor: (input: any) => singleColor,
      ranges: [
        {
          color: singleColor,
          min: formatValue(fullExtent[0]),
          max: formatValue(fullExtent[1]),
        },
      ],
    };
  }
  const maxNumColors = Math.ceil(fullExtent[1] - fullExtent[0]);
  const colors =
    manualColors ??
    d3scaleChromatic.schemeBlues[Math.min(Math.max(maxNumColors, 3), 5)];
  const s = d3scale
    .scaleQuantize<string, string>()
    .domain(fullExtent)
    .range(colors);
  const thresholds = s.thresholds();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const valueToColor = (input: any) => {
    return s(input);
  };
  const formatValue = chartData[0].makeTicksRangeFormatter(thresholds);
  const ranges = colors.map((color, index) => {
    const min = thresholds[index - 1];
    const max = thresholds[index];
    return {
      color,
      min: defined(min) ? formatValue(min) : undefined,
      max: defined(max) ? formatValue(max) : undefined,
    };
  });
  return { ranges, valueToColor, type: "sequential" };
}
