import { assertNever, SpinnerSize, ThemeProvider } from "@fluentui/react";
import { Map } from "mapbox-gl";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState } from "recoil";

import { DefaultLoading } from "../../../../../../components/Loading";
import { ISharingInfo } from "../../../../../../lib/application/files/SharingInfo";
import { useToggle } from "../../../../../../lib/application/hooks/useToggle";
import { replaceMicroMapSetting } from "../../../../../../lib/application/state/actions/micro/updates";
import { singleMicroCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { useCardEditMode } from "../../../../../../lib/application/state/stats/useEditMode";
import { fluentUITheme } from "../../../../../../lib/application/theme";
import { defined } from "../../../../../../lib/core/defined";
import { Progress } from "../../../../../../lib/core/progress";
import { InfostatBoundingBox } from "../../../../../../lib/domain/cartography/types";
import {
  defaultMicroColorSettings,
  colorFromZValue,
} from "../../../../../../lib/domain/micro/colors";
import { logger } from "../../../../../../lib/infra/logging";
import {
  ColorBag,
  mapSetLayerOpacity,
  SELECTION_FILL_OPACITY,
  mapSetFeatureSelected,
  mapSetDesoFeatureColor,
  mapSetFeatureColor,
  mapHideDesoLayer,
  mapHideRegsoLayer,
  mapShowDesoLayer,
  mapShowRegsoLayer,
  mapRemoveFeatureState,
  removeAllFeatureState,
  getGeoBorderLayerId,
  desoRegsoLabelsSourceId,
} from "../shared";
import { useFullscreen } from "../useFullscreen";
import { useSelectionControl } from "../useGeoControl";
import { useToolPanelControl } from "../useToolPanelControl";
import { useGeoState } from "../useGeoState";
import { useResultsControl } from "../useResultsControl";
import { MicroDataset } from "../../../../../../lib/application/stats/datasets/MicroDataset";
import { useStateTransition } from "../../../../../../lib/application/hooks/useStateTransition";
import { MAPBOX_LABEL_LAYERS_ALL, useMapSetup } from "../useMapSetup";
import { classNames } from "../../../../../../lib/core/classNames";
import { useGeoTree } from "../useGeoTree";
import {
  MicroGeoSelections,
  MicroMapSettings,
  MicroMapView,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import {
  MicroMapDownloadContext,
  ShowDraftDataContext,
  UserInfoContext,
} from "../../../../../../lib/application/contexts";
import { useGeometricRendering } from "../useGeometricRendering";
import {
  htmlToPngUri,
  saveHtmlToPng,
} from "../../../../../../lib/application/exports/svg";
import { useGeocoderControl } from "../useGeocoderControl";
import { SelectionPanelControl } from "./SelectionPanel";
import { ToolPanelControl } from "./ToolPanel";
import { PostalExportModal } from "./PostalExportModal";
import {
  exportAddresses,
  exportZipcodes,
} from "../../../../../../lib/application/requests/address_export";
import { useSynchronizeState } from "../../../../../../lib/application/hooks/useSynchronizeState";
import { DocCardMicro } from "../../../../../../lib/application/state/stats/document-core/core";
import { useSaveCard } from "../../../../../../lib/application/state/actions/useSaveDocument";
import {
  createMicroCardImageEventName,
  emitMicroCardImageCreatedEvent,
} from "../../../../../../lib/application/state/stats/packaged-doc/pack";
import { MicroGeoTree } from "../../../../../../lib/application/stats/shared/MicroGeoTree";
import { MicroMapData } from "../../../../../../lib/application/state/stats/document-core/_core-shared";
import { mapOptional } from "../../../../../../lib/core/func/mapOptional";
import { createRoot } from "react-dom/client";

const LOADING_OVERLAY_DELAY_MS = 2000;

interface Props {
  view: MicroMapView;
  cardId: string;
  sharingInfo: ISharingInfo;
}
export const MicroMap = React.memo(MicroMapInner);
function MicroMapInner(props: Props) {
  const { cardId, sharingInfo, view } = props;

  const [expandSelectionPanel, handleToggleExpandSelectionPanel] =
    useToggle(true);
  const [expandQuickSelectPanel, handleToggleQuickSelectPanel] =
    useToggle(true);
  const showAdminDraftData = useContext(ShowDraftDataContext);
  const [addressExportOpen, setAddressExportOpen] = useState(false);
  const [zipcodeExportOpen, setZipcodeExportOpen] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const geoState = useGeoState(cardId);

  const { listenEvent: listenToDownloadEvent } = useContext(
    MicroMapDownloadContext
  );
  const colorBag = useMemo(() => new ColorBag(), []);

  const geoTree = useGeoTree();

  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: props.cardId })
  );

  const fullscreenToggleState = useToggle(false);
  const [isFullscreen] = fullscreenToggleState;

  const selectedAreas = card.data.geoSelections;
  const mapSettings = card.data.settings.map;
  const docId = props.sharingInfo.nodeId() ?? 0;
  const containerId = getMapContainerId(docId, props.cardId);
  const map = useMapSetup(
    containerId,
    card.data.mapLocationBounds,
    isFullscreen
  );

  const geoLayerIds = useGeometricRendering(
    map,
    card,
    isFullscreen,
    showAdminDraftData
  );

  const { isEditingCard } = useCardEditMode(cardId, sharingInfo);

  const saveCard = useSaveCard();

  const handleUpdateSetting = useCallback(
    <T extends keyof MicroMapSettings>(
      setting: T,
      settingValue: MicroMapSettings[T]
    ) => {
      const updatedSettings = replaceMicroMapSetting(
        mapSettings,
        setting,
        settingValue
      );
      const updatedCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          settings: {
            ...card.data.settings,
            map: updatedSettings,
          },
        },
      };
      saveCard?.(updatedCard);
      setCard(updatedCard);
    },
    [card, mapSettings, saveCard, setCard]
  );

  const setLocationBounds = useCallback(
    (bounds: InfostatBoundingBox) => {
      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          mapLocationBounds: bounds,
        },
      };
      setCard(updatedCard);
      saveCard?.(updatedCard);
    },
    [card, saveCard, setCard]
  );

  const microDataset: MicroDataset | undefined =
    card.data.loadedData?.primaryDataset;

  const interactionHandlers = useMemo(() => {
    return !defined(map)
      ? []
      : [map.dragRotate, map.dragPan, map.scrollZoom, map.doubleClickZoom];
  }, [map]);

  useStateTransition(selectedAreas, (prev, current) => {
    if (!defined(map)) {
      return;
    }

    if (!defined(current)) {
      if (defined(prev)) {
        return removeAllFeatureState(map, prev);
      }
      return;
    }

    if (defined(prev) && current.type !== prev.type) {
      return removeAllFeatureState(map, prev);
    }
  });

  const geotype = selectedAreas?.type;
  useEffect(() => {
    if (!defined(geotype)) {
      mapHideDesoLayer(map);
      mapHideRegsoLayer(map);
      return;
    }

    if (geotype === "deso") {
      mapShowDesoLayer(map);
      mapHideRegsoLayer(map);
    } else if (geotype === "regso") {
      mapShowRegsoLayer(map);
      mapHideDesoLayer(map);
    }
  }, [geotype, map]);

  // Set region borders visibility
  useEffect(() => {
    if (!defined(map) || !defined(geotype)) {
      return;
    }
    const show = mapSettings.showBorders;
    try {
      map.setLayoutProperty(
        getGeoBorderLayerId(geotype),
        "visibility",
        show ? "visible" : "none"
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [geotype, map, mapSettings.showBorders]);

  const showMapLabels = mapSettings.showMapLabels ?? true;

  useEffect(() => {
    const disableInteractivity =
      (view === "map-select" && mapSettings.mapSelectTool === "draw-select") ||
      !isEditingCard;
    interactionHandlers.forEach((handler) =>
      disableInteractivity ? handler.disable() : handler.enable()
    );
  }, [interactionHandlers, isEditingCard, mapSettings.mapSelectTool, view]);

  useEffect(() => {
    if (!defined(map)) {
      return;
    }
    for (const layer of MAPBOX_LABEL_LAYERS_ALL) {
      try {
        map.setLayoutProperty(
          layer,
          "visibility",
          showMapLabels ? "visible" : "none"
        );
      } catch (e) {
        logger.warn("Failed to set layer visibility");
      }
    }
  }, [showMapLabels, map]);

  const resultsLoading =
    card.data.loadedData?.primaryProgress?.type === Progress.InProgress;

  useEffect(() => {
    if (!resultsLoading) {
      setShowLoadingOverlay(false);
      return;
    }

    const handle = setTimeout(() => {
      setShowLoadingOverlay(resultsLoading);
    }, LOADING_OVERLAY_DELAY_MS);
    return () => {
      clearTimeout(handle);
    };
  }, [resultsLoading]);

  const oldPrimaryDataLoaded =
    resultsLoading && defined(card.data.loadedData?.primaryDataset);

  const { fullscreenSupported, handleFullscreen } = useFullscreen(
    fullscreenToggleState,
    map
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [geocoderControl, addGeocoderControl, removeGeocoderControl] =
    useGeocoderControl(map);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectionControl, addSelectionControl, removeSelectionControl] =
    useSelectionControl(
      view,
      map,
      mapSettings,
      resultsLoading,
      isEditingCard,
      card.data.loadedData?.primaryDataset,
      colorBag,
      setLocationBounds,
      expandSelectionPanel,
      handleToggleExpandSelectionPanel,
      expandQuickSelectPanel,
      handleToggleQuickSelectPanel,
      geoState,
      geoTree,
      geoLayerIds
    );

  const handleZoomIn = useCallback(() => {
    map?.zoomIn();
  }, [map]);

  const handleZoomOut = useCallback(() => {
    map?.zoomOut();
  }, [map]);

  const zoomActions = useMemo(() => {
    return { handleZoomIn, handleZoomOut };
  }, [handleZoomIn, handleZoomOut]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toolPanelControl, addToolPanelControl, removeToolPanelControl] =
    useToolPanelControl(
      view,
      map,
      showLoadingOverlay,
      selectedAreas,
      mapSettings,
      fullscreenSupported,
      isFullscreen,
      handleFullscreen,
      handleUpdateSetting,
      zoomActions,
      isEditingCard
    );

  const colorSettings = useMemo(() => {
    if (mapSettings.localZRange) {
      return defaultMicroColorSettings(
        mapSettings.colorScheme,
        microDataset?.zMin,
        microDataset?.zMax
      );
    }

    return defaultMicroColorSettings(
      mapSettings.colorScheme,
      -mapSettings.zMinMaxAbsolute,
      mapSettings.zMinMaxAbsolute
    );
  }, [
    mapSettings.colorScheme,
    mapSettings.localZRange,
    mapSettings.zMinMaxAbsolute,
    microDataset?.zMax,
    microDataset?.zMin,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resultsControl, addResultsControl, removeResultsControl] =
    useResultsControl(
      view,
      map,
      mapSettings,
      isEditingCard,
      card.data.loadedData?.primaryDataset
    );

  useEffect(() => {
    if (!showLoadingOverlay) {
      return;
    }
    const canvasContainer = map?.getCanvasContainer();
    if (!defined(canvasContainer)) {
      return;
    }
    const div = document.createElement("div");
    div.className = "micro-results-loading-overlay-container";
    canvasContainer.appendChild(div);

    const root = createRoot(div);
    root.render(<ResultsLoading loading={showLoadingOverlay} />);

    return () => {
      root.unmount();
      canvasContainer.removeChild(div);
    };
  }, [map, showLoadingOverlay]);

  // Render selected areas (not result)
  useEffect(() => {
    if (!defined(map)) {
      return;
    }

    clearDesoRegsoLabels(map);

    if (
      resultsLoading ||
      card.data.loadedData?.primaryProgress?.type === Progress.Error ||
      (view === "map-select" && isEditingCard)
    ) {
      if (!defined(selectedAreas)) {
        return;
      }
      if (oldPrimaryDataLoaded) {
        return;
      }
      const geotype = selectedAreas.type;
      mapSetLayerOpacity(geotype, map, SELECTION_FILL_OPACITY);
      selectedAreas.selected.forEach((area) => {
        switch (area.type) {
          case "regso":
          case "deso":
            mapSetFeatureSelected(map, geotype, area.props.id);
            break;
          case "user-defined":
            area.props.forEach((p) => {
              mapSetFeatureColor(geotype, map, p.id, area.color);
            });
            break;
          default:
            assertNever(area);
        }
      });
      return;
    }
  }, [
    card.data.loadedData?.primaryProgress?.type,
    isEditingCard,
    map,
    oldPrimaryDataLoaded,
    resultsLoading,
    selectedAreas,
    view,
  ]);

  // Render results on map
  useEffect(() => {
    if (!defined(map)) {
      return;
    }
    if (resultsLoading || (view !== "map-view" && isEditingCard)) {
      return;
    }
    const loadedData = card.data.loadedData;
    if (!defined(loadedData)) {
      return;
    }
    const progress = loadedData.microMapState?.loadedMicroMapDataProgress;
    if (progress?.type === Progress.Error) {
      logger.error(progress.error);
      return;
    }

    if (progress?.type !== Progress.Success) {
      return;
    }

    const microMapData = loadedData.microMapState?.loadedMicroMapData;
    if (!defined(microMapData)) {
      return;
    }

    // WORKAROUND: avoid rendering stale datasets
    if (
      microMapData.resultsType === "compare-units-deso" &&
      selectedAreas?.type !== "deso"
    ) {
      return;
    }
    if (
      microMapData.resultsType === "compare-units-regso" &&
      selectedAreas?.type !== "regso"
    ) {
      return;
    }

    const noDataAvailable = new Set<number>();
    if (!defined(selectedAreas)) {
      return;
    }
    for (const area of selectedAreas.selected) {
      if (area.type === "user-defined") {
        area.props.forEach((p) => {
          noDataAvailable.add(p.id);
        });
      } else {
        noDataAvailable.add(area.props.id);
      }
    }

    mapSetLayerOpacity(selectedAreas.type, map, mapSettings.resultsOpacity);

    if (mapSettings.showDesoRegsoLabels || mapSettings.showDesoRegsoValues) {
      addDesoRegsoLabels(
        map,
        selectedAreas,
        mapSettings.showDesoRegsoLabels ?? false,
        mapSettings.showDesoRegsoValues ?? false,
        microMapData,
        geoTree
      );
    } else {
      clearDesoRegsoLabels(map);
    }

    const desoData = microMapData.desoResults;
    if (defined(desoData)) {
      desoData.forEach((part) => {
        noDataAvailable.delete(part.id);
        mapSetDesoFeatureColor(
          map,
          part.id,
          mapSettings.bordersOnlyNoFill
            ? "transparent"
            : colorFromZValue(part.zValue, colorSettings),
          mapSettings.bordersOnlyNoFill ? "rgba(40,40,40,255)" : undefined
        );
      });
    }

    const regsoData = microMapData.regsoResults;
    if (defined(regsoData)) {
      regsoData.forEach((part) => {
        noDataAvailable.delete(part.id);
        mapSetFeatureColor(
          "regso",
          map,
          part.id,
          mapSettings.bordersOnlyNoFill
            ? "transparent"
            : colorFromZValue(part.zValue, colorSettings),
          mapSettings.bordersOnlyNoFill ? "rgba(40,40,40,255)" : undefined
        );
      });
    }

    // TODO: support group selections

    for (const id of noDataAvailable) {
      mapRemoveFeatureState(selectedAreas.type, map, id);
    }
  }, [
    card.data.loadedData,
    colorSettings,
    geoTree,
    isEditingCard,
    map,
    mapSettings.bordersOnlyNoFill,
    mapSettings.resultsOpacity,
    mapSettings.showDesoRegsoLabels,
    mapSettings.showDesoRegsoValues,
    microDataset,
    resultsLoading,
    selectedAreas,
    view,
  ]);

  const isBoxSelect =
    view === "map-select" && mapSettings.mapSelectTool === "draw-select";

  const reconstructControls = useCallback(
    (
      geocoderControl: mapboxgl.IControl | undefined,
      selectionControl: SelectionPanelControl | undefined,
      toolPanelControl: ToolPanelControl | undefined
    ) => {
      const geocoder = geocoderControl ?? removeGeocoderControl(false);
      const selection = selectionControl ?? removeSelectionControl(false);
      const toolPanel = toolPanelControl ?? removeToolPanelControl(false);
      const results = removeResultsControl(false);

      addGeocoderControl(geocoder);
      addSelectionControl(selection);
      addToolPanelControl(toolPanel);
      addResultsControl(results);
    },
    [
      addGeocoderControl,
      addResultsControl,
      addSelectionControl,
      addToolPanelControl,
      removeGeocoderControl,
      removeResultsControl,
      removeSelectionControl,
      removeToolPanelControl,
    ]
  );

  const [fullscreenMode, setFullscreenMode] = useState(false);
  const changeFullscreenMode = useCallback(
    (modeOn: boolean) => {
      if (fullscreenMode !== modeOn) {
        setFullscreenMode(modeOn);
        reconstructControls(
          geocoderControl,
          selectionControl,
          toolPanelControl
        );
      }
    },
    [
      fullscreenMode,
      geocoderControl,
      reconstructControls,
      selectionControl,
      toolPanelControl,
    ]
  );
  useSynchronizeState(isFullscreen, fullscreenMode, changeFullscreenMode, 1500);

  /**
   * We can't access the WebGL buffer when the map has not been freshly rendered
   *  (error: Unable to clone WebGL context as it has preserveDrawingBuffer=false)
   * so we need to re-render the map just before generating the image for export.
   */
  const handleExport = useCallback(
    (exportFunc: (element: HTMLElement, label: string) => Promise<void>) => {
      if (!defined(map)) {
        return;
      }
      const geocoder = removeGeocoderControl(false);
      const selections = removeSelectionControl(false);
      const toolPanel = removeToolPanelControl(false);

      setTimeout(() => {
        map.once("render", () => {
          const element = document.getElementById(
            getMapContainerId(docId, card.id)
          );
          if (!defined(element)) {
            logger.warn("Could not find map container");
            return;
          }
          return exportFunc(element, card.label).finally(() => {
            reconstructControls(geocoder, selections, toolPanel);
          });
        });
        map.triggerRepaint();
      }, 100);
    },
    [
      card.id,
      card.label,
      docId,
      map,
      reconstructControls,
      removeGeocoderControl,
      removeSelectionControl,
      removeToolPanelControl,
    ]
  );

  useEffect(() => {
    const listener = (e: any) => {
      const event: CustomEvent = e;
      if (event.detail.cardId !== card.id) {
        return;
      }

      handleExport((element: HTMLElement, cardLabel: string) => {
        return htmlToPngUri(element).then((uri) => {
          emitMicroCardImageCreatedEvent(card.id, uri);
        });
      });
    };
    document.addEventListener(createMicroCardImageEventName, listener);
    return () => {
      document.removeEventListener(createMicroCardImageEventName, listener);
    };
  }, [card.id, handleExport]);

  useEffect(() => {
    listenToDownloadEvent(() => {
      handleExport(saveHtmlToPng);
    });
  }, [handleExport, listenToDownloadEvent]);

  const userInfo = useContext(UserInfoContext);

  return (
    <div className="fullscreen-container">
      {addressExportOpen &&
        defined(card.data.geoSelections) &&
        defined(microDataset) && (
          <PostalExportModal
            exportTypeLabel="adresser"
            microDataset={microDataset}
            originalGeoSelections={card.data.geoSelections}
            exportFunc={exportAddresses}
            hasAccess={userInfo?.hasExportMicroAddressesAccess() ?? false}
            handleClose={() => setAddressExportOpen(false)}
          ></PostalExportModal>
        )}
      {zipcodeExportOpen &&
        defined(card.data.geoSelections) &&
        defined(microDataset) && (
          <PostalExportModal
            exportTypeLabel="postnummer"
            originalGeoSelections={card.data.geoSelections}
            microDataset={microDataset}
            exportFunc={exportZipcodes}
            hasAccess={userInfo?.hasExportMicroZipcodesAccess() ?? false}
            handleClose={() => setZipcodeExportOpen(false)}
          ></PostalExportModal>
        )}
      <div className="map-outer-container no-text-select">
        <div
          id={getMapContainerId(docId, cardId)}
          className={classNames(
            "mapbox-container",
            isFullscreen ? "fullscreen" : "",
            isBoxSelect ? "box-select" : ""
          )}
        ></div>
      </div>
    </div>
  );
}

function clearDesoRegsoLabels(map: Map) {
  const labelSource = map.getSource(desoRegsoLabelsSourceId);
  if (labelSource && labelSource.type === "geojson") {
    labelSource.setData({
      type: "FeatureCollection",
      features: [],
    });
  }
}

function buildLabel(areaLabel?: string, value?: string) {
  const parts: string[] = [];
  if (defined(areaLabel)) {
    parts.push(areaLabel);
  }
  if (defined(value)) {
    parts.push(value);
  }
  if (parts.length === 0) {
    return;
  }

  let result = parts[0];
  if (defined(parts[1])) {
    result += " (" + parts[1] + ")";
  }
  return result;
}

function addDesoRegsoLabels(
  map: Map,
  selectedAreas: MicroGeoSelections,
  showAreaLabel: boolean,
  showValue: boolean,
  microMapData: MicroMapData,
  geoTree: MicroGeoTree | undefined
) {
  const labelSource = map.getSource(desoRegsoLabelsSourceId);
  const valuesLookup: { [key: string]: number } = {};
  if (showValue) {
    for (const r of microMapData.desoResults ?? []) {
      valuesLookup[r.deso] = r.value;
    }
    for (const r of microMapData.regsoResults ?? []) {
      valuesLookup[r.regso] = r.value;
    }
  }

  if (labelSource && labelSource.type === "geojson") {
    const labelFeatures: GeoJSON.Feature[] = selectedAreas.selected
      .map((area) => {
        if (area.type === "regso") {
          const coordinates = geoTree?.regsoLongLat(area.props.regso_label);
          if (!defined(coordinates)) {
            return;
          }

          return {
            type: "Feature",
            properties: {
              id: area.props.id,
              label:
                buildLabel(
                  showAreaLabel ? area.props.regso_label : undefined,
                  mapOptional(
                    microMapData.formatterSingleValue,
                    valuesLookup[area.props.regso]
                  )
                ) ?? "",
            },
            geometry: {
              type: "Point",
              coordinates,
            },
          } as const;
        } else if (area.type === "deso") {
          const coordinates = geoTree?.desoLongLat(area.props.deso_label);
          if (!defined(coordinates)) {
            return;
          }
          return {
            type: "Feature",
            properties: {
              id: area.props.id,
              label:
                buildLabel(
                  showAreaLabel ? area.props.deso_label : undefined,
                  mapOptional(
                    microMapData.formatterSingleValue,
                    valuesLookup[area.props.deso]
                  )
                ) ?? "",
            },
            geometry: {
              type: "Point",
              coordinates,
            },
          } as const;
        }
      })
      .filter(defined);
    labelSource.setData({
      type: "FeatureCollection",
      features: labelFeatures,
    });
  }
}

function getMapContainerId(documentId: number, cardId: string) {
  return "mapbox-gl-doc:" + documentId + "_card-id:" + cardId;
}

function ResultsLoading(props: { loading: boolean }) {
  return props.loading ? (
    <ThemeProvider theme={fluentUITheme}>
      <div className="micro-results-loading-overlay">
        <div className="spinner-container">
          <DefaultLoading
            delayMs={0} // Delay is handled by parent
            spinnerSize={SpinnerSize.large}
          ></DefaultLoading>
        </div>
      </div>
    </ThemeProvider>
  ) : null;
}
