import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { DefaultLoadingStretchedToFit } from "../../../components/Loading";
import { MainContent } from "../../../components/MainContent";
import {
  GeographiesContext,
  SharingInfoContext,
} from "../../../lib/application/contexts";
import { getSharingInfoNonDocument } from "../../../lib/application/files/SharingInfo";
import { useLoadPackagedDocument } from "../../../lib/application/state/actions/load/useLoadPackagedDocument";
import { defined } from "../../../lib/core/defined";
import { Progress } from "../../../lib/core/progress";
import { voidFunc } from "../../../lib/core/voidFunc";
import { DataCardsContainer } from "./DataCardsContainer";
import { Geographies } from "../../../lib/domain/geography";
import { NavBarBase } from "../../../components/NavBar";
import { classNames } from "../../../lib/core/classNames";
import { reportMetaStateQuery } from "../../../lib/application/state/stats/document-meta/queries";

const categoriesMock = {};
const sharingInfoMock = getSharingInfoNonDocument();
const sharingContext = { info: sharingInfoMock };

interface Props {
  documentId: string;
}
export function StatsDocumentPackaged(props: Props) {
  const [loadProgress, docData] = useLoadPackagedDocument(props.documentId);

  const geographies = useMemo(() => {
    if (!defined(docData)) {
      return;
    }
    return Geographies.serializable(docData.geographies);
  }, [docData]);

  switch (loadProgress) {
    case Progress.NotStarted:
    case Progress.InProgress:
      return <DefaultLoadingStretchedToFit />;
    case Progress.Error:
      return <span>Error</span>; // FIXME
    case Progress.Success:
      if (!defined(docData)) {
        return <DefaultLoadingStretchedToFit></DefaultLoadingStretchedToFit>;
      }
      if (!defined(geographies)) {
        return <DefaultLoadingStretchedToFit></DefaultLoadingStretchedToFit>;
      }
      return (
        <GeographiesContext.Provider value={geographies}>
          <PackagedDocumentInner documentName={docData.documentName} />
        </GeographiesContext.Provider>
      );
  }
}

function PackagedDocumentInner(props: { documentName: string }) {
  const metaState = useRecoilValue(reportMetaStateQuery);
  return (
    <SharingInfoContext.Provider value={sharingContext}>
      <div className="App">
        <div id="main" className="Stats">
          <NavBarBase
            isPackagedDocMode
            documentTitle={props.documentName}
          ></NavBarBase>
          <div
            className={classNames(
              "page-scrollable",
              metaState?.noFramesMode ? "no-frames" : ""
            )}
          >
            <MainContent>
              <DataCardsContainer
                sharingInfo={sharingInfoMock}
                categories={categoriesMock}
                addMaxNumCardsError={voidFunc}
              ></DataCardsContainer>
            </MainContent>
          </div>
        </div>
      </div>
    </SharingInfoContext.Provider>
  );
}
