import {
  Literal,
  Optional,
  Boolean as BooleanRT,
  Record,
  Static,
  Union,
  Array as ArrayRT,
  Number as NumberRT,
  String as StringRT,
  Dictionary,
} from "runtypes";

export const DataValueTypeRegularRT = Union(
  Literal("decimal"),
  Literal("integer"),
  Literal("category")
);

export const DataValueTypeMicroRegularRT = Union(
  Literal("decimal"),
  Literal("integer")
);

export const DataValueTypeMicroPointRT = Literal("point");
export const DataValueTypeMicroPolygonRT = Literal("polygon");
export const DataValueTypeMicroLineRT = Literal("line");
export const DataValueTypeMicroGeoRT = Union(
  DataValueTypeMicroPointRT,
  DataValueTypeMicroPolygonRT,
  DataValueTypeMicroLineRT
);
export const DataValueTypeMicroAllRT = DataValueTypeMicroRegularRT.Or(
  DataValueTypeMicroGeoRT
);
export type DataValueTypeMicroRegular = Static<
  typeof DataValueTypeMicroRegularRT
>;
export type DataValueTypeMicroAll = Static<typeof DataValueTypeMicroAllRT>;
export const dataValueTypesMicroGeo: DataValueTypeMicroAll[] = [
  "point",
  "polygon",
  "line",
];
export const microValueTypes: DataValueTypeMicroAll[] = [
  "decimal",
  "integer",
  "point",
  "polygon",
  "line",
];

export const DataValueTypeSurveyRT = Literal("survey");
export const DataValueTypeSurveyStringRT = Literal("survey_string");
export const DataValueTypeAnyRT = DataValueTypeRegularRT.Or(
  DataValueTypeSurveyRT
).Or(DataValueTypeSurveyStringRT);
export const AggregationMethodRT = Union(
  Literal("sum"),
  Literal("avg"),
  Literal("avg-weighted"),
  Literal("none")
);
export type AggregationMethod = Static<typeof AggregationMethodRT>;

export const aggregationMethods: AggregationMethod[] = [
  "sum",
  "avg",
  "avg-weighted",
  "none",
];

export type DataValueTypeRegular = Static<typeof DataValueTypeRegularRT>;
export type DataValueTypeSurvey = Static<typeof DataValueTypeSurveyRT>;
export type DataValueTypeSurveyString = Static<
  typeof DataValueTypeSurveyStringRT
>;
export type DataValueTypeAny = Static<typeof DataValueTypeAnyRT>;

export const CommonHeaderDataDtoRT = Record({
  ext_source: Optional(StringRT),
  measure: StringRT,
  source: StringRT,
  source_url: Optional(StringRT),
  last_update: Optional(StringRT),
  next_update: Optional(StringRT),
  subject: StringRT,
  unit_label: StringRT,
  value_type: DataValueTypeAnyRT,

  descr_short: Optional(StringRT),
  descr_long: StringRT,
  ext_descr: Optional(StringRT),
  ext_descr_long: Optional(StringRT),
});

export type CommonHeaderDataDto = Static<typeof CommonHeaderDataDtoRT>;

const GroupingMunicipalitiesDtoRT = ArrayRT(
  Record({
    grouping: StringRT,
    labels: ArrayRT(StringRT),
  })
);
export const GroupHeaderDataDtoRT = CommonHeaderDataDtoRT.And(
  Record({
    class_boundaries: Optional(Dictionary(NumberRT, StringRT)),
    municipalities: Optional(GroupingMunicipalitiesDtoRT),
  })
);

export type GroupingMunicipalitiesDto = Static<
  typeof GroupingMunicipalitiesDtoRT
>;

export type GroupHeaderDataDto = Static<typeof GroupHeaderDataDtoRT>;

export interface DatasetHeadersDto {
  groupHeader?: GroupHeaderDataDto;
  header: MainHeaderDataDto;
}
const MainHeaderDataDtoRT = Record({
  dimensions: ArrayRT(StringRT),
  lifted: Optional(Dictionary(StringRT, StringRT)),
  agg_method_geo: Optional(AggregationMethodRT),
  aggegration_note: Optional(StringRT),
});
export interface MainHeaderDataDto extends CommonHeaderDataDto {
  dimensions: string[];
  lifted?: {
    [dimensionOrBreakdown: string]: string;
  };
  agg_method_geo?: AggregationMethod;
  aggregation_note?: string;
}

export type RowsCollection<FieldValueType> = Array<{
  [key: string]: FieldValueType;
}>;

export type RowValueRegularRaw = string;
export type DatasetRowsRaw = RowsCollection<RowValueRegularRaw>;

export const StatsDatasetResponseDtoRT = Record({
  header: MainHeaderDataDtoRT,
  groupHeader: Optional(GroupHeaderDataDtoRT),
  rows: Optional(ArrayRT(Dictionary(StringRT.Or(BooleanRT), StringRT))),
});

export type StatsDatasetResponseDto = {
  header: MainHeaderDataDto;
  groupHeader?: GroupHeaderDataDto;
  rows?: DatasetRowsRaw;
  forecastRows?: DatasetRowsRaw;
};

export type DatasetRegularRowRaw = DatasetRowsRaw[0];
