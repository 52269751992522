import { config } from "../../../config";
import { HttpResult } from "../../infra/HttpResult";
import {
  ThirdPartyOrgsListDto,
  ThirdPartyOrgsListDtoRT,
} from "../../infra/api_responses/admin/organizations";
import { authedRequest, decodedAuthedRequest } from "./shared";

export function listThirdPartyOrgs(): Promise<
  HttpResult<ThirdPartyOrgsListDto>
> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "thirdparty/orgs",
    undefined,
    "GET",
    ThirdPartyOrgsListDtoRT
  );
}

export function addThirdPartyOrgMembers(
  orgId: string,
  memberEmails: string[]
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `thirdparty/orgs/${orgId}/members`,
    { emails: memberEmails },
    "POST"
  );
}

export function removeThirdPartyOrgMember(
  orgId: string,
  email: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `thirdparty/orgs/${orgId}/members/${email}`,
    undefined,
    "DELETE"
  );
}

export function handleThirdPartyOrgMemberToggleAdmin(
  orgId: string,
  email: string,
  admin: boolean
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `thirdparty/orgs/${orgId}/members/${email}`,
    { admin },
    "POST"
  );
}
