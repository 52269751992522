import { fromPairs, uniq } from "lodash";
import { Literal, Static, Union } from "runtypes";
import { v4 } from "uuid";

import { ColorScheme } from "../../../../state/stats/document-core/core";
import {
  COLORS_HIGH_LOW_RANGE_CLASSIFICATION,
  COLOR_GOOD_GREEN,
  COLOR_NEUTRAL_GREY_DARK,
  COLOR_NEUTRAL_GREY_LIGHT,
  DEFAULT_AXIS_COLOR,
  DEFAULT_GRID_LINE_COLOR,
  INFOSTAT_INTERFACE_BLUE,
  INFOSTAT_STD_RED,
  INFOSTAT_STD_YELLOW,
  THEME_USE_DEFAULT_SPECIAL_COLORS,
  standardColors,
} from "./colors";
import { ConditionalColorScheme } from "./conditionalColorScheme";
import { ColorSchemeContainer } from "../../../../state/stats/document-style/definitions";
import { CustomDataOutputSettings } from "../../../../../infra/api_responses/account";

export interface ColorConfig {
  defaultToSingleColor: boolean;
  /**
   * The label by which we determine special color schemes.
   * See below getSpecialColorScheme function.
   */
  colorableDimensionLabel: string | undefined;
}

export const ColorPaletteNameRT = Union(
  Literal("standard"),
  Literal("blackWhite")
);
export type ColorPaletteName = Static<typeof ColorPaletteNameRT>;

/**
 * Collections of colors to be used for visuzaliation
 */
export const colorPalettes: { [key in ColorPaletteName]: string[] } = {
  standard: standardColors,
  blackWhite: ["#fff", "#000"],
};

function addLowerCaseKeys(colorScheme: ColorScheme): ColorScheme {
  const newScheme = { ...colorScheme };
  for (const label of Object.keys(newScheme)) {
    newScheme[label.toLowerCase()] = newScheme[label];
  }
  return newScheme;
}

function makeColorScheme(
  items: { keys: string[]; color: string }[]
): ColorScheme {
  const scheme: Record<string, string> = {};
  for (const item of items) {
    for (const key of item.keys) {
      scheme[key] = item.color;
    }
  }
  return scheme;
}

const highLowColors = addLowerCaseKeys(
  makeColorScheme([
    { keys: ["Hög"], color: COLORS_HIGH_LOW_RANGE_CLASSIFICATION[4] },
    { keys: ["Över medel"], color: COLORS_HIGH_LOW_RANGE_CLASSIFICATION[3] },
    { keys: ["Medel"], color: COLORS_HIGH_LOW_RANGE_CLASSIFICATION[2] },
    { keys: ["Under medel"], color: COLORS_HIGH_LOW_RANGE_CLASSIFICATION[1] },
    { keys: ["Låg"], color: COLORS_HIGH_LOW_RANGE_CLASSIFICATION[0] },
  ])
);

const menWomenTotal = "#ffcf99";
export const DEFAULT_COLOR_WOMEN = "#d7263d";
export const DEFAULT_COLOR_MEN = INFOSTAT_INTERFACE_BLUE;
const menWomenColors = addLowerCaseKeys(
  makeColorScheme([
    {
      keys: ["Kvinnor", "Kvinna", "Flickor", "Flicka"],
      color: DEFAULT_COLOR_WOMEN,
    },
    { keys: ["Samtliga", "Total"], color: menWomenTotal },
    { keys: ["Män", "Man", "Pojkar", "Pojke"], color: INFOSTAT_INTERFACE_BLUE },
  ])
);

const politicalPartiesLeadersColors = addLowerCaseKeys(
  makeColorScheme([
    { keys: ["Vänsterpartiet", "V", "Nooshi Dadgostar"], color: "#96152d" },
    {
      keys: [
        "Socialdemokraterna",
        "Arbetarepartiet-Socialdemokraterna",
        "Arbetarepartiet Socialdemokraterna",
        "S",
        "Stefan Löfven",
        "Magdalena Andersson",
      ],
      color: "#e05263",
    },
    {
      keys: [
        "Miljöpartiet",
        "Miljöpartiet de gröna",
        "MP",
        "Märta Stenevi",
        "Daniel Helldén",
        "Per Bolund",
      ],
      color: "#28bf55",
    },
    {
      keys: ["Centerpartiet", "C", "Annie Lööf", "Muharrem Demirok"],
      color: "#9eff9e",
    },
    {
      keys: [
        "Folkpartiet/Liberalerna",
        "FP/L",
        "Liberalerna",
        "L",
        "Nyamko Sabuni",
        "Johan Pehrson",
      ],
      color: "#3baced",
    },
    { keys: ["Moderaterna", "M", "Ulf Kristersson"], color: "#3b53ed" },
    { keys: ["Kristdemokraterna", "KD", "Ebba Busch"], color: "#3f0dbd" },
    { keys: ["Sverigedemokraterna", "SD", "Jimmie Åkesson"], color: "#e8e84a" },
    { keys: ["Alternativ för Sverige", "AfS"], color: "#14026b" },
    { keys: ["Medborgerlig samling", "MS"], color: "#7261c9" },
    { keys: ["Feministiskt initiativ", "FI"], color: "#e0acd5" },
    { keys: ["Nyans"], color: "#ed7c30" },
    { keys: ["Övriga", "Annat parti"], color: "#A66A5A" },
    { keys: ["Vet ej"], color: COLOR_NEUTRAL_GREY_DARK },
  ])
);

const goodBadColors = new ConditionalColorScheme(
  addLowerCaseKeys(
    makeColorScheme([
      {
        keys: ["Positiv", "Positivt", "Bra", "Till det bättre", "Förbättras"],
        color: COLOR_GOOD_GREEN,
      },
      {
        keys: [
          "Negativ",
          "Negativt",
          "Dålig",
          "Dåligt",
          "Till det sämre",
          "Försämras",
        ],
        color: INFOSTAT_STD_RED,
      },
    ])
  ),
  addLowerCaseKeys(
    makeColorScheme([
      { keys: ["Oförändrad"], color: INFOSTAT_STD_YELLOW },
      { keys: ["Varken eller"], color: COLOR_NEUTRAL_GREY_LIGHT },
      { keys: ["Vet ej/Ej svar"], color: COLOR_NEUTRAL_GREY_DARK },
    ])
  )
);

const politicalBlocksColors: ColorScheme = {
  "S-MP-V-C": "#d7263d",
  "M-KD-SD-L": "#00a6fb",
};

/**
 * Conditional color schemes are used for matching certain labels in a dataset.
 * The resulting color schemes must be added in specialColorSchemes below.
 */
const conditionalColorSchemes = {
  "good-bad": goodBadColors,
};

type ConditionalSchemesKeys = typeof conditionalColorSchemes;
const builtConditionalSchemes: {
  [key in keyof ConditionalSchemesKeys]: ColorScheme;
} = fromPairs(
  Object.entries(conditionalColorSchemes).map(([key, value]) => [
    key,
    value.build(),
  ])
) as any;

const specialColorSchemes = {
  "political-parties": politicalPartiesLeadersColors,
  "political-leaders": politicalPartiesLeadersColors,
  "political-blocks": politicalBlocksColors,
  "men-women": menWomenColors,
  "high-low-range": highLowColors,
};

export function getSpecialColorSchemeKeyByLabels(
  labels: string[]
): string | undefined {
  if (labels.length === 0) {
    return;
  }

  // Match against color schemes where labels are exactly matched or at least 5
  // labels are matched.
  // This works for schemes whene the labels are fairly specific and do not overlap with
  // other label sets.
  for (const [schemeKey, value] of Object.entries(specialColorSchemes)) {
    const validLabels = Object.keys(value);
    const numMatchedLabels = labels.filter((label) =>
      validLabels.includes(label)
    ).length;
    if (numMatchedLabels === labels.length || numMatchedLabels >= 5) {
      return schemeKey;
    }
  }

  // Match against color schemes with special rules for some labels.
  // Useful when some labels are generic and likely appear in many different label sets.
  for (const [schemeKey, value] of Object.entries(conditionalColorSchemes)) {
    if (value.matchesAtLeast2Significant(labels)) {
      return schemeKey;
    }
  }
}

export function getSpecialColorScheme(
  colorDimension: string
): ColorScheme | undefined {
  const colorDimensionLowerCase = colorDimension.toLowerCase();

  if (
    colorDimensionLowerCase.includes("partival") ||
    colorDimensionLowerCase.includes("parti idag")
  ) {
    if (colorDimensionLowerCase.includes("per block")) {
      return specialColorSchemes["political-blocks"];
    }
    return specialColorSchemes["political-parties"];
  }

  switch (colorDimensionLowerCase) {
    case "parti":
    case "partisympati":
    case "näst bästa parti":
    case "partival i senaste riksdagsval":
    case "parti om val idag":
    case "partival idag":
    case "political-parties":
      return specialColorSchemes["political-parties"];
    case "partiledare":
      return specialColorSchemes["political-leaders"];
    case "political-blocks":
    case "partival i senaste riksdagsval, per block":
    case "partival idag, per block":
      return specialColorSchemes["political-blocks"];
    case "high-low-range":
      return specialColorSchemes["high-low-range"];
    case "men-women":
    case "kön":
      return specialColorSchemes["men-women"];
    case "good-bad":
      return builtConditionalSchemes["good-bad"];
    default:
      return;
  }
}

export interface CustomThemeSpec {
  name: string;
  id: string;
  colors: string[];

  /** Whether to use Infostat's special colors for labels like political parties, gender, high-low etc. */
  useDefaultSpecialColors: boolean;
  /** No color if null, default if undefined */
  customBgColor?: string | null;
  /** No color if null, default if undefined */
  customAxesColor?: string | null;
  /** No color if null, default if undefined */
  customGridLinesColor?: string | null;

  /** Default if undefined */
  customHeadersColor?: string | null;
  /** Default if undefined */
  customLabelsColor?: string | null;

  /** Overrides customGridLinesColor if defined */
  customGridLinesXColor?: string | null;
  /** Overrides customGridLinesColor if defined */
  customGridLinesYColor?: string | null;
  customOutputSettings?: CustomDataOutputSettings;
}

const stdPalette = defaultThemeSpec();
export function isInfostatStandardPalette(
  palette: CustomThemeSpecApplied
): boolean {
  return palette.id === stdPalette.id;
}

// d3-based swatches

const category10 = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];
const accent = [
  "#7fc97f",
  "#beaed4",
  "#fdc086",
  "#ffff99",
  "#386cb0",
  "#f0027f",
  "#bf5b17",
  "#666666",
];
const dark2 = [
  "#1b9e77",
  "#d95f02",
  "#7570b3",
  "#e7298a",
  "#66a61e",
  "#e6ab02",
  "#a6761d",
  "#666666",
];
const paired = [
  "#a6cee3",
  "#1f78b4",
  "#b2df8a",
  "#33a02c",
  "#fb9a99",
  "#e31a1c",
  "#fdbf6f",
  "#ff7f00",
  "#cab2d6",
  "#6a3d9a",
  "#ffff99",
  "#b15928",
];

const pastel1 = [
  "#fbb4ae",
  "#b3cde3",
  "#ccebc5",
  "#decbe4",
  "#fed9a6",
  "#ffffcc",
  "#e5d8bd",
  "#fddaec",
  "#f2f2f2",
];

const pastel2 = [
  "#b3e2cd",
  "#fdcdac",
  "#cbd5e8",
  "#f4cae4",
  "#e6f5c9",
  "#fff2ae",
  "#f1e2cc",
  "#cccccc",
];
const set1 = [
  "#e41a1c",
  "#377eb8",
  "#4daf4a",
  "#984ea3",
  "#ff7f00",
  "#ffff33",
  "#a65628",
  "#f781bf",
  "#999999",
];

const set2 = [
  "#66c2a5",
  "#fc8d62",
  "#8da0cb",
  "#e78ac3",
  "#a6d854",
  "#ffd92f",
  "#e5c494",
  "#b3b3b3",
];

const set3 = [
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "ffed6f",
];

const tableau10 = [
  "#4e79a7",
  "#f28e2c",
  "#e15759",
  "#76b7b2",
  "#59a14f",
  "#edc949",
  "#af7aa1",
  "#ff9da7",
  "#9c755f",
  "#bab0ab",
];

export function getInfostatStandardTheme(): CustomThemeSpec {
  return {
    colors: standardColors,
    id: "infostat-1",
    name: "Infostat standard",
    useDefaultSpecialColors: true,
    customAxesColor: DEFAULT_AXIS_COLOR,
    customGridLinesColor: DEFAULT_GRID_LINE_COLOR,
  };
}

const builtInDefaults = {
  useDefaultSpecialColors: true,
  customAxesColor: DEFAULT_AXIS_COLOR,
  customGridLinesColor: DEFAULT_GRID_LINE_COLOR,
};

const FTColors = [
  "#c66682", // Violet
  "#4e86b6", // Blue
  "#878c52", // Green
  "#d9b277", // Yellow
  "#716559", // Grey
  "#35aea7", // Cyan
  "#c08393", // Pink
];

const OWDColors = [
  "#2C8465", // RGB(44, 132, 101) - Teal Green
  "#CF0A66", // RGB(207, 10, 102) - Bright Magenta
  "#286BBB", // RGB(40, 107, 187) - Vibrant Azure
  "#C15065", // RGB(193, 80, 101) - Soft Coral
  "#882439", // RGB(136, 48, 57) - Deep Crimson
  "#BE5915", // RGB(190, 89, 21) - Rich Amber
  "#6D3E91", // RGB(109, 62, 145) - Muted Purple
  "#996D39", // RGB(153, 109, 57) - Warm Taupe
];

/** Palettes that are built-in but exclude the default Infostat standard palette */
export const builtInSecondaryThemes: CustomThemeSpec[] = [
  {
    colors: FTColors,
    id: "ft",
    name: "FT",
    useDefaultSpecialColors: true,
    customBgColor: "#fff1e0",
    customAxesColor: "#a7a59b",
    customGridLinesColor: "#e6d8d8",
    customOutputSettings: {
      showTicksYAxis: false,
      showYAxis: false,
      showTicksXAxis: true,
    },
  },
  // Based on Our World in Data
  {
    colors: OWDColors,
    id: "owd",
    name: "OWD",
    useDefaultSpecialColors: true,
    customAxesColor: "#999",
    customGridLinesColor: "#e6d8d8",
    customOutputSettings: {
      showFatLines: false,
      showLineCircles: true,
      showTicksYAxis: false,
      showYAxis: false,
      showTicksXAxis: true,
      gridLinesXStyle: "2,1",
    },
  },
  {
    colors: category10,
    id: "category10",
    name: "Category10",
    ...builtInDefaults,
  },
  {
    colors: accent,
    id: "accent",
    name: "Accent",
    ...builtInDefaults,
  },
  {
    colors: dark2,
    id: "dark2",
    name: "Dark2",
    ...builtInDefaults,
  },
  {
    colors: paired,
    id: "paired",
    name: "Paired",
    ...builtInDefaults,
  },
  {
    colors: pastel1,
    id: "pastel1",
    name: "Pastel1",
    ...builtInDefaults,
  },
  {
    colors: pastel2,
    id: "pastel2",
    name: "Pastel2",
    ...builtInDefaults,
  },
  {
    colors: set1,
    id: "set1",
    name: "Set1",
    ...builtInDefaults,
  },
  {
    colors: set2,
    id: "set2",
    name: "Set2",
    ...builtInDefaults,
  },
  {
    colors: set3,
    id: "set3",
    name: "Set3",
    ...builtInDefaults,
  },
  {
    colors: tableau10,
    id: "tableau10",
    name: "Tableau10",
    ...builtInDefaults,
  },
];

export const microLineStyleDashArrayOptions = [
  [1, 0], // Solid
  [5, 5], // Dashed
  [5, 3], // Dashed
  [3, 3], // Dashed
  [2, 2], // Dashed
  [2, 1], // Dotted
  [1, 1], // Dotted
];

/** Line style options in dash array format for SVG lines */
export const gridLineStyleOptions = [
  "1,0", // Solid
  "5,5", // Dashed
  "5,3", // Dashed
  "3,3", // Dashed
  "2,2", // Dashed
  "2,1", // Dotted
  "1,1", // Dotted
];

// Same as above, but without the name property
// Why? Because if we share a document, we don't want user-defined names of themes to
// propagate -- they should be kept private.
export interface CustomThemeSpecApplied extends Omit<CustomThemeSpec, "name"> {}
export interface CustomPaletteSpecApplied
  extends Omit<CustomThemeSpec, "name" | "customOutputSettings"> {}

export function defaultThemeSpec(): CustomThemeSpecApplied {
  const infostatStandardPalette = getInfostatStandardTheme();
  return {
    id: infostatStandardPalette.id,
    colors: infostatStandardPalette.colors.slice(),
    useDefaultSpecialColors: infostatStandardPalette.useDefaultSpecialColors,
    customAxesColor: infostatStandardPalette.customAxesColor,
    customGridLinesColor: infostatStandardPalette.customGridLinesColor,
  };
}

export function themeSpecFromColorContainer(
  colorSchemeContainer: ColorSchemeContainer,
  includeColors: string[]
): CustomThemeSpecApplied {
  const uniqueUsedColors = uniq(
    Object.values(colorSchemeContainer.colorScheme)
  );
  const finalColors = includeColors.slice();
  for (const color of uniqueUsedColors) {
    if (!finalColors.includes(color)) {
      finalColors.unshift(color);
    }
  }
  return {
    id: v4(),
    colors: finalColors,
    useDefaultSpecialColors:
      colorSchemeContainer.useDefaultSpecialColors ??
      THEME_USE_DEFAULT_SPECIAL_COLORS,
    customBgColor: colorSchemeContainer.customBgColor,
    customAxesColor: colorSchemeContainer.customAxesColor,
    customGridLinesColor: colorSchemeContainer.customGridLinesColor,
    customHeadersColor: colorSchemeContainer.customHeadersColor,
    customLabelsColor: colorSchemeContainer.customLabelsColor,
  };
}

/** A custom palette as it has been applied. No need for default/name here. */
export interface ThemesSpecial {
  orgDefault?: CustomThemeSpec;
  orgThemes?: CustomThemeSpec[];
  userThemes?: CustomThemeSpec[];
}

export interface ExtendedAppearanceSettings {
  colorPalettesSpecial: ThemesSpecial;
  availableThemes: CustomThemeSpec[];
  defaultTheme: CustomThemeSpecApplied;
  defaultOrgThemeId?: string;
  defaultThemeId?: string;
}

export function extendedAppearanceSettingsDefault(
  defaultColors?: CustomThemeSpecApplied
): ExtendedAppearanceSettings {
  const defaultTheme = defaultColors ?? defaultThemeSpec();
  return {
    colorPalettesSpecial: {},
    defaultTheme,
    availableThemes: [],
  };
}
