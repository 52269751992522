import { IconButton, ISearchBox, SearchBox } from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { classNames } from "../lib/core/classNames";
import { defined } from "../lib/core/defined";
import "./InfostatSearchBox.scss";
import { useKeyUpEnter } from "../lib/application/hooks/useKeyUp";
import { PaddedSmallSpinner } from "./Loading";
import { logger } from "../lib/infra/logging";
import { nonEmptyString } from "../lib/core/nonEmptyString";

interface SearchResultItem<T> {
  key: string;
  item: T;
}

type SearchResponse<T> = Promise<SearchResultItem<T>[]>;

export type SearchBoxSize = "btn-size";

export type HideButtonProps = {
  onClick: () => void;
};

interface Props<T> {
  autofocus: boolean;
  /**
   * Delay autofocus by this many milliseconds. Useful when using transitions/animations that
   * may be affected by autofocus.
   */
  autofocusDelayMs?: number;
  placeholder?: string;
  search: (input: string) => SearchResponse<T>;
  /**
   * Minimum required string length to perform a search
   */
  minimumSearchStringLength: number;
  render: (item: T, searchString: string) => JSX.Element;
  onSelect: (item: T) => void;
  className?: string;
  size?: SearchBoxSize;
  /**
   * If specified, shows a button with a cross, intended to hide the search field.
   */
  hideButton?: HideButtonProps;
}

export function InfostatSearchBox<T>(props: Props<T>) {
  const { onSelect, search } = props;

  const [searchString, setSearchString] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fieldActive, setFieldActive] = useState(false);
  const [searchResults, setSearchResults] = useState<
    SearchResultItem<T>[] | null
  >(null);
  const [focusedResultIndex, setFocusedResultIndex] = useState<
    number | undefined
  >();

  const searchFieldRef = useRef<ISearchBox>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const reset = useCallback(() => {
    setSearchString("");
    setSearchResults(null);
    setFocusedResultIndex(undefined);
  }, []);

  const handleSelect = useCallback(
    (item: T) => {
      reset();
      onSelect(item);
    },
    [onSelect, reset]
  );

  const clickListener = useCallback((e: MouseEvent) => {
    setShowResults(false);
    setFieldActive(false);
  }, []);

  useEffect(() => {
    let requestCanceled = false;
    const trimmedString = searchString.trim();
    if (!nonEmptyString(trimmedString) || trimmedString.length < 2) {
      return;
    }
    setLoading(true);
    const timeoutHandle = setTimeout(() => {
      search(trimmedString)
        .then((res) => {
          if (requestCanceled) {
            return;
          }
          setLoading(false);
          setSearchResults(res);
        })
        .catch((e) => logger.warn("Search request failed", e));
    }, 500);

    return () => {
      requestCanceled = true;
      clearTimeout(timeoutHandle);
      setLoading(false);
    };
  }, [search, searchString]);

  useEffect(() => {
    document.body.addEventListener("click", clickListener);
    return function cleanup() {
      document.body.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  useEffect(() => {
    if (props.autofocus) {
      if (!defined(props.autofocusDelayMs)) {
        searchFieldRef.current?.focus();
      }
      const timeoutHandle = setTimeout(() => {
        searchFieldRef.current?.focus();
      }, props.autofocusDelayMs);
      return () => clearTimeout(timeoutHandle);
    }
  }, [props.autofocus, props.autofocusDelayMs, searchFieldRef]);

  const focusedResultRef = useRef<HTMLDivElement | null>(null);
  const scrollFocusedIntoView = useCallback(() => {
    const element = focusedResultRef.current;
    if (defined(element)) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [focusedResultRef]);

  const handleKeyInput = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!defined(searchResults) || searchResults.length === 0) {
        return;
      }

      const isShifted = event.getModifierState("Shift");
      const isDownMove =
        event.key === "ArrowDown" || (event.key === "Tab" && !isShifted);
      const isUpMove =
        event.key === "ArrowUp" || (event.key === "Tab" && isShifted);
      if (isDownMove && !isShifted) {
        event.stopPropagation();
        event.preventDefault();
        setFocusedResultIndex((prev) => {
          if (!defined(prev)) {
            return 0;
          }
          return (prev + 1) % searchResults.length;
        });
      } else if (isUpMove) {
        event.stopPropagation();
        event.preventDefault();
        setFocusedResultIndex((prev) => {
          if (!defined(prev)) {
            return searchResults.length - 1;
          }
          return prev === 0 ? searchResults.length - 1 : prev - 1;
        });
      }
    },
    [searchResults]
  );

  useEffect(() => {
    if (!defined(focusedResultIndex)) {
      return;
    }
    scrollFocusedIntoView();
  }, [focusedResultIndex, scrollFocusedIntoView]);

  useKeyUpEnter(() => {
    if (!defined(focusedResultIndex)) {
      return;
    }
    const result = searchResults?.[focusedResultIndex];
    if (!defined(result)) {
      return;
    }

    handleSelect(result.item);
  }, searchContainerRef);

  const showSearchResults = !isLoading && defined(searchResults) && showResults;
  const searchActiveClass = fieldActive ? "active" : "";
  return (
    <div
      ref={searchContainerRef}
      className={classNames(
        "infostat-search-box",
        props.size,
        props.className,
        searchActiveClass,
        showSearchResults || isLoading ? "show-results-box" : ""
      )}
    >
      <div className="bar">
        <SearchBox
          componentRef={searchFieldRef}
          placeholder={props.placeholder ?? "Sök"}
          onKeyDownCapture={handleKeyInput}
          onFocus={() => {
            setFieldActive(true);
            setShowResults(true);
          }}
          onBlur={() => {
            if (searchString.trim() === "") {
              setFieldActive(false);
              setShowResults(false);
            }
          }}
          onClick={(event) => {
            event.stopPropagation();
            setShowResults(true);
          }}
          value={searchString}
          onChange={(event, valueRaw) => {
            const value = valueRaw ?? "";
            setSearchString(value);
            if (value.length < props.minimumSearchStringLength) {
              setSearchResults(null);
              return;
            }
          }}
        ></SearchBox>
        {defined(props.hideButton) && !fieldActive && (
          <IconButton
            className="hide-button"
            onClick={props.hideButton.onClick}
            iconProps={{ iconName: "cross" }}
          ></IconButton>
        )}
      </div>
      {(showSearchResults || isLoading) && (
        <div
          className={classNames(
            "search-results",
            !isLoading && searchResults?.length === 0 ? "no-results" : ""
          )}
        >
          {isLoading ? (
            <PaddedSmallSpinner></PaddedSmallSpinner>
          ) : searchResults?.length === 0 ? (
            <div className="no-results">Inga resultat</div>
          ) : (
            searchResults?.map((result, index) => {
              const isFocused = focusedResultIndex === index;
              return (
                <div
                  ref={isFocused ? focusedResultRef : null}
                  tabIndex={0}
                  onFocus={() => {
                    setFocusedResultIndex(index);
                  }}
                  key={result.key}
                  onClick={() => {
                    handleSelect(result.item);
                  }}
                  className={classNames(
                    "result-item",
                    isFocused ? "focused" : ""
                  )}
                >
                  {props.render(result.item, searchString)}
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
}
