import { identity, omit, uniq } from "lodash";

import { defined } from "../../../core/defined";
import { applySortSpec } from "../../../domain/measure";
import {
  AggregationMethod,
  CommonHeaderDataDto,
  DatasetHeadersDto,
  StatsDatasetResponseDto,
  DataValueTypeAny,
  DataValueTypeMicroRegular,
  GroupHeaderDataDto,
  GroupingMunicipalitiesDto,
  MainHeaderDataDto,
} from "../../../infra/api_responses/dataset";
import { MicroDatasetDto } from "../../../infra/api_responses/micro_dataset";
import {
  fixedDecimalsFormatter,
  numSignificantFiguresRounder,
} from "../format";
import { Dimension } from "../shared/core/definitions";
import { groupingSortSpec } from "../shared/dimensions";
import {
  RowForecast,
  RowMicro,
  RowMicroReference,
  RowRegular,
  SurveyRow,
} from "../shared/row";
import { ClassBoundaries, ClassBoundariesNumeric } from "./DatasetGeneric";
import {
  SurveyDatasetDto,
  SurveyHeader,
} from "../../../infra/api_responses/survey_dataset";
import { round } from "../../../core/math/round";
import { threeSFRounderNumeric } from "../format";

export interface DimensionsSpec {
  liftedDimensions?: Record<string, string> | undefined;
  variable: string[];
}

/**
 * Common header data for all datasets
 */
export class CommonHeaderData {
  constructor(private _commonDto: CommonHeaderDataDto) {}
  get lastUpdate(): string | undefined {
    return this._commonDto.last_update;
  }
  get nextUpdate(): string | undefined {
    return this._commonDto.next_update;
  }
  get sourceUrl(): string | undefined {
    return this._commonDto.source_url;
  }
  get externalSource(): string | undefined {
    return this._commonDto.ext_source;
  }
  get measureTitle(): string {
    return this._commonDto.descr_long;
  }
  get measure(): string {
    return this._commonDto.measure;
  }

  get source(): string {
    return this._commonDto.source;
  }
  get subject(): string {
    return this._commonDto.subject;
  }
  get unitLabel(): string {
    return this._commonDto.unit_label;
  }
  get valueType(): DataValueTypeAny {
    return this._commonDto.value_type as any;
  }

  get descriptionShort(): string | undefined {
    return this._commonDto.descr_short;
  }
  get descriptionLong(): string {
    return this._commonDto.descr_long;
  }
  get externalDescription(): string | undefined {
    return this._commonDto.ext_descr;
  }
  get externalDescriptionLong(): string | undefined {
    return this._commonDto.ext_descr_long;
  }

  static fromDto(dto: CommonHeaderDataDto): CommonHeaderData {
    return new CommonHeaderData(dto);
  }
}

export class MainHeaderData extends CommonHeaderData {
  private _dto: MainHeaderDataDto;
  constructor(dto: MainHeaderDataDto | SurveyDatasetDto["header"]) {
    super(dto);
    this._dto = dto;
  }

  get aggregationMethod(): AggregationMethod | undefined {
    return this._dto.agg_method_geo;
  }
  get aggregationNote(): string | undefined {
    return this._dto.aggregation_note;
  }

  get rangeDimension() {
    return this._dto.dimensions[0];
  }

  get domainDimension() {
    return Dimension.date;
  }

  get dimensions(): string[] {
    return this._dto.dimensions;
  }

  get liftedDimensions() {
    return this._dto.lifted;
  }

  static fromDto(dto: MainHeaderDataDto) {
    return new MainHeaderData(dto);
  }
}

export class MicroHeaderData {
  constructor(
    private _raw: MicroDatasetDto["header"],
    private _variableDataDimensions: string[]
  ) {}

  get unitLabel(): string {
    return this._raw.unit_label;
  }

  get valueType(): Exclude<DataValueTypeMicroRegular, "category"> {
    return this._raw.value_type;
  }

  get publicComment(): string | undefined {
    return this._raw.public_comment;
  }

  get dimensions(): string[] {
    return this._variableDataDimensions;
  }

  get source(): string {
    return this._raw.source;
  }

  get externalSource(): string | undefined {
    return this._raw.externalSource;
  }

  get descriptionLong(): string {
    return this._raw.descr_long;
  }

  get liftedDimensions() {
    return this._raw.lifted;
  }
}

function getRounder(valueType: string): (arg: number) => string {
  switch (valueType) {
    case "decimal":
      return numSignificantFiguresRounder(3);
    case "integer":
    case "survey":
      return fixedDecimalsFormatter(0);
  }
  return identity;
}

function getRounderNumeric(valueType: string): (arg: number) => number {
  switch (valueType) {
    case "decimal":
      return (arg) => threeSFRounderNumeric(arg);
    case "integer":
    case "survey":
      return (arg) => round(arg, 0);
  }
  return identity;
}

export class GroupHeaderData extends CommonHeaderData {
  constructor(private _dto: GroupHeaderDataDto) {
    super(_dto);
  }

  get classBoundaries(): ClassBoundaries | undefined {
    const dto = this._dto.class_boundaries;
    if (!defined(dto)) {
      return undefined;
    }

    const boundaries: ClassBoundaries = [];
    const rounder = getRounder(this._dto.value_type);

    const classKeys = Object.keys(dto);
    for (const key of applySortSpec(classKeys, groupingSortSpec).merged()) {
      const upperBound = dto[key];
      const lowerBound = boundaries[boundaries.length - 1]?.maxInclusive;
      boundaries.push({
        label: key,
        maxInclusive: defined(upperBound) ? rounder(upperBound) : undefined,
        minExclusive: lowerBound,
      });
    }
    return boundaries.reverse();
  }

  get classBoundariesNumeric(): ClassBoundariesNumeric | undefined {
    const dto = this._dto.class_boundaries;
    if (!defined(dto)) {
      return undefined;
    }

    const boundaries: ClassBoundariesNumeric = [];
    const rounder = getRounderNumeric(this._dto.value_type);

    const classKeys = Object.keys(dto);
    for (const key of applySortSpec(classKeys, groupingSortSpec).merged()) {
      const upperBound = dto[key];
      const lowerBound = boundaries[boundaries.length - 1]?.maxInclusive;
      boundaries.push({
        label: key,
        maxInclusive: defined(upperBound) ? rounder(upperBound) : undefined,
        minExclusive: lowerBound,
      });
    }
    return boundaries.reverse();
  }

  get groupingMunicipalities(): GroupingMunicipalitiesDto | undefined {
    return this._dto.municipalities;
  }

  static fromDto(dto: GroupHeaderDataDto) {
    return new GroupHeaderData(dto);
  }
}

export class DatasetHeaders {
  constructor(
    private _main: MainHeaderData,
    private _group?: GroupHeaderData
  ) {}

  get header(): MainHeaderData {
    return this._main;
  }

  get groupHeader(): GroupHeaderData | undefined {
    return this._group;
  }

  static fromDto<H extends MainHeaderDataDto | SurveyHeader>(_dto: {
    header: H;
    groupHeader?: DatasetHeadersDto["groupHeader"];
  }) {
    const main = new MainHeaderData(_dto.header);
    const group = defined(_dto.groupHeader)
      ? new GroupHeaderData(_dto.groupHeader)
      : undefined;
    return new DatasetHeaders(main, group);
  }
}

export class SurveyMainHeaderData extends MainHeaderData {
  constructor(private _headerDto: SurveyHeader) {
    super(_headerDto);
  }

  get methodDescription(): string | undefined {
    return this._headerDto.survey_info.method_info_public;
  }

  get targetGroupInfoPublic(): string | undefined {
    return this._headerDto.survey_info.target_group_info_public;
  }
}

export class DatasetHeaderSurvey extends CommonHeaderData {
  private _main: MainHeaderData;

  constructor(private _dto: SurveyHeader) {
    super(_dto);
    this._main = new MainHeaderData(_dto);
  }

  get surveyInfo() {
    return this._dto.survey_info;
  }

  get surveyInterviewPeriod(): string[] {
    return this.surveyInfo.interview_period;
  }

  get main(): SurveyMainHeaderData {
    return new SurveyMainHeaderData(this._dto);
  }

  get dimensions(): string[] {
    return this.main.dimensions;
  }

  get liftedDimensions() {
    return this.main.liftedDimensions;
  }
}

export class DatasetInput<
  Row,
  MainHeaderContainer,
  GroupHeaderContainer = GroupHeaderData
> {
  constructor(
    private _processedRows: Row[],
    private _forecastRows: RowForecast[] | undefined,
    private _mainHeader: MainHeaderContainer,
    private _groupHeader: GroupHeaderContainer | undefined,
    private _dimensions: DimensionsSpec,
    private _valueTypeOriginal: DataValueTypeAny,
    private _options?: {
      numDecimals?: number;
    }
  ) {}

  get valueTypeOriginal(): DataValueTypeAny {
    return this._valueTypeOriginal;
  }

  get header(): MainHeaderContainer {
    return this._mainHeader;
  }

  get dimensionsSpec(): DimensionsSpec {
    return this._dimensions;
  }

  get groupHeader() {
    return this._groupHeader;
  }

  get forecastRows(): RowForecast[] | undefined {
    return this._forecastRows;
  }

  get rows(): Row[] {
    return this._processedRows;
  }

  get numDecimals(): number | undefined {
    return this._options?.numDecimals;
  }

  static fromSurvey(
    rows: SurveyRow[],
    header: DatasetHeaderSurvey,
    groupHeaderDto: GroupHeaderDataDto | undefined,
    dimensions: DimensionsSpec
  ): DatasetInput<SurveyRow, DatasetHeaderSurvey> {
    return new DatasetInput(
      rows,
      undefined,
      header,
      defined(groupHeaderDto) ? new GroupHeaderData(groupHeaderDto) : undefined,
      dimensions,
      header.valueType
    );
  }

  static fromRegularDto(
    dto: StatsDatasetResponseDto,
    rows: RowRegular[],
    forecastRows: RowForecast[],
    forcedVariableDimensions: string[] | undefined,
    numDecimals?: number
  ) {
    const headers = DatasetHeaders.fromDto(dto);
    return new DatasetInput(
      rows,
      forecastRows,
      headers.header,
      headers.groupHeader,
      {
        liftedDimensions: omit(
          headers.header.liftedDimensions,
          forcedVariableDimensions ?? []
        ),
        variable: uniq(
          headers.header.dimensions.concat(forcedVariableDimensions ?? [])
        ),
      },
      dto.header.value_type,
      { numDecimals }
    );
  }
}

export class DatasetInputMicro extends DatasetInput<RowMicro, MicroHeaderData> {
  constructor(
    dto: MicroDatasetDto,
    dimensionsSpec: DimensionsSpec,
    rows: RowMicro[],
    private _aggMethodGeo: AggregationMethod,
    private _microReferenceRows?: RowMicroReference[],
    numDecimals?: number
  ) {
    super(
      rows,
      undefined,
      new MicroHeaderData(dto.header, dimensionsSpec.variable),
      undefined,
      dimensionsSpec,
      dto.header.value_type,
      { numDecimals }
    );
  }

  get aggregationMethodGeo(): AggregationMethod {
    return this._aggMethodGeo;
  }

  get microReferenceRows(): RowMicroReference[] | undefined {
    return this._microReferenceRows;
  }
}
