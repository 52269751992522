import { defined } from "../../../../core/defined";
import { GeographiesSerializable } from "../../../../domain/geography";
import {
  cloneDateRangeRaw,
  defaultGeographySelectionMinResolution,
} from "../../../../domain/measure";
import { MeasureSelection } from "../../../../domain/selections/definitions";
import { LatestSettingsFormat } from "../default-settings/common";
import { applyDefaultSettings } from "../default-settings/apply";
import {
  DocCardStats,
  makeIdWithUnusedLabel,
  makeDataSelection,
  DocCardState,
  DocCardText,
  makeCardId,
  makeSelectionId,
  makeCardLabel,
  DocCardStateNonError,
  DocCardMicro,
  DocCardTextCK,
  DocCardPython,
} from "./core";
import { MicroSettings } from "./core-micro";
import { VIZ_COLOR_FILL_OPACITY_DEFAULT } from "../../../../../views/stats/docs/cards/micro/shared";
import { DateRangeRaw } from "../../../../domain/measure/definitions";
import { Progress } from "../../../../core/progress";
import { CustomDataOutputSettings } from "../../../../infra/api_responses/account";
import { gridLineStyleOptions } from "../../../stats/shared/core/colors/colorSchemes";
import {
  DataOutputSettings,
  COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
  MapChartSettings,
  ChartSettings,
  DataTableSettings,
  DataSurveyStringSettings,
  DataTableMicroSettings,
  ChartMicroSettings,
  ForecastSettings,
} from "./DataOutputSettings";

export function makeDataCardState(
  primarySubjectPath: string[],
  measureSelection?: MeasureSelection,
  geographies?: GeographiesSerializable,
  customDataOutputSettings?: CustomDataOutputSettings,
  defaultSettings?: LatestSettingsFormat
): DocCardStats {
  const { id, label } = makeIdWithUnusedLabel();
  const cardBase: DocCardStats = {
    type: "dataCard",
    id,
    isEditing: true,
    label,
    initState: Progress.Success,
    data: {
      settings: dataOutputSettingsDefault(),
      geoExpansions: [],
      geoSelectionsInherited: false,
      dataSelections: [makeDataSelection(primarySubjectPath, measureSelection)],
      groupingSelection: undefined,
      timeSelection: defined(measureSelection)
        ? defaultTimeSelection(measureSelection.availableDates)
        : undefined,
    },
  };

  const cardBaseWithSettings =
    defined(defaultSettings) && defined(geographies)
      ? applyDefaultSettings(
          defaultSettings,
          customDataOutputSettings,
          cardBase,
          geographies
        )
      : cardBase;

  if (
    !defined(cardBaseWithSettings.data.geoSelections) &&
    defined(measureSelection) &&
    defined(geographies)
  ) {
    cardBaseWithSettings.data.geoSelections =
      defaultGeographySelectionMinResolution(
        measureSelection.measure,
        geographies
      );
  }

  return cardBaseWithSettings;
}

export function makePythonCardState(): DocCardPython {
  const { id, label } = makeIdWithUnusedLabel();
  return {
    type: "pythonCard",
    id,
    label,
    isEditing: true,
    initState: Progress.Success,
    data: {
      columns: [],
      selectedView: "create-dataframe",
    },
  };
}

function defaultTimeSelection(
  availableDates: string[]
): DateRangeRaw | undefined {
  if (availableDates.length === 0) {
    return undefined;
  }
  const first = availableDates[0];
  if (availableDates.length === 1) {
    return [first, first];
  }
  const last = availableDates[availableDates.length - 1];
  return [first, last];
}

export function dataOutputSettingsDefault(): DataOutputSettings {
  return {
    showLabels: false,
    startFromZero: true,
    showLineCircles: false,
    lineChartShowLastLabel: false,
    lineChartShowLastLabelAlignLeft: false,
    lineChartAccentuateLastDatum: false,
    tablePaddingSides: null,
    showFatLines: true,
    showTicksXAxis: true,
    showTicksYAxis: true,
    showYAxis: true,
    showXAxis: true,
    gridLinesXStyle: gridLineStyleOptions[0],
    gridLinesYStyle: gridLineStyleOptions[0],
    fixedNumDecimals: null,
    showReferenceLines: false,
    hideChartTitleSection: false,
    hideLegendDimensionLabels: false,
    showSurveyValueFraction: false,
    fixedDimensionOrder: null,
    customMainHeaderSize: null,
    customUnitSize: null,
    customSubHeaderLargeSize: null,
    customSubHeaderSmallSize: null,
    customSourceText: null,
    customTitle: null,
    customSubtitles: null,
    customUnitText: null,
    customYAxisRange: null,
    customSourceTextSize: null,
    customLabels: null,
    mapChart: mapChartSettingsDefault(),
    chart: chartSettingsDefault(),
    chartMicro: chartMicroSettingsDefault(),
    table: tableSettingsDefault(),
    tableSurveyString: tableSurveyStringSettingsDefault(),
    tableMicro: tableMicroSettingsDefault(),
    forecast: forecastSettingsDefault(),
    computedVariables: [],
    computedVariablesV3: [],
    computationInputRounding: COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
    computationOutputNumDecimals: null,
    hiddenBreakdownCombinations: [],
  };
}

export function mapChartSettingsDefault(): MapChartSettings {
  return {
    showAdministrativeBorders: true,
    showOnlyFirstAndLastDate: false,
    labelSize: 12,
  };
}

export function chartSettingsDefault(): ChartSettings {
  return {
    labelSize: 12,
  };
}

export function tableSettingsDefault(): DataTableSettings {
  return {
    showSumRow: false,
    showMaxRow: false,
    showMinRow: false,
    showMeanRow: false,
    showMedianRow: false,
  };
}

export function tableSurveyStringSettingsDefault(): DataSurveyStringSettings {
  return {
    sliceHeadAndTailRows: true,
    showWeightColumn: false,
  };
}

export function tableMicroSettingsDefault(): DataTableMicroSettings {
  return {
    showMunicipalityReference: false,
    showNuts3Reference: false,
    showCountryReference: false,
    showSelectedAreasAverage: false,
  };
}

export function chartMicroSettingsDefault(): ChartMicroSettings {
  return {
    showMunicipalityReference: false,
    showNuts3Reference: false,
    showCountryReference: false,
    showSelectedAreasAverage: false,
  };
}

export function forecastSettingsDefault(): ForecastSettings {
  return {
    show: false,
  };
}

export function duplicateCard(card: DocCardStateNonError): DocCardState {
  switch (card.type) {
    case "dataCard":
      return duplicateDataCard(card);
    case "textCardSimple":
      return duplicateTextCard(card);
    case "textCardCK":
      return duplicateTextCardCK(card);
    case "microCard":
      return duplicateMicroCard(card);
    case "pythonCard":
      return duplicatePythonCard(card);
    case "microCardImage":
      throw new Error(
        "Cannot duplicate micro card image -- should not exist in writable doc"
      );
  }
}

function duplicateTextCard(card: DocCardText): DocCardState {
  return {
    type: "textCardSimple",
    data: card.data,
    id: makeCardId(),
    isEditing: true,
    label: makeCardLabel(card.id),
  };
}

function duplicateTextCardCK(card: DocCardTextCK): DocCardState {
  return {
    type: "textCardCK",
    data: card.data,
    id: makeCardId(),
    isEditing: true,
    label: makeCardLabel(card.id),
  };
}

function duplicateDataCard(card: DocCardStats): DocCardState {
  const id = makeCardId();
  return {
    ...card,
    id,
    type: "dataCard",
    label: makeCardLabel(id),
    data: {
      ...card.data,
      timeSelection: defined(card.data.timeSelection)
        ? cloneDateRangeRaw(card.data.timeSelection)
        : undefined,
      dataSelections: card.data.dataSelections.map((selection) => {
        return { ...selection, id: makeSelectionId() };
      }),
    },
  };
}

function duplicatePythonCard(card: DocCardPython): DocCardState {
  const id = makeCardId();
  return {
    ...card,
    id,
    type: "pythonCard",
    label: makeCardLabel(id),
    data: {
      ...card.data,
    },
  } as DocCardPython;
}

function duplicateMicroCard(card: DocCardMicro): DocCardState {
  const id = makeCardId();
  return {
    ...card,
    id,
    data: {
      ...card.data,
    },
  };
}

export function defaultMicroSettings(): MicroSettings {
  return {
    dataOutputSettings: dataOutputSettingsDefault(),
    map: {
      showBorders: true,
      resultsOpacity: VIZ_COLOR_FILL_OPACITY_DEFAULT,
      mapSelectTool: "click-select",
      colorScheme: "d3-div-RdYlGn",
      zMinMaxAbsolute: 2.5,
    },
  };
}

export function makeMicroCard(): DocCardMicro {
  const id = makeCardId();
  const label = makeCardLabel(id);
  return {
    id,
    label,
    isEditing: true,
    type: "microCard",
    initState: Progress.Success,
    data: {
      mapLocationBounds: {
        ne: { lat: 60, lng: 19.21 },
        sw: { lat: 59, lng: 16.0 },
      },
      settings: defaultMicroSettings(),
      selectedTab: "map-select",
      filterMeasures: [],
    },
  };
}

export function makeTextCardCK(initialText?: string): DocCardTextCK {
  const id = makeCardId();
  const label = makeCardLabel(id);
  const base = {
    id,
    label,
    isEditing: true,
    type: "textCardCK",
    data: initialText ?? "",
  } as const;
  return base;
}
