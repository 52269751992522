import { chain, fromPairs, groupBy, identity, mapValues, uniq } from "lodash";

import { assertNever } from "../../../core/assert";
import { defined } from "../../../core/defined";
import { mapOptional } from "../../../core/func/mapOptional";
import { last } from "../../../core/last";
import { dateStringComparatorAsc } from "../../../core/time";
import { round } from "../../../core/math/round";
import { dateRangeFromRawUtc } from "../../../domain/measure";
import {
  DateRangeRaw,
  PrimaryMeasureSelectionMicroFull,
} from "../../../domain/measure/definitions";
import { MicroResultsType } from "../../../domain/micro/definitions";
import { DesoRegsoLookup } from "../../../domain/micro/desoLookup";
import { getDateFormatter, TimeResolution } from "../../../domain/time";
import {
  AggregationMethod,
  RowValueRegularRaw,
} from "../../../infra/api_responses/dataset";
import { MicroDatasetDto } from "../../../infra/api_responses/micro_dataset";
import { logger } from "../../../infra/logging";
import {
  MICRO_REFERENCE_TYPES,
  MicroReferenceType,
} from "../../state/stats/document-core/core";
import {
  DesoProperties,
  isUserDefinedAreaDesoMode,
  isUserDefinedAreaRegsoMode,
  MicroGeoSelections,
  MicroSettings,
  RegsoProperties,
} from "../../state/stats/document-core/core-micro";
import { dataSettingsEqual } from "../../state/stats/document-core/eq";
import {
  DEFAULT_NUM_SIGNIFICANT_FIGURES,
  maxNumDecimals,
  numSignificantFiguresRounder,
} from "../format";
import {
  getSourceInfoMicro,
  processLongDescription,
} from "../shared/charts_common";
import { ChartDataRegular } from "../shared/chart_data/ChartDataRegular";
import { Dimension } from "../shared/core/definitions";
import {
  DataDescriptionMicro,
  getApplicableDimensionHeader,
} from "../shared/DataDescription";
import {
  defaultDimensionLabelComparatorAsc,
  filterMicroDataDimensions,
  getHierarchicalDimensionGroups,
  MICRO_DIMENSION_GEOCODES,
} from "../shared/dimensions";
import { RowMicro, RowMicroReference, RowRawRegular } from "../shared/row";
import {
  DatasetGeneric,
  DatasetOutputType,
  DataSourceInfo,
  DetailedDimensionInfo,
  LabelsByDimension,
  LabelsWithIdsByDimension,
  ValuesByDimensionAndLabel,
} from "./DatasetGeneric";
import { DatasetInputMicro, DimensionsSpec, MicroHeaderData } from "./headers";
import { MicroMapResults } from "./MicroMapResults";
import { displaySingleRegion } from "./shared";
import { microDatasetToTable } from "./table/table_micro";
import { MicroGeoTree } from "../shared/MicroGeoTree";
import { chartMicroSettingsLabels } from "../shared/micro_labels";
import { createMicroMapData } from "./createMicroMapData";
import { PackagedDatasetMicroDto } from "../../state/stats/packaged-doc/types";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";
import { TableSpec } from "./table/table_base/table_base_definitions";

const threeSFRounder = numSignificantFiguresRounder(
  DEFAULT_NUM_SIGNIFICANT_FIGURES
);

export class MicroDataset
  implements DatasetGeneric<RowValueRegularRaw, RowMicro>
{
  private _microMapResults: MicroMapResults | undefined;
  private _labelsByDimension: LabelsByDimension;
  private _labelsWithIdsByDimension: LabelsWithIdsByDimension;
  private _numDecimals: number | undefined;
  private _dateDistanceGetter: (first: string, last: string) => number;
  private _dateFormatter: (input: string) => string;
  private _regionTimeString: string;
  private _timeResolution: TimeResolution;
  private _datasetInputMap: DatasetInputMicro;
  private _datasetInputTable: DatasetInputMicro;
  private _settings: DataOutputSettings;

  private _copyWithSettings: (s: DataOutputSettings) => MicroDataset;
  private _header: MicroHeaderData;
  private _zMin: number | undefined;
  private _zMax: number | undefined;
  private _isComputedMeasure: boolean;
  private _processedDataDto: MicroDatasetDto;
  private _aggMethodGeo: AggregationMethod;
  private _makeRow: (r: RowRawRegular) => RowMicro;
  private _translateMicroValues: (
    dimension: string
  ) => ((groupId: string) => string | undefined) | undefined;
  private _variableDataDimensionsWithRegion: string[];
  private _valuesByDimensionAndLabel: ValuesByDimensionAndLabel;
  private _valuesByDimensionAndLabelUnfiltered:
    | ValuesByDimensionAndLabel
    | undefined;

  constructor(
    private _measureSelection: PrimaryMeasureSelectionMicroFull,
    private _geoSelections: MicroGeoSelections | undefined,
    private _microSettings: MicroSettings,
    private _datasetDto: MicroDatasetDto,
    private _comparisonType: MicroResultsType,
    private _desoRegsoLookup: DesoRegsoLookup,
    private _microGeoTree: MicroGeoTree | undefined,
    isPostalCodeExportMode: boolean
  ) {
    // Base variables that depend only on params
    const valueType = _datasetDto.header.value_type;
    const isComputedMeasure = defined(_measureSelection.measure.computed);
    const _settings = _microSettings.dataOutputSettings;
    const supportsDecimalModes = valueType === "decimal";
    const resolution = TimeResolution.deserialize(
      _measureSelection.measure.timeResolution
    );
    const translateMicroValues = getMicroValuesTranslator(
      isPostalCodeExportMode,
      _comparisonType,
      _desoRegsoLookup,
      _geoSelections
    );
    const variableDimensionsBaseUnprocessed = filterMicroDataDimensions(
      _datasetDto.header.dimensions
    );
    const variableDataDimensionsWithRegion =
      _datasetDto.header.dimensions.includes(MICRO_DIMENSION_GEOCODES)
        ? variableDimensionsBaseUnprocessed.concat([Dimension.region])
        : variableDimensionsBaseUnprocessed;
    // END base variables

    const dimensionsSpecUnprocessed: DimensionsSpec = {
      liftedDimensions: mapValues(
        _datasetDto.header.lifted,
        (value) => (value as any).toString() // FIXME
      ),
      variable: variableDataDimensionsWithRegion,
    };

    this._microMapResults = isPostalCodeExportMode
      ? undefined
      : new MicroMapResults(
          _datasetDto,
          dimensionsSpecUnprocessed,
          _comparisonType,
          _measureSelection,
          _desoRegsoLookup,
          _microGeoTree
        );

    const actualTimespan = isComputedMeasure
      ? undefined
      : getActualTimespan(_datasetDto);

    const { parsedRowValues, zMin, zMax } = parseRowValues(_datasetDto);
    const numDecimals = supportsDecimalModes
      ? _settings.fixedNumDecimals !== null
        ? _settings.fixedNumDecimals
        : maxNumDecimals(parsedRowValues, DEFAULT_NUM_SIGNIFICANT_FIGURES)
      : undefined;

    const makeRow = (r: RowRawRegular) =>
      new RowMicro(
        r,
        {
          rangeDimension: variableDataDimensionsWithRegion[0] as Dimension,
          domainDimension: variableDataDimensionsWithRegion[1] as Dimension,
          valueType,
        },
        {
          numDecimals,
          translateMicroValues,
          microMode: _comparisonType,
        }
      );
    const { dto: processedDataDto, valuesByDimensionAndLabel } =
      processDataWithSettings(
        _datasetDto,
        _measureSelection,
        _settings,
        variableDataDimensionsWithRegion,
        makeRow,
        true
      );
    const processedDataUnfiltered =
      _settings.hiddenBreakdownCombinations.length > 0
        ? processDataWithSettings(
            _datasetDto,
            _measureSelection,
            _settings,
            variableDataDimensionsWithRegion,
            makeRow,
            false
          )
        : undefined;

    const dimensionsSpecProcessed: DimensionsSpec = {
      liftedDimensions: mapValues(processedDataDto.header.lifted, (value) =>
        (value as any).toString()
      ),
      variable: processedDataDto.header.dimensions,
    };

    const rowsProcessed = (processedDataDto.rows as RowRawRegular[])?.map(
      makeRow
    );
    const rowsProcessedUnfiltered = (
      processedDataUnfiltered?.dto.rows as RowRawRegular[]
    )?.map(makeRow);

    const aggMethodGeo = _measureSelection.measure.aggMethodGeo;
    const datasetInputMap = new DatasetInputMicro(
      processedDataDto,
      dimensionsSpecUnprocessed, // Use the unprocessed spec for map results
      rowsProcessed,
      aggMethodGeo,
      processedDataDto.reference_rows?.map((r) => new RowMicroReference(r)),
      numDecimals
    );

    const datasetInputTable = new DatasetInputMicro(
      processedDataDto,
      dimensionsSpecProcessed,
      rowsProcessed,
      aggMethodGeo,
      processedDataDto.reference_rows?.map((r) => new RowMicroReference(r)),
      numDecimals
    );

    const labelDims = datasetInputTable.header.dimensions
      .slice(1)
      .concat(Object.keys(dimensionsSpecProcessed.liftedDimensions ?? {}));
    const labelsByDimension: LabelsByDimension = labelDims.map((dimension) => {
      return [
        dimension,
        Object.keys(
          groupBy(
            rowsProcessedUnfiltered ?? rowsProcessed,
            (r) =>
              r.dimension(dimension) ??
              dimensionsSpecProcessed.liftedDimensions?.[dimension]
          )
        ).sort(defaultDimensionLabelComparatorAsc(dimension)),
      ] as [string, string[]];
    });

    const labelsWithIdsByDimension: LabelsWithIdsByDimension = labelDims.map(
      (dimension) => {
        const dimInfo = _measureSelection.measure.dimensions?.find(
          (d) => d.data_column === dimension
        );
        return [
          dimension,
          Object.keys(
            groupBy(
              rowsProcessedUnfiltered ?? rowsProcessed,
              (r) =>
                r.dimension(dimension) ??
                dimensionsSpecProcessed.liftedDimensions?.[dimension]
            )
          )
            .sort(defaultDimensionLabelComparatorAsc(dimension))
            .map((label) => {
              return {
                text: label,
                id: dimInfo?.values?.find((v) => v.label === label)?.id,
              };
            }),
        ];
      }
    );

    const dateResolutionFormatter = getDateFormatter(resolution);
    const formatDate = (input: string) =>
      dateResolutionFormatter(new Date(input));

    const singleSelectedGeoLabel = mapOptional(
      getSingleSelectedGeolabel,
      _geoSelections
    );
    const singleRegion = displaySingleRegion(
      singleSelectedGeoLabel,
      processedDataDto.header.lifted
    );
    const singleTimePeriod =
      defined(actualTimespan) && actualTimespan[0] === actualTimespan[1]
        ? formatDate(actualTimespan[0])
        : undefined;
    const regionTimeString = [singleRegion, singleTimePeriod]
      .filter(defined)
      .join(", ");

    this._copyWithSettings = (s: DataOutputSettings): MicroDataset => {
      if (dataSettingsEqual(s, _microSettings.dataOutputSettings)) {
        return this;
      }
      return new MicroDataset(
        _measureSelection,
        _geoSelections,
        { ..._microSettings, dataOutputSettings: s },
        _datasetDto,
        _comparisonType,
        _desoRegsoLookup,
        _microGeoTree,
        isPostalCodeExportMode
      );
    };

    const header = new MicroHeaderData(
      processedDataDto.header,
      dimensionsSpecProcessed.variable
    );

    const dateDistanceGetter = (first: string, last: string) => {
      const range = dateRangeFromRawUtc([first, last]);
      return resolution.distance(range[0], range[1]);
    };

    this._valuesByDimensionAndLabel = valuesByDimensionAndLabel;
    this._variableDataDimensionsWithRegion = variableDataDimensionsWithRegion;
    this._translateMicroValues = translateMicroValues;
    this._makeRow = makeRow;
    this._processedDataDto = processedDataDto;
    this._aggMethodGeo = aggMethodGeo;
    this._isComputedMeasure = isComputedMeasure;
    this._zMin = zMin;
    this._zMax = zMax;
    this._header = header;
    this._regionTimeString = regionTimeString;
    this._settings = _settings;
    this._labelsByDimension = labelsByDimension;
    this._labelsWithIdsByDimension = labelsWithIdsByDimension;
    this._numDecimals = numDecimals;
    this._datasetInputMap = datasetInputMap;
    this._datasetInputTable = datasetInputTable;
    this._dateDistanceGetter = dateDistanceGetter;
    this._dateFormatter = formatDate;
    this._regionTimeString = regionTimeString;
    this._timeResolution = resolution;
  }

  private _nonPrimaryDataDimensions(): string[] {
    return this._datasetInputMap.dimensionsSpec.variable.filter(
      (d) => d !== Dimension.value
    );
  }

  private _eligibleDimensionsComputedVariable(): string[] {
    const hierarchicalDimensionGroups = getHierarchicalDimensionGroups(
      this._datasetDto.header.dimensions,
      this._measureSelection.measure.dimensions ?? []
    );
    const spec = this._datasetInputMap.dimensionsSpec;
    return spec.variable
      .concat(Object.keys(spec.liftedDimensions ?? {}))
      .filter(
        (d) => ![Dimension.value, Dimension.date].includes(d as Dimension)
      )
      .filter((d) => {
        // Exclude tree dimensions unless it's the last one -- we boil down all tree dimensions to a single one
        return hierarchicalDimensionGroups.every(
          (group) => !group.includes(d) || d === last(group)
        );
      });
  }

  private _dataDescription(): DataDescriptionMicro {
    return new DataDescriptionMicro(
      this._processedDataDto.header,
      this._measureSelection,
      this._regionTimeString,
      this._settings
    );
  }

  aggMethodGeo(): AggregationMethod {
    return this._aggMethodGeo;
  }

  // ------------------------------------------------------------
  // Non-inherited methods
  // ------------------------------------------------------------

  /**
   * NOTE: Does not work with user-defined areas!
   *
   * Filter geo selections so that only ones matched by the result dataset remain.
   * This is useful when filter measures have been applied and the backend returns a smaller set of geographical areas
   * than the user has selected.
   */
  filterGeoSelectionsByResults(
    selections: MicroGeoSelections
  ): MicroGeoSelections {
    const geoLabels = new Set<string>();
    for (const r of this._datasetInputMap.rows) {
      const value = r.dimension(Dimension.region);
      geoLabels.add(value);
    }
    if (selections.type === "deso") {
      return {
        type: "deso",
        selected: selections.selected.filter((s) => {
          return s.type === "deso" && geoLabels.has(s.props.deso_label);
        }),
      };
    } else if (selections.type === "regso") {
      return {
        type: "regso",
        selected: selections.selected.filter((s) => {
          return s.type === "regso" && geoLabels.has(s.props.regso_label);
        }),
      };
    }
    assertNever(selections);
  }

  isComputedMeasure(): boolean {
    return this._isComputedMeasure;
  }

  unitLabel(): string {
    return this._processedDataDto.header.unit_label;
  }

  displayValue(value: number): string {
    switch (this._header.valueType) {
      case "integer":
        return round(value, 0).toString();
      case "decimal":
        if (defined(this._numDecimals)) {
          return round(value, this._numDecimals).toString();
        }
        return threeSFRounder(value).toString();
    }
    assertNever(this._header.valueType);
  }

  packDatasetForPublication(): PackagedDatasetMicroDto {
    if (!defined(this._microGeoTree)) {
      throw new Error("MicroGeoTree is undefined");
    }
    if (!defined(this._geoSelections)) {
      throw new Error("GeoSelections is undefined");
    }
    return {
      type: "micro",
      microSettings: this._microSettings,
      microResultsType: this._comparisonType,
      measureSelection: this._measureSelection,
      geoSelection: this._geoSelections,
      data: this._datasetDto,
      microGeoTree: this._microGeoTree,
    };
  }

  microMapData() {
    return createMicroMapData(
      this._processedDataDto,
      this._datasetInputMap.dimensionsSpec,
      this._comparisonType,
      this._measureSelection,
      this._desoRegsoLookup,
      this._microGeoTree
    );
  }

  microMapResults(): MicroMapResults | undefined {
    return this._microMapResults;
  }

  // ------------------------------------------------------------
  // Implementations of generic methods
  // ------------------------------------------------------------

  get outputType(): DatasetOutputType {
    const valueType = this._header.valueType;
    switch (valueType) {
      case "decimal":
      case "integer":
        return "numeric";
    }
    return assertNever(valueType);
  }

  public groupingSourceInfo = undefined;

  canChangeDimensionOrder(): boolean {
    return this._nonPrimaryDataDimensions().length > 1;
  }

  copyWithSettings(settings: DataOutputSettings): MicroDataset {
    return this._copyWithSettings(settings);
  }

  chartData() {
    const valueType = this._datasetDto.header.value_type;
    const supportsDecimalModes = valueType === "decimal";
    const { parsedRowValues } = parseRowValues(this._datasetDto);
    const numDecimals = supportsDecimalModes
      ? this._settings.fixedNumDecimals !== null
        ? this._settings.fixedNumDecimals
        : maxNumDecimals(parsedRowValues, DEFAULT_NUM_SIGNIFICANT_FIGURES)
      : undefined;
    const aggMethodGeo = this._measureSelection.measure.aggMethodGeo;
    const processedDataDto = this._processedDataDto;

    const rowsOriginal: RowMicro[] =
      this._datasetDto.rows?.map(this._makeRow as any) ?? [];
    const rowsProcessed = (processedDataDto.rows as RowRawRegular[]).map(
      this._makeRow
    );

    let dimensionsSpecProcessed: DimensionsSpec = {
      liftedDimensions: mapValues(processedDataDto.header.lifted, (value) =>
        (value as any).toString()
      ),
      variable: processedDataDto.header.dimensions,
    };
    const rowsProcessedChart = this._settings.chartMicro.showReferenceValuesOnly
      ? []
      : rowsProcessed.slice();
    const usedRefRows: RowMicro[] = [];
    for (const refRow of this._datasetDto.reference_rows ?? []) {
      for (const referenceType of MICRO_REFERENCE_TYPES) {
        const key = referenceType;
        const value = this._settings.chartMicro?.[key];
        if (!value) {
          continue;
        }

        // Municipality/nuts3 reference are only shown
        // when all selected deso/regso are within the same municipality/nuts3,
        // thus we can use the any row (we use the first) to determine the region
        const region = rowsOriginal[0]?.singleGeocode();
        const regionName: string | undefined = chartMicroSettingsLabels(
          key,
          region,
          this._microGeoTree,
          this._measureSelection.measure.aggMethodGeo
        );
        usedRefRows.push(
          new RowMicro(
            {
              ...(refRow as any),
              region: regionName,
            },
            {
              rangeDimension: microReferenceKey(key),
              domainDimension: this
                ._variableDataDimensionsWithRegion[1] as Dimension,
              valueType,
            },
            {
              numDecimals,
              translateMicroValues: this._translateMicroValues,
              microMode: this._comparisonType,
            }
          ) as any as RowMicro
        );
      }
    }

    if (usedRefRows.length > 0) {
      if (
        defined(dimensionsSpecProcessed.liftedDimensions?.[Dimension.region])
      ) {
        dimensionsSpecProcessed = {
          variable: [...dimensionsSpecProcessed.variable, Dimension.region],
          liftedDimensions: fromPairs(
            Object.entries(
              dimensionsSpecProcessed.liftedDimensions ?? {}
            ).filter(([key]) => key !== Dimension.region)
          ) as { [key: string]: string },
        };
      }
      rowsProcessedChart.push(...usedRefRows);
    }

    const datasetInputChart = new DatasetInputMicro(
      processedDataDto,
      dimensionsSpecProcessed,
      rowsProcessedChart,
      aggMethodGeo,
      processedDataDto.reference_rows?.map((r) => new RowMicroReference(r)),
      numDecimals
    );
    const datasetInput = datasetInputChart;
    const labelsByDimension: LabelsByDimension = datasetInput.header.dimensions
      .slice(1)
      .map((dimension) => {
        return [
          dimension,
          Object.keys(
            groupBy(datasetInput.rows, (r) => r.dimension(dimension))
          ).sort(defaultDimensionLabelComparatorAsc(dimension)),
        ] as [string, string[]];
      });

    return ChartDataRegular.fromMicroDataset(
      datasetInputChart,
      labelsByDimension,
      this._dateFormatter,
      this._dateDistanceGetter,
      this._dataDescription(),
      this._settings
    );
  }

  get zMin(): number | undefined {
    return this._zMin;
  }

  get zMax(): number | undefined {
    return this._zMax;
  }

  get supportsDecimalModes(): boolean {
    return this._header.valueType === "decimal";
  }

  isChartable(): boolean {
    const dto = this._processedDataDto;
    if (Object.values(this._settings.chartMicro).some(identity)) {
      // Showing reference values
      return dto.rows.length > 0 || (dto.reference_rows?.length ?? 0) > 0;
    }
    return dto.rows.length > 0;
  }

  lookupDesoRegsoByLabel(label: string): string | undefined {
    const item = this._desoRegsoLookup.lookupDesoRegsoByLabel(label);
    if (!defined(item)) {
      return;
    }
    return (item as DesoProperties).deso ?? (item as RegsoProperties).regso;
  }

  labelsByDimension(): LabelsByDimension {
    return this._labelsByDimension;
  }

  labelsWithIdsByDimension(): LabelsWithIdsByDimension {
    return this._labelsWithIdsByDimension;
  }

  nonPrimaryDataDimensions(): string[] {
    return this._nonPrimaryDataDimensions();
  }

  eligibleDimensionsComputedVariable(): string[] {
    return this._eligibleDimensionsComputedVariable();
  }

  dataDescription(): DataDescriptionMicro {
    return this._dataDescription();
  }

  get canMakeComputedValue(): boolean {
    return (
      this.outputType === "numeric" &&
      this._eligibleDimensionsComputedVariable().length > 0
    );
  }

  internalLabel(): string | undefined {
    return this._measureSelection.measure.label;
  }

  infostatPublicComment(): string[] {
    return processLongDescription(this._header.publicComment);
  }

  get sourceInfo(): DataSourceInfo | undefined {
    return getSourceInfoMicro(this._header);
  }

  table(): TableSpec {
    const datasetInput = this._datasetInputTable;
    const dataDescription = this._dataDescription();
    const getDimensionHeader = (dimension: string) => {
      return getApplicableDimensionHeader(
        dimension,
        this._settings,
        dataDescription
      );
    };
    const dimensions = this._measureSelection.measure.dimensions ?? [];

    return microDatasetToTable(
      datasetInput,
      dataDescription,
      this._microGeoTree,
      datasetInput.dimensionsSpec.variable.slice(1),
      this._dateFormatter,
      dimensions,
      getDimensionHeader,
      this._valuesByDimensionAndLabel,
      this._settings
    );
  }
}

/**
 * Return function that translates geocodes to labels
 */
function getMicroValuesTranslator(
  isPostalCodeExportMode: boolean,
  comparisonType: string,
  desoRegsoLookup: DesoRegsoLookup,
  geoSelection: MicroGeoSelections | undefined
) {
  return isPostalCodeExportMode
    ? (dimension: string) => (value: string) => undefined
    : comparisonType === "compare-units-deso"
    ? (dimension: string) => {
        if (dimension === Dimension.region) {
          return (deso: string) => {
            return desoRegsoLookup.lookupDeso(deso)?.deso_label ?? deso;
          };
        }
      }
    : comparisonType === "compare-units-regso"
    ? (dimension: string) => {
        if (dimension === Dimension.region) {
          return (regso: string) => {
            return desoRegsoLookup.lookupRegso(regso)?.regso_label ?? regso;
          };
        }
      }
    : (dimension: string) => {
        if (dimension === Dimension.region) {
          return (groupId: string) => {
            if (!defined(geoSelection)) {
              return;
            }
            switch (geoSelection.type) {
              case "deso":
                const groups = geoSelection.selected.filter(
                  isUserDefinedAreaDesoMode
                );
                return groups.find((g) => g.groupId === groupId)?.groupName;
              case "regso":
                const regsoGroups = geoSelection.selected.filter(
                  isUserDefinedAreaRegsoMode
                );
                return regsoGroups.find((g) => g.groupId === groupId)
                  ?.groupName;
              default:
                assertNever(geoSelection);
            }
          };
        }
      };
}

/**
 * Return the actual dates used by the dataset.
 * Note that for computed measures, backend does not specify a date at all since the date is
 * is irrelevant.
 */
function getActualTimespan(datasetDto: MicroDatasetDto): DateRangeRaw {
  const liftedDate: string | undefined = mapOptional(
    assertDateString,
    datasetDto.header.lifted[Dimension.date] as unknown | undefined
  );

  const actualDates = defined(liftedDate)
    ? [liftedDate]
    : chain(datasetDto.rows)
        .map((r) => {
          const d = r[Dimension.date];
          if (typeof d !== "string") {
            throw new Error("Expected date string");
          }
          return d;
        })
        .uniq()
        .sort(dateStringComparatorAsc)
        .value();

  const actualStart = actualDates[0];
  const actualEnd = last(actualDates) ?? actualStart;
  return [actualStart, actualEnd];
}

function getSingleSelectedGeolabel(
  areas: MicroGeoSelections
): string | undefined {
  const selected = areas.selected;
  if (selected.length !== 1) {
    return;
  }
  const area = selected[0];
  if (area.type === "deso") {
    return area.props.deso_label;
  } else if (area.type === "regso") {
    return area.props.regso_label;
  } else if (area.type === "user-defined") {
    return area.groupName;
  }
  assertNever(area);
}

function assertDateString(s: unknown): string {
  if (typeof s !== "string") {
    throw new Error("Expected date string");
  }
  return s;
}

function microReferenceKey(setting: MicroReferenceType) {
  switch (setting) {
    case "showMunicipalityReference":
      return "value_municipality";
    case "showNuts3Reference":
      return "value_nuts3";
    case "showCountryReference":
      return "value_country";
    case "showSelectedAreasAverage":
      return "value_selected_areas_average";
    default:
      assertNever(setting);
  }
}

/** Process data dto in order to adjust rows and variable/lifted dimensions so that the following are matched:
 * - computed values
 * - hidden breakdown combinations
 */
function processDataWithSettings(
  dataRaw: MicroDatasetDto,
  primaryMeasureSelection: PrimaryMeasureSelectionMicroFull,
  settings: DataOutputSettings,
  dataDimensions: string[],
  makeRow: (raw: Record<string, any>) => RowMicro,
  applyFilters: boolean
): {
  dto: MicroDatasetDto;
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel;
} {
  const rowsRaw = dataRaw.rows?.slice() ?? [];
  const rows = rowsRaw.map(makeRow);

  // Sort order by dimension and label
  const valuesByDimensionAndLabel: ValuesByDimensionAndLabel = {};

  const selectedDimValuesAll: DetailedDimensionInfo[] = Object.keys(
    dataRaw.header.lifted ?? {}
  )
    .concat(dataRaw.header.dimensions)
    .map((d) => {
      const dimInfo = primaryMeasureSelection.measure.dimensions?.find(
        (md) => md.data_column === d
      );
      if (!defined(dimInfo)) {
        return undefined;
      }
      const breakdown = primaryMeasureSelection.selectedDimensions[d];
      if (!defined(breakdown)) {
        return undefined;
      }
      return {
        dataColumn: d,
        dimensionId: dimInfo.dimension_id,
        parentId: dimInfo.parent_id ?? undefined,
        values: dimInfo.values?.filter((v) => breakdown.includes(v.id)) ?? [],
      };
    })
    .filter(defined);

  const filteredRows =
    settings.hiddenBreakdownCombinations.length > 0 && applyFilters
      ? rows.filter(
          (r) =>
            // If any of the hidden combinations match, filter out the row
            !settings.hiddenBreakdownCombinations.some((combo) =>
              // All values in the combination must match
              combo.every((part) => r.dimension(part.dimension) === part.value)
            )
        )
      : rows;

  // Adjust variable/lifted dimensions accordingly

  const dimToValues: { [key: string]: Set<string | number> } = {};
  const originalDataDimensions = [
    ...dataDimensions,
    ...Object.keys(dataRaw.header.lifted ?? {}),
  ].filter((d) => d !== Dimension.value);

  for (const r of filteredRows) {
    for (const dim of originalDataDimensions) {
      if (!dimToValues[dim]) {
        dimToValues[dim] = new Set<string | number>();
      }
      const dimValue = r.dimension(dim);
      if (defined(dimValue)) {
        dimToValues[dim].add(dimValue as string | number);
      }
    }
  }

  const header = {
    ...dataRaw.header,
    lifted: { ...dataRaw.header.lifted },
    dimensions: [...dataDimensions],
  };

  for (const d of originalDataDimensions) {
    const values = dimToValues[d];
    if (!defined(values)) {
      delete header.lifted[d];
      header.dimensions = header.dimensions.filter((dim) => dim !== d);
      continue;
    }

    if (values.size === 1) {
      const value = values.values().next().value;
      header.lifted[d] = value as string;
      header.dimensions = header.dimensions.filter((dim) => dim !== d);
    } else if (values.size > 1) {
      delete header.lifted[d];
      header.dimensions = uniq([...header.dimensions, d]);
    }
  }

  const regularDataDims = header.dimensions.filter(
    (dim) => dim !== Dimension.value
  );

  for (const d of regularDataDims) {
    valuesByDimensionAndLabel[d] = {};
    for (const label of Array.from(dimToValues[d])) {
      valuesByDimensionAndLabel[d][label.toString()] ??= selectedDimValuesAll
        .find((v) => v.dataColumn === d)
        ?.values.find((v) => v.label === label.toString());
    }
  }

  return {
    dto: { ...dataRaw, rows: filteredRows.map((r) => r.raw()), header },
    valuesByDimensionAndLabel,
  };
}

function parseRowValues(datasetDto: MicroDatasetDto) {
  const parsedRowValues: number[] = [];
  let zMin: number | undefined;
  let zMax: number | undefined;
  for (const row of datasetDto.rows) {
    const zValue = row[Dimension.index];
    if (typeof zValue === "number") {
      if (zMin === undefined || zValue < zMin) {
        zMin = zValue;
      }
      if (zMax === undefined || zValue > zMax) {
        zMax = zValue;
      }
    } else {
      logger.error("Expected number for z value. Got: " + zValue);
    }

    // Parse row values for below calculation of num decimals
    const raw = row[Dimension.value];
    if (!defined(raw) || typeof raw !== "string") {
      continue;
    }
    try {
      parsedRowValues.push(parseFloat(raw));
    } catch (e) {
      logger.error("Error parsing micro row value. Raw: " + raw);
    }
  }
  return { parsedRowValues, zMin, zMax };
}
