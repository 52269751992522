// TODO: Improve types and validation in this file!
import { Number as NumberRT } from "runtypes";

import { config } from "../../../../config";
import {
  DocumentDto,
  ThirdPartyDocDto,
  ThirdPartyDocDtoRT,
} from "../../../infra/api_responses/document";
import {
  EmbeddedDocumentDto,
  EmbeddedDocumentDtoRT,
} from "../../../infra/api_responses/document_embed";
import { HttpResult } from "../../../infra/HttpResult";
import { logger } from "../../../infra/logging";
import { rawRequestEmbedded } from "../../../infra/request";
import { SharingInfo } from "../../files/SharingInfo";
import { DocumentMetadata } from "../../state/stats/document-meta/DocumentMetadata";
import { WorkspaceLatest } from "../../state/stats/workspace/shared";
import { authedRequest, CrudMaker, decodedAuthedRequest } from "../shared";
import {
  CreateShareLink,
  CreateShareLinkRT,
  DocumentMetadataRT,
  RoleDto,
  SharingInfoDtoRT,
} from "./dto";
import { dtoToDomain, parseNodeSharingInfo } from "./parse";

export interface ReportMetadata {
  id: number;
  title: string;
  lastModified: Date;
}

const favoritesCrud = new CrudMaker<number>("nodes/favorites");
export const addFavorite = favoritesCrud.create<{ id: number }>();
export const listFavorites = favoritesCrud.listParse(dtoToDomain);
export const deleteFavorite = favoritesCrud.delete();

const shortcutsCrud = new CrudMaker<number>("sample_libraries/shortcuts");
export const listShortcuts = shortcutsCrud.listParse(dtoToDomain);

const sampleLibraryCrud = new CrudMaker<number>("sample_libraries");
export const getSampleLibraryRoots = sampleLibraryCrud.listParse(dtoToDomain);

const helpLibraryCrud = new CrudMaker<number>("help_libraries");
export const getHelpLibraryRoots = helpLibraryCrud.listParse(dtoToDomain);

const nodeCrud = new CrudMaker<number>("nodes");
export const listNodes = nodeCrud.listParse(dtoToDomain);
export const readNode = nodeCrud.readParse(dtoToDomain);
export const deleteNode = nodeCrud.delete();
export const updateNode = nodeCrud.update<{
  title: string;
  parent_folder: number | null;
}>();
export const patchNode = nodeCrud.patch<{ title: string }>();

const shareLinkCrud = new CrudMaker<string>("share_links");
export const createLink = shareLinkCrud.create<
  { node_id: number; role: RoleDto },
  CreateShareLink
>(CreateShareLinkRT);
export const deleteLink = shareLinkCrud.delete();

export function getNodeSharingInfo(
  nodeId: number
): Promise<HttpResult<SharingInfo>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `nodes/${nodeId}/sharing`,
    undefined,
    "GET",
    SharingInfoDtoRT
  ).then((res) => res.map(parseNodeSharingInfo));
}

export function shareNode(
  nodeId: number,
  type: "org",
  role: RoleDto
): Promise<HttpResult<void>> {
  return authedRequest(
    config.apis.statsV1,
    `nodes/${nodeId}/sharing`,
    { type, role },
    "POST"
  );
}

/**
 * Activates a link for the current user, returning the node id.
 */
export function activateSharingLink(
  linkCode: string
): Promise<HttpResult<number>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `share_links/${linkCode}/activation`,
    undefined,
    "PUT",
    NumberRT
  );
}

export function getDocumentMetadata(
  documentId: number
): Promise<HttpResult<DocumentMetadata>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `documents/${documentId}/metadata`,
    undefined,
    "GET",
    DocumentMetadataRT
  ).then((res) => res.map(DocumentMetadata.fromDto));
}

export function deleteSharing(sharingId: number): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `sharing/${sharingId}`,
    undefined,
    "DELETE"
  );
}

export function createFolder(
  title: string,
  parentFolderId?: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `folders`,
    { title, parent_folder: parentFolderId },
    "POST"
  );
}

export function getDocument(id: number): Promise<HttpResult<DocumentDto>> {
  return authedRequest<DocumentDto>(
    config.apis.statsV1,
    `documents/${id}`,
    undefined,
    "GET"
  ).then((res) => {
    return res;
  });
}

export function getThirdPartyDocument(
  id: string
): Promise<HttpResult<ThirdPartyDocDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `documents/thirdparty/${id}`,
    undefined,
    "GET",
    ThirdPartyDocDtoRT
  );
}

export function getEmbeddedDocument(
  embedKey: string
): Promise<HttpResult<EmbeddedDocumentDto>> {
  return rawRequestEmbedded(
    embedKey,
    config.apis.statsV1,
    `public/embedcards/${embedKey}`,
    undefined,
    "GET"
  ).then((response) => {
    return response.match({
      ok: (data) => {
        try {
          const result = EmbeddedDocumentDtoRT.check(data);
          return HttpResult.fromOk(result);
        } catch (e) {
          logger.error("Error parsing embedded document", e);
          return HttpResult.fromErr({ code: "unknown-error" });
        }
      },
      err: (e) => {
        logger.error(e);
        return HttpResult.fromErr({ code: "unknown-error" });
      },
    });
  });
}

export function updateDocument(
  id: number,
  workspace: object
): Promise<HttpResult<void>> {
  return authedRequest(
    config.apis.statsV1,
    `documents/${id}`,
    workspace,
    "PUT"
  );
}

export function savePotentiallyCorruptDocument(
  id: number,
  workspace: object
): Promise<HttpResult<void>> {
  return authedRequest(
    config.apis.statsV2,
    `corrupt_documents`,
    { document_content: workspace, document_id: id },
    "POST"
  );
}

export function createDocument(
  title: string,
  workspace: WorkspaceLatest,
  parentNodeId?: number
): Promise<HttpResult<number>> {
  return authedRequest<{ id: number }>(
    config.apis.statsV1,
    "documents",
    { title, workspace, parent_folder: parentNodeId },
    "POST"
  ).then((res) => {
    return res.map((res) => res.id);
  });
}
