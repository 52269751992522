import { useCallback, useEffect, useState, useContext, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import { UserInfoContext } from "../../../../../lib/application/contexts";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { CardContainer } from "../CardContainer";
import {
  PageBreakAfter,
  PageBreakBefore,
} from "../../../../../components/print/page_breaks";
import { useRecoilState } from "recoil";
import { textCardCKQuery } from "../../../../../lib/application/state/stats/document-core/queries/textCard";
import {
  TabbedCardMain,
  TabbedCardTab,
  TabbedCardTabsContainer,
} from "../../../../../components/Card";
import { TextCardTab } from "../card_tabs/TextCardTab";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { DocCardTextCK } from "../../../../../lib/application/state/stats/document-core/core";
import {
  config,
  auth0AuthorizationParams,
  timeSinceStartup,
} from "../../../../../config";
import { authState } from "../../../../../lib/application/state/AuthState";
import { defined } from "../../../../../lib/core/defined";

import "./TextCardCK.scss";
import { Milliseconds } from "../../../../../lib/core/time";
import {
  ckEditorConfig,
  Editor,
  readOnlyCkEditorConfig,
} from "../../../../../lib/application/editor";

interface Props {
  cardId: string;
  documentId?: number;
  removeCard: (cardId: string) => void;
  onDuplicateCard: (cardId: string) => void;
  sharingInfo: ISharingInfo;
}

export function TextCardCK(props: Props) {
  const { removeCard, cardId, documentId } = props;

  const userInfo = useContext(UserInfoContext);

  const [configured, setConfigured] = useState(false);
  const [isRemovingCard, setIsRemovingCard] = useState(false);
  const [card, setCard] = useRecoilState(
    textCardCKQuery({ cardStateId: cardId })
  );
  // Keep track of the previous content to avoid unnecessary saves
  const prevCardContentRef = useRef<string | null>(null);

  const { isEditingCard, hasEditDocumentAccess } = useCardEditMode(
    cardId,
    props.sharingInfo
  );

  const saveCard = useSaveCard();

  const handleUpdateCardState = useCallback(
    (value: string) => {
      // Do not update card if content has not been changed
      if (prevCardContentRef.current === value) {
        return;
      }
      prevCardContentRef.current = card.data;
      const updatedCard: DocCardTextCK = { ...card, data: value };
      setCard(updatedCard);
    },
    [card, setCard]
  );

  // Cleanup
  useEffect(() => {
    if (!isEditingCard) {
      return;
    }
    // Do not save card if content has not been changed
    if (
      prevCardContentRef.current === null ||
      prevCardContentRef.current === card.data
    ) {
      return;
    }
    const handle = setTimeout(() => {
      // Avoid recording that a save was postponed if the app just started. Text
      // cards will trigger saving on startup, which is hard to avoid.
      const disableRecordPostponedSave =
        timeSinceStartup() < Milliseconds.minute * 5;
      saveCard?.(card, disableRecordPostponedSave);
    }, 350);
    return () => {
      clearTimeout(handle);
    };
  }, [card, isEditingCard, saveCard]);

  const removeCardWithDelay = useCallback(() => {
    setIsRemovingCard(true);
    setTimeout(() => {
      removeCard(cardId);
    }, 200);
  }, [cardId, removeCard]);

  const tabName = "text";

  useEffect(() => {
    // If no doc ID is available we can't configure the editor for upload.
    // This is the case for packaged documents, which are read-only.
    if (!defined(documentId)) {
      setConfigured(true);
      return;
    }

    async function configureCkEditorWithAuth() {
      if (configured || !defined(userInfo)) {
        return;
      }
      const token = await authState.silentTokenGetterOrThrow()({
        authorizationParams: auth0AuthorizationParams,
      });

      if (ckEditorConfig.simpleUpload) {
        const baseUrl = config.apis.statsV2.url.replace(/\/$/, "");
        ckEditorConfig.simpleUpload.uploadUrl = `${baseUrl}/documents/${documentId}/images`;
        ckEditorConfig.simpleUpload.headers ??= {};
        ckEditorConfig.simpleUpload.headers.Authorization = `Bearer ${token}`;
        ckEditorConfig.simpleUpload.withCredentials = false;

        readOnlyCkEditorConfig.simpleUpload = ckEditorConfig.simpleUpload;
      }

      if (
        ckEditorConfig.toolbar &&
        !(ckEditorConfig.toolbar as any).items.includes("htmlEmbed") &&
        userInfo.internalCanManageEmbedCards()
      ) {
        (ckEditorConfig.toolbar as any).items.push("htmlEmbed");
      }

      setConfigured(true);
    }
    configureCkEditorWithAuth();
  }, [documentId, configured, userInfo]);

  if (!configured) {
    return <></>;
  }

  return (
    <CardContainer
      className="text-card-ck"
      cardId={props.cardId}
      isRemovingCard={isRemovingCard}
      removeBrokenCard={props.removeCard}
      sharingInfo={props.sharingInfo}
    >
      <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
      <TabbedCardTabsContainer currentTab={tabName}>
        {hasEditDocumentAccess ? (
          <TabbedCardTab key="primary" name={tabName} onSelect={voidFunc}>
            <TextCardTab
              sharingInfo={props.sharingInfo}
              cardId={card.id}
              handleDuplicateCard={() => props.onDuplicateCard(cardId)}
              handleRemoveCard={removeCardWithDelay}
            ></TextCardTab>
          </TabbedCardTab>
        ) : (
          <></>
        )}
      </TabbedCardTabsContainer>
      <TabbedCardMain name={tabName}>
        <>
          {isEditingCard ? (
            <CKEditor
              key="editable"
              editor={Editor}
              config={ckEditorConfig}
              data={card.data}
              onChange={(event, editor) => {
                const editorData = editor.getData();
                handleUpdateCardState(editorData);
              }}
              onBlur={(event, editor) => {
                const editorData = editor.getData();
                handleUpdateCardState(editorData);
              }}
            />
          ) : (
            <CKEditor
              key="readonly"
              editor={Editor}
              config={readOnlyCkEditorConfig}
              data={card.data}
              onReady={(editor) => {
                editor.enableReadOnlyMode("global");
              }}
            />
          )}
        </>
      </TabbedCardMain>
      <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
    </CardContainer>
  );
}
