import { useContext, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { logger } from "../../../../infra/logging";
import { AppMessagesHandler } from "../../../AppMessagesHandler";
import { useSetAllCardsCallback, useSetCardCallback } from "../cardCallbacks";
import { reportMetaStateQuery } from "../../stats/document-meta/queries";
import { LoadingResult } from "../../../loading/LoadingResult";
import { HttpError, HttpResult } from "../../../../infra/HttpResult";
import { useLoadableHttpResource } from "../../../hooks/useLoadableResource";
import { Progress } from "../../../../core/progress";
import { defined } from "../../../../core/defined";
import {
  GeographiesContext,
  ShowDraftDataContext,
  UserInfoContext,
} from "../../../contexts";
import { cardWithUpdatedDataState } from "../../stats/cardToDataStateStats";
import { DocCardState, cloneCard } from "../../stats/document-core/core";
import { cardWithUpdatedDataStateMicro } from "../../stats/cardToDataStateMicro";
import { fetchGeoTreeWithCache } from "../../../../../views/stats/docs/cards/micro/useGeoTree";
import {
  useAddGeoMicroLinks,
  useSetAllGeoMicroStyles,
} from "../micro/geoColors";
import { config } from "../../../../../config";
import { ReportMetaPersistentSharing } from "../../stats/document-meta/definitions";
import {
  CardStyleContainerGeoMicroLink,
  ColorSchemeContainer,
  StyleContainerGeoMicro,
} from "../../stats/document-style/definitions";
import { UserInfo } from "../../../auth/UserInfo";
import { Milliseconds } from "../../../../core/time";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

export type GeneratedCard = {
  card: DocCardState;
  colorScheme?: ColorSchemeContainer;
  styleContainersGeoMicro?: StyleContainerGeoMicro[];
};

export type DocMetadataState = ReportMetaPersistentSharing & {
  userHasApplicableWriteLicenses: boolean;
  editModeAvailable: boolean;
  id: number;
  title: string;
};

export function useLoadDocumentGeneric<T>(
  resourceGetter: () => Promise<HttpResult<T>>,
  getDocumentMetadata: (doc: T, userInfo?: UserInfo) => DocMetadataState,
  getUninitializedCards: (doc: T) => DocCardState[],
  getCardGenerator: (doc: T) => Generator<Promise<GeneratedCard>, void>,
  appMessagesHandler: AppMessagesHandler | undefined
): LoadingResult<HttpError, T> {
  const geographies = useContext(GeographiesContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const userInfo = useContext(UserInfoContext);

  const setReportMetaState = useSetRecoilState(reportMetaStateQuery);
  const setAllCards = useSetAllCardsCallback();
  const setSingleCard = useSetCardCallback();
  const setAllGeoMicroStyles = useSetAllGeoMicroStyles();
  const addGeoMicroLinks = useAddGeoMicroLinks();

  const appearanceSettings = useExtendedAppearanceSettings();

  const [docDtoLoadStatus] = useLoadableHttpResource(resourceGetter);
  const [documentLoadStatus, setDocumentLoadProgress] = useState<
    LoadingResult<HttpError, T>
  >(LoadingResult.notStarted());

  useEffect(() => {
    if (!defined(geographies)) {
      return;
    }

    // If appMode is not embedded, user info must be defined for loading to run
    if (config.appMode === "infostat" && !defined(userInfo)) {
      return;
    }

    docDtoLoadStatus.match({
      notReady: (param) => {
        if (param.type === Progress.Error) {
          logger.error("Failed to load workspace", param.err.code);
          setDocumentLoadProgress(LoadingResult.err(param.err));
        }
      },
      ready: async (document) => {
        setDocumentLoadProgress(LoadingResult.inProgress());
        const reportMeta = getDocumentMetadata(document, userInfo);
        // In embedded mode mode we don't have an authenticated user,
        // and we don't rely on editModeOn
        if (config.appMode !== "embedded") {
          if (!defined(userInfo)) {
            logger.error("userInfo not defined");
            reportMeta.editModeOn = false;
          } else {
            const editModeAvailable = reportMeta.editModeAvailable;
            reportMeta.editModeOn = reportMeta.editModeOn && editModeAvailable;
          }
        }

        setReportMetaState({
          ...reportMeta,
          noFramesMode: reportMeta.noFramesMode ?? false,
        });

        setDocumentLoadProgress(LoadingResult.from(document));

        const uninitializedCards = getUninitializedCards(document);
        // Initialize cards as loading
        setAllCards(uninitializedCards.map(cloneCard));

        const cardGenerator = getCardGenerator(document);
        let cardResult;
        while ((cardResult = cardGenerator.next()) && !cardResult.done) {
          try {
            const cardContainer = await cardResult.value;
            const card = cardContainer.card;

            if (card.type === "dataCard") {
              const colorSchemeContainer = cardContainer.colorScheme;
              const updatedCard = await cardWithUpdatedDataState(
                () => false,
                card,
                geographies,
                adminShowDraftData,
                appearanceSettings,
                colorSchemeContainer
              );
              if (!defined(updatedCard)) {
                logger.error(
                  "Failed to update stats/survey card",
                  cardContainer.card
                );
                continue;
              }
              if (updatedCard.initState !== Progress.Success) {
                throw new Error(
                  "Expected updatedCard.initState === Progress.Success"
                );
              }

              // If there are colors for this card, and if the current selected view is not diagram,
              // then instantiate chart data in addition to other data, in order to keep user's color
              // settings
              if (
                defined(colorSchemeContainer) &&
                card.data.selectedView !== "diagram"
              ) {
                const updatedCard2 = await cardWithUpdatedDataState(
                  () => false,
                  {
                    ...updatedCard,
                    data: { ...updatedCard.data, selectedView: "diagram" },
                  },
                  geographies,
                  adminShowDraftData,
                  appearanceSettings,
                  colorSchemeContainer
                );

                if (defined(updatedCard2)) {
                  if (updatedCard2.initState !== Progress.Success) {
                    throw new Error(
                      "Expected updatedCard.initState === Progress.Success"
                    );
                  }
                  // Reset the selectedView to the actual selected view
                  updatedCard2.data.selectedView = card.data.selectedView;
                  card.data = updatedCard2?.data;
                }
              }
              card.data = updatedCard?.data;
            } else if (card.type === "microCard") {
              const microStyles = cardContainer.styleContainersGeoMicro;
              if (defined(microStyles)) {
                setAllGeoMicroStyles(microStyles);
                const links: CardStyleContainerGeoMicroLink[] = microStyles.map(
                  (s) => ({ cardId: card.id, styleContainerId: s.id })
                );
                addGeoMicroLinks(links);
              }

              const colorSchemeContainer = cardContainer.colorScheme;
              const geoTree = await fetchGeoTreeWithCache();
              const updatedCard = await cardWithUpdatedDataStateMicro(
                () => false,
                card,
                geoTree,
                adminShowDraftData,
                appearanceSettings,
                colorSchemeContainer
              );
              if (!defined(updatedCard)) {
                logger.warn("Failed to update micro card", card);
                setSingleCard(card);
                continue;
              }
              card.data = updatedCard?.data;
            } else {
              // All other cards have no data to initialize,
              // we just set the card as-is and move on
            }

            setSingleCard(card);
          } catch (e) {
            appMessagesHandler?.add(
              "error",
              "Misslyckades med att ladda kort! Försök igen senare.",
              { durationMs: Milliseconds.minute }
            );
            logger.error("Failed to instantiate card", e);
          }
        }
      },
    });
  }, [
    adminShowDraftData,
    appMessagesHandler,
    docDtoLoadStatus,
    geographies,
    setAllCards,
    setReportMetaState,
    setAllGeoMicroStyles,
    userInfo,
    getDocumentMetadata,
    getUninitializedCards,
    getCardGenerator,
    setSingleCard,
    addGeoMicroLinks,
    appearanceSettings,
  ]);

  return documentLoadStatus;
}
