import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  TabbedCardTabsContainer,
  TabbedCardTab,
  TabbedCardMain,
} from "../../../../../components/Card";
import {
  PageBreakAfter,
  PageBreakBefore,
} from "../../../../../components/print/page_breaks";
import { Quill, QuillReadonly } from "../../../../../components/Quill";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { useUpdateHtmlThumbnail } from "../../../../../lib/application/hooks/thumbnails";
import { QuillDocState } from "../../../../../lib/application/state/stats/document-core/core";
import {
  textCardQuery,
  textCardContentQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/textCard";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";
import { defined } from "../../../../../lib/core/defined";
import { voidFunc } from "../../../../../lib/core/voidFunc";

import { CardContainer } from "../CardContainer";
import { TextCardTab } from "../card_tabs/TextCardTab";

import "./TextCard.scss";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { timeSinceStartup } from "../../../../../config";
import { Milliseconds } from "../../../../../lib/core/time";

interface Props {
  cardId: string;
  removeCard: (cardId: string) => void;
  onDuplicateCard: (cardId: string) => void;
  sharingInfo: ISharingInfo;
}
export function TextCard(props: Props) {
  const { cardId, removeCard } = props;
  const card = useRecoilValue(textCardQuery({ cardStateId: cardId }));

  const saveUpdates = useSaveCard();

  const { isEditingCard, hasEditDocumentAccess } = useCardEditMode(
    cardId,
    props.sharingInfo
  );

  const quillRef = useRef<ReactQuill>(null);
  const editorRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  useEffect(() => {
    const editingArea = quillRef.current?.getEditingArea();
    if (defined(editingArea)) {
      editorRef.current = editingArea as HTMLDivElement;
    }
  });

  useUpdateHtmlThumbnail(editorRef, props.cardId);

  const [content, setContent] = useRecoilState(
    textCardContentQuery({ cardStateId: card.id })
  );

  const [isRemovingCard, setIsRemovingCard] = useState(false);

  const { data, label } = card;
  useEffect(() => {
    if (hasEditDocumentAccess) {
      // Avoid recording that a save was postponed if the app just started. Text
      // cards will trigger saving on startup, which is hard to avoid.
      const disableRecordPostponedSave =
        timeSinceStartup() < Milliseconds.minute * 5;
      saveUpdates?.(card, disableRecordPostponedSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEditDocumentAccess, card, data, isEditingCard, label]);

  const removeCardWithDelay = useCallback(() => {
    setIsRemovingCard(true);
    setTimeout(() => {
      removeCard(cardId);
    }, 200);
  }, [cardId, removeCard]);

  const handleEditorChange = useCallback(
    (content: QuillDocState) => {
      setContent(content);
    },
    [setContent]
  );

  const tabName = "text";

  return (
    <CardContainer
      className="text-card"
      isRemovingCard={isRemovingCard}
      removeBrokenCard={props.removeCard}
      cardId={card.id}
      sharingInfo={props.sharingInfo}
    >
      <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
      <TabbedCardTabsContainer currentTab={tabName}>
        {hasEditDocumentAccess ? (
          <TabbedCardTab key="primary" name={tabName} onSelect={voidFunc}>
            <TextCardTab
              sharingInfo={props.sharingInfo}
              cardId={card.id}
              handleDuplicateCard={() => props.onDuplicateCard(cardId)}
              handleRemoveCard={removeCardWithDelay}
            ></TextCardTab>
          </TabbedCardTab>
        ) : (
          <></>
        )}
      </TabbedCardTabsContainer>
      <TabbedCardMain name={tabName}>
        <>
          {isEditingCard ? (
            <Quill
              quillRef={quillRef}
              key={card.id + "_edit"}
              initialValue={content}
              className="editor-container"
              onChange={handleEditorChange}
            ></Quill>
          ) : (
            <QuillReadonly
              quillRef={quillRef}
              key={card.id + "_read-only"}
              className="editor-container read-only"
              content={content}
            ></QuillReadonly>
          )}
        </>
      </TabbedCardMain>
      <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
    </CardContainer>
  );
}
