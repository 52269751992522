import { defined } from "../../../core/defined";
import {
  ChartDimensions,
  Dimension,
  GOLDEN_RATIO,
  LabelAreas,
  LegendPositioning,
  MainChartDimensions,
  Orientation,
} from "./core/definitions";
import { ChartDataUnknown } from "./chart_data/ChartData";
import { TextRowSet } from "./core/text_containers";
import {
  CommonHeaderData,
  GroupHeaderData,
  MainHeaderData,
  MicroHeaderData,
  SurveyMainHeaderData,
} from "../datasets/headers";
import { XTicksContainer } from "./core/ticks";
import { DataSourceInfo, LabelsByDimension } from "../datasets/DatasetGeneric";
import { getSpecialColorSchemeKeyByLabels } from "./core/colors/colorSchemes";
import { nonEmptyString } from "../../../core/nonEmptyString";
import { SurveyStringDatasetDto } from "../../../infra/api_responses/survey_dataset";
import { MeasureSurveyStringDto } from "../../../domain/measure/definitions";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";

const CHART_TOP_PADDING = 10;
export const APPROX_MAX_BAR_WIDTH = 40;
export const RANGE_TICKS_SIZE_PIXELS = 10;
export const RANGE_TICKS_PADDING_PIXELS = 5;
export const DEFAULT_MARGIN_LEFT = 40;

const DEFAULT_SURVEY_METHOD_DESCRIPTION =
  "Undersökningen har gjorts av Infostat. Intervjuerna har genomförts i en slumpmässigt rekryterad webbpanel. Urvalet har kvoterats med avseende på kön, ålder och geografi. Resultatet har därefter vägts på kön, ålder, utbildning och politiskt parti för att korrigera för eventuella kvarvarande skevheter i urvalet.";
const DEFAULT_SURVEY_TARGET_DESCRIPTION = "Allmänheten";

/**
 * Max chart width excluding side legend
 */
export const SVG_CHART_MAX_WIDTH = 600;
/**
 * Min chart width excluding side legend
 */
export const SVG_CHART_MIN_WIDTH = 300;

/**
 * Get standard width for a chart which is wider than it is tall
 */
export function getStandardChartInnerWidth(
  vizAreaWidth: number,
  windowHeight: number
) {
  // For small window heights, we decrease the width of the chart to make an overall smaller chart
  // since there is a fixed relation between width and height for standard charts
  const maxDynamicWidth = windowHeight < 900 ? 450 : 600;
  const minWidthAllScreens = 150;
  const minLeftRightMargins = 140;

  // Chart inner width cannot exceed the width of the visualization area
  // minus min space of margins
  const chartInnerWidthBounded = Math.min(
    vizAreaWidth - minLeftRightMargins,
    Math.floor(vizAreaWidth * 0.7)
  );

  // Chart inner width must not exceed the max width
  const tentativeWidth = Math.min(maxDynamicWidth, chartInnerWidthBounded);

  // Never shrink below a certain threshold
  return Math.max(tentativeWidth, minWidthAllScreens);
}

export function calculateNumberOfBars(
  labelsByDimension: LabelsByDimension
): number {
  return labelsByDimension
    .map(([_dimension, labelsSorted]) => labelsSorted.length)
    .reduce((prev, current) => prev * current, 1);
}

/**
 * Process text description containing return characters and output
 * list of strings
 */
export function processLongDescription(input?: string): string[] {
  const inputTrimmed = input?.trim();
  if (!defined(inputTrimmed) || !nonEmptyString(inputTrimmed)) {
    return [];
  }
  return inputTrimmed.split(/[\r\n]/);
}

export function getSourceInfoSurveyHeader(
  data: SurveyMainHeaderData
): DataSourceInfo {
  return {
    aggregationNote: data.aggregationNote,
    methodDescription:
      data.methodDescription ?? DEFAULT_SURVEY_METHOD_DESCRIPTION,
    surveyTargetAudience:
      data.targetGroupInfoPublic ?? DEFAULT_SURVEY_TARGET_DESCRIPTION,
    sourceLong: "Undersökning genomförd av Infostat",
    ...getSourceInfoCommonHeader(data),
  };
}

export function getSourceInfoSurveyString(
  measure: MeasureSurveyStringDto,
  header: SurveyStringDatasetDto["header"]
): DataSourceInfo {
  return {
    methodDescription:
      header.survey_info.method_info_public ??
      DEFAULT_SURVEY_METHOD_DESCRIPTION,
    surveyTargetAudience:
      header.survey_info.target_group_info_public ??
      DEFAULT_SURVEY_TARGET_DESCRIPTION,
    source: header.source,
    sourceLong: "Undersökning genomförd av Infostat",
    descriptionLong: processLongDescription(header.descr_long),
  };
}

export function getSourceInfoMainHeader(data: MainHeaderData): DataSourceInfo {
  return {
    aggregationNote: data.aggregationNote,
    aggregationMethod: data.aggregationMethod,
    ...getSourceInfoCommonHeader(data),
  };
}

export function getSourceInfoGroupingHeader(
  data: GroupHeaderData
): DataSourceInfo {
  return {
    classBoundaries: data.classBoundaries,
    classBoundariesNumeric: data.classBoundariesNumeric,
    groupingMunicipalities: data.groupingMunicipalities,
    ...getSourceInfoCommonHeader(data),
  };
}

export function getSourceInfoMicro(data: MicroHeaderData): DataSourceInfo {
  return {
    unitLabel: data.unitLabel,
    measureTitle: data.descriptionLong,
    source: data.source,
    descriptionLong: processLongDescription(data.descriptionLong),
  };
}

export function getSourceInfoCommonHeader(
  data: CommonHeaderData
): DataSourceInfo {
  return {
    unitLabel: data.unitLabel,
    measureTitle: data.measureTitle,
    source: data.source,
    lastUpdate: data.lastUpdate,
    descriptionLong: processLongDescription(data.descriptionLong),
    sourceUrl: data.sourceUrl,
    externalSource: data.externalSource,
    externalDescription: data.externalDescription,
    externalDescriptionLong: processLongDescription(
      data.externalDescriptionLong
    ),
  };
}

export interface ChartDimensionStep1 {
  innerChartWidth: number;
  innerChartHeight: number;
  marginTop: number;
  sourceHeight: number;
  /**
   * Lower bound for the full height of chart area (including labels)
   */
  fullHeightLowerBound: number;
  /**
   * Lower bound for the bottom margin of chart area (including labels)
   */
  minMarginBottom: number;

  /**
   * Height of the first title line. Used to shift text
   */
  firstTitleLineHeight: number;
}

/**
 * For: line charts, standing bar charts
 *
 * Calculate dimensions that can be determined at an early stage
 * before all inputs required for the final calculation are available
 *
 * In particular, this is useful because the final dimensions depend on
 * label positioning, which in turn depends on chart size, so we have to get
 * around the circular dependency.
 */
export function calcChartDimensionsStep1(
  chartInnerWidth: number,
  chartData: ChartDataUnknown,
  settings: DataOutputSettings
): { dimensions: ChartDimensionStep1; titleRows: TextRowSet } {
  const innerChartWidth = chartInnerWidth;
  const innerChartHeight = Math.round(innerChartWidth / GOLDEN_RATIO);
  const sourceRowSet: TextRowSet = chartData.source(
    innerChartHeight,
    settings.customSourceTextSize
  );
  const sourceHeight = sourceRowSet.totalHeight;
  const isNarrowChartWidth = innerChartWidth < 300;
  const titleRows = chartData.titleRows(
    // Use available right margin space when title space would otherwise be very narrow
    innerChartWidth + (isNarrowChartWidth ? sourceHeight : 0),
    settings
  );

  function calcMarginTop(): number {
    return titleRows.totalHeight + CHART_TOP_PADDING;
  }
  const marginTop = Math.ceil(calcMarginTop());

  return {
    titleRows,
    dimensions: {
      innerChartHeight,
      innerChartWidth,
      firstTitleLineHeight: titleRows.offsetRows[0].rowHeightWithPadding ?? 0,
      marginTop,
      sourceHeight: sourceHeight,
      minMarginBottom: sourceHeight,
      fullHeightLowerBound: innerChartHeight + marginTop + sourceHeight,
    },
  };
}

/**
 * For: line charts, standing bar charts
 *
 * Calculate chart dimensions for a chart where:
 * 1) the inner chart area is fixed
 * 2) other lengths are fully variable and
 * 3) range is y axis, domain x axis
 */
export function calcChartDimensionsStep2(
  dimensionsStep1: ChartDimensionStep1,
  legendPaddingLeft: number,
  labelAreas: LabelAreas,
  sourceRowsSet: TextRowSet,
  legend: LegendPositioning | undefined,
  marginLeft: number
): ChartDimensions {
  const MIN_MARGIN_BOTTOM = 10;
  const MIN_MARGIN_RIGHT = 40;
  const BOTTOM_LABELS_MARGIN = 15;

  const { innerChartHeight, innerChartWidth, marginTop } = dimensionsStep1;

  const sideLegendWidth =
    defined(legend) && legend.orientation === Orientation.vertical
      ? legend.width
      : 0;
  function calcMarginRight(): number {
    // Use totalHeight of sourceRowsSet since we're going to rotate 90 degrees
    return Math.ceil(
      Math.max(
        sideLegendWidth + legendPaddingLeft + sourceRowsSet.totalHeight,
        MIN_MARGIN_RIGHT
      )
    );
  }

  function calcMarginBottom(): number {
    const legendHeight =
      legend?.orientation === Orientation.horizontal ? legend.height : 0;

    const labelsBottom = labelAreas.labelsBottom;
    const rowsHeight =
      (labelsBottom?.heightWithoutLegend ?? 0) + BOTTOM_LABELS_MARGIN;
    return Math.ceil(legendHeight + Math.max(rowsHeight, MIN_MARGIN_BOTTOM));
  }

  const marginRight = calcMarginRight();
  const marginBottom = calcMarginBottom();
  const fullWidth = innerChartWidth + marginRight + marginLeft;
  const fullHeight = innerChartHeight + marginTop + marginBottom;

  const dims = {
    fullWidth,
    fullHeight,
    boundedWidth: innerChartWidth,
    boundedHeight: innerChartHeight,
    marginTop: marginTop,
    marginRight: marginRight,
    marginBottom: marginBottom,
    marginLeft: marginLeft,
  };

  const legendDims = {
    position: {
      x:
        dims.marginLeft +
        dims.boundedWidth +
        legendPaddingLeft +
        sourceRowsSet.totalHeight,
      y: marginTop,
    },
    width: sideLegendWidth + legendPaddingLeft,
    height: dims.fullHeight,
  };

  const titleDims = {
    position: {
      x: dims.marginLeft,
      y: 0,
    },
    width: dims.boundedWidth,
    height: dims.marginTop,
  };

  const source = calculateSourcePositioning(sourceRowsSet, dims);

  return {
    main: dims,
    legend: legendDims,
    title: titleDims,
    source,
  };
}

const MIN_MARGIN_LEFT = 40;
const MIN_MARGIN_RIGHT = 60;
const MIN_MARGIN_BOTTOM = 40;
interface HorizontalDimensions {
  fullWidth: number;
  boundedWidth: number;
  marginRight: number;
}

export function calculateBarChartHorizontalWidths(
  svgWidth: number,
  chartSource: TextRowSet,
  leftLabelsTotalWidth: number
): HorizontalDimensions {
  function calcMarginLeft() {
    return Math.max(leftLabelsTotalWidth, MIN_MARGIN_LEFT) + 10; // FIXME: remove +10!
  }

  const marginLeft = calcMarginLeft();
  const marginRight = Math.max(MIN_MARGIN_RIGHT, chartSource.totalHeight);
  const boundedWidth = svgWidth - marginLeft - marginRight;

  return {
    fullWidth: svgWidth,
    boundedWidth,
    marginRight,
  };
}

export function calculateBarChartHorizontalDimensions(
  widths: HorizontalDimensions,
  labelsLeftTotalWidth: number,
  chartInnerHeight: number,
  titleRowSet: TextRowSet,
  sourceRowSet: TextRowSet,
  xTicks: XTicksContainer,
  useTopTicksAxis: boolean,
  legend?: LegendPositioning
): ChartDimensions {
  const boundedHeight = chartInnerHeight;

  function calcMarginTop(): number {
    const topTicksHeight = useTopTicksAxis ? xTicks.totalHeight : 0;

    return titleRowSet.totalHeight + topTicksHeight + CHART_TOP_PADDING;
  }

  function calcMarginBottom(): number {
    const legendHeight =
      legend?.orientation === Orientation.horizontal ? legend.height : 0;

    return legendHeight + Math.max(xTicks.totalHeight, MIN_MARGIN_BOTTOM);
  }

  const marginBottom = calcMarginBottom();
  const marginTop = calcMarginTop();
  const { marginRight, boundedWidth } = widths;
  const fullHeight = boundedHeight + marginTop + marginBottom;

  const legendDims = {
    position: {
      x: labelsLeftTotalWidth + boundedWidth,
      y: 0,
    },
    width: marginRight,
    height: legend?.orientation === Orientation.horizontal ? legend.height : 0,
  };

  const titleDims = {
    position: {
      x: labelsLeftTotalWidth,
      y: 0,
    },
    width: boundedWidth,
    height: marginTop,
  };

  const main = {
    ...widths,
    marginLeft: labelsLeftTotalWidth,
    fullHeight,
    boundedHeight,
    marginTop: marginTop,
    marginBottom: marginBottom,
  };

  const source = calculateSourcePositioning(sourceRowSet, main);
  return {
    title: titleDims,
    legend: legendDims,
    source,
    main,
  };
}

function calculateSourcePositioning(
  sourceRowSet: TextRowSet,
  mainDimensions: MainChartDimensions
) {
  const sourceWidth = sourceRowSet.width;
  const sourceHeight = sourceRowSet.totalHeight;
  const { marginLeft, marginTop, boundedWidth, boundedHeight } = mainDimensions;
  return {
    height: sourceHeight,
    width: sourceWidth,
    position: {
      x: marginLeft + boundedWidth + (sourceRowSet.first?.totalHeight ?? 0),
      y: marginTop + boundedHeight,
    },
  };
}

export function getSpecialColorSchemeKeyByDimensionAndLabels(
  dimension: string,
  labels: string[]
): string | undefined {
  if (
    [
      Dimension.subquestion,
      Dimension.subquestion1,
      Dimension.subquestion2,
      Dimension.subquestion3,
      Dimension.value,
      Dimension.grouping,
    ].includes(dimension as Dimension)
  ) {
    return getSpecialColorSchemeKeyByLabels(labels);
  }
}
