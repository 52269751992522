import {
  Array as ArrayRT,
  Record,
  Boolean as BooleanRT,
  Static,
  Dictionary,
  Number as NumberRT,
  String as StringRT,
  Optional,
  Union,
  Literal,
  Null,
  Partial,
  Tuple,
} from "runtypes";

const SortOrderRT = Union(Literal("ascending"), Literal("descending"));
const forecastSettingsRT = Union(
  Record({
    show: Literal(true),
    showUncertaintyInterval: BooleanRT,
    until: StringRT,
  }),
  Record({ show: Literal(false) })
);
export type ForecastSettings = Static<typeof forecastSettingsRT>;
export const ComputedVariableOperatorRT = Union(
  Literal("+"),
  Literal("-"),
  Literal("*"),
  Literal("/"),
  Literal("%")
);
export type ComputedVariableOperator = Static<
  typeof ComputedVariableOperatorRT
>;

const OperandV3RT = Union(
  Record({
    type: Literal("dimension_value_with_id"),
    id: NumberRT,
  }),
  Record({
    type: Literal("user_defined_value"),
    label: StringRT,
  }),
  Record({
    type: Literal("region"),
    value: StringRT,
    label: StringRT,
  }),
  Record({
    type: Literal("grouping"),
    label: StringRT,
  }),
  Record({
    type: Literal("constant"),
    value: NumberRT,
  })
);

export type OperandV3 = Static<typeof OperandV3RT>;

const ComputedVariableV3RT = Record({
  version: Literal("3"),
  label: StringRT,
  operator: ComputedVariableOperatorRT,
  dimension: StringRT,
  leftOperand: ArrayRT(OperandV3RT),
  rightOperand: ArrayRT(OperandV3RT),
});
const ComputedVariableV2RT = Record({
  version: Literal("2"),
  label: StringRT,
  operator: ComputedVariableOperatorRT,
  // The operands are arrays of dimension value pointers. For each of these values, the numeric value will be looked up and summed before
  // applying the operator to left and right operands.
  // To make a computation over a tree dimension, the setup will be like:
  // Dim: {breakdown1: "Kultur och fritid", breakdown2: "Bibliotek"}
  // ----
  // dimensions: ["breakdown1", "breakdown2"]
  // leftOperand: [["Kultur och fritid", "Bibliotek"]]
  // operator: "+"
  // rightOperand: [["Kultur och fritid", "Fritidsgårdar"]]
  dimensions: ArrayRT(StringRT),
  leftOperand: ArrayRT(ArrayRT(StringRT).Or(NumberRT)),
  rightOperand: ArrayRT(ArrayRT(StringRT).Or(NumberRT)),
});
const ComputedVariableV1RT = Record({
  version: Literal("1"),
  label: StringRT,
  dimension: StringRT,
  // The operands are arrays of dimension values. For each of these values, the numeric value will be looked up and summed before
  // applying the operator to left and right operands.
  leftOperand: ArrayRT(StringRT.Or(NumberRT)),
  rightOperand: ArrayRT(StringRT.Or(NumberRT)),
  operator: ComputedVariableOperatorRT,
});

export type ComputedVariableV1 = Static<typeof ComputedVariableV1RT>;
export type ComputedVariableV2 = Static<typeof ComputedVariableV2RT>;
export type ComputedVariableV3 = Static<typeof ComputedVariableV3RT>;

export const COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY =
  "same_as_display_precision";
export const COMPUTATION_INPUT_ROUNDING_NONE = "none";

export const CUSTOM_LINE_CHART_LABELS_KEY = "_lineChart";
export const DataOutputSettingsPartialRT = Record({
  showLabels: BooleanRT,
  startFromZero: BooleanRT,
  showLineCircles: BooleanRT,
  showFatLines: BooleanRT,
  lineChartShowLastLabel: Optional(BooleanRT), // default to false
  lineChartShowLastLabelAlignLeft: Optional(BooleanRT), // default to false
  lineChartAccentuateLastDatum: Optional(BooleanRT), // default to false
  tablePaddingSides: Optional(StringRT.Or(Null)),

  showTicksYAxis: Optional(BooleanRT), // default to true
  showTicksXAxis: Optional(BooleanRT), // default to true
  showYAxis: Optional(BooleanRT), // default to true
  showXAxis: Optional(BooleanRT), // default to true
  gridLinesXStyle: Optional(StringRT), // dashed array format
  gridLinesYStyle: Optional(StringRT), // dashed array format

  fixedNumDecimals: Optional(NumberRT.Or(Null)),
  showReferenceLines: Optional(BooleanRT), // default to false
  hideChartTitleSection: Optional(BooleanRT), // default to false
  hideLegendDimensionLabels: Optional(BooleanRT), // default to false
  showSurveyValueFraction: Optional(BooleanRT), // default to false
  fixedDimensionOrder: Optional(ArrayRT(StringRT).Or(Null)),
  /** Custom chart/table/output description */
  customSourceText: Optional(StringRT.Or(Null)),
  customUnitText: Optional(StringRT.Or(Null)),
  customTitle: Optional(StringRT.Or(Null)),
  customSubtitles: Optional(ArrayRT(StringRT).Or(Null)),
  customMainHeaderSize: Optional(NumberRT.Or(Null)),
  customSubHeaderSmallSize: Optional(NumberRT.Or(Null)),
  customSubHeaderLargeSize: Optional(NumberRT.Or(Null)),
  customUnitSize: Optional(NumberRT.Or(Null)),
  customSourceTextSize: Optional(NumberRT.Or(Null)),
  customLabels: Optional(
    Dictionary(Dictionary(StringRT, StringRT), StringRT).Or(Null)
  ),

  customYAxisRange: Optional(Tuple(NumberRT, NumberRT).Or(Null)),

  forecast: Optional(forecastSettingsRT),
  /**
   * @deprecated
   * Computed variables give the user the ability to add their own variables to a dataset,
   * based on actual dataset variables.
   */
  computedVariables: Optional(
    ArrayRT(Union(ComputedVariableV1RT, ComputedVariableV2RT))
  ),
  computedVariablesV3: Optional(ArrayRT(ComputedVariableV3RT)),
  computationInputRounding: Optional(
    Union(
      Literal(COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY),
      Literal(COMPUTATION_INPUT_ROUNDING_NONE)
    )
  ),
  computationOutputNumDecimals: Optional(NumberRT.Or(Null)),

  /**
   * Combinations of breakdown values that are hidden in charts/tables.
   * Can be used in combination with computedVariables to show only the computed variables and not the base variables.
   */
  hiddenBreakdownCombinations: Optional(
    ArrayRT(ArrayRT(Record({ dimension: StringRT, value: StringRT })))
  ),
  mapChart: Optional(
    Record({
      labelSize: Optional(NumberRT),
      mapSize: Optional(StringRT),
      colorScale: Optional(StringRT),
      scaleType: Optional(StringRT),
      manualBreakpoints: Optional(ArrayRT(NumberRT)),
      manualColorsForRanges: Optional(ArrayRT(StringRT)),
      manualColorsForCategories: Optional(Dictionary(StringRT, StringRT)),

      showAdministrativeBorders: BooleanRT, // default to true
      showOnlyFirstAndLastDate: BooleanRT, // default to false
    })
  ),
  chart: Optional(
    Record({
      labelSize: NumberRT,
    })
  ),
  table: Optional(
    Partial({
      showSumRow: BooleanRT, // default to false
      showMeanRow: BooleanRT, // default to false
      showMaxRow: BooleanRT, // default to false
      showMinRow: BooleanRT, // default to false
      showMedianRow: BooleanRT, // default to false
      columnSort: Optional(
        Record({
          type: Literal("primary"),
          order: SortOrderRT,
        }).Or(
          Record({
            type: Literal("secondary"),
            columnIndex: NumberRT,
            order: SortOrderRT,
          })
        )
      ),
    })
  ),
  tableSurveyString: Optional(
    Partial({
      sliceHeadAndTailRows: BooleanRT, // default to true
      showWeightColumn: BooleanRT, // default to false
      columnSort: Record({ column: StringRT, ascending: BooleanRT }), // default to null
    })
  ),
  // TODO: migrate settings so reference values for table and chart are handle as one setting
  chartMicro: Optional(
    Partial({
      showReferenceValuesOnly: BooleanRT, // default to false
      showMunicipalityReference: BooleanRT, // default to false
      showNuts3Reference: BooleanRT, // default to false
      showCountryReference: BooleanRT, // default to false
      showSelectedAreasAverage: BooleanRT, // default to false
    })
  ),
  tableMicro: Optional(
    Partial({
      showReferenceValuesOnly: BooleanRT, // default to false
      showMunicipalityReference: BooleanRT, // default to false
      showNuts3Reference: BooleanRT, // default to false
      showCountryReference: BooleanRT, // default to false
      showSelectedAreasAverage: BooleanRT, // default to false
    })
  ),
});

export type DataOutputSettingsPartial = Static<
  typeof DataOutputSettingsPartialRT
>;

export type DataOutputSettings = Required<DataOutputSettingsPartial>;

export type MapChartSettings = DataOutputSettings["mapChart"];
export type ChartSettings = DataOutputSettings["chart"];
export type DataTableSettings = DataOutputSettings["table"];
export type DataSurveyStringSettings = DataOutputSettings["tableSurveyString"];
export type DataTableMicroSettings = DataOutputSettings["tableMicro"];
export type ChartMicroSettings = DataOutputSettings["chartMicro"];
export type ComputedValueConfig = DataOutputSettings["computedVariables"][0];
export type hiddenBreakdownCombination =
  DataOutputSettings["hiddenBreakdownCombinations"][0];
